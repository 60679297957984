input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 400px #fff inset !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 400px #fff inset !important;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ant-message {
  z-index: 9999 !important;
}

.custom-popover {
  width: 208px !important;
}
