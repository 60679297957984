.tab___Abk4x {
  margin-bottom: 14px;
}
.tab___Abk4x .title___mZQi- {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.tab___Abk4x .active___izB5R,
.tab___Abk4x .titleContent___bgkVX:hover {
  color: #5187d5;
  font-weight: 500;
}
