.articial_box___1sNPE {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.articial_box___1sNPE .contrast___iVd0l {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 16px;
}
.articial_box___1sNPE .content_main___16Njb {
  margin-top: 0px;
}
.articial_box___1sNPE h2 {
  font-size: 42px;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.articial_box___1sNPE .change_contents___2-0Ff {
  padding: 0px 80px 20px 80px;
}
.articial_box___1sNPE .change_contents___2-0Ff .study_project___2m2oT {
  display: block;
  margin: 20px auto;
  width: 700px;
}
.articial_box___1sNPE .change_contents___2-0Ff span {
  font-weight: bold;
  color: black;
}
.articial_box___1sNPE .change_contents___2-0Ff li {
  line-height: 35px;
  margin-top: 20px;
  text-indent: 32px;
}
.articial_box___1sNPE .change_contents___2-0Ff .imgContrast___2QwfN {
  text-align: center;
  width: 850px;
  margin: 10px auto;
}
.articial_box___1sNPE .change_contents___2-0Ff .imgContrast___2QwfN .home_img_1___N_z1_ {
  width: 400px;
}
.articial_box___1sNPE .change_contents___2-0Ff .like_a___20Zdj {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.articial_box___1sNPE .change_contents___2-0Ff a {
  color: #303de2;
}
