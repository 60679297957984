.comp_box___3B65X {
  margin-bottom: 40px;
}
.comp_box___3B65X li {
  line-height: 35px;
}
.comp_box___3B65X .cycle_item___2mXSI {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.comp_box___3B65X .body_text___3v9_f {
  line-height: 35px;
}
