.ContentReviewResults___3ZpCZ {
  width: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContentReviewResults___3ZpCZ .dashboard-container___mNBzZ {
  margin: 20px;
}
.ContentReviewResults___3ZpCZ .dashboard-text___1IuVw {
  font-size: 30px;
  line-height: 46px;
}
.ContentReviewResults___3ZpCZ .handleBtn___ZxUCp {
  padding: 0 10px;
  cursor: pointer;
  float: right;
  border: none;
}
.ContentReviewResults___3ZpCZ .videoBox___2qFDz {
  position: relative;
  height: 100%;
}
.ContentReviewResults___3ZpCZ .videoBox___2qFDz .closeBtn___12re8 {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  cursor: pointer;
  font-size: 25px;
  transition: 0.5s;
}
.ContentReviewResults___3ZpCZ .videoBox___2qFDz .videoContent___1A7q4 {
  height: 100%;
}
.ContentReviewResults___3ZpCZ .videoBox___2qFDz:hover .closeBtn___12re8 {
  right: 10px;
  top: 10px;
  font-size: 30px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S {
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .table_container___2-AWd {
  background: #fff;
  height: 100%;
  border-radius: 8px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .table_container___2-AWd .title_p___-6Hlq {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 20px 0;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .upload_box___1FSgg {
  width: 536px;
  height: 391px;
  max-height: 80%;
  background: #f7f9fa;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .upload_box___1FSgg .upload_box_file___26ny3 {
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .upload_box___1FSgg .upload_text___2L_wa {
  color: #606266;
  font-size: 14px;
  text-align: center;
  width: 280px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .upload_box___1FSgg .upload_text___2L_wa span {
  color: #409eff;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .upload_box___1FSgg .upload_tip___ptPqm {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_result___3vlpE img {
  display: block;
  width: 93px;
  height: 93px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_result___3vlpE .p_check_detail___ORlSp > p {
  margin-bottom: 24px;
  height: 48px;
  line-height: 48px;
  font-family: 'PingFang SC';
  box-shadow: inset -1px 0px 0px #ebedf0, inset 0px -1px 0px #ebedf0, inset 0px 1px 0px #ebedf0;
  border-radius: 8px;
  width: 268px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_result___3vlpE .result_title_left___3Ir41 {
  background: #f7f8fa;
  width: 108px;
  height: 100%;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_result___3vlpE .result_title_right___2dNEg {
  width: 160px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 16px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_tip_box___AgeOK {
  height: 40px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_tip_box___AgeOK span {
  display: block;
  font-weight: 800;
  font-size: 26px;
  padding-left: 30px;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_tip_box___AgeOK .video_right___27iWs {
  color: #65e765;
}
.ContentReviewResults___3ZpCZ .tableBox___-GO1S .check_tip_box___AgeOK .video_error___-AZ35 {
  color: #ac2037;
}
