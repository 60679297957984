.detailsArea___hDb2Z {
  padding-top: 75px;
  min-height: 100vh;
  box-sizing: border-box;
}
.detailsArea___hDb2Z .highlight___2DfwK {
  /*.ant-table-tbody
        > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td {
        background: none;
      }*/
}
.detailsArea___hDb2Z .highlight___2DfwK .ant-table-tbody tr:hover td {
  background: none;
}
.even {
  background: transparent;
}
.odd {
  background: #fafafa;
}
.ant-table-tbody .ant-table-row td {
  vertical-align: middle;
}
