@font-face {
  font-family: 'AvenirMediumwoff';
  src: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/AvenirMediumwoff.ttf');
  font-style: normal;
  font-weight: 400;
}
textarea {
  resize: none;
}
.ask-list-box___1FO7N {
  padding-left: 9px;
  padding-right: 5px;
  padding-bottom: 60px;
  padding-top: 31px;
  min-width: 840px;
  overflow-x: hidden;
}
.ask-list-box___1FO7N.share___1UJzT {
  height: auto !important;
}
.ask-list-box___1FO7N.share___1UJzT .ask-ques___2t4B6,
.ask-list-box___1FO7N.share___1UJzT .ask-ans___1syW8 {
  padding: 28px 10%;
}
.ques-input___TW-GN {
  width: calc(100% - 80px) !important;
  padding: 10px 15px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  vertical-align: middle !important;
  overflow-x: auto !important;
}
.ques-input___TW-GN ::-webkit-scrollbar {
  width: 5px;
}
.ques-input___TW-GN ::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 10px;
}
.ques-input___TW-GN p {
  margin: 12px 0 !important;
}
.ques-input___TW-GN:focus {
  outline: none;
}
.control-icon-btn___CyGXu {
  border: none;
  background-color: transparent;
  outline: none;
}
.control-icon-btn___CyGXu .control-icon___1BoUl {
  color: #c5c5d2;
}
.control-icon-btn___CyGXu .control-icon___1BoUl:hover {
  color: #fff;
}
.control-icon-btn___CyGXu .diancai-icon___1Ge0c {
  transform: rotate(180deg);
}
.control-icon-btn___CyGXu .icon-shan-dian___OXUTP {
  font-size: 16px;
}
.dialog-box___1g4i5 {
  margin-bottom: 40px;
}
.dialog-box___1g4i5 .usr-icon___1Ugnx,
.dialog-box___1g4i5 .usr-icon-gpt___iiPPA {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 18px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 14px;
}
.dialog-box___1g4i5 .usr-icon___1Ugnx {
  background: linear-gradient(144.94deg, #7cf0f0 -7.27%, #94f6ab 55.01%, #adfd66 117.71%);
}
.dialog-box___1g4i5 .usr-icon___1Ugnx .external-icon___-POrI {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.dialog-box___1g4i5 .usr-icon-gpt___iiPPA {
  background-color: #040000;
  text-align: center;
}
.dialog-box___1g4i5 .usr-icon-gpt___iiPPA img {
  width: 26px;
  margin-top: -3px;
}
.dialog-box___1g4i5 .usr-icon-gpt___iiPPA.chatgpt-img___IrJ3r {
  background-color: transparent;
}
.dialog-box___1g4i5 .usr-icon-gpt___iiPPA.chatgpt-img___IrJ3r img {
  margin-top: 0px;
  width: 100%;
  border-radius: 4px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6,
.dialog-box___1g4i5 .ask-ans___1syW8 {
  margin: 0 auto;
  width: 70%;
  min-width: 787.5px;
  padding: 9px 2px;
  min-height: 80px;
  transform: translateX(-24px);
  box-sizing: border-box;
  line-height: 36px;
  font-size: 16px;
  word-break: break-all;
  text-align: justify;
  display: block;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-wrap___3UPF6,
.dialog-box___1g4i5 .ask-ans___1syW8 .ask-wrap___3UPF6 {
  display: inline-block;
  width: calc(100% - 54px);
  position: relative;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-inner___1uL1Y,
.dialog-box___1g4i5 .ask-ans___1syW8 .ask-inner___1uL1Y {
  white-space: pre-wrap !important;
  display: inline-block;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 15px;
  color: #1a1a1a;
  position: relative;
  font-family: 'Microsoft YaHei', AvenirMediumwoff !important;
  line-height: 3;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-inner___1uL1Y em,
.dialog-box___1g4i5 .ask-ans___1syW8 .ask-inner___1uL1Y em {
  display: inline-block;
  width: 30px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-inner___1uL1Y::-webkit-scrollbar,
.dialog-box___1g4i5 .ask-ans___1syW8 .ask-inner___1uL1Y::-webkit-scrollbar {
  height: 5px !important;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-inner___1uL1Y::-webkit-scrollbar-thumb,
.dialog-box___1g4i5 .ask-ans___1syW8 .ask-inner___1uL1Y::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 2px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .btn-box___hazH8,
.dialog-box___1g4i5 .ask-ans___1syW8 .btn-box___hazH8 {
  position: absolute;
  bottom: -1px;
  right: 16px;
}
.dialog-box___1g4i5 .ask-ans___1syW8 {
  display: flex;
  margin-bottom: 15px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 {
  margin-bottom: -15px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .icon-copy-ask___1Za-A {
  display: none;
  position: absolute;
  right: 14px;
  bottom: -6px;
}
.dialog-box___1g4i5 .ask-ques___2t4B6 .ask-inner___1uL1Y {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Microsoft YaHei', AvenirMediumwoff !important;
}
.dialog-box___1g4i5 .ask-ques___2t4B6:hover .icon-copy-ask___1Za-A {
  display: block;
}
.dialog-box___1g4i5 .answer-wrap___1lOt8 {
  display: inline-block;
  width: calc(100% - 40px);
  position: relative;
  padding: 10px 16px 15px;
  background-color: #f9f9f9;
  border-radius: 14px;
}
.dialog-box___1g4i5 .answer-wrap___1lOt8 a:focus {
  outline: none;
}
.dialog-box___1g4i5 .composition-ans___3GLm_.evaluate-report___1H_QG {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.dialog-box___1g4i5 .composition-ans___3GLm_.evaluate-report___1H_QG .evaluate-content___3YEK8 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-box___1g4i5 .icon-copy-ask___1Za-A,
.dialog-box___1g4i5 .icon-copy-ques___32ZVh {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK3SURBVHgB7Zo7bttAEIaHkqBClcqU9AliF6qTnCA+glJIUCfmBKHLVJEBFYLU6AjxCawDCEh8gy3TRZUKQo/MJI5BULJ3SS4fWP8fQNBeExDxaXf4mztEAAAAAAAAAAAAAE7hUcGMRiOfTz6VyH6/3ywWi59kgUIEBUHQ3W634+PxGHie16VqUHx8bzQat7PZTFFGrAuSGXM4HO6p5FnzAoolfcgqyaqgGsr5j4qi6Gq5XG4oJQ2yCMsZU/3kCH673Q4oAy2yy/WZsRsWt6IS4bp3zcc4MSy/h5QSa0tsMBhc8k39SAxP5vP5Z6qA4XAY8ulLfIxr0UXaWmRtifET6+RpxTd0RxXxzKz1KSVWa5CL5KpB8RDI4ext8u+73e6SryGb2AyBJqQWFA+BPI2flhXXn5NreYl942vIJvI5XF8UWQiBJqRaYjJjWI4U4rDChCz4fMgXdP84iwvDWFBNQ+Dfe+r3+4V9WcaCXAyBJqSpQc6FQBOMBEkIpNPZIyEwpPJZcZGW/6niIbArJaCIgm20xFwNgSYgKGqAIA0QpAGCNECQBgjSAEEaIEgDBGmAIA0QpAGCNECQBgjSAEEanBXE76s+Jsdky4hSYntvvjLie3D8Qu0dn5LvqVWW/TRnBOn24PitaKY3oK+lBqlmszmhDLwGQbk6zJwVxEtKCvJNp9O5yrPb4UwNkl4BPh7k51arpWxtATkjiGvMA0tZkWUQFDUYCZIpmxzjR+p7qghbIdAE4x5F3n7+fablZVL2DutjCAwTw4q3wS+oAIxrEMu5pURTJP3r0Smss8KUrCHQBOMaFEWRBC1F9SNzCDTBWJB0qUvgonpJyhUCTWimuXi9Xm96vd4dLytecd4bHqqkDU9CIH/+Vw6Bn6bT6S8qkFyN5NI3dK41pkhshkAAAAAAAAAy8wf4kw/wcTZwcwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
  cursor: pointer;
}
.dialog-box___1g4i5 .icon-copy-ask___1Za-A:hover,
.dialog-box___1g4i5 .icon-copy-ques___32ZVh:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALYSURBVHgB7dtPbtNAFAbwb2yniZQscgR3gwhCIj0B9AR0hfgjFj1BwgkaTkB6gixQyxZOQG4QEIVsfQOyaEXauH54XCHFscqMnXFsjd5v1TpSO/pqTz5lXgHGGGOMMcYYY4wxqwiUrDWb+J7n+dghEs7i6vGbbzCglIC6s0k3bLUGIBrGv6GLChAQCOF8DunP6bJ3HKAg4wG15hPfRfNr/IN91IAM6hbXh0VDMhpQ3cL5R4bUWF4fLA6OF8jJgUGeaA7qFo4k1xTu7Q1RgAeDiHCUvSXpPRBNsUvkHEGIQeqak3w/Qk7GHrH2xaQv3OYs/dOd8eXDl+9Qgc7Ps1Ecysn6tRBif9l7FSAHY4+YIC/7bkWrL6hKlL1rPYQ+cjK6B9loqz1ovQRSo/FEUJR6ncjtd358hEkmS6CO3AHdVwI3w0muCXyA58IkuWm25+dGSqCOXI+Y7DmrltyIaVRVQ5aSKkHRUHYuuSaUSDugOpZAuRa5JnlXoyTaAdlYAnVo70E2lkAdWgElJXDz7rkrgSPs3jQugYuNEthtzT/5eUugDq1HzNYSqIOLogIHpMABKXBAChyQAgekwAEpcEAKHJACB6TAASlwQAockAIHpMABKdgbUMN9vnmJ3Gbu4QWjZ/NVSp3Bed7T5Fhq/XUguHrwIvd5mjUBpc/gKPs6UaFPQK0J6H+SIar4M3QUYP0mfTdhJg6LfqBvb0CEhTyWkpNl25x22LNJC2coaPVdfh0iDJaPzJzZ27NJx+Fc9t5OYRgXRQWtgELPCzIXI+cZqmKoBOrQnlHs/Dr/nRl5ERhjdbvbE1ZZAuX4zZqkBPZe76ME+nsQ0Wncxk7S1zCMy1lpkxX3LCRzpWgJ1KE//nJzM5Z/KdSMXFNYsATqyDUGLCcoXFDN/s2geAnUketdTC5ELkg+bpXeTYZKoI6tBsnbF2d94UY7nVVMSmDJg5uMMcYYY4wp/QUJTgEdcm+YkwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1g4i5 .icon-copy-ask___1Za-A.copy-format___3ZcTz,
.dialog-box___1g4i5 .icon-copy-ques___32ZVh.copy-format___3ZcTz {
  color: #0cd7a6;
  background: none;
  width: 80px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #0cd7a6;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
  padding: 2px 5px;
  transform: translateY(-8px);
}
.dialog-box___1g4i5 .icon-zan___KozB8 {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ+SURBVHgB7ZtbThsxFIZPLkIIHpquoO4KICsgrKC0GyBIgHhjWEHJChr6gLhJDSuAHZCsIOlb35iuoOkDCHFJ+rsapBnPJGM7Hjut/EmjZIydmfxzfI7PcSDyeDwej8fj8Xg8Ho9tSjRH7O3tMbzwgxYXFwftdntIjnEuUBAEtfv7+/3xeByUSqVa/G8476K9c3Z2dkmOcCoQLKbx8vJyJQojwoXCsXVychKSZZwJtLu7u4GXK4Uh4ePjY73T6ViddmVyQORrvpAabGFh4TNZxolAo9HokCJn/Ap8DbeMFqbcR37gfZbfCfi0JItUyQ1rwnlYqVTWBR9zjWnIzxNWA/H41OySJaxb0Pb2doME6wFHWQ4Y0esQL4l2OOs1soh1gcrl8qrYhunVnTKkJ5wzsoh1gWABH4Sm8Pz8fDBlyBtyiFWBms1mDdbSiLdBsO85w5jQf0AWsSoQHHFDbINgE9dC0XIgMSURAfMENYptgVJrH/ik3qT+iFiNjM+4JotYCfNRvvWN0tOlOy19yPBXhP5dsoiyQFH+tIKbfyvTH/3e3d3dbWTlW5guBznDxYgXYm10SPIMYKGDWXI46VyMWwG+KE8sG2SGVrTOyYT7Hwh4S2ZoI49r6eRx0j4IU6RtShx88ctp4nAeHh5MJqUB8rgbHkVJESmBomiySTMS5VsHFxcXzby+/GlzIckcqxApIEWkBHp+fmakCRclWim3lpeX38Ny2rJjcd0AY48iYU2wr2pF2lEMzm89K6LAiXK/wWL9eqenpxukQeQzguhQZmdnh7uF/VhTDaVc7vi7sp9hYx30i9zRERsQgRkpUIRALH4CP/KbHIFrM7ENC82QFCjcgmDiznYmIEaqcoDoqJTLGRVoggN0uXWzIpwPVddCRgWCA0wJZDAC6cDiJzqVAKMCwQGmBEIUc+KkI2tOTDE8rB4pYlQg3EBKIDw1J066Wq2m/A/upU+KONnVsEFWaRdR7ScpYlQgPCEmtmHaufJBokDDnNJuJoVbEEKtK4ESEUy3VFu4BRnOylUwUqot3IJs76VzkINlOeiQNCg6ijmxnqwUg1cWSQPTU2wuBIIYDbFNt5ZteoqJm3yuBBJTDO29tEItyFWiiim2KtyH8vrnlf9uocjLw+KDytn7n4ppJ82EJutpxtPTUyqC6Trov2OpWKwnqiZqQHFMC8TiJ46qiTPXgOIUakGOnDSj5D1oWw/HmEDzUE00VQOKY0ygeagmmqoBxTEm0DxUE03VgOJI/XiB//ASF79JDCyVOrCQ+MUZCdvTGX0Kg699IMamsAYaYidX6lcok5DaWc1ytvjizbxxMn1MgWvx+0y04fyaZkRqivFKnOPdCR1uIVCLZkTaB8F8t+jf4RYP9JOJf36pyHbs9/s/6vV6D76IkeXfKucwjr0PcXxdWlraOj4+Dsnj8Xg8Ho/H4/F45pM/fceodqxtQ4oAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___1g4i5 .icon-zan___KozB8:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASWSURBVHgB7ZpdbttGEMdnyJUlIyrqnqDsSwEFAWz3AlFOELUPBeqgTXQCKyeodYI6J1CCwumjfYPKJ7CKNtGj1RNURS2EiklOdy0nIHclm6SWuw6wP0C2d7zUx1+zszOzBHA4HA6Hw+FwOBwOh2kQ7hCNs0HAGAvE3yyKRtPd7hQsY12grbPBVtRo7ANRj7+brex/aUiAL2etvVdgCasCNf/8tQ2+f6wKI0PDCN53w1Z3AoaxJtBnb193COE473wCmNTC+a7pZeeBBRrjQZAg/FLkGv5NBtHm5s9gGCsC+VQ/EB84YyTgnkF9pOhb8SACNe5Q0muO+bI0CAMbIDxMD8XyiREfha29Scp80hwf8TFmvYa8Dv85BEMY9yARmJd4z4uw9cNEnnvRenIgxMsYPXwIBjEuEPn+jmJkbLj6AjiVxgEYxLhAiPQ4PRYeMvv6+9HK+QCfg0WMCiSSQv6R22kbEv5xy2VBdkgjMIjRIB3X/bZsI59W5kIiHeC/skuS4DZBtWLUgxJkSu4TJ3i6ar4fb7QVIyYnYBAjHnRVb23WB2qApaG0tWdADx/LtovWj0MwSGGBxDZNtdo2xvEXeeaTB19GhB0ujlJvkQ/Pb7wWYSddC4mA3nxzdAA5QcTRJc5H69RwuWuxRdW9cSwH2fJQX+Q5q/7bGP8WMKBz0AF6h+zdu36ZOi53DLqs1w91icM94dVN4ggaYaivKOUlCl/ivy920WLkEkjsJojwFNaF11u8xnrO+zvPbpsqvu2l9Vj5196JNjZ6UJBcArFo0eUrxaIIHYolxebzr2b39w7zXlqbz3u8kfZi8Rwa8HC/qBetsYvFj5btKPfGr8+ztRaeXrT2OlCC65jRu34Upvn26JBH6v2UicdRJvKqYd7nqDwP4jvRP2AJSt6/VGyxF0ABtAskV+pIyb9gCc/zA9mGfjKBAlSfSeuKH2VemtTOAQujQrWcVoGWBUBCtHd0g7gtWaZFcyGtAoWNhrpDJGTzbCvIDot3ArQK5MehIpDHfCtB+sqbUeoEJHAKBdEqEBJTlxhdWgnSEWNK/EHfP4OCWDnVMMGy1m7ieX9DQbQKRKjmGOTXbcUgWaDpTa3dVVTuQXEcWxGI52PSDlauVas3BqF64qC1Ki8C6mnVVu5BNm5huffXQIk/BDiBEugVSL1Lw4r3LC0xMLa/xPi3tJUd2xGIQD09KdvL1huDpEM+HpNsxZ9taVz6LE3zEqPsEiNLZQahlEFj4fznA3bu7qiQ68NGaamLjmY5dMegIGvwjJcZtSUtjrIBWlDpNm+jm6ijB5RGd5AOMmMb3UQNPaA01SaKdrqJQXa43t0g2gS6C91EXT2gNNoEugvdRF09oDTaBLoL3URdPaA0pfMgAva0+eao/XGMGMhzEqIOn7MLJljUgfLxeKkeUJpcAhFGUwQfsu+HnvGj3NRYRZ5jGp6XncCa5Fpiswfdkc3zrTLwQvk8BujDmuSOQQhRFz4RhDgQ03fL7r0uSm6B/rv/04m4YQHWqGsqgj48roQB6tfC+TezB0/Wij0Oh8PhcDgcDofDUSH/A41qn9LJOnLQAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.dialog-box___1g4i5 .icon-zan___KozB8.active___3nuWM {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQESURBVHgB7Zs/TBRBFMa/N0tpcWqhnSTai7aagJV0EmNjZSisLIBWQ4RgYScNtVDZYKDUyjPR2rMnATssTK7Ajpvn2z3+7O0sl529uZnzMr/kDu4xc7d89+bNe293gUgkEolEIpFIJBKJ+IYwQvBKYxIJJrMXHbRopd1GYIILJKI0MKEW5NdFeTQKf21CqU16+WcLgQgqEK81ZkBqB4YwxsgmjnlePOoAngkmEK9dnQPxTvUJOEBH3/G97BQCkMUaxe+sJpHEpgn1Gp4J4kH85sqm/HjWa8SRHM1HOaS9E8M9eZo1J+sHtNxuwhMTCAFhWgQ5h3EI5iUgOcwN+gbSv1EUEmpOnprwhPcllgVmPtnKTyHeBuXFOR2sNjPxesdOwyP+YxBhyrBp+tlnRqvwBpPwSIAgTY96XqYeQmrv4uF8CQHxKlCWFIJmeoyEvf6TcL1gaMEjfj0oSWYMG9P3C8crEYfoVmF8v+XoHL8CleU+rC/2CObbplHvwiNetvluvUXvjd0rXS5lu9cZ+n4xVfOZA2WfB0uybVqp29C4XHHKDfmUNHcpq7eey1beJ0DrD/J8HoOYD8RoUbhSC51Oa5AarrJAJ16wYwTZ+mxlec6FHyjCqEwgF6zjWK/WqeOqx6AJte5QnM99xUkhKT3csSjH/6W7i9pRSaCsuDRS/hpk9RZviDhvK4xOBfoEd0whUYuWcyp6UFIMrhakonRzF4kd6il0sl19rtoQT9ruvocDCAu2XjTILrYk/0DJFi1xg3KBlaRU4GQZ9TiCFpGAjZ7itipKv5B5T3KWhnzZaanTrPwWGD4uY4kdLLHOsCWTsMC9QFQoDTigQETXTFvnABaMtwdpvmXYOrCq5VwLZFbexAE9yBCobZsLuRWIUdaaCBmDBu4EuBVI6RKB1F+E4VJJJ+ArLHErkC7zoEBLjLUZf0j/gCVBTvt4QfFNw0b4BUscL7GSbVWHikFkBuhX7cAxqJRQS6y4g9Vr1Trexfi6YaMkjECOWrXjmShSWYCWc/s1GHaiGMp7zFjYr/fdh/EUSLNxcrJuL9utQMWTfEyBPAhOAnSKYw+iXoFIhyozijHIOv85ZfwSRZXVX4WlTk3UxHWxWtjmQyyxkgy6ZoBOGbYH+RfIQQ8oj+MgPQLdRAc9oDzj50GOrwZxKdAodBOd9IDyuBNoFLqJjnpAedwJNArdREc9oDyDnDh8KFtG/vods5LPLuUtOehhoMSDmYqXDdfqAeWpJpBCu+TM5myFa0OqjHEDl9oGvtiq0hLrfgsc/M4bK5j30dGrGJDqMYh5Hv8LqTiKH7u4+aWyQNIu2E1vA8juvBkt+OxB2JfnVXT47qCxJxKJRCKRSCQSiUSGyD9H/k6b2HhqvAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1g4i5 .composition-ans___3GLm_ {
  display: flex;
  margin-bottom: 5px;
}
.dialog-box___1g4i5 .composition-ans___3GLm_.ease-in___2bPhu:last-child {
  animation: composition-delay___3_xqc 1.5s;
}
.dialog-box___1g4i5 .composition-ans___3GLm_ .evaluate-title___2O39l {
  max-width: 200px;
  font-weight: 500;
}
.dialog-box___1g4i5 .composition-ans___3GLm_ .evaluate-content___3YEK8 {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
}
.dialog-box___1g4i5 .icon-cai___3qJZD {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARCSURBVHgB7Zu7ThtBFIYPvkgUSDFvsGW6WJgiHUuXdH4DjASG0jwB5glwOi5Ccbp0hjIVTpcCw6ZLuenSxSmQHItL/iOtJXt27ezMrmcWmE9C9oxn18s/c86cOTMmslgsFovFYrFYLBaLbhYoJRqNRun29tbJ5/MlyhiLi4teq9XqkwKJBdre3i7j5XBhYcGlbNM6OTnZI0nylIB6vV7FSwfivKbs87ZSqZR6vd4XmYuUR9Du7q5zf39/A3EyZ1KzGA6Hy+12O7a55UiRh4eH5lMThykWi1WZ9gVSZ00o+xhRexBNyRnKgu9x8PdRqD5Ax3VHhVwuxx3YoQQoCVSr1fiLHaH609nZ2TlpYmtry4VAE3UQpAtH3B2V2Q1AMEqCkokVCoWyWIeHvaFniJJA6Kkogb6TRtjEIqp9ShlVJy36n/7R0ZFPzxBVgZzxAnrTI81EjaB5dJKqQBMmBkeo1bx0Ii0Qzx6hm+Ryl6SZx8dHMQabS3ghLVCUg8YI+kmaiQhSsyEQes4R605PT7X7IPBKKGdmBL0RqkyIExpB84rgpQWCOU2YmIkZTCdSAnHuR+w5mJyRGSzC1H2aA1ICYfQ4oRvkcnYEjUA6NTSDDQYDUwI54wWMqLnMpFICwbzEJYYnk3x6isiamOigtcc/TJBuETE7i/FDRThoI+aFXYqQQHgWswJlKQeEzGVIIEwWv2kOxBYoCzmgERHrMH6WPzQHZHzQi8kBjSMjkDNeMBlB68omMjICvZgc0DixBMpKDmiErmwio7xx+FKItS+GKd4X95cwk7Sw76Q0c6C3u5QMRyj38Syu2AjhgCPunckS++qdnZ1LiOJSOvgw0XUZs2AB8P0b6Khqki1vXFs9Pj6+iNs+tomhNw4oPXjHM7YPq9frh9weAtWSngeQjd1iH3+5vr72K5UKjziX0qG0srJygfv+mtUI4nC0LnXgYBoQ+AO2pj/LXCPlpHHzJr5kk1JKs/7vNBqPHBLCCxV4ncbiIHfeIEkSnQ+6u7tz4raHGPuCD+tD8OVp7Tm0mBJKeLhPWyaTubS0pHwET/n4S+Bg/bjtka7tjM8oeP91VvtA0Ik61VGQBC1xUHBURUyVTD23w6mViBnT1y0Oo0UgmIor1s0yEYweN6K6SQbQIlBEqtaftdkIgUKz1nA4jB27pIkWgcT8TYxYZE1o3zWV+za1Fns17QOeHUlYSpjMHOgSaOIfxIgqB0KEgBgbYh1M7pwMoUsg0d+UIERHFClYcDaFtn4Ki1tlkhwDjg0cbLtYLO4LU32Z11dYBHd50w9/ayi74rXsf8ggiX6KEBfP8warq6t/8fad8BELxksJd1oaFSHC5tXVlbHNSS0CMb1e75vMYjfY53qP5cgPMog2gRiI1I0pkgfHvG5aHCa134vJwM4ZI6TBfoeC1XowYjyYGqckjM1aFovFYrFYLBaLxaKBfxlCo3LuuJS3AAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___1g4i5 .icon-cai___3qJZD:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARzSURBVHgB7ZtRcts2EIZ3QbjSjNyJcgM+dSK3nSonsHMD9aWTxm1T3yA5QZwT2DmB0mnjzPTF6QmsnsDuNGN3+qSeoOpY7khjihuAshIRlG0ApEA6xvcGmBTpX9hd7C4E4PF4PB6Px+PxeDwe1yAURPOw2zxf+SREiptQMXgUHQ3ubw3AgtwCNd6+amMAO+KjNqDKINsd3nv4FAzJJdCnx3sdAuiKT6ncqlmIhUjWAtVPuiGn2uGNEecCPhrfNTE3BpYEVNu+aeJIhJ/smFzPwRaE9fmhMLU+o+gpIVo5Q1MIWIiAXWX2OUDcm42QqEnI9yEHVgLJiBUBhPNzGNNPp5//8AYcsfrnzxvAA2U27g1b3/dmo/rJ65DLry4HViYWcd5W5zAIDuEjxEogCoKMQOdEf4BDCFmozkXA+1AwVgKJ0LeuTA1GrW/78BFiG8XC9JCOwDGI6jsALONLshMIIW1iBE7NyyXGAiXRQwFZcACuye7BlrK9MBZokYOOGfsHHEOAzfS4IgIhUKjOnX32jXsfBHAnNcaKCCTe7Ctl7Fyc6XMpbWJEFRGIMGViRFiOQI4wEqjxttvOOEfHG8T3jwUM02PWhyVgJBBjQajOIU78CppBlI1gfBSVIhBmkuV4KZHUzAcxJcUQDtq21ntTMBNIcdAQo/P9j0SWW9S5ZdWhtAVKXirjoONSzGtUr2crmXHJYb5KNaBgMsoIxHjwLywBbYGqUAOagcSzJkbn/8ES0BboNtWA5jFx0mF6SKX4n+TJjqqJEn2BblENaB4tgSpTA5o921E1UWLdOLwtaPXFRIjvq/2lmOJdsbKsIsfwyw+9KxvEpjBUeuaDRaucJnEIQb7zGdp3r568OijqBIfswk5g/GDU2urr3iMFIM4eI2EnT8sbkXVO7z38Tfd6AxOLn0NByEQzgJq2D1v9a29HdFEPRKv5x7znAUz3btoCTVu6VKhIjb9/bV93nRDnUCy5J1AERC9MnbmRkx62NrcJaEu8cCF7IJyMr1wNycohuFbEayFRrxbiDNc2jYXOcT5IHgyIQv072DPFhw2GrUd3L7v64nBC1gxFiUVUD18inWubiqxZ2ZZlrI+/XCzVvu71q8d7+6mvg/D3K2/gUlAFuQpam8WYmyZO9kHJalCcKwV06bmdab0nHTFl5LMxkby42SgytpGZQ36piUxqQfZ6BttQAo4Eyp5Gu6rZGAPvqHMr/4+19y5F4kYgSreJxWbvageLammFemXVvsvJxTC+c9mf5OlZtWNRZuXAiUDCpJR/ENtSiEXXcqg/zkxi/AZKwtUKUv1Nk2NtXxVpmnDS9vxcEr1a+ZLbPNgfAzZgZTx+GdVqz1KhXuyQZT7WOHndS5p+iSNflAxjD0qksB+zXEfj+JcnIpPeMblnmvXjgzJr386c9Nnad7tGya7Mnyb0ddmNAadRTCa7WiKJfCtCvH/2xWbpByOcmdg8SaJLsUgbcP19M0CuGKQjsUd6cbr2qLSo5fF4PB6Px+PxeDwOeAfZRXwESQkdAgAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1g4i5 .icon-cai___3qJZD.active___3nuWM {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP5SURBVHgB7Zq/bxMxFMffc8KWIYiBjBm6E/ZKLVNZkIrEHwBzl3ZioFGDCjNlYGKg/AewsdGhzJQdpI5daG8oC2r8eL4DmrOvydl3sa+tP1KlnHVXn77n99MGiEQikUgkEolEIpGIbxBqgkbdLtyAPkjoQtMYwwGOkgQcqCwQvewOgPAV/6tlaDY7uHm8AZZUEoi2b60C0jv+2bxVU4y1SM4CsUn1oS2+wuURJ+NM3rQxNwGutMUILps4CtFatbm9Da4gLAFNXBMcAeIb/nEKPiDssXk/1Ubf89/B+SV2lCOACjgJlEYs4oiV5xO/9H6NgXHGS8hBwVQHQOJcIIIeCIIquJlYCwbGGOIPuIK4CYQFAkn5HXwi8LYxJukIasbRSeOSNnAK2Kr95ZqAq0D9/DX5XT3plNQzxubwkVzDfN7ECP0L5AlrgWi7u2wMIn4D/3S067mkF/YrqNBBUwj/01CBSPSNMRT+TQwpLxBhU1YQ3cldB/M/mBcIZUME0h000pV10AorgdLeD6BWoAYSSJUROXAuftBuBclW3xy8miXGPyxNjMwIRiLMCkJtBaluwhywEwgpX2JkDtpPeyMQtk5ad9Ch6q+OMYIUNoqlPSDdQROEctCdgtHAYb5JPSAhCwQSv2AOlBeoCT2g//MWrSAKnShenx7QJDYC9fPXATNoT93EdCqLe69ND2iSUgI1qAeU4ambqHDfOLwmlNsXG8OheSet8Zd0ixw4sXfl9LxeqHLAUPtkOmpzsSKld/noxc3PNZ7g4F1Y2uDit7xZKAEErPCvRYDCRLEkchU3k49l7y5vYkTPoT562ZGZ0pOv8Zuq++9DJXGYM7DynaUFwmGyxyl+zSLJhZl3CXrLq+0R1AHBaxwlhzaPWDlpHB6POHt+wjNV8yHns89YDamfmy3iTChJxRker4Ml1c4HtYwDDFNmwi3Nh7FjFQ8uniD1OQVmyB8HaZfLjfKmEvIIXlnYyZ9o3YB9Fmh44QMolThacuq2Cqrgfj7IgizR1Fsl+GXKI8r0NHHoEIcnXsVReEoUxbIxRFNqOaFtLSlaNIIA+BHIaNWqPGhKL5to0Rj7DaVzlzrxtII088KZnUgtK6Y9VydblVC12MXhXaRlhLZjEa4w9iMQGSF5AcS4uE6StFIw+AEC4ckHST2x7IAU24ZI6cFMepwfU9GLs/hAeAnzXP/sQpu2cr4IOUNW9ZgSL3XaMCjsewPuQUA8JopdzmGERYEK6eqBMd2zrZ/qxJuT5hbDjl2xy/WToIchxVF4jWJpsVtKJK63zuguPkvqKYor4M3EJskKXbGeJZD41++QynPUSXmut34Gi1qRSCQSiUQikUgk4oE/MztDr9z3FKUAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.dialog-box___1g4i5 .remark___2_DQb {
  color: #868686;
  border: 1px solid #868686;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  display: inline-block;
  transform: translate(12px, -5px);
  line-height: 10px;
  cursor: pointer;
}
.dialog-box___1g4i5 .remark___2_DQb:hover {
  color: #1bc37d;
  border-color: #1bc37d;
}
@keyframes composition-delay___3_xqc {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.close-modal___aQcj3 {
  color: #333333;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABICAYAAAC6L9h5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKCSURBVHgB7ZpBbtpAFIYHVixYuDdIb0CPkNygvUEXSOygJ6h6grJEYtOTJEdIbhBO0GQBEiyAvj+1o1FijMFv3nOS/5NGzyB75unTjD32TAiEEEIIIYQQQgghJKcTlJhMJtl6vR70er276XT6GByRXMJ+v882m41KPiqSRqPRxXa7ve10Opn8XEiCX+fz+V1wYDgcInyRcp3ncy/5fGuSTzcosNvtfucJgQs5vpFkB8GYEkHgc7fb/RkaoCKphMxa1AFBKqhIku78S8rLcW8mqkqQ5PUg//0IDVCRlI/3Sw9RxwRJuJrNZovQALWnG8hl3JR090dJ+FL7Zl5HkEabqpKAlSgrQUBdEkgtylIQSCIJpBJlLQgkkwS0RXkIAkklAS1RXoJAckmgqShPQcBEEjhXlLcgYCYJnCqqDYJAqne3Us6YmbsLAqY9qaBOjzp0jrUg4CIJVImSMhEZ0zYIAm6SQIWoV3gJAq6SQB1RnoKAuyRQJcpbEDB9up2DiAvevInhJjxIj/qYw+2UG3dwFOU23CrmQZgrfS+ZcH6Sc689VmFcJFUJkoDJ5J9QPjN3EWUuqYagp+GUx6s2iDKVVFdQxG1ogSj3TyUVgorrEA4tOprczF0/uh0TFF2P4CbK7fNtXUFRPQguolwWAk4VFNWHYC7KfEnpXEFRvQimokwXJ5sKiupHMBNltsytJShqB8FElMmGCW1BUXsIyUWpSbIWFLWLkFRUij2Tz6QWVJBaVIo9k09YCQLSBsLBV5hW7pm0FFRwRFQj1PZMSljkx+aCCmJRUu7/p7P/23TPpOpm9+VyOej3++6b3cfjMUK2Wq1akQ8hhBBCCCGEEELIO+If2AWTJ1upolYAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.ant-input:focus,
.ant-input-focused {
  outline: none;
  box-shadow: 0 0;
  -webkit-box-shadow: 0 0;
}
.cursor-blink {
  animation: blink 1s infinite;
  color: #0cd7a6;
  font-weight: 800;
  margin-left: 5px;
}
.ant-input {
  resize: none;
}
.mathtex-block {
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
}
.copy-tip {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  background-color: #000;
  color: #fff;
  left: 0;
  top: 0;
  border-radius: 5px;
  position: fixed;
  display: none;
  text-align: center;
  transform: translateX(-50%);
}
.copy-tip::after {
  content: '';
  border: 5px solid #000;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback-modal .ant-modal-content {
  border-radius: 14px;
}
.feedback-modal .ant-modal-close {
  top: 5px !important;
}
.feedback-modal .ant-modal-title {
  text-align: center;
  color: #1a1a1a;
  font-size: 17px;
  font-weight: 600;
  font-family: PingFang SC;
}
.feedback-modal .ant-modal-header {
  border-radius: 14px 14px 0 0;
  border: 0;
}
.feedback-modal .ant-modal-close-x {
  color: #333;
}
.feedback-modal .ant-modal-body {
  padding: 0px 25px;
}
.feedback-modal .ant-modal-footer {
  border-radius: 0 0 14px 14px;
  border: 0;
}
.feedback-modal textarea {
  padding: 14px;
  border-radius: 20px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal textarea::placeholder {
  color: #a6a6a6;
}
.feedback-modal .ant-btn {
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal .ant-btn-primary {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.feedback-modal .ant-btn-primary:hover {
  border-color: #2aebbc;
  background-color: #2aebbc;
}
.feedback-modal .ant-btn[disabled] {
  opacity: 0.3;
  color: #fff;
}
.feedback-modal .ant-btn[disabled]:hover {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.model-ques-input___qdLY3 {
  resize: none;
}
.tal-gpt-box___20Zb- {
  height: calc(100vh - 120px);
  overflow-y: auto;
  color: #1a1a1a;
}
.tal-gpt-box___20Zb- .tal-title___1ev63 {
  font-size: 35px;
  text-align: center;
  padding-top: 120px;
  margin-bottom: 80px;
  color: #040000;
}
.tal-gpt-box___20Zb- .tal-title___1ev63 .title-bold___3uo-z {
  font-weight: 500;
}
.tal-gpt-box___20Zb- .tal-title___1ev63 .title-thin___4s2cS {
  font-weight: 400;
}
.tal-gpt-box___20Zb- .tal-content___387RI {
  width: 70%;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}
.tal-gpt-box___20Zb- .tal-content___387RI .tal-tab-title___2mIdd {
  margin-bottom: 15px;
}
.tal-gpt-box___20Zb- .tal-content___387RI .tal-tab-title___2mIdd .tal-tab-title-item___zvpHm {
  display: inline-block;
  width: 33%;
  text-align: center;
}
.tal-gpt-box___20Zb- .tal-content___387RI .tal-tab-title___2mIdd .tal-tab-title-item___zvpHm img {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
}
.tal-gpt-box___20Zb- .tal-content___387RI .tal-tab-title___2mIdd .tal-tab-title-item___zvpHm p {
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0;
}
.tal-gpt-box___20Zb- .tal-content___387RI .tal-tab-title___2mIdd .tal-tab-title-item___zvpHm .anticon {
  font-size: 30px;
}
.tal-gpt-box___20Zb- .tal-ques-content___1orlo {
  display: flex;
  flex-wrap: wrap;
}
.tal-gpt-box___20Zb- .tal-ques-content___1orlo .tal-ques-colum-item___T2czn {
  color: #1a1a1a;
  flex-basis: calc(33.33% - 10px);
  display: table;
  text-align: center;
  width: 250px;
  min-height: 65px;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-radius: 14px;
  opacity: 1;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}
.tal-gpt-box___20Zb- .tal-ques-content___1orlo .tal-ques-colum-item___T2czn span {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 18px;
  font-size: 13px;
}
.tal-gpt-box___20Zb- .tal-ques-content___1orlo .tal-ques-colum-item___T2czn:nth-child(3n + 1):hover {
  cursor: pointer;
  border: 1px solid #0cd7a6;
}
.hidden___3LcPh {
  display: none;
}
.model-tip___AbpOf {
  width: 130px;
  height: 24px;
  line-height: 20px;
  position: absolute;
  top: 26px;
  right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 2px 10px;
}
.model-tip___AbpOf span:hover {
  color: #0CD7A6;
}
@media only screen and (max-width: 1280px) {
  .model-tip___AbpOf {
    width: 105px;
    height: 24px;
    line-height: 20px;
    position: absolute;
    top: 26px;
    right: 2px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    background-color: #f5f5f5;
    border-radius: 16px;
    padding: 2px 1px;
  }
}
.ask-inner-list___2Ba6e {
  height: 10vh;
  background-color: #ffffff;
  margin: 10px 5px 10px 5px;
  border-radius: 14px;
  width: 15vw;
  font-size: 14px;
  border: 1px solid #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.ask-inner-list___2Ba6e::-webkit-scrollbar {
  display: none;
}
.ask-inner-list___2Ba6e:hover {
  border: 1px solid #53e8c4;
}
.ask-inner-h1____pGGE {
  font-weight: bolder;
  font-size: 24px;
  line-height: 33.6px;
  margin-top: 10px;
}
.ask-inner-p___3BmAB {
  width: 50vw;
  font-size: 14px;
  color: #a6a6a6;
  font-weight: 400;
  line-height: 19.6px;
  margin: 0;
}
.ask-inner-a___2FE4t {
  position: absolute;
  top: 110px;
  right: 8px;
  color: #53e8c4;
  width: 53px;
  height: 21px;
  font-size: 13px;
}
.ask-inner-separator___2nmH2 {
  position: absolute;
  width: 78px;
  right: 70px;
  top: 117px;
  border-right: 1px solid #ececec;
  padding-right: 10px;
  color: #a1a1a1;
}
.ask-inner-ul___mcxZA {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
.ask-icon-title___174_d {
  display: flex;
  /* 使用flex布局让图标和标题在同一行 */
  align-items: center;
  /* 垂直居中对齐元素 */
  padding: 5px;
  margin-top: -10px;
  margin-left: 5px;
}
.ask-title-h4___Jh92i {
  margin-top: 20px;
  color: #1a1a1a;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 500;
}
.ask-icon___2y0KI {
  margin-right: 0.5em;
  margin-top: 10px;
  /* 图标右侧添加间距 */
  width: 30px;
  height: 30px;
}
.ask-description___2QdiK {
  width: 14vw;
  padding: 5px;
  margin-top: -12px;
  margin-left: 8px;
  margin-right: 5px;
  font-size: small;
  font-weight: 400;
  line-height: 21.45px;
  color: #a6a6a6;
  font-size: 13px;
}
.math_example___2g1ef .ant-card {
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #f9f9f9;
  background-image: radial-gradient(circle at top left, #e8fffa 0%, #ffffff 36%);
}
.math_example___2g1ef .ant-card:hover {
  border: 1px solid #53e8c4;
  color: #53e8c4;
}
.math_example___2g1ef .ant-divider {
  margin: 12px 0;
}
.math_example___2g1ef .ant-typography {
  height: 288px;
  line-height: 24px;
}
.math_example___2g1ef .ant-typography-expand {
  display: none;
}
.math_example___2g1ef .ant-card {
  cursor: pointer;
}
