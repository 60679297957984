.functionalExperience___3Ouum {
  width: 100%;
  background-color: #ffffff;
}
.functionalExperience___3Ouum .top___12-59 {
  width: 100%;
  height: 82px;
  padding-top: 49px;
  font-size: 24px;
  font-weight: 500;
  color: #042044;
  line-height: 33px;
  margin-bottom: 41px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.functionalExperience___3Ouum .bottom___w5sk1 {
  width: 100%;
  padding-bottom: 55px;
  display: flex;
  justify-content: center;
}
.functionalExperience___3Ouum .bottom___w5sk1 .bottomCon___20zE0 {
  width: 1200px;
}
