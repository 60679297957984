.evaluate-wrapper___1Ycsb {
  width: 1280px;
  height: 780px;
  margin: 0px auto;
  padding-top: 10px;
  background-color: #142532;
  border-radius: 10px;
  transform: translateY(100px);
  color: #fff;
}
.evaluate-wrapper___1Ycsb .result-ctn___18M-F {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.1);
  transition: all 0.5s ease-in;
  transform-origin: center;
}
.evaluate-wrapper___1Ycsb .result-ctn___18M-F.result-animate___3w0QJ {
  transform: scale(1);
}
.evaluate-wrapper___1Ycsb .result-ctn___18M-F img {
  width: 500px;
  margin-top: 100px;
  margin-bottom: 20px;
}
.evaluate-wrapper___1Ycsb .result-ctn___18M-F .evaluate-done___wfhiy {
  font-size: 45px;
  font-weight: 600;
  font-style: italic;
  margin-left: 22px;
}
.evaluate-wrapper___1Ycsb .result-ctn___18M-F .evaluate-redo___2KblG {
  color: #fff;
  margin-top: 40px;
  margin-right: 15px;
  width: 150px;
  height: 40px;
  font-size: 16px;
  background-color: #80be47;
  border: 0 none;
}
