.allContainer___vj_Gg .detailsArea___2f-hY {
  margin: 0 auto !important;
  width: 1000px;
  padding-top: 0px;
  min-height: 100vh;
  box-sizing: border-box;
}
.allContainer___vj_Gg .detailsArea___2f-hY .highlight___fjsJZ .ant-table-tbody tr:hover td {
  background: none;
}
.allContainer___vj_Gg .detailsArea___2f-hY .even {
  background: #ffffff;
}
.allContainer___vj_Gg .detailsArea___2f-hY .odd {
  background: #fafafa;
}
.allContainer___vj_Gg .detailsArea___2f-hY .ant-table-tbody .ant-table-row td {
  vertical-align: middle;
}
.allContainer___vj_Gg .banner___3Xq5f {
  position: relative;
  margin-top: 20px;
}
.allContainer___vj_Gg .banner___3Xq5f .bannnerImage___2ccJz {
  width: 100%;
  display: block;
}
.allContainer___vj_Gg .banner___3Xq5f .bannerContent___2vApG {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 38%;
  left: 0px;
  display: flex;
  justify-content: center;
}
.allContainer___vj_Gg .banner___3Xq5f .bannerContent___2vApG .bannerContentInner___1zLPZ {
  height: 104px;
}
.allContainer___vj_Gg .banner___3Xq5f .bannerContent___2vApG .bannerContentInner___1zLPZ p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.allContainer___vj_Gg .imgTitle___3KPmE {
  margin: 0 auto;
  width: 1000px;
  text-align: center;
}
.allContainer___vj_Gg .imgTitle___3KPmE word-wrap:break-word .desc___3bUR6 {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.result-html {
  width: 880px;
  margin: 10px auto;
  line-height: 30px;
  margin-top: 30px;
  font-size: 18px;
  color: #042044;
}
.result-html ol,
.result-html li,
.result-html ul {
  list-style: none;
  margin-left: 0;
}
.result-html .even {
  background: #ffffff;
}
.result-html .odd {
  background: #fafafa;
}
.result-html .highlight {
  margin-top: 20px;
}
.result-html .highlight .ant-table-tbody tr:hover td {
  background: none;
}
.result-html .title {
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: center;
}
.result-html .zhihui-block {
  margin-bottom: 20px;
}
.result-html .zhihui-block:last-of-type {
  margin-bottom: 60px;
}
.result-html .zhihui-block p:first-of-type {
  text-indent: 30px;
}
