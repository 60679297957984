.ask-inner___wyQMi {
  line-height: 30px;
  font-size: 15px;
}
.ask-inner___wyQMi mfrac * {
  font-size: 16px !important;
  font-family: 'PingFang SC', '\5FAE\8F6F\96C5\9ED1', 'Microsoft YaHei', '\5B8B\4F53', sans-serif !important;
}
.ask-inner___wyQMi mfrac mrow[data-mjx-texclass='ORD'] mn {
  font-size: 12px !important;
}
.ask-inner___wyQMi math {
  white-space: normal !important;
}
textarea {
  resize: none;
}
.ask-list-box___2vlPS {
  padding-left: 9px;
  padding-right: 5px;
  padding-bottom: 20px;
  padding-top: 31px;
  overflow: auto;
}
.ask-list-box___2vlPS.share___3Wtjq {
  height: auto !important;
}
.ask-list-box___2vlPS.share___3Wtjq .ask-ques___nEn9K,
.ask-list-box___2vlPS.share___3Wtjq .ask-ans___R7Z7W {
  padding: 28px 10%;
}
.ques-input___2VHA_ {
  width: calc(100% - 80px) !important;
  padding: 10px 15px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  vertical-align: middle !important;
  overflow-x: auto !important;
}
.ques-input___2VHA_::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
.ques-input___2VHA_::-webkit-scrollbar {
  width: 5px;
}
.ques-input___2VHA_::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ques-input___2VHA_::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
.ques-input___2VHA_ p {
  margin: 12px 0 !important;
}
.ques-input___2VHA_:focus {
  outline: none;
}
.control-icon-btn___1g4us {
  border: none;
  background-color: transparent;
  outline: none;
}
.control-icon-btn___1g4us .control-icon___3F7aT {
  color: #c5c5d2;
}
.control-icon-btn___1g4us .control-icon___3F7aT:hover {
  color: #fff;
}
.control-icon-btn___1g4us .diancai-icon___1RCyF {
  transform: rotate(180deg);
}
.control-icon-btn___1g4us .icon-shan-dian___37ReY {
  font-size: 16px;
}
.dialog-box___ACM0S {
  margin-bottom: 40px;
}
.dialog-box___ACM0S .usr-icon___3Atr3,
.dialog-box___ACM0S .usr-icon-gpt___UPqcn {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 18px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
}
.dialog-box___ACM0S .usr-icon___3Atr3 {
  border-radius: 14px;
  background: linear-gradient(144.94deg, #7cf0f0 -7.27%, #94f6ab 55.01%, #adfd66 117.71%);
}
.dialog-box___ACM0S .usr-icon___3Atr3 .external-icon___deVSv {
  width: 100%;
  height: 100%;
  border-radius: 14px;
}
.dialog-box___ACM0S .usr-icon-gpt___UPqcn {
  border-radius: 14px;
  background-color: #040000;
  text-align: center;
}
.dialog-box___ACM0S .usr-icon-gpt___UPqcn img {
  width: 26px;
  margin-top: -3px;
}
.dialog-box___ACM0S .usr-icon-gpt___UPqcn.chatgpt-img___L2WlR {
  background-color: transparent;
}
.dialog-box___ACM0S .usr-icon-gpt___UPqcn.chatgpt-img___L2WlR img {
  margin-top: 0px;
  width: 100%;
  border-radius: 4px;
}
.dialog-box___ACM0S .ask-ques___nEn9K,
.dialog-box___ACM0S .ask-ans___R7Z7W {
  width: 100%;
  padding: 9px 2px;
  min-height: 80px;
  box-sizing: border-box;
  line-height: 36px;
  font-size: 16px;
  word-break: break-all;
  text-align: justify;
  display: flex;
  justify-content: center;
  transform: translateX(-30px);
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-wrap___1rkae,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-wrap___1rkae {
  display: inline-block;
  width: 76%;
  position: relative;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi {
  white-space: pre-wrap;
  display: inline-block;
  width: 100%;
  padding-bottom: 0;
  color: #1a1a1a;
  word-break: break-all;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi em,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi em {
  display: inline-block;
  width: 30px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy {
  position: relative;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy:not(.no-use-content___2gCi4):hover,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy:not(.no-use-content___2gCi4):hover {
  background-color: #e7fbf5;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy:not(.no-use-content___2gCi4):hover .answer-recommend___NBTM_,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy:not(.no-use-content___2gCi4):hover .answer-recommend___NBTM_ {
  display: block;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7 {
  margin: 0 30px 0 16px;
  text-align: left;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7::-webkit-scrollbar,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7::-webkit-scrollbar-thumb,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .line-content___3ZXC7::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 5px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA {
  position: absolute;
  width: 100%;
  max-height: 220px;
  min-height: 108px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 80px 0px #e4e4e4;
  border-radius: 14px;
  z-index: 10;
  overflow-y: auto;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .origin-content___2ovYw,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .origin-content___2ovYw {
  position: relative;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .origin-content___2ovYw .line-expand___SuiSs,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .origin-content___2ovYw .line-expand___SuiSs {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .current-content___ZiR3A,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .current-content___ZiR3A {
  background-color: #e7fbf5 !important;
  border-radius: 14px 14px 0 0;
  padding: 12px 39px 12px 21px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .remommend-item___1aOs-,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .remommend-item___1aOs- {
  padding: 12px 39px 12px 21px;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .remommend-item___1aOs-:hover,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .remommend-item___1aOs-:hover {
  background-color: #f9f9f9;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .no-recommend___2KN68,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .no-recommend___2KN68 {
  display: block;
  text-align: center;
  padding: 30px 0;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .ant-spin-spinning,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .answer-recommend-panel___xciHA .ant-spin-spinning {
  padding: 30px 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI {
  min-height: 90px;
  max-height: 144px;
  padding-bottom: 38px;
  background-color: #e7fbf5;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-toolbar,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-toolbar {
  border: 0 !important;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck-editor__main,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck-editor__main {
  max-height: 90px;
  overflow-y: auto;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-editor__main > .ck-editor__editable,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-editor__main > .ck-editor__editable {
  border: 0 !important;
  background-color: #e7fbf5;
  caret-color: #0cd7a6;
  padding: 0 41px 0 16px;
  overflow-y: hidden;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-editor__editable.ck-focused:not(
                .ck-editor__nested-editable
              ),
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .edit-wrapper___1fPQI .ck.ck-editor__editable.ck-focused:not(
                .ck-editor__nested-editable
              ) {
  box-shadow: 0 0 0 0 !important;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .editor-ctn___2mgtq,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .editor-ctn___2mgtq {
  padding: 16px 41px 10px 16px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 6px 31px 6px 16px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb {
  border: 1px solid #0cd7a6;
  color: #0cd7a6;
  border-radius: 27px;
  height: 26px;
  padding: 0 16px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb .mathPop___2gqS0,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb .mathPop___2gqS0 {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAYAAABVsFofAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHNSURBVHgB7dpBSgJRHMfx35upFCzyCNJOaFE3GDpBtIuIzAt0g+omDUTWthNkJ7AoslXZBcpFgeXov2dLk//iTTB/6PcBN28hztd54vxnACIiIiIiIiIi0lQ7J9Vy56QGIyIYUXm8OMhKpee5cum50m09LT20NlEwBwMq92drLnadqeW3ucHnSn99v4+CmDhzXITGjOXqcH6h0LPHRByBq8IgM785FjGOgnEUjKNgHAXjKBhHwTgKxlEwjoJxFIyjYBzFn8xzFu9OE8TRpjgsI4hL/Aep/V6XtgA9BHBjvGTRVzqo7/cQKHecxe75kT+IY9jk24423uu7bQTIva0E0oBd/suPDhEoV5xy97w2eztY4hIEyhVnUN/u+VPnDaZJG4FybyuHrImfvZ3rpQl/X8FrhqiJ4GP7A5PtFWOcIJBDtOePJZle97PlFBhfI4zMD4aX/fVG8N0LG7dmHlqpc9ibWhYZSfNjdSdFQfgnUME4CsZRMI6CcRSMo2AcBeMoGEfBOArGUTCOwnacgi+LbTwTCJk5VojiuNBBmo1nAsdIMTX0mtx1GIrcokAm4viZzY2TbMsXmTxu67vIFUay9TOGJSIiIiIiIqJ/6Bt/lYzU6o/KLAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb:hover,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb:hover {
  background-color: #0cd7a6;
  color: #fff;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb:hover .mathPop___2gqS0,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .mathpop-btn___29BNb:hover .mathPop___2gqS0 {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEcSURBVHgB7dphDYJAGMbxBxMYgQhGwATaQE1gBKsQwQZiAiOgCbTB68PwmzAPpuf5+vy2mxtD55/DmzAAEREREe/MLMc/YOiW42qtmmMJrxg3s2dN/BSRTBDXumNbExttlmMHR5vJPrGDv07B3inYOwV7p2DvFOydgr3LhuzM69aCLwuMv+opOPKO7RXHGeNcOMosy854J8buLG1FSEfwDPMDa3TPTioqzvL81U5BwdbecKuROAa/7AlatB6/jxvSVoXsNGSV3iBdzWQEfb+hq3SOdqUda9Xz/pLjiPH2PAvTOwN5wMqeFXaNSPRPyzsFe6dg7xTsnYK9U7B3Cv6w28Dtv826n+KpzfMzW4xbcpwesYfmIEBERETEozs4gTgOP+pCtAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .ensure-btn___2edv5 img,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .ensure-btn___2edv5 img {
  width: 26px;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .ensure-btn___2edv5 .mathedit-cancel___2kERT,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .line-ctn___3ZcZy .operate-ctn___2wV7x .ensure-btn___2edv5 .mathedit-cancel___2kERT {
  margin-right: 24px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi .answer-recommend___NBTM_,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi .answer-recommend___NBTM_ {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi::-webkit-scrollbar-track,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi::-webkit-scrollbar,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi::-webkit-scrollbar {
  width: 5px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi::-webkit-scrollbar-corner,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi::-webkit-scrollbar-corner {
  background-color: transparent;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi::-webkit-scrollbar-thumb,
.dialog-box___ACM0S .ask-ans___R7Z7W .ask-inner___wyQMi::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
.dialog-box___ACM0S .ask-ques___nEn9K .btn-box___c3OMZ,
.dialog-box___ACM0S .ask-ans___R7Z7W .btn-box___c3OMZ {
  position: absolute;
  bottom: 18px;
  right: 18px;
  height: 24px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q {
  position: absolute;
  bottom: 10px;
  left: 11px;
  background-color: #fff;
  border-radius: 37px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q .comment-title___3tGPv,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q .comment-title___3tGPv {
  color: #000;
  margin-right: 30px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q .comment-select-item___2gcGK,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q .comment-select-item___2gcGK {
  background-color: #f9f9f9;
  border-radius: 29px;
  padding: 5px 19px;
  margin-right: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q .comment-select-item___2gcGK.also-so-so___JWHQj,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q .comment-select-item___2gcGK.also-so-so___JWHQj {
  margin-right: 108px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q .comment-select-item___2gcGK:hover,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q .comment-select-item___2gcGK:hover {
  color: #1ad9ab;
  border-color: #1ad9ab;
  background-color: #fff;
}
.dialog-box___ACM0S .ask-ques___nEn9K .comment-ctn___1Xl2Q img,
.dialog-box___ACM0S .ask-ans___R7Z7W .comment-ctn___1Xl2Q img {
  width: 20px;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ques___nEn9K .generate-action___2X3A0,
.dialog-box___ACM0S .ask-ans___R7Z7W .generate-action___2X3A0 {
  position: absolute;
  bottom: -33px;
  left: 16px;
  color: #1ad9ab;
  font-size: 14px;
  cursor: pointer;
}
.dialog-box___ACM0S .ask-ans___R7Z7W {
  margin-bottom: 15px;
}
.dialog-box___ACM0S .ask-ques___nEn9K {
  margin-bottom: -15px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .icon-copy-ask___vw9DN {
  display: none;
  position: absolute;
  right: 14px;
  bottom: 4px;
}
.dialog-box___ACM0S .ask-ques___nEn9K .ask-inner___wyQMi {
  font-size: 15px;
  font-weight: 600;
}
.dialog-box___ACM0S .ask-ques___nEn9K:hover .icon-copy-ask___vw9DN {
  display: block;
}
.dialog-box___ACM0S .answer-wrap___3GcMe {
  display: inline-block;
  width: 76%;
  position: relative;
  padding: 10px 0px 40px;
  background-color: #f9f9f9;
  border-radius: 14px;
}
.dialog-box___ACM0S .answer-wrap___3GcMe a:focus {
  outline: none;
}
.dialog-box___ACM0S .composition-ans___1duHY.evaluate-report___1pnTk {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-box___ACM0S .composition-ans___1duHY.evaluate-report___1pnTk .evaluate-content___3TWC- {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-box___ACM0S .icon-copy-ask___vw9DN,
.dialog-box___ACM0S .icon-copy-ques___eUisP {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK3SURBVHgB7Zo7bttAEIaHkqBClcqU9AliF6qTnCA+glJIUCfmBKHLVJEBFYLU6AjxCawDCEh8gy3TRZUKQo/MJI5BULJ3SS4fWP8fQNBeExDxaXf4mztEAAAAAAAAAAAAAE7hUcGMRiOfTz6VyH6/3ywWi59kgUIEBUHQ3W634+PxGHie16VqUHx8bzQat7PZTFFGrAuSGXM4HO6p5FnzAoolfcgqyaqgGsr5j4qi6Gq5XG4oJQ2yCMsZU/3kCH673Q4oAy2yy/WZsRsWt6IS4bp3zcc4MSy/h5QSa0tsMBhc8k39SAxP5vP5Z6qA4XAY8ulLfIxr0UXaWmRtifET6+RpxTd0RxXxzKz1KSVWa5CL5KpB8RDI4ext8u+73e6SryGb2AyBJqQWFA+BPI2flhXXn5NreYl942vIJvI5XF8UWQiBJqRaYjJjWI4U4rDChCz4fMgXdP84iwvDWFBNQ+Dfe+r3+4V9WcaCXAyBJqSpQc6FQBOMBEkIpNPZIyEwpPJZcZGW/6niIbArJaCIgm20xFwNgSYgKGqAIA0QpAGCNECQBgjSAEEaIEgDBGmAIA0QpAGCNECQBgjSAEEanBXE76s+Jsdky4hSYntvvjLie3D8Qu0dn5LvqVWW/TRnBOn24PitaKY3oK+lBqlmszmhDLwGQbk6zJwVxEtKCvJNp9O5yrPb4UwNkl4BPh7k51arpWxtATkjiGvMA0tZkWUQFDUYCZIpmxzjR+p7qghbIdAE4x5F3n7+fablZVL2DutjCAwTw4q3wS+oAIxrEMu5pURTJP3r0Smss8KUrCHQBOMaFEWRBC1F9SNzCDTBWJB0qUvgonpJyhUCTWimuXi9Xm96vd4dLytecd4bHqqkDU9CIH/+Vw6Bn6bT6S8qkFyN5NI3dK41pkhshkAAAAAAAAAy8wf4kw/wcTZwcwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
  cursor: pointer;
}
.dialog-box___ACM0S .icon-copy-ask___vw9DN:hover,
.dialog-box___ACM0S .icon-copy-ques___eUisP:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALYSURBVHgB7dtPbtNAFAbwb2yniZQscgR3gwhCIj0B9AR0hfgjFj1BwgkaTkB6gixQyxZOQG4QEIVsfQOyaEXauH54XCHFscqMnXFsjd5v1TpSO/pqTz5lXgHGGGOMMcYYY4wxqwiUrDWb+J7n+dghEs7i6vGbbzCglIC6s0k3bLUGIBrGv6GLChAQCOF8DunP6bJ3HKAg4wG15hPfRfNr/IN91IAM6hbXh0VDMhpQ3cL5R4bUWF4fLA6OF8jJgUGeaA7qFo4k1xTu7Q1RgAeDiHCUvSXpPRBNsUvkHEGIQeqak3w/Qk7GHrH2xaQv3OYs/dOd8eXDl+9Qgc7Ps1Ecysn6tRBif9l7FSAHY4+YIC/7bkWrL6hKlL1rPYQ+cjK6B9loqz1ovQRSo/FEUJR6ncjtd358hEkmS6CO3AHdVwI3w0muCXyA58IkuWm25+dGSqCOXI+Y7DmrltyIaVRVQ5aSKkHRUHYuuSaUSDugOpZAuRa5JnlXoyTaAdlYAnVo70E2lkAdWgElJXDz7rkrgSPs3jQugYuNEthtzT/5eUugDq1HzNYSqIOLogIHpMABKXBAChyQAgekwAEpcEAKHJACB6TAASlwQAockAIHpMABKdgbUMN9vnmJ3Gbu4QWjZ/NVSp3Bed7T5Fhq/XUguHrwIvd5mjUBpc/gKPs6UaFPQK0J6H+SIar4M3QUYP0mfTdhJg6LfqBvb0CEhTyWkpNl25x22LNJC2coaPVdfh0iDJaPzJzZ27NJx+Fc9t5OYRgXRQWtgELPCzIXI+cZqmKoBOrQnlHs/Dr/nRl5ERhjdbvbE1ZZAuX4zZqkBPZe76ME+nsQ0Wncxk7S1zCMy1lpkxX3LCRzpWgJ1KE//nJzM5Z/KdSMXFNYsATqyDUGLCcoXFDN/s2geAnUketdTC5ELkg+bpXeTYZKoI6tBsnbF2d94UY7nVVMSmDJg5uMMcYYY4wp/QUJTgEdcm+YkwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .icon-zan___3ITJw {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ+SURBVHgB7ZtbThsxFIZPLkIIHpquoO4KICsgrKC0GyBIgHhjWEHJChr6gLhJDSuAHZCsIOlb35iuoOkDCHFJ+rsapBnPJGM7Hjut/EmjZIydmfxzfI7PcSDyeDwej8fj8Xg8Ho9tSjRH7O3tMbzwgxYXFwftdntIjnEuUBAEtfv7+/3xeByUSqVa/G8476K9c3Z2dkmOcCoQLKbx8vJyJQojwoXCsXVychKSZZwJtLu7u4GXK4Uh4ePjY73T6ViddmVyQORrvpAabGFh4TNZxolAo9HokCJn/Ap8DbeMFqbcR37gfZbfCfi0JItUyQ1rwnlYqVTWBR9zjWnIzxNWA/H41OySJaxb0Pb2doME6wFHWQ4Y0esQL4l2OOs1soh1gcrl8qrYhunVnTKkJ5wzsoh1gWABH4Sm8Pz8fDBlyBtyiFWBms1mDdbSiLdBsO85w5jQf0AWsSoQHHFDbINgE9dC0XIgMSURAfMENYptgVJrH/ik3qT+iFiNjM+4JotYCfNRvvWN0tOlOy19yPBXhP5dsoiyQFH+tIKbfyvTH/3e3d3dbWTlW5guBznDxYgXYm10SPIMYKGDWXI46VyMWwG+KE8sG2SGVrTOyYT7Hwh4S2ZoI49r6eRx0j4IU6RtShx88ctp4nAeHh5MJqUB8rgbHkVJESmBomiySTMS5VsHFxcXzby+/GlzIckcqxApIEWkBHp+fmakCRclWim3lpeX38Ny2rJjcd0AY48iYU2wr2pF2lEMzm89K6LAiXK/wWL9eqenpxukQeQzguhQZmdnh7uF/VhTDaVc7vi7sp9hYx30i9zRERsQgRkpUIRALH4CP/KbHIFrM7ENC82QFCjcgmDiznYmIEaqcoDoqJTLGRVoggN0uXWzIpwPVddCRgWCA0wJZDAC6cDiJzqVAKMCwQGmBEIUc+KkI2tOTDE8rB4pYlQg3EBKIDw1J066Wq2m/A/upU+KONnVsEFWaRdR7ScpYlQgPCEmtmHaufJBokDDnNJuJoVbEEKtK4ESEUy3VFu4BRnOylUwUqot3IJs76VzkINlOeiQNCg6ijmxnqwUg1cWSQPTU2wuBIIYDbFNt5ZteoqJm3yuBBJTDO29tEItyFWiiim2KtyH8vrnlf9uocjLw+KDytn7n4ppJ82EJutpxtPTUyqC6Trov2OpWKwnqiZqQHFMC8TiJ46qiTPXgOIUakGOnDSj5D1oWw/HmEDzUE00VQOKY0ygeagmmqoBxTEm0DxUE03VgOJI/XiB//ASF79JDCyVOrCQ+MUZCdvTGX0Kg699IMamsAYaYidX6lcok5DaWc1ytvjizbxxMn1MgWvx+0y04fyaZkRqivFKnOPdCR1uIVCLZkTaB8F8t+jf4RYP9JOJf36pyHbs9/s/6vV6D76IkeXfKucwjr0PcXxdWlraOj4+Dsnj8Xg8Ho/H4/F45pM/fceodqxtQ4oAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___ACM0S .icon-zan___3ITJw:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASWSURBVHgB7ZpdbttGEMdnyJUlIyrqnqDsSwEFAWz3AlFOELUPBeqgTXQCKyeodYI6J1CCwumjfYPKJ7CKNtGj1RNURS2EiklOdy0nIHclm6SWuw6wP0C2d7zUx1+zszOzBHA4HA6Hw+FwOBwOh2kQ7hCNs0HAGAvE3yyKRtPd7hQsY12grbPBVtRo7ANRj7+brex/aUiAL2etvVdgCasCNf/8tQ2+f6wKI0PDCN53w1Z3AoaxJtBnb193COE473wCmNTC+a7pZeeBBRrjQZAg/FLkGv5NBtHm5s9gGCsC+VQ/EB84YyTgnkF9pOhb8SACNe5Q0muO+bI0CAMbIDxMD8XyiREfha29Scp80hwf8TFmvYa8Dv85BEMY9yARmJd4z4uw9cNEnnvRenIgxMsYPXwIBjEuEPn+jmJkbLj6AjiVxgEYxLhAiPQ4PRYeMvv6+9HK+QCfg0WMCiSSQv6R22kbEv5xy2VBdkgjMIjRIB3X/bZsI59W5kIiHeC/skuS4DZBtWLUgxJkSu4TJ3i6ar4fb7QVIyYnYBAjHnRVb23WB2qApaG0tWdADx/LtovWj0MwSGGBxDZNtdo2xvEXeeaTB19GhB0ujlJvkQ/Pb7wWYSddC4mA3nxzdAA5QcTRJc5H69RwuWuxRdW9cSwH2fJQX+Q5q/7bGP8WMKBz0AF6h+zdu36ZOi53DLqs1w91icM94dVN4ggaYaivKOUlCl/ivy920WLkEkjsJojwFNaF11u8xnrO+zvPbpsqvu2l9Vj5196JNjZ6UJBcArFo0eUrxaIIHYolxebzr2b39w7zXlqbz3u8kfZi8Rwa8HC/qBetsYvFj5btKPfGr8+ztRaeXrT2OlCC65jRu34Upvn26JBH6v2UicdRJvKqYd7nqDwP4jvRP2AJSt6/VGyxF0ABtAskV+pIyb9gCc/zA9mGfjKBAlSfSeuKH2VemtTOAQujQrWcVoGWBUBCtHd0g7gtWaZFcyGtAoWNhrpDJGTzbCvIDot3ArQK5MehIpDHfCtB+sqbUeoEJHAKBdEqEBJTlxhdWgnSEWNK/EHfP4OCWDnVMMGy1m7ieX9DQbQKRKjmGOTXbcUgWaDpTa3dVVTuQXEcWxGI52PSDlauVas3BqF64qC1Ki8C6mnVVu5BNm5huffXQIk/BDiBEugVSL1Lw4r3LC0xMLa/xPi3tJUd2xGIQD09KdvL1huDpEM+HpNsxZ9taVz6LE3zEqPsEiNLZQahlEFj4fznA3bu7qiQ68NGaamLjmY5dMegIGvwjJcZtSUtjrIBWlDpNm+jm6ijB5RGd5AOMmMb3UQNPaA01SaKdrqJQXa43t0g2gS6C91EXT2gNNoEugvdRF09oDTaBLoL3URdPaA0pfMgAva0+eao/XGMGMhzEqIOn7MLJljUgfLxeKkeUJpcAhFGUwQfsu+HnvGj3NRYRZ5jGp6XncCa5Fpiswfdkc3zrTLwQvk8BujDmuSOQQhRFz4RhDgQ03fL7r0uSm6B/rv/04m4YQHWqGsqgj48roQB6tfC+TezB0/Wij0Oh8PhcDgcDofDUSH/A41qn9LJOnLQAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .icon-zan___3ITJw.active___1ZJIa {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQESURBVHgB7Zs/TBRBFMa/N0tpcWqhnSTai7aagJV0EmNjZSisLIBWQ4RgYScNtVDZYKDUyjPR2rMnATssTK7Ajpvn2z3+7O0sl529uZnzMr/kDu4xc7d89+bNe293gUgkEolEIpFIJBKJ+IYwQvBKYxIJJrMXHbRopd1GYIILJKI0MKEW5NdFeTQKf21CqU16+WcLgQgqEK81ZkBqB4YwxsgmjnlePOoAngkmEK9dnQPxTvUJOEBH3/G97BQCkMUaxe+sJpHEpgn1Gp4J4kH85sqm/HjWa8SRHM1HOaS9E8M9eZo1J+sHtNxuwhMTCAFhWgQ5h3EI5iUgOcwN+gbSv1EUEmpOnprwhPcllgVmPtnKTyHeBuXFOR2sNjPxesdOwyP+YxBhyrBp+tlnRqvwBpPwSIAgTY96XqYeQmrv4uF8CQHxKlCWFIJmeoyEvf6TcL1gaMEjfj0oSWYMG9P3C8crEYfoVmF8v+XoHL8CleU+rC/2CObbplHvwiNetvluvUXvjd0rXS5lu9cZ+n4xVfOZA2WfB0uybVqp29C4XHHKDfmUNHcpq7eey1beJ0DrD/J8HoOYD8RoUbhSC51Oa5AarrJAJ16wYwTZ+mxlec6FHyjCqEwgF6zjWK/WqeOqx6AJte5QnM99xUkhKT3csSjH/6W7i9pRSaCsuDRS/hpk9RZviDhvK4xOBfoEd0whUYuWcyp6UFIMrhakonRzF4kd6il0sl19rtoQT9ruvocDCAu2XjTILrYk/0DJFi1xg3KBlaRU4GQZ9TiCFpGAjZ7itipKv5B5T3KWhnzZaanTrPwWGD4uY4kdLLHOsCWTsMC9QFQoDTigQETXTFvnABaMtwdpvmXYOrCq5VwLZFbexAE9yBCobZsLuRWIUdaaCBmDBu4EuBVI6RKB1F+E4VJJJ+ArLHErkC7zoEBLjLUZf0j/gCVBTvt4QfFNw0b4BUscL7GSbVWHikFkBuhX7cAxqJRQS6y4g9Vr1Trexfi6YaMkjECOWrXjmShSWYCWc/s1GHaiGMp7zFjYr/fdh/EUSLNxcrJuL9utQMWTfEyBPAhOAnSKYw+iXoFIhyozijHIOv85ZfwSRZXVX4WlTk3UxHWxWtjmQyyxkgy6ZoBOGbYH+RfIQQ8oj+MgPQLdRAc9oDzj50GOrwZxKdAodBOd9IDyuBNoFLqJjnpAedwJNArdREc9oDyDnDh8KFtG/vods5LPLuUtOehhoMSDmYqXDdfqAeWpJpBCu+TM5myFa0OqjHEDl9oGvtiq0hLrfgsc/M4bK5j30dGrGJDqMYh5Hv8LqTiKH7u4+aWyQNIu2E1vA8juvBkt+OxB2JfnVXT47qCxJxKJRCKRSCQSiUSGyD9H/k6b2HhqvAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .composition-ans___1duHY {
  display: flex;
  margin-bottom: 5px;
}
.dialog-box___ACM0S .composition-ans___1duHY .evaluate-title___2uAsE {
  max-width: 200px;
  font-weight: 500;
}
.dialog-box___ACM0S .composition-ans___1duHY .evaluate-content___3TWC- {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
}
.dialog-box___ACM0S .icon-cai___1xVo2 {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARCSURBVHgB7Zu7ThtBFIYPvkgUSDFvsGW6WJgiHUuXdH4DjASG0jwB5glwOi5Ccbp0hjIVTpcCw6ZLuenSxSmQHItL/iOtJXt27ezMrmcWmE9C9oxn18s/c86cOTMmslgsFovFYrFYLBaLbhYoJRqNRun29tbJ5/MlyhiLi4teq9XqkwKJBdre3i7j5XBhYcGlbNM6OTnZI0nylIB6vV7FSwfivKbs87ZSqZR6vd4XmYuUR9Du7q5zf39/A3EyZ1KzGA6Hy+12O7a55UiRh4eH5lMThykWi1WZ9gVSZ00o+xhRexBNyRnKgu9x8PdRqD5Ax3VHhVwuxx3YoQQoCVSr1fiLHaH609nZ2TlpYmtry4VAE3UQpAtH3B2V2Q1AMEqCkokVCoWyWIeHvaFniJJA6Kkogb6TRtjEIqp9ShlVJy36n/7R0ZFPzxBVgZzxAnrTI81EjaB5dJKqQBMmBkeo1bx0Ii0Qzx6hm+Ryl6SZx8dHMQabS3ghLVCUg8YI+kmaiQhSsyEQes4R605PT7X7IPBKKGdmBL0RqkyIExpB84rgpQWCOU2YmIkZTCdSAnHuR+w5mJyRGSzC1H2aA1ICYfQ4oRvkcnYEjUA6NTSDDQYDUwI54wWMqLnMpFICwbzEJYYnk3x6isiamOigtcc/TJBuETE7i/FDRThoI+aFXYqQQHgWswJlKQeEzGVIIEwWv2kOxBYoCzmgERHrMH6WPzQHZHzQi8kBjSMjkDNeMBlB68omMjICvZgc0DixBMpKDmiErmwio7xx+FKItS+GKd4X95cwk7Sw76Q0c6C3u5QMRyj38Syu2AjhgCPunckS++qdnZ1LiOJSOvgw0XUZs2AB8P0b6Khqki1vXFs9Pj6+iNs+tomhNw4oPXjHM7YPq9frh9weAtWSngeQjd1iH3+5vr72K5UKjziX0qG0srJygfv+mtUI4nC0LnXgYBoQ+AO2pj/LXCPlpHHzJr5kk1JKs/7vNBqPHBLCCxV4ncbiIHfeIEkSnQ+6u7tz4raHGPuCD+tD8OVp7Tm0mBJKeLhPWyaTubS0pHwET/n4S+Bg/bjtka7tjM8oeP91VvtA0Ik61VGQBC1xUHBURUyVTD23w6mViBnT1y0Oo0UgmIor1s0yEYweN6K6SQbQIlBEqtaftdkIgUKz1nA4jB27pIkWgcT8TYxYZE1o3zWV+za1Fns17QOeHUlYSpjMHOgSaOIfxIgqB0KEgBgbYh1M7pwMoUsg0d+UIERHFClYcDaFtn4Ki1tlkhwDjg0cbLtYLO4LU32Z11dYBHd50w9/ayi74rXsf8ggiX6KEBfP8warq6t/8fad8BELxksJd1oaFSHC5tXVlbHNSS0CMb1e75vMYjfY53qP5cgPMog2gRiI1I0pkgfHvG5aHCa134vJwM4ZI6TBfoeC1XowYjyYGqckjM1aFovFYrFYLBaLxaKBfxlCo3LuuJS3AAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___ACM0S .icon-cai___1xVo2:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARzSURBVHgB7ZtRcts2EIZ3QbjSjNyJcgM+dSK3nSonsHMD9aWTxm1T3yA5QZwT2DmB0mnjzPTF6QmsnsDuNGN3+qSeoOpY7khjihuAshIRlG0ApEA6xvcGmBTpX9hd7C4E4PF4PB6Px+PxeDwe1yAURPOw2zxf+SREiptQMXgUHQ3ubw3AgtwCNd6+amMAO+KjNqDKINsd3nv4FAzJJdCnx3sdAuiKT6ncqlmIhUjWAtVPuiGn2uGNEecCPhrfNTE3BpYEVNu+aeJIhJ/smFzPwRaE9fmhMLU+o+gpIVo5Q1MIWIiAXWX2OUDcm42QqEnI9yEHVgLJiBUBhPNzGNNPp5//8AYcsfrnzxvAA2U27g1b3/dmo/rJ65DLry4HViYWcd5W5zAIDuEjxEogCoKMQOdEf4BDCFmozkXA+1AwVgKJ0LeuTA1GrW/78BFiG8XC9JCOwDGI6jsALONLshMIIW1iBE7NyyXGAiXRQwFZcACuye7BlrK9MBZokYOOGfsHHEOAzfS4IgIhUKjOnX32jXsfBHAnNcaKCCTe7Ctl7Fyc6XMpbWJEFRGIMGViRFiOQI4wEqjxttvOOEfHG8T3jwUM02PWhyVgJBBjQajOIU78CppBlI1gfBSVIhBmkuV4KZHUzAcxJcUQDtq21ntTMBNIcdAQo/P9j0SWW9S5ZdWhtAVKXirjoONSzGtUr2crmXHJYb5KNaBgMsoIxHjwLywBbYGqUAOagcSzJkbn/8ES0BboNtWA5jFx0mF6SKX4n+TJjqqJEn2BblENaB4tgSpTA5o921E1UWLdOLwtaPXFRIjvq/2lmOJdsbKsIsfwyw+9KxvEpjBUeuaDRaucJnEIQb7zGdp3r568OijqBIfswk5g/GDU2urr3iMFIM4eI2EnT8sbkXVO7z38Tfd6AxOLn0NByEQzgJq2D1v9a29HdFEPRKv5x7znAUz3btoCTVu6VKhIjb9/bV93nRDnUCy5J1AERC9MnbmRkx62NrcJaEu8cCF7IJyMr1wNycohuFbEayFRrxbiDNc2jYXOcT5IHgyIQv072DPFhw2GrUd3L7v64nBC1gxFiUVUD18inWubiqxZ2ZZlrI+/XCzVvu71q8d7+6mvg/D3K2/gUlAFuQpam8WYmyZO9kHJalCcKwV06bmdab0nHTFl5LMxkby42SgytpGZQ36piUxqQfZ6BttQAo4Eyp5Gu6rZGAPvqHMr/4+19y5F4kYgSreJxWbvageLammFemXVvsvJxTC+c9mf5OlZtWNRZuXAiUDCpJR/ENtSiEXXcqg/zkxi/AZKwtUKUv1Nk2NtXxVpmnDS9vxcEr1a+ZLbPNgfAzZgZTx+GdVqz1KhXuyQZT7WOHndS5p+iSNflAxjD0qksB+zXEfj+JcnIpPeMblnmvXjgzJr386c9Nnad7tGya7Mnyb0ddmNAadRTCa7WiKJfCtCvH/2xWbpByOcmdg8SaJLsUgbcP19M0CuGKQjsUd6cbr2qLSo5fF4PB6Px+PxeDwOeAfZRXwESQkdAgAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .icon-cai___1xVo2.active___1ZJIa {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP5SURBVHgB7Zq/bxMxFMffc8KWIYiBjBm6E/ZKLVNZkIrEHwBzl3ZioFGDCjNlYGKg/AewsdGhzJQdpI5daG8oC2r8eL4DmrOvydl3sa+tP1KlnHVXn77n99MGiEQikUgkEolEIpGIbxBqgkbdLtyAPkjoQtMYwwGOkgQcqCwQvewOgPAV/6tlaDY7uHm8AZZUEoi2b60C0jv+2bxVU4y1SM4CsUn1oS2+wuURJ+NM3rQxNwGutMUILps4CtFatbm9Da4gLAFNXBMcAeIb/nEKPiDssXk/1Ubf89/B+SV2lCOACjgJlEYs4oiV5xO/9H6NgXHGS8hBwVQHQOJcIIIeCIIquJlYCwbGGOIPuIK4CYQFAkn5HXwi8LYxJukIasbRSeOSNnAK2Kr95ZqAq0D9/DX5XT3plNQzxubwkVzDfN7ECP0L5AlrgWi7u2wMIn4D/3S067mkF/YrqNBBUwj/01CBSPSNMRT+TQwpLxBhU1YQ3cldB/M/mBcIZUME0h000pV10AorgdLeD6BWoAYSSJUROXAuftBuBclW3xy8miXGPyxNjMwIRiLMCkJtBaluwhywEwgpX2JkDtpPeyMQtk5ad9Ch6q+OMYIUNoqlPSDdQROEctCdgtHAYb5JPSAhCwQSv2AOlBeoCT2g//MWrSAKnShenx7QJDYC9fPXATNoT93EdCqLe69ND2iSUgI1qAeU4ambqHDfOLwmlNsXG8OheSet8Zd0ixw4sXfl9LxeqHLAUPtkOmpzsSKld/noxc3PNZ7g4F1Y2uDit7xZKAEErPCvRYDCRLEkchU3k49l7y5vYkTPoT562ZGZ0pOv8Zuq++9DJXGYM7DynaUFwmGyxyl+zSLJhZl3CXrLq+0R1AHBaxwlhzaPWDlpHB6POHt+wjNV8yHns89YDamfmy3iTChJxRker4Ml1c4HtYwDDFNmwi3Nh7FjFQ8uniD1OQVmyB8HaZfLjfKmEvIIXlnYyZ9o3YB9Fmh44QMolThacuq2Cqrgfj7IgizR1Fsl+GXKI8r0NHHoEIcnXsVReEoUxbIxRFNqOaFtLSlaNIIA+BHIaNWqPGhKL5to0Rj7DaVzlzrxtII088KZnUgtK6Y9VydblVC12MXhXaRlhLZjEa4w9iMQGSF5AcS4uE6StFIw+AEC4ckHST2x7IAU24ZI6cFMepwfU9GLs/hAeAnzXP/sQpu2cr4IOUNW9ZgSL3XaMCjsewPuQUA8JopdzmGERYEK6eqBMd2zrZ/qxJuT5hbDjl2xy/WToIchxVF4jWJpsVtKJK63zuguPkvqKYor4M3EJskKXbGeJZD41++QynPUSXmut34Gi1qRSCQSiUQikUgk4oE/MztDr9z3FKUAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.dialog-box___ACM0S .remark___2Kpw9 {
  color: #868686;
  border: 1px solid #868686;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  display: inline-block;
  transform: translate(12px, -5px);
  line-height: 10px;
  cursor: pointer;
}
.dialog-box___ACM0S .remark___2Kpw9:hover {
  color: #1bc37d;
  border-color: #1bc37d;
}
.close-modal___2U46y {
  color: #333333;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABICAYAAAC6L9h5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKCSURBVHgB7ZpBbtpAFIYHVixYuDdIb0CPkNygvUEXSOygJ6h6grJEYtOTJEdIbhBO0GQBEiyAvj+1o1FijMFv3nOS/5NGzyB75unTjD32TAiEEEIIIYQQQgghJKcTlJhMJtl6vR70er276XT6GByRXMJ+v882m41KPiqSRqPRxXa7ve10Opn8XEiCX+fz+V1wYDgcInyRcp3ncy/5fGuSTzcosNvtfucJgQs5vpFkB8GYEkHgc7fb/RkaoCKphMxa1AFBKqhIku78S8rLcW8mqkqQ5PUg//0IDVCRlI/3Sw9RxwRJuJrNZovQALWnG8hl3JR090dJ+FL7Zl5HkEabqpKAlSgrQUBdEkgtylIQSCIJpBJlLQgkkwS0RXkIAkklAS1RXoJAckmgqShPQcBEEjhXlLcgYCYJnCqqDYJAqne3Us6YmbsLAqY9qaBOjzp0jrUg4CIJVImSMhEZ0zYIAm6SQIWoV3gJAq6SQB1RnoKAuyRQJcpbEDB9up2DiAvevInhJjxIj/qYw+2UG3dwFOU23CrmQZgrfS+ZcH6Sc689VmFcJFUJkoDJ5J9QPjN3EWUuqYagp+GUx6s2iDKVVFdQxG1ogSj3TyUVgorrEA4tOprczF0/uh0TFF2P4CbK7fNtXUFRPQguolwWAk4VFNWHYC7KfEnpXEFRvQimokwXJ5sKiupHMBNltsytJShqB8FElMmGCW1BUXsIyUWpSbIWFLWLkFRUij2Tz6QWVJBaVIo9k09YCQLSBsLBV5hW7pm0FFRwRFQj1PZMSljkx+aCCmJRUu7/p7P/23TPpOpm9+VyOej3++6b3cfjMUK2Wq1akQ8hhBBCCCGEEELIO+If2AWTJ1upolYAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.ant-input:focus,
.ant-input-focused {
  outline: none;
  box-shadow: 0 0;
  -webkit-box-shadow: 0 0;
}
.cursor-blink {
  animation: blink 1s infinite;
  color: #0cd7a6;
  font-weight: 800;
  margin-left: 5px;
}
.ant-input {
  resize: none;
}
.mathtex-block {
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
  cursor: default;
}
.copy-tip {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  background-color: #000;
  color: #fff;
  left: 0;
  top: 0;
  border-radius: 5px;
  position: fixed;
  display: none;
  text-align: center;
  transform: translateX(-50%);
}
.copy-tip::after {
  content: '';
  border: 5px solid #000;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback-modal .ant-modal-content {
  border-radius: 14px;
}
.feedback-modal .ant-modal-close {
  top: 5px !important;
}
.feedback-modal .ant-modal-title {
  text-align: center;
  color: #1a1a1a;
  font-size: 17px;
  font-weight: 600;
  font-family: PingFang SC;
}
.feedback-modal .ant-modal-header {
  border-radius: 14px 14px 0 0;
  border: 0;
}
.feedback-modal .ant-modal-close-x {
  color: #333;
}
.feedback-modal .ant-modal-body {
  padding: 0px 25px;
}
.feedback-modal .ant-modal-footer {
  border-radius: 0 0 14px 14px;
  border: 0;
}
.feedback-modal textarea {
  padding: 14px;
  border-radius: 20px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal textarea::placeholder {
  color: #a6a6a6;
}
.feedback-modal .ant-btn {
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal .ant-btn-primary {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.feedback-modal .ant-btn-primary:hover {
  border-color: #2aebbc;
  background-color: #2aebbc;
}
.feedback-modal .ant-btn[disabled] {
  opacity: 0.3;
  color: #fff;
}
.feedback-modal .ant-btn[disabled]:hover {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.model-ques-input___1yjpS {
  resize: none;
}
.tal-gpt-box___8Cico {
  width: 76%;
  height: calc(100vh - 120px);
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  color: #1a1a1a;
}
.tal-gpt-box___8Cico.hide-example___GdrAJ {
  display: none;
}
.tal-gpt-box___8Cico .tal-title___J7q4g {
  font-size: 35px;
  text-align: center;
  padding-top: 120px;
  margin-bottom: 80px;
  color: #040000;
}
.tal-gpt-box___8Cico .tal-title___J7q4g .title-bold___iPSU3 {
  font-weight: 500;
}
.tal-gpt-box___8Cico .tal-title___J7q4g .title-thin___2bbGX {
  font-weight: 400;
}
.tal-gpt-box___8Cico .tal-content___2LF8W {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 54px;
  box-sizing: content-box;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP {
  display: flex;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ {
  display: flex;
  flex: 1;
  justify-content: center;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ img {
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ p {
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt {
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  z-index: 1998;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt .arrow-louder-icon___Hgw1L {
  font-size: 14px;
  color: #5e5d5d;
  margin-left: 2px;
  transform: rotate(180deg);
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt .arrow-icon___3PMV8 {
  transform: translateY(-1px);
  width: 24px !important;
  height: 24px !important;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-primary___2nIF1:hover .arrow-louder-icon___Hgw1L {
  color: #0cd7a6;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-primary___2nIF1:hover p {
  color: #0cd7a6;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-primary___2nIF1.active___1ZJIa p {
  color: #0cd7a6;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-primary___2nIF1.active___1ZJIa .arrow-louder-icon___Hgw1L {
  color: #0cd7a6;
  transform: rotate(0deg);
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-junior___Q0iu5:hover .arrow-louder-icon___Hgw1L {
  color: #1890ff;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-junior___Q0iu5:hover p {
  color: #1890ff;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-junior___Q0iu5.active___1ZJIa p {
  color: #1890ff;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-junior___Q0iu5.active___1ZJIa .arrow-louder-icon___Hgw1L {
  color: #1890ff;
  transform: rotate(0deg);
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-high___KnjmM:hover p {
  color: #ff7f09;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-high___KnjmM:hover .arrow-louder-icon___Hgw1L {
  color: #ff7f09;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-high___KnjmM.active___1ZJIa p {
  color: #ff7f09;
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .item-content-area___19jXt.item-content-area-high___KnjmM.active___1ZJIa .arrow-louder-icon___Hgw1L {
  color: #ff7f09;
  transform: rotate(0deg);
}
.tal-gpt-box___8Cico .tal-content___2LF8W .tal-tab-title___eKmLP .tal-tab-title-item___3Z5aQ .anticon {
  font-size: 30px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J {
  display: flex;
  position: relative;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE {
  position: relative;
  padding-bottom: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 290px);
  overflow-y: scroll;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE::-webkit-scrollbar {
  display: none;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE::-moz-scrollbar {
  display: none;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE::-o-scrollbar {
  display: none;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE::-ms-scrollbar {
  display: none;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh {
  color: #1a1a1a;
  width: calc(100% - 50px);
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-radius: 14px;
  opacity: 1;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 10px;
  justify-content: center;
  border: 1px solid transparent;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .p-green___1Yn0F:hover {
  color: #0cd7a6;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .p-blue___1APpe:hover {
  color: #1890ff;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .p-orange___2xqGr:hover {
  color: #ff7f09;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh p {
  text-align: left;
  margin-bottom: 0;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 22px;
  font-size: 13px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-green___1Xj7B {
  background-color: rgba(12, 215, 166, 0.1);
  color: #0cd7a6;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-green___1Xj7B span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #0cd7a6;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-green___1Xj7B:hover {
  color: #00ba8d;
  background-color: rgba(12, 215, 166, 0.2);
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-blue___hYzUL {
  background-color: rgba(24, 144, 255, 0.1);
  color: #1890ff;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-blue___hYzUL span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #1890ff;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-blue___hYzUL:hover {
  color: #0676dd;
  background-color: rgba(24, 144, 255, 0.2);
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-orange___LtocQ {
  background-color: rgba(255, 127, 9, 0.1);
  color: #ff7f09;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-orange___LtocQ span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #ff7f09;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .tal-ques-colum-item___c2cIh .knowledge-points-orange___LtocQ:hover {
  color: #de6b00;
  background-color: rgba(255, 127, 9, 0.2);
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .p-border-green___2Id-m:hover {
  cursor: pointer;
  border: 1px solid #0cd7a6;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .p-border-blue___1GBeX:hover {
  cursor: pointer;
  border: 1px solid #1890ff;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE .p-border-orange___1VKYy:hover {
  cursor: pointer;
  border: 1px solid #ff7f09;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE:nth-child(1) {
  margin-left: -15px;
}
.tal-gpt-box___8Cico .tal-ques-content___Ubc2J .tal-ques-colum___mOLIE:nth-child(3) {
  margin-right: -15px;
}
.tal-gpt-box___8Cico .shadow-box-content___3ozxh {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -40px;
  z-index: 10;
}
.tal-gpt-box___8Cico .shadow-box-content___3ozxh .shadow-box___1gEVd {
  width: 100%;
  margin: 0 25px;
  height: 40px;
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.tal-gpt-box___8Cico .shadow-box-content___3ozxh .shadow-box___1gEVd:nth-child(1) {
  margin-left: 10px;
}
.tal-gpt-box___8Cico .shadow-box-content___3ozxh .shadow-box___1gEVd:nth-child(3) {
  margin-right: 12px;
}
.hidden___1cU07 {
  display: none;
}
.model-tip___2Kxc1 {
  position: absolute;
  top: 26px;
  right: 30px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.know-point-modal-wrap___3IVqA .ant-modal-content {
  height: inherit;
  width: inherit;
  background-color: #f5f5f5;
  border-radius: 0 0 14px 14px;
}
.know-point-modal-wrap___3IVqA .ant-modal-header {
  background-color: #f5f5f5;
  border-radius: 14px 14px 0 0;
}
.dialog-list-area___39gRx {
  height: auto;
  position: relative;
}
.dialog-list-area___39gRx.tip-show___3dVRq {
  z-index: 101;
}
.load-more___6KlIz {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00ba8d;
}
.load-more___6KlIz i {
  margin-right: 10px;
  font-size: 20px;
}
.know-point-popover-card-area___29N0m {
  width: 75%;
  left: 0px !important;
  right: 0px !important;
  margin: 0 auto;
}
.know-point-popover-card-area___29N0m .ant-popover-content {
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
}
.know-point-popover-card-area___29N0m .ant-popover-inner {
  border-radius: 20px;
}
.know-point-popover-card-area___29N0m .ant-popover-arrow {
  display: none;
}
.know-point-popover-card-area___29N0m .ant-popover-inner-content {
  padding: 10px 0px;
}
