.treasureBox-out___fx8A- {
  border-radius: 30%;
  position: fixed;
  right: 43px;
  top: 28px;
  font-size: xx-large;
  width: 35px;
  cursor: pointer;
}
.treasureBox-ul___Wfl34 {
  /* 添加你想要应用到ul元素上的样式 */
  /* display:none; */
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0;
  padding-left: 0;
}
.treasureBox-li___2CVg_ {
  /* 添加你想要应用到li元素上的样式 */
  /* height: 220px; */
  height: 264px;
  /* height: calc(100% / 3); */
  background-color: #fefefe;
  margin: 0px 8px 15px 8px;
  border-radius: 16px;
  /* width: calc(100% / 3.5); */
  width: 28.6%;
  font-size: 14px;
  position: relative;
  border: 1px solid #e8e8e8;
  overflow-x: hidden;
  padding-bottom: 7px;
}
.treasureBox-li___2CVg_::-webkit-scrollbar {
  display: none;
}
.treasureBox-li___2CVg_:hover {
  border: 1px solid #6eecce;
}
.treasureBox-li___2CVg_:hover .treasureBox-li-button___1ZyCE {
  background-color: #53e8c4;
  color: #ffffff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABsSURBVHgB7dLRCYAwDEXRV3EQNzObGCdxNTeJBiyEIPTHBIRcKO1H6SmlwB8TEdKBjB6styE6B6ahXGihYehsNtA9Lfi205ypF0Az2IGEJiTX+iLoSVd35o6o9JO4T8OIqrDCRhilYS8gj/ZfK4g9n+lmBvcAAAAASUVORK5CYII=);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50px 13px;
  padding-left: 15px;
}
.treasureBox-content-div___3wseC {
  margin: 0px 10px 8px 10px;
}
.treasureBox-li-title___5uC1K {
  display: flex;
  /* 使用flex布局让图标和标题在同一行 */
  align-items: center;
  /* 垂直居中对齐元素 */
  /* margin-top: 5px; */
  padding: 5px;
  margin-top: 15px;
  margin-left: 19px;
  margin-bottom: 1px;
  align-content: center;
}
.treasureBox-li-icon___1idQ3 {
  margin-right: 0.2em;
  /* 图标右侧添加间距 */
  width: 35px;
  height: 35px;
}
.treasureBox-li-h4___xx2ok {
  font-size: 16px;
  margin-bottom: 0px;
}
.treasureBox-li-description___82mtl {
  /* width: 450px; */
  /* height: 30px; */
  width: 90%;
  height: 137px;
  padding: 5px;
  margin-left: 19px;
  margin-right: 19px;
  /* margin-bottom: 0; */
  font-size: 14px;
  font-weight: 400;
  overflow-y: auto;
  line-height: 28px;
}
.treasureBox-li-description___82mtl::-webkit-scrollbar-track {
  background: transparent;
  /* 设定轨道为透明 */
}
/* .treasureBox-li-hr{
    margin-top: 50px;
    color: black;
    width: calc(100% / 1.1);
  } */
.treasureBox-li-button___1ZyCE {
  position: absolute;
  bottom: 16px;
  right: 24px;
  border-radius: 26px;
  background-color: transparent;
  color: #1a1a1a;
  width: 74px;
  height: 35px;
  margin: 8px 0px 8px 0px;
  border: none;
  margin-right: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACvSURBVHgB7ZTRCcMgEIZ/22cX0JeMkhG6QbtJzSRZpd0gU7iAA5gTDPiQGIOXQEI+OFD54eOEO+CMaK0/oUqyT1QSRN77no4vKSWcc/9c/gFejFLqmwtUd0gdDdSZoGMbn9pcp9XCKP2VSlmEW6RswlIpq7BEyi5ck4opFOepAS9vqia5G5HIehwA9+CvcvSXdtiLsOKofFIGe3HLri0TqGRmaXTWWrOU5x78rCwwApv3dfBjle1jAAAAAElFTkSuQmCC);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50px 13px;
  padding-left: 15px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.treasureBox-li-button___1ZyCE:hover {
  background-color: #53e8c4;
  color: #ffffff;
  font-weight: 500;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABsSURBVHgB7dLRCYAwDEXRV3EQNzObGCdxNTeJBiyEIPTHBIRcKO1H6SmlwB8TEdKBjB6styE6B6ahXGihYehsNtA9Lfi205ypF0Az2IGEJiTX+iLoSVd35o6o9JO4T8OIqrDCRhilYS8gj/ZfK4g9n+lmBvcAAAAASUVORK5CYII=);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50px 13px;
  padding-left: 15px;
}
.treasureBox-li-unbutton___2MghA {
  position: absolute;
  right: 39px;
  bottom: 2%;
  border-radius: 26px;
  background-color: transparent;
  color: #1a1a1a;
  width: 75px;
  height: 35px;
  margin: 8px 0px 8px 0px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  margin-bottom: 12px;
}
.treasureBox-li-undev___8QFS0 {
  margin-left: 5px;
  width: 16px;
  height: 14px;
}
.treasureBox-li-button-icon___3fOAB {
  margin-left: 8px;
  width: 8px;
  height: 6px;
}
/* .treasureBox-img{
    margin-right: .5em;
    width: 20px;
    height: 20px;
    background-color: black;
  } */
.treasureBox-span___28V7l {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
/* .treasureBox-ul,
  .treasureBox-span {
    text-align: left;
  } */
.menu-item::after {
  border-right-style: none !important;
}
.svgicon {
  position: absolute;
  left: 12px;
}
.content-scroll::-webkit-scrollbar-track {
  background: transparent;
  /* 设定轨道为透明 */
}
