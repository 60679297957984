.control-icon-btn___1TEcX {
  border: none;
  background-color: transparent;
  outline: none;
}
.control-icon-btn___1TEcX .control-icon___3HoiE {
  color: #c5c5d2;
}
.control-icon-btn___1TEcX .control-icon___3HoiE:hover {
  color: #fff;
}
.control-icon-btn___1TEcX .icon-yuyin___eZ3qJ {
  font-size: 26px;
}
.buttonActive___3aiIH {
  display: none;
}
.canvasDiv___31bDa {
  display: inline-block;
  height: 0;
  width: 0;
}
.gif-icon___3qmWn {
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  transform: translateX(3px);
  cursor: pointer;
}
