.basic-item-child-top-tag-text___3p0uo {
  border: 1px solid #333;
  background-color: #102331;
  color: #fff;
  width: 120px;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  display: inline-block;
}
.basic-item-child-left-tag-text___1612q {
  border: 2px solid gray;
  width: 68px;
  min-height: 20px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-sizing: content-box;
  padding: 0px 8px;
  margin-right: 10px;
}
