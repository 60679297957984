.help-ctn___b8qFS {
  padding: 0;
  margin: 0;
  list-style: none;
}
.help-ctn___b8qFS li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.triangle-line___3CWG0 {
  position: relative;
}
.triangle-line___3CWG0::after {
  position: absolute;
  content: '';
  width: 75%;
  height: 60%;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-color: transparent transparent #fff;
}
.read-excellent___3t5us {
  color: #318323;
}
.read-shortcoming___2JEvj {
  color: #962d27;
}
.read-missing___Xmg-9 {
  color: #797e82;
}
.icon-text___3ahxO {
  margin-left: 10px;
  color: #787d81;
}
.triangle-up___1Fdls {
  transform: scale(1.2);
}
.read-block___3bVc- {
  padding-left: 2px;
}
