.banner___352Sr {
  position: relative;
  margin-top: -1px;
}
.banner___352Sr .bannnerImage___15gFJ {
  width: 100%;
  display: block;
}
.banner___352Sr .bannerContent___2W8bj {
  width: 100%;
  height: 157.09px;
  position: absolute;
  top: 185px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.banner___352Sr .bannerContent___2W8bj .bannerContentInner___jF0hY {
  height: 157.09px;
}
.banner___352Sr .bannerContent___2W8bj .bannerContentInner___jF0hY .bannerContentInnerTitle___2fLc9 {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  text-align: center;
  line-height: 157.09px;
}
.title___2SVI1 {
  width: 100%;
  padding-top: 51px;
}
.title___2SVI1 .itemTitle___2VREu {
  display: flex;
  font-size: 28px;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #0c0e0f;
}
.title___2SVI1 .logos___2Kq6f {
  list-style: none;
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto 0;
}
.title___2SVI1 .logos___2Kq6f .logoItem___IgJd5 {
  display: flex;
  min-width: calc((100% - 10px) / 6);
  padding: 25px;
  justify-content: center;
  align-items: center;
}
.title___2SVI1 .logos___2Kq6f .logoItem___IgJd5 .logoImg___3etPq {
  width: 150px;
  -webkit-filter: grayscale(100%);
  /* Chrome, Safari, Opera */
  filter: grayscale(100%);
}
.title___2SVI1 .logos___2Kq6f .logoItem___IgJd5 .logoImg___3etPq:hover {
  -webkit-filter: grayscale(50%);
  /* Chrome, Safari, Opera */
  filter: grayscale(50%);
}
