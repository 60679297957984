.data_box___3uCcS {
  margin-bottom: 40px;
}
.data_box___3uCcS .banner___3MAhq {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.data_box___3uCcS .content_main___18Ggt {
  margin-top: 20px;
}
.data_box___3uCcS h2 {
  margin: 20px;
  margin-top: 30px;
}
.data_box___3uCcS .home_img_1___Lrdfm {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.data_box___3uCcS .home_connect___5auUw b {
  margin-right: 8px;
}
.data_box___3uCcS .home_connect___5auUw p {
  line-height: 35px;
}
.data_box___3uCcS .home_connect___5auUw img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.data_box___3uCcS .home_connect___5auUw img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.data_box___3uCcS .home_connect___5auUw .img_box___WMYUT {
  margin: 0 auto;
  width: max-content;
}
.data_box___3uCcS .home_connect___5auUw .like_a___2hDS6 {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2 !important;
}
.data_box___3uCcS .data_box___3uCcS li {
  line-height: 35px;
}
.data_box___3uCcS .data_box___3uCcS .cycle_item___1jqHv {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.data_box___3uCcS .data_box___3uCcS table {
  margin: 20px auto;
  width: 1000px;
}
.data_box___3uCcS .data_box___3uCcS table thead {
  background: gray;
  color: #fff;
  font-weight: 600;
}
.data_box___3uCcS .data_box___3uCcS table td {
  padding: 20px 30px;
  text-align: center;
}
.data_box___3uCcS .data_box___3uCcS table tbody tr {
  border-bottom: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: #eeeeee;
}
.data_box___3uCcS .data_box___3uCcS table tbody tr:nth-child(2n + 1) {
  background-color: rgba(220, 220, 220, 0.25);
}
.data_box___3uCcS .body_text___NQpQk {
  line-height: 35px;
}
.data_box___3uCcS .body_text___NQpQk > .body_text_pre___27xk_ {
  margin-top: 10px;
  background: #eef;
  overflow: auto;
  padding: 15px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}
.data_box___3uCcS .body_text___NQpQk .like_a___2hDS6 {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2 !important;
}
.data_box___3uCcS table {
  font-size: 12px;
  color: #12111b;
  width: 100%;
  border-width: 1px;
  border-color: #12111b;
  border-collapse: collapse;
}
