.video-evaluation-area___1lMKg .video-output-area___kpBcb .video-output___ZFJuf {
  width: 100%;
  height: 300px;
  background-color: #ccc;
}
.video-evaluation-area___1lMKg .video-output-area___kpBcb .video-control-area___3TzfO {
  position: relative;
  height: 30px;
  margin-bottom: -30px;
  bottom: 30px;
}
.video-evaluation-area___1lMKg .video-output-area___kpBcb .video-control-area___3TzfO .select-device-icon___l0xdY {
  font-size: 30px;
}
.video-evaluation-area___1lMKg .video-output-area___kpBcb .user-state-area___324wT {
  display: flex;
  flex-direction: row;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F {
  margin-top: 10px;
  padding-bottom: 30px;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs {
  margin-top: 20px;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu {
  display: flex;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS .status-result-item___10lX0 {
  margin-top: 10px;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS .status-result-item___10lX0.act-item___2Cz7v {
  background-color: red;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS .status-result-item___10lX0.act-item___2Cz7v .status-result-item-icon___lCg_l {
  color: green;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS .status-result-item___10lX0.act-item___2Cz7v .status-result-item-text___3MHyO {
  color: green;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .tab-item-area___3wQ-F .tab-item-status-list___2rBBs .tab-item-status-item___3p-Eu .status-child-result-list___1qIsS .status-result-item___10lX0.act-item___2Cz7v .child-act-text___1HC7K {
  color: green;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-nav {
  margin-top: 14px;
  width: 100%;
  padding: 0 10px;
  background: transparent;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-nav::after {
  content: none;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-tab {
  width: 24%;
  margin: 0;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid gray;
  font-size: 18px;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-tab:hover {
  color: rgba(0, 0, 0, 0.65);
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-ink-bar {
  display: none !important;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-tab-active {
  background-color: #102331;
  color: #fff;
  font-weight: bold;
  transition: none;
  border: 1px solid #102331;
}
.video-evaluation-area___1lMKg .video-evaluation-tabs___y5pJu .ant-tabs-tab-active:hover {
  color: #fff;
}
