.sidebarBox___2Efet {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 10;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.15);
}
.sidebarBox___2Efet .qrCode___38BKO {
  cursor: pointer;
  width: 82px;
  text-align: center;
  height: 94px;
  background: #ffffff;
  line-height: 94px;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeImg___396Xq {
  width: 55px;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeHoverImgBox___GdO8w {
  display: none;
  width: 180px;
  height: 250px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px #ccc;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeHoverImgBox___GdO8w .triangle___2-oGo {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: white;
  transform: rotateZ(45deg);
  top: 50%;
  right: -8px;
  box-shadow: 0 0 10px #ccc;
  z-index: -1;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeHoverImgBox___GdO8w .text___B8gMz {
  line-height: 25px;
  margin: 10px 0;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeHoverImgBox___GdO8w img {
  width: 100%;
}
.sidebarBox___2Efet .qrCode___38BKO .qrCodeText___1lqGc {
  display: none;
  font-size: 18px;
  color: #042044;
  line-height: 20px;
  width: 48px;
  padding: 32px 0;
}
.sidebarBox___2Efet .qrCode___38BKO:hover {
  background: #eeeeee;
}
.sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeHoverImgBox___GdO8w {
  display: block;
  top: 10px;
  right: 95px;
}
.sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeImg___396Xq {
  display: none;
}
.sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeText___1lqGc {
  display: inline-block;
}
.sidebarBox___2Efet .cooperation___v6nmJ {
  cursor: pointer;
  width: 82px;
  text-align: center;
  height: 94px;
  background: #ffffff;
  line-height: 94px;
}
.sidebarBox___2Efet .cooperation___v6nmJ .cooperationImg___1l66X {
  width: 55px;
}
.sidebarBox___2Efet .cooperation___v6nmJ .cooperationText___1vvtn {
  display: none;
  font-size: 18px;
  color: #042044;
  line-height: 20px;
  width: 48px;
  padding: 32px 0;
}
.sidebarBox___2Efet .cooperation___v6nmJ:hover {
  background: #eeeeee;
}
.sidebarBox___2Efet .cooperation___v6nmJ:hover .cooperationImg___1l66X {
  display: none;
}
.sidebarBox___2Efet .cooperation___v6nmJ:hover .cooperationText___1vvtn {
  display: inline-block;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 {
  cursor: pointer;
  width: 82px;
  text-align: center;
  height: 94px;
  background: #ffffff;
  line-height: 94px;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatImg___1RCdN {
  width: 60px;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatHoverImgBox___3oq9Z {
  display: none;
  width: 180px;
  height: 250px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px #ccc;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatHoverImgBox___3oq9Z .triangle___2-oGo {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: white;
  transform: rotateZ(45deg);
  top: 50%;
  right: -8px;
  box-shadow: 0 0 10px #ccc;
  z-index: -1;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatHoverImgBox___3oq9Z .text___B8gMz {
  line-height: 25px;
  margin: 10px 0;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatHoverImgBox___3oq9Z img {
  width: 100%;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatText___1GxCb {
  display: none;
  font-size: 18px;
  color: #042044;
  line-height: 20px;
  width: 48px;
  padding: 32px 0;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover {
  background: #eeeeee;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatHoverImgBox___3oq9Z {
  display: block;
  top: -80px;
  right: 95px;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatImg___1RCdN {
  display: none;
}
.sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatText___1GxCb {
  display: inline-block;
}
@media (max-width: 1450px) {
  .sidebarBox___2Efet .qrCode___38BKO,
  .sidebarBox___2Efet .cooperation___v6nmJ,
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6 {
    width: 50px;
    height: 57px;
    line-height: 57px;
  }
  .sidebarBox___2Efet .qrCode___38BKO .qrCodeImg___396Xq {
    width: 35px;
  }
  .sidebarBox___2Efet .qrCode___38BKO .qrCodeText___1lqGc {
    display: none;
    font-size: 14px;
    color: #042044;
    line-height: 20px;
    width: 40px;
    padding: 10px 0;
  }
  .sidebarBox___2Efet .qrCode___38BKO:hover {
    background: #eeeeee;
  }
  .sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeHoverImgBox___GdO8w {
    display: block;
    top: -45px;
    right: 62px;
  }
  .sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeImg___396Xq {
    display: none;
  }
  .sidebarBox___2Efet .qrCode___38BKO:hover .qrCodeText___1lqGc {
    display: inline-block;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatImg___1RCdN {
    width: 40px;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6 .EnterpriseWeChatText___1GxCb {
    display: none;
    font-size: 14px;
    color: #042044;
    line-height: 20px;
    width: 40px;
    padding: 10px 0;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover {
    background: #eeeeee;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatHoverImgBox___3oq9Z {
    display: block;
    top: -102px;
    right: 62px;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatImg___1RCdN {
    display: none;
  }
  .sidebarBox___2Efet .EnterpriseWeChat___31nv6:hover .EnterpriseWeChatText___1GxCb {
    display: inline-block;
  }
  .sidebarBox___2Efet .cooperation___v6nmJ .cooperationImg___1l66X {
    width: 35px;
  }
  .sidebarBox___2Efet .cooperation___v6nmJ .cooperationText___1vvtn {
    display: none;
    font-size: 14px;
    color: #042044;
    line-height: 20px;
    width: 40px;
    padding: 10px 0;
  }
  .sidebarBox___2Efet .cooperation___v6nmJ:hover {
    background: #eeeeee;
  }
  .sidebarBox___2Efet .cooperation___v6nmJ:hover .cooperationImg___1l66X {
    display: none;
  }
  .sidebarBox___2Efet .cooperation___v6nmJ:hover .cooperationText___1vvtn {
    display: inline-block;
  }
}
