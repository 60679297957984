.datasetContainer___2B7yU {
  margin-bottom: 40px;
}
.datasetContainer___2B7yU .banner___ZTYQd {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.datasetContainer___2B7yU .content_main___2r4Ec {
  margin-top: 20px;
}
.datasetContainer___2B7yU h1 {
  font-size: 46px;
}
.datasetContainer___2B7yU h2 {
  margin: 20px;
  margin-top: 30px;
}
.datasetContainer___2B7yU .home_img_1___2vuB- {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.datasetContainer___2B7yU .tab_box___a2AFJ {
  background: #5187d5;
  margin-bottom: 20px;
}
.datasetContainer___2B7yU .tab_box___a2AFJ ul {
  height: 60px;
  line-height: 60px;
}
.datasetContainer___2B7yU .tab_box___a2AFJ ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.datasetContainer___2B7yU .tab_box___a2AFJ ul > li:last-child {
  width: 15%;
}
.datasetContainer___2B7yU .tab_box___a2AFJ ul .isActived___FUwPx {
  background: #1a3a68;
  font-weight: bold;
}
.datasetContainer___2B7yU .change_contents___2jg_D {
  padding: 20px 80px;
}
.datasetContainer___2B7yU .change_contents___2jg_D .home_img_1___2vuB- {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.datasetContainer___2B7yU .change_contents___2jg_D .like_a___EZLWP {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2;
}
.datasetContainer___2B7yU .change_contents___2jg_D a {
  color: #303de2;
}
.datasetContainer___2B7yU .change_contents___2jg_D .like_a___EZLWP:hover {
  text-decoration: underline;
}
.datasetContainer___2B7yU .change_contents___2jg_D .home_connect___3FVLk img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.datasetContainer___2B7yU .change_contents___2jg_D .home_connect___3FVLk img:nth-of-type(3) {
  width: 220px;
  height: 70px;
}
.datasetContainer___2B7yU .home_connect___3FVLk b {
  margin-right: 8px;
}
.datasetContainer___2B7yU .home_connect___3FVLk p {
  line-height: 35px;
}
.datasetContainer___2B7yU .home_connect___3FVLk img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.datasetContainer___2B7yU .home_connect___3FVLk img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.datasetContainer___2B7yU .home_connect___3FVLk .img_box___1ChCc {
  margin: 0 auto;
  width: max-content;
}
.datasetContainer___2B7yU table {
  margin: 20px auto;
  width: 1000px;
}
.datasetContainer___2B7yU table thead {
  background: gray;
  color: #fff;
  font-weight: 600;
}
.datasetContainer___2B7yU table td {
  padding: 20px 30px;
  text-align: center;
}
.datasetContainer___2B7yU table tbody tr {
  border-bottom: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: #eeeeee;
}
.datasetContainer___2B7yU table tbody tr:nth-child(2n + 1) {
  background-color: rgba(220, 220, 220, 0.25);
}
.datasetContainer___2B7yU .body_text___3Lv9c {
  line-height: 35px;
}
.datasetContainer___2B7yU .latexClassN___3sN0N .katex___1ke5I .base___2wtm- {
  font-size: 20px;
}
