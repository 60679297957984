.icdar_box {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
}
.icdar_box .banner {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.icdar_box .content_main {
  margin-top: 20px;
}
.icdar_box b {
  font-weight: bold;
}
.icdar_box h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.icdar_box h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.icdar_box h3 {
  font-weight: bold;
  font-size: 18px;
}
.icdar_box .latexClassN .katex .base {
  font-size: 16px !important;
}
.icdar_box .tab_box {
  background: #5187d5;
  margin-bottom: 20px;
}
.icdar_box .tab_box ul {
  height: 60px;
  line-height: 60px;
}
.icdar_box .tab_box ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.icdar_box .tab_box ul > li:last-child {
  width: 15%;
}
.icdar_box .tab_box ul .isActived {
  background: #1a3a68;
  font-weight: bold;
}
.icdar_box .change_contents {
  padding: 20px 80px;
}
.icdar_box .change_contents .home_img_1 {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.icdar_box .change_contents .like_a {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.icdar_box .change_contents a {
  color: #303de2;
}
.icdar_box .change_contents .home_connect img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.icdar_box .change_contents .home_connect img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.icdar_box .home_connect p {
  line-height: 35px;
}
.icdar_box .home_connect b {
  margin-right: 8px;
}
.icdar_box .home_connect .img_box {
  margin: 0 auto;
  width: max-content;
}
.icdar_box .home_connect .img_box img {
  margin: 40px;
}
.icdar_box .body_text {
  line-height: 35px;
}
.icdar_box .span_bg {
  color: #fff;
  background: #666666;
  padding: 0 3px;
  border-radius: 3px;
}
.icdar_box table {
  margin: 20px auto;
  width: 1000px;
}
.icdar_box table thead {
  background: gray;
  color: #fff;
  font-weight: 600;
}
.icdar_box table td {
  padding: 20px 30px;
  text-align: center;
}
.icdar_box table tbody tr {
  border-bottom: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: #eeeeee;
}
.icdar_box table tbody tr:nth-child(2n + 1) {
  background-color: rgba(220, 220, 220, 0.25);
}
