.ask-inner-list___2F5Z4 {
  height: 104px;
  background-color: #ffffff;
  margin: 10px 5px 10px 5px;
  border-radius: 14px;
  width: 31.1%;
  font-size: 14px;
  border: 1px solid #fff;
  position: relative;
}
.ask-inner-list___2F5Z4::-webkit-scrollbar {
  display: none;
}
.ask-inner-h1___3rHN6 {
  font-weight: bolder;
  font-size: 24px;
  line-height: 33.6px;
  margin-top: 10px;
}
.ask-inner-p___1cxHd {
  width: 99%;
  font-size: 14px;
  color: #a6a6a6;
  font-weight: 400;
  line-height: 19.6px;
  margin-top: 10px;
  margin-bottom: 0;
}
.ask-inner-a___I4AoE {
  position: absolute;
  right: 18px;
  color: #53e8c4;
  width: 53px;
  height: 21px;
  font-size: 13px;
  margin-top: -35px;
}
.ask-inner-a___I4AoE:hover {
  font-weight: 500;
  color: #53e8c4;
}
.ask-inner-change___3U1qY {
  position: absolute;
  color: #53e8c4;
  width: 53px;
  height: 21px;
  font-size: 13px;
  border-right: 1px solid #ececec;
  padding: 0;
  text-align: center;
}
.ask-inner-change___3U1qY:hover {
  font-weight: 500;
  color: #53e8c4;
}
.ask-inner-separator___-M1G2 {
  position: absolute;
  right: 120px;
  padding-right: 10px;
  color: #a1a1a1;
  margin-top: -27px;
  cursor: pointer;
  line-height: 20px;
}
.ask-inner-separator-img___3Pkn9 {
  position: absolute;
  width: 16px;
  height: 13px;
  right: 10px;
  margin-top: 4px;
}
.ask-inner-ul___1T-JU {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
.ask-icon-title___2LT16 {
  display: flex;
  /* 使用flex布局让图标和标题在同一行 */
  align-items: center;
  justify-content: center;
  /* 垂直居中对齐元素 */
  padding: 0 5px 0 11px;
  margin-top: 12px;
  margin-left: 5px;
  position: absolute;
  margin-bottom: 4px;
}
.ask-inner-list___2F5Z4:hover {
  border: 1px solid #53e8c4;
  cursor: pointer;
}
.ask-inner-h1___3rHN6 {
  font-weight: bolder;
  font-size: 24px;
  line-height: 33.6px;
  margin-top: 10px;
}
.ask-inner-text___1J7rV {
  font-size: 16px;
  font-weight: 500;
  line-height: 33.6px;
  color: #1a1a1a;
}
.ask-inner-a___I4AoE {
  position: absolute;
  right: 18px;
  color: #a1a1a1;
  width: 53px;
  height: 21px;
  font-size: 13px;
  margin-top: -35px;
}
.ask-inner-a___I4AoE:hover {
  font-weight: 500;
  color: #53e8c4;
}
.ask-inner-change___3U1qY {
  position: absolute;
  color: #a1a1a1;
  width: 53px;
  height: 21px;
  font-size: 13px;
  border-right: 1px solid #ececec;
  padding: 0;
  text-align: center;
}
.ask-inner-change___3U1qY:hover {
  font-weight: 500;
  color: #53e8c4;
}
.ask-inner-separator___-M1G2 {
  position: absolute;
  right: 120px;
  padding-right: 10px;
  color: #a1a1a1;
  margin-top: -27px;
  cursor: pointer;
  line-height: 20px;
}
.ask-inner-separator-img___3Pkn9 {
  position: absolute;
  width: 16px;
  height: 13px;
  right: 10px;
  margin-top: 4px;
}
@keyframes spin___1nwNB {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ask-inner-separator-img___3Pkn9.loading___238ON {
  animation: spin___1nwNB 0.8s linear infinite;
}
.ask-inner-ul___1T-JU {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
.ask-icon-title___2LT16 {
  display: flex;
  /* 使用flex布局让图标和标题在同一行 */
  align-items: center;
  justify-content: center;
  /* 垂直居中对齐元素 */
  padding: 0 5px 0 11px;
  margin-top: 12px;
  margin-left: 5px;
  position: absolute;
  margin-bottom: 4px;
}
.ask-title-h4___1TTa_ {
  color: #1a1a1a;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 500;
  margin-bottom: 0px;
}
.ask-icon___TRzXY {
  margin-right: 0.5em;
  /* 图标右侧添加间距 */
  width: 30px;
  height: 30px;
}
.ask-description___19H08 {
  width: 94%;
  padding-right: 8px;
  padding-left: 8px;
  margin-left: 8px;
  margin-right: 5px;
  font-size: small;
  font-weight: 400;
  line-height: 21.45px;
  color: #a6a6a6;
  font-size: 13px;
  position: absolute;
  top: 50px;
  overflow-y: auto;
  height: 43px;
  margin-bottom: 5px;
}
