.sponsor_box___3OzD3 h2 {
  margin: 20px;
  margin-top: 30px;
}
.sponsor_box___3OzD3 .home_img_1___fiG0N {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.sponsor_box___3OzD3 .data_box___3DKVE li {
  line-height: 35px;
}
.sponsor_box___3OzD3 .data_box___3DKVE .cycle_item___2va0m {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
