.iscslp_box___K6DlZ {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.iscslp_box___K6DlZ .banner___3cKxK {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.iscslp_box___K6DlZ .content_main___Z971D {
  margin-top: 20px;
}
.iscslp_box___K6DlZ b {
  font-weight: bold;
}
.iscslp_box___K6DlZ h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.iscslp_box___K6DlZ h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.iscslp_box___K6DlZ h3 {
  font-weight: bold;
  font-size: 18px;
}
.iscslp_box___K6DlZ .tab_box___Po2Ac {
  background: #5187d5;
  margin-bottom: 20px;
}
.iscslp_box___K6DlZ .tab_box___Po2Ac ul {
  height: 60px;
  line-height: 60px;
}
.iscslp_box___K6DlZ .tab_box___Po2Ac ul li {
  display: inline-block;
  width: 28%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.iscslp_box___K6DlZ .tab_box___Po2Ac ul > li:last-child {
  width: 16%;
}
.iscslp_box___K6DlZ .tab_box___Po2Ac ul .isActived___VjU7- {
  background: #1a3a68;
  font-weight: bold;
}
.iscslp_box___K6DlZ .change_contents___2HB1K {
  padding: 20px 80px;
}
.iscslp_box___K6DlZ .change_contents___2HB1K .home_img_1___3xgv- {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.iscslp_box___K6DlZ .change_contents___2HB1K .like_a___TQTSu {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.iscslp_box___K6DlZ .change_contents___2HB1K a {
  color: #303de2;
}
.iscslp_box___K6DlZ .change_contents___2HB1K .home_connect___2DhbA img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.iscslp_box___K6DlZ .change_contents___2HB1K .home_connect___2DhbA img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.iscslp_box___K6DlZ .home_connect___2DhbA p {
  line-height: 35px;
}
.iscslp_box___K6DlZ .home_connect___2DhbA b {
  margin-right: 8px;
}
.iscslp_box___K6DlZ .home_connect___2DhbA .img_box___3DP2F {
  margin: 0 auto;
  width: max-content;
}
.iscslp_box___K6DlZ .home_connect___2DhbA .img_box___3DP2F img {
  margin: 40px;
}
.iscslp_box___K6DlZ .body_text___1DbSS {
  line-height: 35px;
}
.iscslp_box___K6DlZ .body_text_in___1vIKE {
  line-height: 35px;
  text-indent: 32px;
}
.iscslp_box___K6DlZ .span_bg___1vQGF {
  color: #fff;
  background: #666666;
  padding: 0 3px;
  border-radius: 3px;
}
