.title___FzOZS {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.desc___C7dm2 {
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}
.scenesBox___3y9YA .title___FzOZS {
  color: #042044;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.scenesBox___3y9YA .scenesTypesBox___27eTx {
  display: flex;
  width: 100%;
  color: #042044;
  font-size: 18px;
  margin-bottom: 26px;
}
.scenesBox___3y9YA .scenesTypesBox___27eTx .scenesType___3GB1G {
  min-width: 50px;
  cursor: pointer;
  margin-right: 45px;
}
.scenesBox___3y9YA .scenesTypesBox___27eTx .scenesType___3GB1G.active___1Qzj5,
.scenesBox___3y9YA .scenesTypesBox___27eTx .scenesType___3GB1G:hover {
  color: #5187d5;
  font-weight: 400;
}
.scenesBox___3y9YA .scenesTypesBox___27eTx .scenesType___3GB1G.active___1Qzj5 .bottomLine___1LjYo,
.scenesBox___3y9YA .scenesTypesBox___27eTx .scenesType___3GB1G:hover .bottomLine___1LjYo {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px auto 0;
}
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw {
  display: flex;
  color: #042044;
  font-size: 18px;
  margin-bottom: 26px;
}
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw .scenesType___3GB1G {
  min-width: 50px;
  cursor: pointer;
  margin-right: 35px;
}
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw .scenesType___3GB1G.active___1Qzj5,
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw .scenesType___3GB1G:hover {
  color: #5187d5;
  font-weight: 400;
}
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw .scenesType___3GB1G.active___1Qzj5 .bottomLine___1LjYo,
.scenesBox___3y9YA .scenesTypesBoxLarge___1zQAw .scenesType___3GB1G:hover .bottomLine___1LjYo {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px auto 0;
}
.scenesBox___3y9YA .scenesContent___1Podk {
  max-height: 283px;
}
.scenesBox___3y9YA .scenesContent___1Podk .videoBox___30t8e {
  max-width: 100%;
  display: flex;
  max-height: 283px;
  outline-style: none;
}
.scenesBox___3y9YA .scenesContent___1Podk .scenesDesc___348R0 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 60px;
  background: #fafafa;
  line-height: 26px;
}
.scenesBox___3y9YA .scenesContent___1Podk .scenesDesc___348R0 {
  color: #042044;
}
.adBox___18rOb {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.adBox___18rOb .adImg___Z2Qp8 {
  width: 65px;
  height: 65px;
  vertical-align: middle;
  flex-grow: 0;
  flex-shrink: 0;
}
.adBox___18rOb .adContent____SSgZ {
  padding-left: 16px;
  height: 100%;
  align-items: center;
}
.adBox___18rOb .adContent____SSgZ .adTitle___2qoIB {
  color: #fff;
  margin-bottom: 6px;
}
.adBox___18rOb .adContent____SSgZ .adDesc___2hAQJ {
  color: #fff;
}
.caseBox___2DNU- {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
}
.caseBox___2DNU- .caseImg___AaPqp {
  width: 233px;
  height: 177px;
}
.caseBox___2DNU- .casevideoBox___2Kbm0 {
  display: flex;
  background: #fff;
  min-width: 419px;
  outline-style: none;
}
.caseBox___2DNU- .casevideoBox___2Kbm0 .caseVideo___38B1J {
  width: 100%;
  max-height: 233px;
}
.caseBox___2DNU- .caseContent___3BZNV {
  background: #fff;
  height: 100%;
  padding: 0px 36px 0 20px;
}
.caseBox___2DNU- .caseContent___3BZNV .caseTitle___3om_4 {
  color: #5187d5;
  margin-bottom: 11px;
}
.caseBox___2DNU- .caseContent___3BZNV .caseDesc___1mZjW {
  color: #042044;
}
.compositionBox____BFZU {
  display: flex;
  flex-direction: row;
  padding: 18px 30px 14px 22px;
  background: #fff;
  margin-bottom: 28px;
  border: none;
  align-items: center;
}
.compositionBox____BFZU .compositionBoxContent___WykeE {
  display: flex;
}
.compositionBox____BFZU .compositionBoxContent___WykeE .compositionImg___1EypM {
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
}
.compositionBox____BFZU .compositionBoxContent___WykeE .compositionContent___3qHRK {
  padding-left: 16px;
}
.compositionBox____BFZU .compositionBoxContent___WykeE .compositionContent___3qHRK .compositionTitle___SgyKE {
  color: #042044;
}
.compositionBox____BFZU .compositionBoxContent___WykeE .compositionContent___3qHRK .compositionDesc___2GNA1 {
  color: #777777;
  margin-top: 6px;
}
.newsWrapped___2s1tU {
  display: flex;
  flex-direction: column;
}
.newsWrapped___2s1tU .newsBox___1uZmk:hover {
  box-shadow: 3px 3px 11px 0px rgba(0, 0, 0, 0.13);
}
.newsWrapped___2s1tU .newsBox___1uZmk .newsImg___2G9vk,
.newsWrapped___2s1tU .newsBox___1uZmk .newsVideo___2oGTl {
  width: 100%;
}
.newsWrapped___2s1tU .newsBox___1uZmk .newsContent___p0hVj {
  height: 42px;
  text-align: center;
  background: #fafafa;
}
.newsWrapped___2s1tU .newsBox___1uZmk .newsContent___p0hVj .newsDesc___2_rNV {
  color: #042044;
  line-height: 42px;
  text-align: center;
}
.newsWrapped___2s1tU .newsPlaceholder___3ZDy3 {
  height: 30px;
}
