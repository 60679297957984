@font-face {
  font-family: 'AvenirMediumwoff';
  src: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/AvenirMediumwoff.ttf');
  font-style: normal;
  font-weight: 400;
}
@keyframes spin___3ZaaI {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ask-list-box___-jO4H {
  padding-left: 9px;
  padding-right: 5px;
  padding-bottom: 60px;
  margin-top: 52px;
  overflow-x: hidden;
}
.ask-list-box___-jO4H.share___110cZ {
  height: auto !important;
}
.ask-list-box___-jO4H.share___110cZ .ask-ques___2Al77,
.ask-list-box___-jO4H.share___110cZ .ask-ans___q3U-- {
  padding: 28px 10%;
}
.correct-box___3nRiP {
  margin: 0 auto;
  margin-bottom: 16px;
  min-height: 90px;
  width: 70%;
  display: flex;
  position: relative;
}
.correct-box___3nRiP .imgBtn-box___1rJB3 {
  position: absolute;
  bottom: 18px;
  right: 24px;
}
.model-tip___2V18O {
  width: 140px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 22px;
  right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
.control-icon-btn___3xwf5 {
  border: none;
  background-color: transparent;
  outline: none;
}
.control-icon-btn___3xwf5 .control-icon___3ylhz {
  color: #c5c5d2;
}
.control-icon-btn___3xwf5 .control-icon___3ylhz:hover {
  color: #fff;
}
.control-icon-btn___3xwf5 .diancai-icon___1oB3u {
  transform: rotate(180deg);
}
.control-icon-btn___3xwf5 .icon-shan-dian___235g3 {
  font-size: 16px;
}
.com6b-box-margin___3eZX2 {
  margin-bottom: 10px;
}
.com6b-box___mYv_B {
  margin-bottom: 96px;
}
.com6b-box___mYv_B .ask-ques___2Al77,
.com6b-box___mYv_B .ask-ans___q3U-- {
  position: relative;
}
.com6b-box___mYv_B .ant-checkbox-wrapper {
  position: absolute;
  left: -30px;
  top: 0px;
}
.com6b-box___mYv_B .usr-icon___nBMw9,
.com6b-box___mYv_B .usr-icon-gpt___1ZW2r {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 9px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 14px;
}
.com6b-box___mYv_B .usr-icon___nBMw9 {
  background: linear-gradient(144.94deg, #7cf0f0 -7.27%, #94f6ab 55.01%, #adfd66 117.71%);
}
.com6b-box___mYv_B .usr-icon___nBMw9 .external-icon___1mSsV {
  width: 100%;
  height: 100%;
  border-radius: 14px;
}
.com6b-box___mYv_B .usr-icon-gpt___1ZW2r {
  background-color: #040000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.com6b-box___mYv_B .usr-icon-gpt___1ZW2r img {
  width: 36px;
}
.com6b-box___mYv_B .usr-icon-gpt___1ZW2r.chatgpt-img___27rrV {
  background-color: transparent;
}
.com6b-box___mYv_B .usr-icon-gpt___1ZW2r.chatgpt-img___27rrV img {
  margin-top: 0px;
  width: 100%;
  border-radius: 4px;
}
.com6b-box___mYv_B .ask-ques___2Al77,
.com6b-box___mYv_B .ask-ans___q3U-- {
  margin: 0 auto;
  width: 70%;
  min-width: 788px;
  padding: 0px 2px 9px 2px;
  min-height: 80px;
  box-sizing: border-box;
  line-height: 28px;
  font-size: 16px;
  word-break: break-all;
  text-align: justify;
  display: block;
  transform: translateX(-17px);
}
.com6b-box___mYv_B .ask-ques___2Al77 .guide-box___3UkQz,
.com6b-box___mYv_B .ask-ans___q3U-- .guide-box___3UkQz {
  margin-left: 52px;
  margin-top: 10px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .guide-box___3UkQz .ask-guide___1ZjQ4,
.com6b-box___mYv_B .ask-ans___q3U-- .guide-box___3UkQz .ask-guide___1ZjQ4 {
  border: 1px solid #dddde5;
  background-color: #fff;
  padding: 8px;
  display: inline-block;
  font-size: 14px;
  color: #999;
  line-height: 1.5;
  margin-right: 10px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .guide-box___3UkQz .ask-guide___1ZjQ4:hover,
.com6b-box___mYv_B .ask-ans___q3U-- .guide-box___3UkQz .ask-guide___1ZjQ4:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ansLoading___1o1q4,
.com6b-box___mYv_B .ask-ans___q3U-- .ansLoading___1o1q4 {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ansLoading___1o1q4 .rotate___ufJ80,
.com6b-box___mYv_B .ask-ans___q3U-- .ansLoading___1o1q4 .rotate___ufJ80 {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  animation: spin___3ZaaI 2s linear infinite;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-wrap___1a9em,
.com6b-box___mYv_B .ask-ans___q3U-- .ask-wrap___1a9em {
  display: inline-block;
  width: calc(100% - 54px);
  position: relative;
}
.com6b-box___mYv_B .ask-ques___2Al77 .askImg___2kXA5,
.com6b-box___mYv_B .ask-ans___q3U-- .askImg___2kXA5 {
  width: 108px;
  height: 158px;
  padding-bottom: 15px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .structureSvg___GTYnM,
.com6b-box___mYv_B .ask-ans___q3U-- .structureSvg___GTYnM {
  margin-bottom: 28px;
  background-color: #f9f9f9;
  overflow-x: auto;
}
.com6b-box___mYv_B .ask-ques___2Al77 .structureSvg___GTYnM .markmap,
.com6b-box___mYv_B .ask-ans___q3U-- .structureSvg___GTYnM .markmap {
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}
.com6b-box___mYv_B .ask-ques___2Al77 .structureSvg___GTYnM::-webkit-scrollbar,
.com6b-box___mYv_B .ask-ans___q3U-- .structureSvg___GTYnM::-webkit-scrollbar {
  height: 5px !important;
}
.com6b-box___mYv_B .ask-ques___2Al77 .structureSvg___GTYnM::-webkit-scrollbar-thumb,
.com6b-box___mYv_B .ask-ans___q3U-- .structureSvg___GTYnM::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 2px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-inner___2u_ju,
.com6b-box___mYv_B .ask-ans___q3U-- .ask-inner___2u_ju {
  white-space: pre-wrap !important;
  display: inline-block;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  color: #1a1a1a;
  position: relative;
  font-family: 'Microsoft YaHei', AvenirMediumwoff !important;
  line-height: 28px;
  text-align: left;
  margin-bottom: 20px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-inner___2u_ju em,
.com6b-box___mYv_B .ask-ans___q3U-- .ask-inner___2u_ju em {
  display: inline-block;
  width: 30px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-inner___2u_ju::-webkit-scrollbar,
.com6b-box___mYv_B .ask-ans___q3U-- .ask-inner___2u_ju::-webkit-scrollbar {
  height: 5px !important;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-inner___2u_ju::-webkit-scrollbar-thumb,
.com6b-box___mYv_B .ask-ans___q3U-- .ask-inner___2u_ju::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 2px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .btn-box___3Xzod,
.com6b-box___mYv_B .ask-ans___q3U-- .btn-box___3Xzod {
  position: absolute;
  bottom: 6px;
  right: 24px;
}
.com6b-box___mYv_B .ask-ans___q3U-- {
  display: flex;
  margin-bottom: 15px;
}
.com6b-box___mYv_B .ask-ques___2Al77 {
  margin-bottom: -10px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .icon-copy-ask___ukXoN {
  display: none;
  position: absolute;
  right: 14px;
  bottom: 4px;
}
.com6b-box___mYv_B .ask-ques___2Al77 .ask-inner___2u_ju {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Microsoft YaHei', AvenirMediumwoff !important;
  text-align: left;
}
.com6b-box___mYv_B .ask-ques___2Al77:hover .icon-copy-ask___ukXoN {
  display: block;
}
.com6b-box___mYv_B .answer-wrap___1D84N {
  display: inline-block;
  width: calc(100% - 54px);
  position: relative;
  padding: 10px 16px 15px;
  background-color: #f9f9f9;
  border-radius: 14px;
}
.com6b-box___mYv_B .answer-wrap___1D84N a:focus {
  outline: none;
}
.com6b-box___mYv_B .answer-guide___1EpXj {
  margin: 0 auto;
  width: 70%;
  transform: translateX(50px);
}
.com6b-box___mYv_B .answer-guide-question___Edm0I {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #1a1a1a;
}
.com6b-box___mYv_B .answer-guide-box___2NoYe {
  margin-top: 16px;
}
.com6b-box___mYv_B .answer-guide-box___2NoYe .guideItem___m9Ib4 {
  display: inline-block;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #a6a6a6;
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid transparent;
  padding: 8px 16px;
}
.com6b-box___mYv_B .answer-guide-box___2NoYe .guideItem___m9Ib4:hover {
  cursor: pointer;
  border: 1px solid #0cd7a6;
  color: #1a1a1a;
  background-color: #fff;
}
.com6b-box___mYv_B .composition-ans___24CTh.evaluate-report___3yOfk {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.com6b-box___mYv_B .composition-ans___24CTh.evaluate-report___3yOfk .evaluate-content___WU-B_ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.com6b-box___mYv_B .icon-copy-ask___ukXoN,
.com6b-box___mYv_B .icon-copy-ques___3tJjC {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK3SURBVHgB7Zo7bttAEIaHkqBClcqU9AliF6qTnCA+glJIUCfmBKHLVJEBFYLU6AjxCawDCEh8gy3TRZUKQo/MJI5BULJ3SS4fWP8fQNBeExDxaXf4mztEAAAAAAAAAAAAAE7hUcGMRiOfTz6VyH6/3ywWi59kgUIEBUHQ3W634+PxGHie16VqUHx8bzQat7PZTFFGrAuSGXM4HO6p5FnzAoolfcgqyaqgGsr5j4qi6Gq5XG4oJQ2yCMsZU/3kCH673Q4oAy2yy/WZsRsWt6IS4bp3zcc4MSy/h5QSa0tsMBhc8k39SAxP5vP5Z6qA4XAY8ulLfIxr0UXaWmRtifET6+RpxTd0RxXxzKz1KSVWa5CL5KpB8RDI4ext8u+73e6SryGb2AyBJqQWFA+BPI2flhXXn5NreYl942vIJvI5XF8UWQiBJqRaYjJjWI4U4rDChCz4fMgXdP84iwvDWFBNQ+Dfe+r3+4V9WcaCXAyBJqSpQc6FQBOMBEkIpNPZIyEwpPJZcZGW/6niIbArJaCIgm20xFwNgSYgKGqAIA0QpAGCNECQBgjSAEEaIEgDBGmAIA0QpAGCNECQBgjSAEEanBXE76s+Jsdky4hSYntvvjLie3D8Qu0dn5LvqVWW/TRnBOn24PitaKY3oK+lBqlmszmhDLwGQbk6zJwVxEtKCvJNp9O5yrPb4UwNkl4BPh7k51arpWxtATkjiGvMA0tZkWUQFDUYCZIpmxzjR+p7qghbIdAE4x5F3n7+fablZVL2DutjCAwTw4q3wS+oAIxrEMu5pURTJP3r0Smss8KUrCHQBOMaFEWRBC1F9SNzCDTBWJB0qUvgonpJyhUCTWimuXi9Xm96vd4dLytecd4bHqqkDU9CIH/+Vw6Bn6bT6S8qkFyN5NI3dK41pkhshkAAAAAAAAAy8wf4kw/wcTZwcwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
  cursor: pointer;
}
.com6b-box___mYv_B .icon-copy-ask___ukXoN:hover,
.com6b-box___mYv_B .icon-copy-ques___3tJjC:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALYSURBVHgB7dtPbtNAFAbwb2yniZQscgR3gwhCIj0B9AR0hfgjFj1BwgkaTkB6gixQyxZOQG4QEIVsfQOyaEXauH54XCHFscqMnXFsjd5v1TpSO/pqTz5lXgHGGGOMMcYYY4wxqwiUrDWb+J7n+dghEs7i6vGbbzCglIC6s0k3bLUGIBrGv6GLChAQCOF8DunP6bJ3HKAg4wG15hPfRfNr/IN91IAM6hbXh0VDMhpQ3cL5R4bUWF4fLA6OF8jJgUGeaA7qFo4k1xTu7Q1RgAeDiHCUvSXpPRBNsUvkHEGIQeqak3w/Qk7GHrH2xaQv3OYs/dOd8eXDl+9Qgc7Ps1Ecysn6tRBif9l7FSAHY4+YIC/7bkWrL6hKlL1rPYQ+cjK6B9loqz1ovQRSo/FEUJR6ncjtd358hEkmS6CO3AHdVwI3w0muCXyA58IkuWm25+dGSqCOXI+Y7DmrltyIaVRVQ5aSKkHRUHYuuSaUSDugOpZAuRa5JnlXoyTaAdlYAnVo70E2lkAdWgElJXDz7rkrgSPs3jQugYuNEthtzT/5eUugDq1HzNYSqIOLogIHpMABKXBAChyQAgekwAEpcEAKHJACB6TAASlwQAockAIHpMABKdgbUMN9vnmJ3Gbu4QWjZ/NVSp3Bed7T5Fhq/XUguHrwIvd5mjUBpc/gKPs6UaFPQK0J6H+SIar4M3QUYP0mfTdhJg6LfqBvb0CEhTyWkpNl25x22LNJC2coaPVdfh0iDJaPzJzZ27NJx+Fc9t5OYRgXRQWtgELPCzIXI+cZqmKoBOrQnlHs/Dr/nRl5ERhjdbvbE1ZZAuX4zZqkBPZe76ME+nsQ0Wncxk7S1zCMy1lpkxX3LCRzpWgJ1KE//nJzM5Z/KdSMXFNYsATqyDUGLCcoXFDN/s2geAnUketdTC5ELkg+bpXeTYZKoI6tBsnbF2d94UY7nVVMSmDJg5uMMcYYY4wp/QUJTgEdcm+YkwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.com6b-box___mYv_B .icon-copy-ask___ukXoN.copy-format___3Lydk,
.com6b-box___mYv_B .icon-copy-ques___3tJjC.copy-format___3Lydk {
  color: #0cd7a6;
  background: none;
  width: 80px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #0cd7a6;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
  padding: 2px 5px;
  transform: translateY(-8px);
}
.com6b-box___mYv_B .icon-zan___1Suso {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ+SURBVHgB7ZtbThsxFIZPLkIIHpquoO4KICsgrKC0GyBIgHhjWEHJChr6gLhJDSuAHZCsIOlb35iuoOkDCHFJ+rsapBnPJGM7Hjut/EmjZIydmfxzfI7PcSDyeDwej8fj8Xg8Ho9tSjRH7O3tMbzwgxYXFwftdntIjnEuUBAEtfv7+/3xeByUSqVa/G8476K9c3Z2dkmOcCoQLKbx8vJyJQojwoXCsXVychKSZZwJtLu7u4GXK4Uh4ePjY73T6ViddmVyQORrvpAabGFh4TNZxolAo9HokCJn/Ap8DbeMFqbcR37gfZbfCfi0JItUyQ1rwnlYqVTWBR9zjWnIzxNWA/H41OySJaxb0Pb2doME6wFHWQ4Y0esQL4l2OOs1soh1gcrl8qrYhunVnTKkJ5wzsoh1gWABH4Sm8Pz8fDBlyBtyiFWBms1mDdbSiLdBsO85w5jQf0AWsSoQHHFDbINgE9dC0XIgMSURAfMENYptgVJrH/ik3qT+iFiNjM+4JotYCfNRvvWN0tOlOy19yPBXhP5dsoiyQFH+tIKbfyvTH/3e3d3dbWTlW5guBznDxYgXYm10SPIMYKGDWXI46VyMWwG+KE8sG2SGVrTOyYT7Hwh4S2ZoI49r6eRx0j4IU6RtShx88ctp4nAeHh5MJqUB8rgbHkVJESmBomiySTMS5VsHFxcXzby+/GlzIckcqxApIEWkBHp+fmakCRclWim3lpeX38Ny2rJjcd0AY48iYU2wr2pF2lEMzm89K6LAiXK/wWL9eqenpxukQeQzguhQZmdnh7uF/VhTDaVc7vi7sp9hYx30i9zRERsQgRkpUIRALH4CP/KbHIFrM7ENC82QFCjcgmDiznYmIEaqcoDoqJTLGRVoggN0uXWzIpwPVddCRgWCA0wJZDAC6cDiJzqVAKMCwQGmBEIUc+KkI2tOTDE8rB4pYlQg3EBKIDw1J066Wq2m/A/upU+KONnVsEFWaRdR7ScpYlQgPCEmtmHaufJBokDDnNJuJoVbEEKtK4ESEUy3VFu4BRnOylUwUqot3IJs76VzkINlOeiQNCg6ijmxnqwUg1cWSQPTU2wuBIIYDbFNt5ZteoqJm3yuBBJTDO29tEItyFWiiim2KtyH8vrnlf9uocjLw+KDytn7n4ppJ82EJutpxtPTUyqC6Trov2OpWKwnqiZqQHFMC8TiJ46qiTPXgOIUakGOnDSj5D1oWw/HmEDzUE00VQOKY0ygeagmmqoBxTEm0DxUE03VgOJI/XiB//ASF79JDCyVOrCQ+MUZCdvTGX0Kg699IMamsAYaYidX6lcok5DaWc1ytvjizbxxMn1MgWvx+0y04fyaZkRqivFKnOPdCR1uIVCLZkTaB8F8t+jf4RYP9JOJf36pyHbs9/s/6vV6D76IkeXfKucwjr0PcXxdWlraOj4+Dsnj8Xg8Ho/H4/F45pM/fceodqxtQ4oAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.com6b-box___mYv_B .icon-zan___1Suso:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASWSURBVHgB7ZpdbttGEMdnyJUlIyrqnqDsSwEFAWz3AlFOELUPBeqgTXQCKyeodYI6J1CCwumjfYPKJ7CKNtGj1RNURS2EiklOdy0nIHclm6SWuw6wP0C2d7zUx1+zszOzBHA4HA6Hw+FwOBwOh2kQ7hCNs0HAGAvE3yyKRtPd7hQsY12grbPBVtRo7ANRj7+brex/aUiAL2etvVdgCasCNf/8tQ2+f6wKI0PDCN53w1Z3AoaxJtBnb193COE473wCmNTC+a7pZeeBBRrjQZAg/FLkGv5NBtHm5s9gGCsC+VQ/EB84YyTgnkF9pOhb8SACNe5Q0muO+bI0CAMbIDxMD8XyiREfha29Scp80hwf8TFmvYa8Dv85BEMY9yARmJd4z4uw9cNEnnvRenIgxMsYPXwIBjEuEPn+jmJkbLj6AjiVxgEYxLhAiPQ4PRYeMvv6+9HK+QCfg0WMCiSSQv6R22kbEv5xy2VBdkgjMIjRIB3X/bZsI59W5kIiHeC/skuS4DZBtWLUgxJkSu4TJ3i6ar4fb7QVIyYnYBAjHnRVb23WB2qApaG0tWdADx/LtovWj0MwSGGBxDZNtdo2xvEXeeaTB19GhB0ujlJvkQ/Pb7wWYSddC4mA3nxzdAA5QcTRJc5H69RwuWuxRdW9cSwH2fJQX+Q5q/7bGP8WMKBz0AF6h+zdu36ZOi53DLqs1w91icM94dVN4ggaYaivKOUlCl/ivy920WLkEkjsJojwFNaF11u8xnrO+zvPbpsqvu2l9Vj5196JNjZ6UJBcArFo0eUrxaIIHYolxebzr2b39w7zXlqbz3u8kfZi8Rwa8HC/qBetsYvFj5btKPfGr8+ztRaeXrT2OlCC65jRu34Upvn26JBH6v2UicdRJvKqYd7nqDwP4jvRP2AJSt6/VGyxF0ABtAskV+pIyb9gCc/zA9mGfjKBAlSfSeuKH2VemtTOAQujQrWcVoGWBUBCtHd0g7gtWaZFcyGtAoWNhrpDJGTzbCvIDot3ArQK5MehIpDHfCtB+sqbUeoEJHAKBdEqEBJTlxhdWgnSEWNK/EHfP4OCWDnVMMGy1m7ieX9DQbQKRKjmGOTXbcUgWaDpTa3dVVTuQXEcWxGI52PSDlauVas3BqF64qC1Ki8C6mnVVu5BNm5huffXQIk/BDiBEugVSL1Lw4r3LC0xMLa/xPi3tJUd2xGIQD09KdvL1huDpEM+HpNsxZ9taVz6LE3zEqPsEiNLZQahlEFj4fznA3bu7qiQ68NGaamLjmY5dMegIGvwjJcZtSUtjrIBWlDpNm+jm6ijB5RGd5AOMmMb3UQNPaA01SaKdrqJQXa43t0g2gS6C91EXT2gNNoEugvdRF09oDTaBLoL3URdPaA0pfMgAva0+eao/XGMGMhzEqIOn7MLJljUgfLxeKkeUJpcAhFGUwQfsu+HnvGj3NRYRZ5jGp6XncCa5Fpiswfdkc3zrTLwQvk8BujDmuSOQQhRFz4RhDgQ03fL7r0uSm6B/rv/04m4YQHWqGsqgj48roQB6tfC+TezB0/Wij0Oh8PhcDgcDofDUSH/A41qn9LJOnLQAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.com6b-box___mYv_B .icon-zan___1Suso.active___1vXbY {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQESURBVHgB7Zs/TBRBFMa/N0tpcWqhnSTai7aagJV0EmNjZSisLIBWQ4RgYScNtVDZYKDUyjPR2rMnATssTK7Ajpvn2z3+7O0sl529uZnzMr/kDu4xc7d89+bNe293gUgkEolEIpFIJBKJ+IYwQvBKYxIJJrMXHbRopd1GYIILJKI0MKEW5NdFeTQKf21CqU16+WcLgQgqEK81ZkBqB4YwxsgmjnlePOoAngkmEK9dnQPxTvUJOEBH3/G97BQCkMUaxe+sJpHEpgn1Gp4J4kH85sqm/HjWa8SRHM1HOaS9E8M9eZo1J+sHtNxuwhMTCAFhWgQ5h3EI5iUgOcwN+gbSv1EUEmpOnprwhPcllgVmPtnKTyHeBuXFOR2sNjPxesdOwyP+YxBhyrBp+tlnRqvwBpPwSIAgTY96XqYeQmrv4uF8CQHxKlCWFIJmeoyEvf6TcL1gaMEjfj0oSWYMG9P3C8crEYfoVmF8v+XoHL8CleU+rC/2CObbplHvwiNetvluvUXvjd0rXS5lu9cZ+n4xVfOZA2WfB0uybVqp29C4XHHKDfmUNHcpq7eey1beJ0DrD/J8HoOYD8RoUbhSC51Oa5AarrJAJ16wYwTZ+mxlec6FHyjCqEwgF6zjWK/WqeOqx6AJte5QnM99xUkhKT3csSjH/6W7i9pRSaCsuDRS/hpk9RZviDhvK4xOBfoEd0whUYuWcyp6UFIMrhakonRzF4kd6il0sl19rtoQT9ruvocDCAu2XjTILrYk/0DJFi1xg3KBlaRU4GQZ9TiCFpGAjZ7itipKv5B5T3KWhnzZaanTrPwWGD4uY4kdLLHOsCWTsMC9QFQoDTigQETXTFvnABaMtwdpvmXYOrCq5VwLZFbexAE9yBCobZsLuRWIUdaaCBmDBu4EuBVI6RKB1F+E4VJJJ+ArLHErkC7zoEBLjLUZf0j/gCVBTvt4QfFNw0b4BUscL7GSbVWHikFkBuhX7cAxqJRQS6y4g9Vr1Trexfi6YaMkjECOWrXjmShSWYCWc/s1GHaiGMp7zFjYr/fdh/EUSLNxcrJuL9utQMWTfEyBPAhOAnSKYw+iXoFIhyozijHIOv85ZfwSRZXVX4WlTk3UxHWxWtjmQyyxkgy6ZoBOGbYH+RfIQQ8oj+MgPQLdRAc9oDzj50GOrwZxKdAodBOd9IDyuBNoFLqJjnpAedwJNArdREc9oDyDnDh8KFtG/vods5LPLuUtOehhoMSDmYqXDdfqAeWpJpBCu+TM5myFa0OqjHEDl9oGvtiq0hLrfgsc/M4bK5j30dGrGJDqMYh5Hv8LqTiKH7u4+aWyQNIu2E1vA8juvBkt+OxB2JfnVXT47qCxJxKJRCKRSCQSiUSGyD9H/k6b2HhqvAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.com6b-box___mYv_B .composition-ans___24CTh {
  margin-bottom: 5px;
}
.com6b-box___mYv_B .composition-ans___24CTh.ease-in___3FFI0:last-child {
  animation: composition-delay___1W0k0 1.5s;
}
.com6b-box___mYv_B .composition-ans___24CTh .evaluate-title___KKMfp {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  background-size: 100% 26px;
  background-repeat: no-repeat;
  background-position: 0 7px;
}
.com6b-box___mYv_B .composition-ans___24CTh .evaluate-content___WU-B_ {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 26px;
}
.com6b-box___mYv_B .icon-cai___mOkkJ {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARCSURBVHgB7Zu7ThtBFIYPvkgUSDFvsGW6WJgiHUuXdH4DjASG0jwB5glwOi5Ccbp0hjIVTpcCw6ZLuenSxSmQHItL/iOtJXt27ezMrmcWmE9C9oxn18s/c86cOTMmslgsFovFYrFYLBaLbhYoJRqNRun29tbJ5/MlyhiLi4teq9XqkwKJBdre3i7j5XBhYcGlbNM6OTnZI0nylIB6vV7FSwfivKbs87ZSqZR6vd4XmYuUR9Du7q5zf39/A3EyZ1KzGA6Hy+12O7a55UiRh4eH5lMThykWi1WZ9gVSZ00o+xhRexBNyRnKgu9x8PdRqD5Ax3VHhVwuxx3YoQQoCVSr1fiLHaH609nZ2TlpYmtry4VAE3UQpAtH3B2V2Q1AMEqCkokVCoWyWIeHvaFniJJA6Kkogb6TRtjEIqp9ShlVJy36n/7R0ZFPzxBVgZzxAnrTI81EjaB5dJKqQBMmBkeo1bx0Ii0Qzx6hm+Ryl6SZx8dHMQabS3ghLVCUg8YI+kmaiQhSsyEQes4R605PT7X7IPBKKGdmBL0RqkyIExpB84rgpQWCOU2YmIkZTCdSAnHuR+w5mJyRGSzC1H2aA1ICYfQ4oRvkcnYEjUA6NTSDDQYDUwI54wWMqLnMpFICwbzEJYYnk3x6isiamOigtcc/TJBuETE7i/FDRThoI+aFXYqQQHgWswJlKQeEzGVIIEwWv2kOxBYoCzmgERHrMH6WPzQHZHzQi8kBjSMjkDNeMBlB68omMjICvZgc0DixBMpKDmiErmwio7xx+FKItS+GKd4X95cwk7Sw76Q0c6C3u5QMRyj38Syu2AjhgCPunckS++qdnZ1LiOJSOvgw0XUZs2AB8P0b6Khqki1vXFs9Pj6+iNs+tomhNw4oPXjHM7YPq9frh9weAtWSngeQjd1iH3+5vr72K5UKjziX0qG0srJygfv+mtUI4nC0LnXgYBoQ+AO2pj/LXCPlpHHzJr5kk1JKs/7vNBqPHBLCCxV4ncbiIHfeIEkSnQ+6u7tz4raHGPuCD+tD8OVp7Tm0mBJKeLhPWyaTubS0pHwET/n4S+Bg/bjtka7tjM8oeP91VvtA0Ik61VGQBC1xUHBURUyVTD23w6mViBnT1y0Oo0UgmIor1s0yEYweN6K6SQbQIlBEqtaftdkIgUKz1nA4jB27pIkWgcT8TYxYZE1o3zWV+za1Fns17QOeHUlYSpjMHOgSaOIfxIgqB0KEgBgbYh1M7pwMoUsg0d+UIERHFClYcDaFtn4Ki1tlkhwDjg0cbLtYLO4LU32Z11dYBHd50w9/ayi74rXsf8ggiX6KEBfP8warq6t/8fad8BELxksJd1oaFSHC5tXVlbHNSS0CMb1e75vMYjfY53qP5cgPMog2gRiI1I0pkgfHvG5aHCa134vJwM4ZI6TBfoeC1XowYjyYGqckjM1aFovFYrFYLBaLxaKBfxlCo3LuuJS3AAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.com6b-box___mYv_B .icon-cai___mOkkJ:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARzSURBVHgB7ZtRcts2EIZ3QbjSjNyJcgM+dSK3nSonsHMD9aWTxm1T3yA5QZwT2DmB0mnjzPTF6QmsnsDuNGN3+qSeoOpY7khjihuAshIRlG0ApEA6xvcGmBTpX9hd7C4E4PF4PB6Px+PxeDwe1yAURPOw2zxf+SREiptQMXgUHQ3ubw3AgtwCNd6+amMAO+KjNqDKINsd3nv4FAzJJdCnx3sdAuiKT6ncqlmIhUjWAtVPuiGn2uGNEecCPhrfNTE3BpYEVNu+aeJIhJ/smFzPwRaE9fmhMLU+o+gpIVo5Q1MIWIiAXWX2OUDcm42QqEnI9yEHVgLJiBUBhPNzGNNPp5//8AYcsfrnzxvAA2U27g1b3/dmo/rJ65DLry4HViYWcd5W5zAIDuEjxEogCoKMQOdEf4BDCFmozkXA+1AwVgKJ0LeuTA1GrW/78BFiG8XC9JCOwDGI6jsALONLshMIIW1iBE7NyyXGAiXRQwFZcACuye7BlrK9MBZokYOOGfsHHEOAzfS4IgIhUKjOnX32jXsfBHAnNcaKCCTe7Ctl7Fyc6XMpbWJEFRGIMGViRFiOQI4wEqjxttvOOEfHG8T3jwUM02PWhyVgJBBjQajOIU78CppBlI1gfBSVIhBmkuV4KZHUzAcxJcUQDtq21ntTMBNIcdAQo/P9j0SWW9S5ZdWhtAVKXirjoONSzGtUr2crmXHJYb5KNaBgMsoIxHjwLywBbYGqUAOagcSzJkbn/8ES0BboNtWA5jFx0mF6SKX4n+TJjqqJEn2BblENaB4tgSpTA5o921E1UWLdOLwtaPXFRIjvq/2lmOJdsbKsIsfwyw+9KxvEpjBUeuaDRaucJnEIQb7zGdp3r568OijqBIfswk5g/GDU2urr3iMFIM4eI2EnT8sbkXVO7z38Tfd6AxOLn0NByEQzgJq2D1v9a29HdFEPRKv5x7znAUz3btoCTVu6VKhIjb9/bV93nRDnUCy5J1AERC9MnbmRkx62NrcJaEu8cCF7IJyMr1wNycohuFbEayFRrxbiDNc2jYXOcT5IHgyIQv072DPFhw2GrUd3L7v64nBC1gxFiUVUD18inWubiqxZ2ZZlrI+/XCzVvu71q8d7+6mvg/D3K2/gUlAFuQpam8WYmyZO9kHJalCcKwV06bmdab0nHTFl5LMxkby42SgytpGZQ36piUxqQfZ6BttQAo4Eyp5Gu6rZGAPvqHMr/4+19y5F4kYgSreJxWbvageLammFemXVvsvJxTC+c9mf5OlZtWNRZuXAiUDCpJR/ENtSiEXXcqg/zkxi/AZKwtUKUv1Nk2NtXxVpmnDS9vxcEr1a+ZLbPNgfAzZgZTx+GdVqz1KhXuyQZT7WOHndS5p+iSNflAxjD0qksB+zXEfj+JcnIpPeMblnmvXjgzJr386c9Nnad7tGya7Mnyb0ddmNAadRTCa7WiKJfCtCvH/2xWbpByOcmdg8SaJLsUgbcP19M0CuGKQjsUd6cbr2qLSo5fF4PB6Px+PxeDwOeAfZRXwESQkdAgAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.com6b-box___mYv_B .icon-cai___mOkkJ.active___1vXbY {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP5SURBVHgB7Zq/bxMxFMffc8KWIYiBjBm6E/ZKLVNZkIrEHwBzl3ZioFGDCjNlYGKg/AewsdGhzJQdpI5daG8oC2r8eL4DmrOvydl3sa+tP1KlnHVXn77n99MGiEQikUgkEolEIpGIbxBqgkbdLtyAPkjoQtMYwwGOkgQcqCwQvewOgPAV/6tlaDY7uHm8AZZUEoi2b60C0jv+2bxVU4y1SM4CsUn1oS2+wuURJ+NM3rQxNwGutMUILps4CtFatbm9Da4gLAFNXBMcAeIb/nEKPiDssXk/1Ubf89/B+SV2lCOACjgJlEYs4oiV5xO/9H6NgXHGS8hBwVQHQOJcIIIeCIIquJlYCwbGGOIPuIK4CYQFAkn5HXwi8LYxJukIasbRSeOSNnAK2Kr95ZqAq0D9/DX5XT3plNQzxubwkVzDfN7ECP0L5AlrgWi7u2wMIn4D/3S067mkF/YrqNBBUwj/01CBSPSNMRT+TQwpLxBhU1YQ3cldB/M/mBcIZUME0h000pV10AorgdLeD6BWoAYSSJUROXAuftBuBclW3xy8miXGPyxNjMwIRiLMCkJtBaluwhywEwgpX2JkDtpPeyMQtk5ad9Ch6q+OMYIUNoqlPSDdQROEctCdgtHAYb5JPSAhCwQSv2AOlBeoCT2g//MWrSAKnShenx7QJDYC9fPXATNoT93EdCqLe69ND2iSUgI1qAeU4ambqHDfOLwmlNsXG8OheSet8Zd0ixw4sXfl9LxeqHLAUPtkOmpzsSKld/noxc3PNZ7g4F1Y2uDit7xZKAEErPCvRYDCRLEkchU3k49l7y5vYkTPoT562ZGZ0pOv8Zuq++9DJXGYM7DynaUFwmGyxyl+zSLJhZl3CXrLq+0R1AHBaxwlhzaPWDlpHB6POHt+wjNV8yHns89YDamfmy3iTChJxRker4Ml1c4HtYwDDFNmwi3Nh7FjFQ8uniD1OQVmyB8HaZfLjfKmEvIIXlnYyZ9o3YB9Fmh44QMolThacuq2Cqrgfj7IgizR1Fsl+GXKI8r0NHHoEIcnXsVReEoUxbIxRFNqOaFtLSlaNIIA+BHIaNWqPGhKL5to0Rj7DaVzlzrxtII088KZnUgtK6Y9VydblVC12MXhXaRlhLZjEa4w9iMQGSF5AcS4uE6StFIw+AEC4ckHST2x7IAU24ZI6cFMepwfU9GLs/hAeAnzXP/sQpu2cr4IOUNW9ZgSL3XaMCjsewPuQUA8JopdzmGERYEK6eqBMd2zrZ/qxJuT5hbDjl2xy/WToIchxVF4jWJpsVtKJK63zuguPkvqKYor4M3EJskKXbGeJZD41++QynPUSXmut34Gi1qRSCQSiUQikUgk4oE/MztDr9z3FKUAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.com6b-box___mYv_B .remark___1mBA5 {
  color: #868686;
  border: 1px solid #868686;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  display: inline-block;
  transform: translate(12px, -5px);
  line-height: 10px;
  cursor: pointer;
}
.com6b-box___mYv_B .remark___1mBA5:hover {
  color: #1bc37d;
  border-color: #1bc37d;
}
@keyframes composition-delay___1W0k0 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
