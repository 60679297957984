.modelBox___2AmMg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f8f8f8;
  padding: 60px 5%;
  position: relative;
}
.modelBox___2AmMg .modelHeader___3grD5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 0 69px;
  background-color: #292929;
  color: #ffffff;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  cursor: default;
}
.modelBox___2AmMg .searchBox___2In00 {
  margin-bottom: 20px;
}
.modelBox___2AmMg .searchBox___2In00 .ant-btn-primary {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}
.modelBox___2AmMg .tableBox___2XbKL {
  width: 100%;
  height: calc(100vh - 180px);
  border: 1px solid #eaeaea;
  border-radius: 16px;
  overflow-y: auto;
}
.modelBox___2AmMg .tableBox___2XbKL ::-webkit-scrollbar {
  height: 5px !important;
}
.modelBox___2AmMg .tableBox___2XbKL ::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 2px;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-fixed .ant-table-tbody tr,
.modelBox___2AmMg .tableBox___2XbKL .ant-table-fixed .ant-table-tbody td {
  border-bottom: 0px;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-fixed .ant-table-tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-switch {
  background-color: #d7d7d7;
  border: 1px solid #bfbfbf;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-switch::after {
  background-color: #bfbfbf;
  margin-top: -0.5px;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-switch-checked {
  background-color: #53e8c433;
  border: 1px solid #53e8c4;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-switch-checked::after {
  background-color: #53e8c4;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-progress .ant-progress-outer {
  width: 80px;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .ant-progress span {
  display: none;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-table-row .opening {
  width: 45px;
  font-size: 12px;
  line-height: 16.8px;
  text-align: center;
  color: #1a1a1a80;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-pagination {
  position: fixed;
  bottom: 12px;
  right: 5%;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-pagination-item-active {
  border-color: #000000;
}
.modelBox___2AmMg .tableBox___2XbKL .ant-pagination-item-active a {
  color: #000000;
}
.modelBox___2AmMg .tableBox___2XbKL .operation___3C3cq {
  color: #69d5b1;
  background-color: #fff;
}
.modelBox___2AmMg .tableBox___2XbKL .bgc___2eEvF {
  background-color: #fff;
}
.custom-popover .ant-btn-primary {
  background-color: #000;
  border-color: #000;
}
.custom-popover .ant-btn-primary:hover {
  background-color: #000;
  border-color: #000;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-modal-content .ant-modal-header {
  border-bottom: 0px !important;
  border-radius: 16px;
  padding: 16px 24px 8px 24px;
}
.ant-modal-content .ant-modal-content {
  padding: 16px 24px;
}
.ant-modal-content .ant-modal-footer {
  border-top: 0px !important;
  padding: 10px 16px 16px;
}
.ant-modal-content .ant-btn {
  width: 100px;
  height: 40px;
  border-radius: 16px;
}
.ant-modal-content .ant-btn-primary {
  background-color: #000000;
  border-color: #000000;
}
