.fullScreenControl___1EiXH {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  z-index: 0;
  padding: 70px 0 80px;
}
.blockWrapperBox___2MIiH {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  z-index: 0;
  padding: 70px 0 80px;
}
