#sidebarBox .ant-back-top {
  position: relative;
  height: 64px;
  width: 100%;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 1);
}
#sidebarBox .ant-back-top:hover {
  background: rgba(238, 238, 238, 1);
}
#sidebarBox .ant-back-top .backtopImg {
  width: 100%;
  height: 100%;
  background-image: url('https://openplantform.oss-cn-beijing.aliyuncs.com/assets/images/backTop.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5186930941&Signature=35SDhAnZk0gfEGYnezDC4%2BAxHdI%3D');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center center;
}
#sidebarBox .ant-back-top .backtopImg:hover {
  background-image: url('https://openplantform.oss-cn-beijing.aliyuncs.com/assets/images/backtop-hover.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5187023161&Signature=g0WK5XRRaTsaD0JxOVoRfcxxyNA%3D');
}
@media (max-width: 1450px) {
  #sidebarBox .ant-back-top {
    height: 43px;
  }
}
