.breadExpandTabItem___2bn02,
.breadExpandTabWrap___lp7W4 .breadExpandTabContent___1Q3qZ > li {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 10px;
  box-shadow: 5px 2px 2px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.breadExpandTabWrap___lp7W4 {
  width: 180px;
}
.breadExpandTabWrap___lp7W4 .breadExpandTabHeader___3dNYw {
  color: #fff;
}
.breadExpandTabWrap___lp7W4 .breadExpandTabHeader___3dNYw .triangle___3xNae {
  float: right;
}
.breadExpandTabWrap___lp7W4 .breadExpandTabContent___1Q3qZ {
  overflow: hidden;
  padding-right: 7px;
  box-sizing: content-box;
  width: 100%;
  transition: all 0.5s;
}
.breadExpandTabWrap___lp7W4 .breadExpandTabContent___1Q3qZ > li {
  background: #fff;
  border-bottom: 1px solid #eee;
}
.breadExpandTabWrap___lp7W4 .breadExpandTabContent___1Q3qZ > li .breadExpandTabContentItem___1pZHm {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.max1000___2HUtL {
  max-height: 1000px;
}
.max0___Kxsjk {
  max-height: 0px;
}
.triangle___3xNae {
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid transparent;
  transition: all 0.5s;
  transform-origin: 25% center;
  margin-top: 6px;
}
.triangleRotate___jt95p {
  transform: rotate(90deg);
}
