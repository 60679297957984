.cvpr2021_box___259qI {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.cvpr2021_box___259qI .banner___1Nzbz {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.cvpr2021_box___259qI .content_main___28zdM {
  margin-top: 20px;
}
.cvpr2021_box___259qI b {
  font-weight: bold;
}
.cvpr2021_box___259qI h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.cvpr2021_box___259qI h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.cvpr2021_box___259qI h3 {
  font-weight: bold;
  font-size: 18px;
}
.cvpr2021_box___259qI .tab_box____Hr2Y {
  background: #5187d5;
  margin-bottom: 20px;
}
.cvpr2021_box___259qI .tab_box____Hr2Y ul {
  height: 60px;
  line-height: 60px;
}
.cvpr2021_box___259qI .tab_box____Hr2Y ul li {
  display: inline-block;
  width: 25%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.cvpr2021_box___259qI .tab_box____Hr2Y ul > li:last-child {
  width: 15%;
}
.cvpr2021_box___259qI .tab_box____Hr2Y ul .isActived___E70bH {
  background: #1a3a68;
  font-weight: bold;
}
.cvpr2021_box___259qI .imgFont___28OAV {
  width: 700px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #b2b2b2;
  font-style: italic;
}
.cvpr2021_box___259qI .change_contents___1XkpS {
  padding: 20px 80px;
}
.cvpr2021_box___259qI .change_contents___1XkpS b {
  color: #377ef7;
}
.cvpr2021_box___259qI .change_contents___1XkpS span {
  font-weight: bold;
  color: #504f4f;
}
.cvpr2021_box___259qI .change_contents___1XkpS li {
  line-height: 35px;
}
.cvpr2021_box___259qI .change_contents___1XkpS .body_contect___3flyg {
  line-height: 35px;
  text-indent: 32px;
}
.cvpr2021_box___259qI .change_contents___1XkpS .cycle_item___2DxiJ {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.cvpr2021_box___259qI .change_contents___1XkpS .home_img_1___1eAe5 {
  width: 800px;
  display: block;
  margin: 20px auto;
}
.cvpr2021_box___259qI .change_contents___1XkpS .like_a___xkO-P {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.cvpr2021_box___259qI .change_contents___1XkpS a {
  color: #303de2;
}
.cvpr2021_box___259qI .change_contents___1XkpS .home_connect___32Rt1 img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.cvpr2021_box___259qI .change_contents___1XkpS .home_connect___32Rt1 img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
