.knowledge-list-popver-area___33KxJ {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  min-height: 310px;
  position: relative;
}
.knowledge-list-popver-area___33KxJ .white-arrow___t2p2s {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top-color: #f5f5f5;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #f5f5f5;
  box-shadow: -2px -4px 5px rgba(0, 0, 0, 0.06);
  position: absolute;
  border-radius: 4px;
  top: -18px;
  transform: rotate(45deg);
  opacity: 0;
  z-index: 1;
}
.knowledge-list-popver-area___33KxJ .white-arrow___t2p2s.left___76X8c {
  opacity: 1;
  left: 16%;
}
.knowledge-list-popver-area___33KxJ .white-arrow___t2p2s.middle___p_rUV {
  opacity: 1;
  left: 51%;
}
.knowledge-list-popver-area___33KxJ .white-arrow___t2p2s.right___3W0au {
  opacity: 1;
  left: 86%;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 {
  max-height: 500px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 310px;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92.loading-area___2XKmQ {
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 .know-point-item___RNjMG {
  flex-basis: 16.6%;
  cursor: pointer;
  z-index: 2;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 .know-point-item___RNjMG .know-point-text___34BOi {
  border: 2px solid #fff;
  user-select: none;
  background-color: #f5f5f5;
  border-radius: 14px;
  line-height: 44px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 120px;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  /* 防止文本折行 */
  overflow: hidden;
  /* 隐藏超出部分的内容 */
  text-overflow: ellipsis;
  /* 使用省略号表示被裁剪的文本 */
  color: #1a1a1a;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 .know-point-item___RNjMG.act-item-popover-area___1A1Fh .know-point-text___34BOi.text-green___uFC3M {
  color: #0cd7a6;
  border-radius: 14px 14px 4px 0px;
  background: #E7FBF6;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 .know-point-item___RNjMG.act-item-popover-area___1A1Fh .know-point-text___34BOi.text-blue___3HXU4 {
  color: #1890ff;
  border-radius: 14px 14px 4px 0px;
  background-color: #E8F4FF;
}
.knowledge-list-popver-area___33KxJ .know-point-list-area___xIk92 .know-point-item___RNjMG.act-item-popover-area___1A1Fh .know-point-text___34BOi.text-orange___2RuNR {
  color: #ff7f09;
  border-radius: 14px 14px 4px 0px;
  background-color: #FFF2E6;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV {
  animation-duration: 0ms !important;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV .ant-popover-content {
  background: transparent;
  border: none;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV .ant-popover-inner {
  border-radius: 0px 14px 14px 14px;
  border: none;
  box-shadow: none;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV .ant-popover-arrow {
  display: none;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV .ant-popover-inner-content {
  padding: 0px;
  border-radius: 0 20px 20px 20px;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV.green-popover___191uE .ant-popover-inner {
  background-color: #E7FBF6;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV.blue-popover___QIJAi .ant-popover-inner {
  background-color: #E8F4FF;
}
.knowledge-list-popver-area___33KxJ .know-point-item-popover-area___FNDYV.orange-popover___3C_LN .ant-popover-inner {
  background-color: #FFF2E6;
}
