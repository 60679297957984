.magi_box___1y4WW {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.magi_box___1y4WW .banner___2LCAw {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.magi_box___1y4WW .content_main___22bxu {
  margin-top: 20px;
}
.magi_box___1y4WW b {
  font-weight: bold;
}
.magi_box___1y4WW h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.magi_box___1y4WW h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.magi_box___1y4WW h3 {
  font-weight: bold;
  font-size: 18px;
}
.magi_box___1y4WW .tab_box___3a3vr {
  background: #5187d5;
  margin-bottom: 20px;
}
.magi_box___1y4WW .tab_box___3a3vr ul {
  height: 60px;
  line-height: 60px;
}
.magi_box___1y4WW .tab_box___3a3vr ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.magi_box___1y4WW .tab_box___3a3vr ul > li:last-child {
  width: 15%;
}
.magi_box___1y4WW .tab_box___3a3vr ul .isActived___3ow2y {
  background: #1a3a68;
  font-weight: bold;
}
.magi_box___1y4WW .change_contents___3kU-i {
  padding: 20px 80px;
}
.magi_box___1y4WW .change_contents___3kU-i .home_img_1___28qF- {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.magi_box___1y4WW .change_contents___3kU-i .like_a___1iur3 {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.magi_box___1y4WW .change_contents___3kU-i a {
  color: #303de2;
}
.magi_box___1y4WW .change_contents___3kU-i .home_connect___3obWd img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.magi_box___1y4WW .change_contents___3kU-i .home_connect___3obWd img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.magi_box___1y4WW .home_connect___3obWd p {
  line-height: 35px;
}
.magi_box___1y4WW .home_connect___3obWd b {
  margin-right: 8px;
}
.magi_box___1y4WW .home_connect___3obWd .img_box___Bw9bO {
  margin: 0 auto;
  width: max-content;
}
.magi_box___1y4WW .home_connect___3obWd .img_box___Bw9bO img {
  margin: 40px;
}
.magi_box___1y4WW .home_connect1___IGfJ7 img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.magi_box___1y4WW .home_connect1___IGfJ7 img:nth-of-type(1) {
  width: 200px;
  height: 200px;
}
.magi_box___1y4WW .body_text___2dv4p {
  line-height: 35px;
}
.magi_box___1y4WW .body_text_in___11mpl {
  line-height: 35px;
  text-indent: 32px;
}
.magi_box___1y4WW .span_bg___1E5dy {
  color: #fff;
  background: #666666;
  padding: 0 3px;
  border-radius: 3px;
}
