.EnterpriseWeChat___3YvQD {
  display: inline-block;
  height: 0;
  position: relative;
}
.EnterpriseWeChat___3YvQD .EnterpriseWeChatHoverImgBox___1ECCL {
  display: none;
  width: 180px;
  height: 250px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px #ccc;
  z-index: 3;
}
.EnterpriseWeChat___3YvQD .EnterpriseWeChatHoverImgBox___1ECCL .triangle___3qqlZ {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: white;
  transform: rotateZ(45deg);
  top: 50%;
  left: -8px;
  box-shadow: 0 0 10px #ccc;
  z-index: -1;
}
.EnterpriseWeChat___3YvQD .EnterpriseWeChatHoverImgBox___1ECCL .text___q6nTP {
  line-height: 25px;
  margin: 10px 0;
  color: black;
  text-align: center;
}
.EnterpriseWeChat___3YvQD .EnterpriseWeChatHoverImgBox___1ECCL img {
  width: 100%;
}
.EnterpriseWeChat___3YvQD .singleImgBox___1i0m- {
  display: none;
  width: 180px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px #ccc;
  z-index: 3;
}
.EnterpriseWeChat___3YvQD .singleImgBox___1i0m- .triangle___3qqlZ {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: white;
  transform: rotateZ(45deg);
  top: 50%;
  left: -8px;
  box-shadow: 0 0 10px #ccc;
  z-index: -1;
}
.EnterpriseWeChat___3YvQD .singleImgBox___1i0m- .text___q6nTP {
  line-height: 25px;
  margin: 10px 0;
  color: black;
  text-align: center;
}
.EnterpriseWeChat___3YvQD .singleImgBox___1i0m- img {
  width: 100%;
}
.EnterpriseWeChat___3YvQD:hover {
  background: #eeeeee;
}
.EnterpriseWeChat___3YvQD:hover .EnterpriseWeChatHoverImgBox___1ECCL {
  display: block;
  top: -70px;
  left: 130px;
}
.EnterpriseWeChat___3YvQD:hover .singleImgBox___1i0m- {
  display: block;
  top: -35px;
  left: 130px;
}
.EnterpriseWeChat___3YvQD:hover .singleImgBox___1i0m-.wechatAppImgBox___3X89q {
  left: 144px;
}
