.ExamPaperSplitRecord___3VBNq {
  width: 100%;
  height: 686px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR {
  width: 851px;
  height: 100%;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imageResult___Mh-lY {
  width: 851px;
  height: 509px;
  overflow-y: auto;
  background-color: #fafafa;
  padding: 19px 58px 0px;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imageResult___Mh-lY .image___HLymt {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imageResult___Mh-lY .imageError___1AsuT {
  background: #fafafa;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgList___3GssE {
  width: 100%;
  height: 130px;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgList___3GssE .imageItem___1j2oi {
  width: 158px;
  height: 104px;
  cursor: pointer;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgList___3GssE .activeImageItem___2RiWp {
  border: 2px solid #2372e0;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgUpload___2jZx9 {
  width: 100%;
  height: 47px;
  padding-left: 12px;
  padding-bottom: 11px;
  display: flex;
  justify-content: space-between;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgUpload___2jZx9 .detection___1pzz0 {
  width: 674px;
  height: 36px;
  display: flex;
  justify-content: space-between;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgUpload___2jZx9 .detection___1pzz0 .input___1NgjY {
  width: 593px;
  height: 36px;
  background-color: #e4eaf3;
  border-radius: 2px;
  border: 0px;
  padding: 9px 14px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #888888;
  line-height: 17px;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgUpload___2jZx9 .detection___1pzz0 .button___3M5H2 {
  width: 73px;
  height: 36px;
  background: #e4eaf3;
  border-radius: 2px;
  border: 0px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 17px;
}
.ExamPaperSplitRecord___3VBNq .left___3SKnR .imgUpload___2jZx9 .uploadButton___ILkno {
  width: 83px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  border: 0px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI {
  width: 349px;
  height: 100%;
  background-color: #ffffff;
  padding: 18px 27px 18px 23px;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv {
  width: 100%;
  height: 100%;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .title___2xnfr {
  width: 100%;
  height: 29px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
  border-bottom: 3px solid #f6f6f6;
  margin-bottom: 15px;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .title___2xnfr p:first-child {
  margin-right: 46px;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .activeTitle___3LHiR {
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #5187d5;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .panel___2nn2D {
  width: 100%;
  height: 612px;
  overflow: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .panel___2nn2D p {
  margin-bottom: 15px;
}
.ExamPaperSplitRecord___3VBNq .right___2WjdI .result___1ZZDv .tab___NuVsp .panelDisplay___3aQSz {
  display: none;
}
