.banner_title___2HTgN {
  font-family: PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  height: 75px;
  line-height: 75px;
  padding-left: 10px;
  background-color: #069dee;
  color: whitesmoke;
  font-size: 16px;
  align-items: center;
  position: relative;
}
.banner_title___2HTgN .logo___3Jezm {
  height: 38px;
}
.banner_title___2HTgN .title_txt___29rVs {
  position: absolute;
  margin-left: 20px;
  margin-top: 5px;
}
.content___1cBxc {
  width: 85% !important;
  font-family: PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  margin: 20px auto;
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.content___1cBxc .content_left___R9mg3 {
  padding-right: 50px;
}
.content___1cBxc .title___1ZxDu {
  font-size: 18px;
  color: black;
  font-weight: 500;
}
.content___1cBxc .same___3lbAo {
  margin-bottom: 40px;
}
.content___1cBxc .question_box___KFopq {
  margin-bottom: 40px;
}
.content___1cBxc .question_box___KFopq .question_details___3INRs {
  line-height: 25px;
  margin: 20px 0;
  word-break: break-all;
  word-wrap: break-word;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL {
  margin-top: 40px;
  display: flex;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_box_area___2qc4m {
  width: 180px;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg {
  overflow-y: scroll;
  height: 500px;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK {
  width: 180px;
  height: 100px;
  margin: 0 5px 20px 0;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border: 3px solid rgba(9, 147, 236, 0);
  transition: 0.5s;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK:hover {
  border: 3px solid #0993ec;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK:hover .video_player___3pG6y {
  opacity: 0.8;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK .video_label___3mtxZ {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: black;
  color: whitesmoke;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK .video_label_active___Cm95M {
  background-color: #069dee;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item___1OiMK .video_player___3pG6y {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_list___309Gg .video_item_active___1Hqwy {
  border: 3px solid #0993ec;
}
.content___1cBxc .question_box___KFopq .video_box___20rOL .video_main___1L0Wk {
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid gainsboro;
  width: 900px;
  height: 500px;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 {
  border: 1px solid gray;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .videoItemCol___1kaEy {
  width: 180px;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .questionDomArea___1KPQk {
  flex: 1;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .questionDomArea___1KPQk img {
  max-width: 200px;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .video_item___1OiMK {
  width: 180px;
  height: 100px;
  overflow: hidden;
  position: relative;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .video_item___1OiMK video {
  border-radius: 10px;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .video_player___3pG6y {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .video_time___1Eeug {
  position: absolute;
  bottom: 10%;
  right: 5%;
  color: whitesmoke;
  padding: 2px 7px;
  border-radius: 15px;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .video_time_bck___2TWZh {
  position: absolute;
  bottom: 10%;
  right: 5%;
  background-color: black;
  padding: 2px 7px;
  border-radius: 15px;
  opacity: 0.5;
}
.content___1cBxc .question_box___KFopq .Similar_box___22ce0 .options___1ONpb p {
  display: inline-block;
}
.content___1cBxc .related_box___1qb6A {
  margin-bottom: 20px;
  line-height: 30px;
}
.content___1cBxc .list___3RJBx {
  cursor: pointer;
  word-wrap: normal;
  transition: 0.5s;
}
.content___1cBxc .list___3RJBx:hover {
  color: #069dee;
}
.content___1cBxc .list___3RJBx .video_icon___2k8IZ {
  width: 23px;
  margin-left: 10px;
  margin-top: -3px;
}
.content___1cBxc .list_yun___3RjaC {
  display: block;
  color: rgba(0, 0, 0, 0.65);
  transition: 0.5s;
  word-wrap: normal;
}
.content___1cBxc .list_yun___3RjaC:hover {
  color: #069dee;
}
.img_box___3-67v img {
  width: 80%;
}
.pop_input___2FrLq {
  height: 41px;
  font-size: 14px;
}
