.newsInfoArea___2laU7 .banner___NnjDl {
  position: relative;
  margin-top: 20px;
}
.newsInfoArea___2laU7 .banner___NnjDl .bannnerImage___1qfKG {
  width: 100%;
  display: block;
}
.newsInfoArea___2laU7 .banner___NnjDl .bannerContent___p8aTV {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 38%;
  left: 0px;
  display: flex;
  justify-content: center;
}
.newsInfoArea___2laU7 .banner___NnjDl .bannerContent___p8aTV .bannerContentInner___WXKpu {
  height: 104px;
}
.newsInfoArea___2laU7 .banner___NnjDl .bannerContent___p8aTV .bannerContentInner___WXKpu p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.newsInfoArea___2laU7 .containerOut___38B-i {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs {
  width: 1030px;
  text-align: center;
  padding-top: 50px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .title___2_5U9 {
  height: 33px;
  font-size: 35px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .desc___1LfGt {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 40px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .image___li8s3 {
  width: 800px;
  margin-bottom: 22px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .imageAlt___3rFT- {
  height: 22px;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 29px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .content___20bJW {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs .content___20bJW span {
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
}
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs p,
.newsInfoArea___2laU7 .containerOut___38B-i .containerIn___2TqJs span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.newsInfoArea___2laU7 b {
  font-weight: bold;
}
