.terms_box___1CRdA {
  margin-bottom: 40px;
}
.terms_box___1CRdA li {
  line-height: 35px;
}
.terms_box___1CRdA .cycle_item___3R3Q- {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.terms_box___1CRdA .body_text___19osv {
  line-height: 35px;
}
.terms_box___1CRdA .body_text___19osv > .body_text_pre___1tSSI {
  margin-top: 10px;
  background: #eef;
  overflow: auto;
  padding: 15px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}
