.generalSpeechEvaluationItem___2O7ku {
  width: 100%;
}
.generalSpeechEvaluationItem___2O7ku .active___1VT2M {
  display: none;
}
.generalSpeechEvaluationItem___2O7ku .currentTitle___2vIUA {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 28px;
  text-align: center;
  margin-bottom: 18px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .li___3RV9S {
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .li___3RV9S .liItem___1AOIs {
  width: 984px;
  height: 58px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .li___3RV9S .liItem___1AOIs .resultButton___3cabO {
  width: 984px;
  height: 58px;
  background: #fcfcfc;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  text-align: left;
  padding: 18px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .buttonActive___1cZnA {
  background: #e9f2ff !important;
  border: 0px !important;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 {
  width: 984px;
  height: 171px;
  background: #fcfcfc;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  padding: 14px 22px 22px 22px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .title___2p-lX {
  width: 100%;
  height: 22px;
  margin-bottom: 15px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .content___1Q7lg {
  width: 100%;
  height: 90px;
  margin-bottom: 3px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .content___1Q7lg .contentText___3hFNg {
  height: 90px !important;
  padding: 14px 18px;
  border-radius: 3px;
  border: 1px solid #ffffff;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .content___1Q7lg .customResultError___1Ptfo {
  border: 1px solid #d0021b !important;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .errorTip___3XbgT {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #d0021b;
  line-height: 20px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContent___1KNEa .customInner___1M4Z4 .tip___2Upv9 {
  width: 37px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
  position: relative;
  bottom: 55px;
  left: 885px;
}
.generalSpeechEvaluationItem___2O7ku .initRecorder___1Uiq7 .customReviewContentActive___1Qw_0 {
  background: #e9f2ff !important;
  border: 0px !important;
}
.generalSpeechEvaluationItem___2O7ku .emphasis___1z8j9 {
  width: 100%;
  height: 22px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
  margin-bottom: 36px;
}
.generalSpeechEvaluationItem___2O7ku .emphasis___1z8j9 .uselessVoice___10ZVA {
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #d0021b;
}
.generalSpeechEvaluationItem___2O7ku .emphasis___1z8j9 .tip___2Upv9 span {
  color: #5187d5;
}
.generalSpeechEvaluationItem___2O7ku .recogniseButton___3f2jZ {
  width: 100%;
  display: flex;
  justify-content: center;
}
.generalSpeechEvaluationItem___2O7ku .recogniseButton___3f2jZ .button___wZkdS {
  width: 140px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border: 0px;
}
.generalSpeechEvaluationItem___2O7ku .hidden___22tYD {
  display: none;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl {
  width: 100%;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .resultButton___3cabO {
  width: 984px;
  height: 58px;
  background: #fcfcfc;
  border-radius: 3px;
  border: 0px;
  text-align: left;
  padding: 18px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #939393;
  line-height: 22px;
  margin-bottom: 6px;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .buttonActive___1cZnA {
  background: #e9f2ff !important;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .tipMessage___24VwO {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #5187d5;
  line-height: 22px;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBar___4DqMN {
  width: 100%;
  height: 276px;
  padding: 54px 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBar___4DqMN .scoreProgressBarItem___1xHOw {
  width: 124px;
  height: 169px;
  text-align: center;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBar___4DqMN .scoreProgressBarItem___1xHOw .process___3dt_- {
  width: 124px;
  height: 124px;
  margin-bottom: 20px;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBar___4DqMN .scoreProgressBarItem___1xHOw .process___3dt_- .progress___1TvMj {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 600;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBar___4DqMN .scoreProgressBarItem___1xHOw .label___1Qzfv {
  width: 100%;
  height: 25px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 25px;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .scoreProgressBarDisplay___211f6 {
  display: none;
}
.generalSpeechEvaluationItem___2O7ku .recordOuter___3QcIl .marginBottomP___2sr0y {
  margin-bottom: 276px;
}
.generalSpeechEvaluationItem___2O7ku .reEvaluating___NcbOM {
  width: 100%;
  height: 36px;
  margin-bottom: 20px;
  text-align: right;
}
.generalSpeechEvaluationItem___2O7ku .reEvaluating___NcbOM .button___wZkdS {
  width: 122px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border: 0px;
}
.generalSpeechEvaluationItem___2O7ku .buttonIcon___1QBcY {
  width: 66px;
  height: 66px;
  background: linear-gradient(180deg, #5187d5 0%, #356cbc 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  color: #ffffff;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .audio___1dA53 {
  width: 715px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .audio___1dA53 .audioBtn___3cmCl {
  width: 44px;
  height: 44px;
  border: 3px solid #042044;
  border-radius: 50%;
  font-size: 30px;
  color: #042044;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 23px;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .audio___1dA53 .audioBtnPadding___2Tv96 {
  padding-left: 4px;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .audio___1dA53 .progressPercent___1ku-5 {
  width: 648px;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .audioTime___2K-vB {
  width: 112px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 22px;
}
.generalSpeechEvaluationItem___2O7ku .recordingPlay___35avT .button___wZkdS {
  width: 122px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  border: 0px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.generalSpeechEvaluationSpecial___3x8uc {
  padding-top: 10px;
}
