.share-wrapper___22HWd {
  background-color: #f9f9f9;
  color: #1a1a1a;
  min-height: 100vh;
}
.share-wrapper___22HWd .share-content___3OspC {
  width: 770px;
  margin: 0 auto;
  overflow: hidden;
}
.share-wrapper___22HWd .share-header___1_Ti9 {
  margin-top: 38px;
  border-bottom: 1px solid #a6a6a6;
  padding-bottom: 10px;
  width: 743px;
}
.share-wrapper___22HWd .share-header___1_Ti9 h4 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.share-wrapper___22HWd .share-header___1_Ti9 span {
  font-size: 16px;
  color: #999;
}
.share-wrapper___22HWd .share-list___6XChE {
  height: calc(100vh - 305px);
  overflow: auto;
}
.share-wrapper___22HWd .share-list___6XChE .share-tip___1TxIP {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  line-height: calc(100vh - 305px);
}
.share-wrapper___22HWd .share-list___6XChE::-webkit-scrollbar {
  width: 4px;
}
.share-wrapper___22HWd .share-list___6XChE::-webkit-scrollbar-thumb {
  background-color: #838395;
  border-radius: 5px;
}
.share-wrapper___22HWd .share-list___6XChE [class*='ask-list-box'] {
  height: auto !important;
  padding-top: 37px;
  padding-bottom: 18px;
}
.share-wrapper___22HWd .share-list___6XChE [class*='ask-ques'],
.share-wrapper___22HWd .share-list___6XChE [class*='ask-ans'] {
  padding: 0;
}
.share-wrapper___22HWd .share-list___6XChE [class*='ask-ans'] {
  padding-bottom: 10px;
}
.share-wrapper___22HWd .share-list___6XChE [class*='ask-wrap'],
.share-wrapper___22HWd .share-list___6XChE [class*='answer-wrap'] {
  width: calc(100% - 60px);
}
.share-wrapper___22HWd .share-list___6XChE [class*='answer-wrap'] {
  padding-bottom: 0;
}
.share-wrapper___22HWd .share-list___6XChE [class*='answer-wrap'] [class*='ask-inner'] {
  padding: 0;
}
.share-wrapper___22HWd .continue-dialog___30NLq {
  position: fixed;
  width: 300px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background-color: #0cd7a6;
  color: #fff;
  border-radius: 12px;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.share-wrapper___22HWd .continue-dialog___30NLq.disabled____8dsr {
  cursor: not-allowed;
  opacity: 0.4;
}
.share-wrapper___22HWd .mathtex-block {
  cursor: default;
}
.share-wrapper___22HWd .mathtex-block:hover {
  border-color: transparent;
}
