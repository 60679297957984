.ai_taste___3ZnbV {
  height: 300px;
  background-color: #f6f6f6;
  box-sizing: border-box;
}
.ai_taste___3ZnbV .ai_title___D8jFS {
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: #042044;
  padding-top: 60px;
  box-sizing: border-box;
}
.ai_taste___3ZnbV .ai_smalltitle___3MGRs {
  display: flex;
  justify-content: center;
  color: #374d6a;
  padding-top: 32px;
}
.ai_taste___3ZnbV .button___2v0uo {
  display: inline-block;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.ai_taste___3ZnbV .button___2v0uo .button1___sJXiB {
  display: block;
  width: 140px;
  height: 36px;
  border-radius: 2px;
  margin-top: 60px;
  font-size: 14px;
  background-color: #5187d5;
  color: #fff;
  border: none;
}
.ai_taste___3ZnbV .button___2v0uo .button1___sJXiB:hover {
  background-color: #417acc;
  transition: 0.4s;
}
