.realTimeSpeechRecognition___2WbJf {
  width: 984px;
  text-align: center;
  margin: 0 auto;
}
.realTimeSpeechRecognition___2WbJf .scenesBox___rK8e6 .scenesContent___1AKPa {
  text-align: left;
  width: 100%;
  height: 145px;
  background: #fcfcfc;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  padding: 14px 18px;
  margin-bottom: 40px;
}
.realTimeSpeechRecognition___2WbJf .tip___3tr04 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
  margin-bottom: 37px;
}
.realTimeSpeechRecognition___2WbJf .tip___3tr04 span {
  color: #5187d5;
}
.realTimeSpeechRecognition___2WbJf .button___1p7Dl {
  width: 140px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border: 0px;
}
.realTimeSpeechRecognition___2WbJf .hidden___1blAq {
  display: none;
}
.realTimeSpeechRecognition___2WbJf .recording___2rw7L {
  height: 80px;
}
