.option-active___2oMe0 .ant-select-dropdown-menu-item-active {
  background-color: rgba(104, 234, 203, 0.2) !important;
}
.drawer-add-model___1FR26 .ant-drawer-body {
  padding-left: 34px !important;
  padding-right: 5px !important;
}
.drawer-add-model___1FR26 .ant-form-explain {
  position: absolute;
  top: 39px;
  left: 0;
}
.drawer-add-model___1FR26 .ant-upload.ant-upload-drag {
  display: block;
  width: 128px;
  height: 90px;
  padding: 0;
}
.drawer-add-model___1FR26 .ant-select-selection:focus {
  border-color: #53E8C4;
  outline: 0;
  box-shadow: none;
}
.drawer-add-model___1FR26 .ant-select-selection:hover {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-radio-checked .ant-radio-inner {
  border-color: #000000;
}
.drawer-add-model___1FR26 .ant-radio-checked .ant-radio-inner::after {
  background-color: #000000;
}
.drawer-add-model___1FR26 .ant-radio:hover .ant-radio-inner {
  border-color: #000000;
}
.drawer-add-model___1FR26 .ant-input:hover {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-input:focus {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-input-affix-wrapper:focus .ant-input:not(.ant-input-disabled) {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-input-number:hover {
  border-color: #53E8C4;
}
.drawer-add-model___1FR26 .ant-input-number:focus {
  border-color: #53E8C4;
}
.add-model-box___31_Hg {
  width: 578px;
  height: calc(100vh - 138px);
  overflow-y: auto;
  /* 滚动条样式 */
}
.add-model-box___31_Hg::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: #f1f1f1;
}
.add-model-box___31_Hg::-webkit-scrollbar {
  width: 5px;
}
.add-model-box___31_Hg::-webkit-scrollbar-corner {
  background-color: #888;
}
.add-model-box___31_Hg::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
.add-model-box___31_Hg .new-form-item___33zrZ {
  margin-bottom: 16px !important;
}
.add-model-box___31_Hg .new-form-item___33zrZ .ant-form-item-label {
  text-align: left;
}
.add-model-box___31_Hg .new-form-item-1___32fyD .ant-form-item-children {
  display: block;
}
.add-model-box___31_Hg .new-form-item-1___32fyD .ant-form-item-children > span {
  display: block;
  width: 128px;
  height: 90px;
  padding: 0;
  float: left;
}
.add-model-box___31_Hg .deploy-input___2eGeq {
  width: 70px;
  margin-right: 12px;
}
.add-model-box___31_Hg .example-box___2WvPV {
  display: block;
  width: 560px;
  background-color: #f9f9fa;
  min-height: 153px;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 2px;
}
.add-model-box___31_Hg .example-box___2WvPV .tlt___GE91V {
  display: block;
  width: 62px;
  height: 37px;
  background-color: rgba(83, 232, 196, 0.1);
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: 37px;
  text-align: center;
  margin-bottom: 10px;
}
.add-model-box___31_Hg .model-image-box___3DvPw {
  width: 128px;
  height: 90px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-right: 14px;
  float: left;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.add-model-box___31_Hg .model-image-box___3DvPw:last-child {
  margin-right: 0;
}
.add-model-box___31_Hg .model-image-box___3DvPw img {
  height: 100%;
  margin: 0 auto;
}
.add-model-box___31_Hg .model-image-box___3DvPw .comp-close___Mcydx {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 6px;
  right: 6px;
  cursor: pointer;
}
.add-model-box___31_Hg .model-tips-box___C1zjN {
  height: 10px;
  position: relative;
}
.add-model-box___31_Hg .model-tips___27fTh {
  position: absolute;
  top: -18px;
}
.add-model-box___31_Hg .model-tips___27fTh .icon-tips___2bJeV {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  margin-right: 2px;
  float: left;
}
.add-model-box___31_Hg .model-tips___27fTh .text___28ADx {
  color: rgba(26, 26, 26, 0.5);
  font-size: 12px;
  float: left;
  line-height: 14px;
}
.closed-icon___3NEgM {
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 33px;
}
.button-box___1Z1rg {
  height: 35px;
  text-align: right;
  padding: 13px 22px 0 0;
}
.custom-input-error___3LRVq {
  color: red;
  position: absolute;
  top: 18px;
  left: 0;
}
.custom-input-error-1___2zM3w {
  width: 400px;
  color: red;
  position: absolute;
  top: 80px;
  left: 0;
}
