.applicationScenarios___3Ojai {
  width: 100%;
  background-color: #f4f4f4;
}
.applicationScenarios___3Ojai .top___20vyP {
  width: 100%;
  height: 68px;
  padding-top: 35px;
  font-size: 24px;
  font-weight: 500;
  color: #042044;
  line-height: 33px;
  margin-bottom: 21px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.applicationScenarios___3Ojai .bottom___3EF-v {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}
.applicationScenarios___3Ojai .bottom___3EF-v .bottomCon___1Zsgd {
  width: 1200px;
  padding: 0px 108px;
}
.scenesBox___CByl2 .scenesTypesBox___1iYSc {
  display: flex;
  width: 100%;
  color: #042044;
  font-size: 18px;
  margin-bottom: 26px;
}
.scenesBox___CByl2 .scenesTypesBox___1iYSc .scenesType___35rGx {
  min-width: 50px;
  cursor: pointer;
  margin-right: 45px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400 !important;
}
.scenesBox___CByl2 .scenesTypesBox___1iYSc .scenesType___35rGx.active___2lYE_,
.scenesBox___CByl2 .scenesTypesBox___1iYSc .scenesType___35rGx:hover {
  color: #5187d5;
  font-family: PingFangSC-Semibold, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 600 !important;
}
.scenesBox___CByl2 .scenesTypesBox___1iYSc .scenesType___35rGx.active___2lYE_ .bottomLine___wfrxY,
.scenesBox___CByl2 .scenesTypesBox___1iYSc .scenesType___35rGx:hover .bottomLine___wfrxY {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px auto 0;
}
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf {
  display: flex;
  color: #042044;
  font-size: 18px;
  margin-bottom: 26px;
}
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf .scenesType___35rGx {
  min-width: 50px;
  cursor: pointer;
  margin-right: 35px;
}
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf .scenesType___35rGx.active___2lYE_,
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf .scenesType___35rGx:hover {
  color: #5187d5;
  font-weight: 400;
}
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf .scenesType___35rGx.active___2lYE_ .bottomLine___wfrxY,
.scenesBox___CByl2 .scenesTypesBoxLarge___2TWBf .scenesType___35rGx:hover .bottomLine___wfrxY {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px auto 0;
}
.scenesBox___CByl2 .scenesContent___Eb5ek {
  max-height: 310px !important;
  padding: 20px;
  background-color: #ffffff;
}
.scenesBox___CByl2 .scenesContent___Eb5ek .videoBox___LP8RH {
  max-width: 100%;
  display: flex;
  max-height: 270px !important;
  outline-style: none;
}
.scenesBox___CByl2 .scenesContent___Eb5ek .scenesDesc___5tZUM {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  padding: 60px;
  line-height: 26px;
  color: #042044;
  background: #ffffff !important;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
