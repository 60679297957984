html {
  min-width: unset !important;
}
.exerciseContainer___2wGiP {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 586px) and (max-width: 820px) {
  .exerciseContainer___2wGiP .browserOpen___2c0vK {
    width: 50% !important;
    left: 88% !important;
  }
}
.exerciseContainer___2wGiP .model___3RZ1q {
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background: #000;
  display: none;
  z-index: 998;
}
.exerciseContainer___2wGiP .model___3RZ1q .browserOpen___2c0vK {
  position: relative;
  top: 2%;
  left: 80%;
  width: 100%;
}
.exerciseContainer___2wGiP .model___3RZ1q .browserOpen___2c0vK .modelImg___2IdPh {
  width: 16%;
}
.exerciseContainer___2wGiP .model___3RZ1q .browserOpen___2c0vK .modelContent___1XQtC {
  margin-left: -40%;
  display: flex;
  align-items: center;
}
.exerciseContainer___2wGiP .model___3RZ1q .browserOpen___2c0vK .modelContent___1XQtC .earthImg___3uQma {
  transform: translateY(-1px);
  width: 16px;
}
.exerciseContainer___2wGiP .model___3RZ1q .browserOpen___2c0vK .modelContent___1XQtC .font___JiK6c {
  white-space: nowrap;
  margin-left: 1%;
  color: #fff;
  font-size: 14px;
}
.exerciseContainer___2wGiP .content___HctLe {
  width: 100%;
  margin-top: -30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.exerciseContainer___2wGiP .content_font___3vsX- {
  margin-top: 5%;
  font-size: 18px;
  font-weight: bold;
}
.exerciseContainer___2wGiP .content_volume___1xEXo {
  margin-top: 2%;
  font-size: 14px;
}
.exerciseContainer___2wGiP .content_button___2s6Zk {
  border: none;
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  margin-top: 120px;
  width: 50%;
}
.exerciseContainer___2wGiP .content___HctLe .iconImg___2qG8L {
  width: 24%;
}
