.PhotoSearchResults___Q1lqC {
  width: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PhotoSearchResults___Q1lqC .dashboard-container___3H9p1 {
  margin: 20px;
}
.PhotoSearchResults___Q1lqC .dashboard-text___1FwSI {
  font-size: 30px;
  line-height: 46px;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk {
  padding: 24px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
  text-align: center;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk .title_p___Oh38D {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk .upload_dragger___RBIGj {
  margin-bottom: 20px;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk .upload_dragger___RBIGj div {
  border: none;
  cursor: auto;
  background: padding-box;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk .upload_dragger___RBIGj .search_input___23X7e {
  text-align: center;
  height: 60px;
  width: 600px;
  border-radius: 20px;
}
.PhotoSearchResults___Q1lqC .tableBox___36bmk .search_btn___XZvbn {
  width: 240px;
  height: 35px;
  border-radius: 10px;
}
