.dialog_assistant___3oRn8 {
  font-size: 15px;
}
.dialog_assistant___3oRn8 .ant-card {
  height: 382px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid rgba(83, 232, 196, 0.4);
  background-image: radial-gradient(circle at top left, #e8fffa 0%, #ffffff 36%);
}
.dialog_assistant___3oRn8 .ant-card:hover {
  border: 2px solid #53e8c4;
  color: #53e8c4;
}
.dialog_assistant___3oRn8 .ant-divider {
  margin: 12px 0;
}
.dialog_assistant___3oRn8 .ant-typography-expand {
  display: none;
}
.dialog_assistant___3oRn8 .ant-card {
  cursor: pointer;
}
.dialog_assistant___3oRn8 .ant-typography {
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #1a1a1a;
}
.main-title___3Cvnq {
  color: #1a1a1a;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.example-list-item___BzCdR {
  color: #1a1a1a;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 600;
}
.assistantImg___1uBHG {
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
  background: #f9f9f9;
  overflow: hidden;
  display: flex;
}
.assistantImg___1uBHG img {
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
