.container___3TPsX {
  max-width: 1920px;
  min-width: 1400px;
  height: 75px;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 8;
  align-items: center;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 {
  width: 132px;
  top: 75px !important;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .ant-dropdown-menu {
  background: #1b1b1b;
  padding: 16px 0px;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .gptNavList___Z4D6U {
  width: 132px;
  padding: 8px 24px 24px 24px;
  background: #1b1b1b;
  display: flex;
  flex-direction: column;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .gptNavList___Z4D6U .gptNavItem___3vS68 {
  margin-top: 16px;
  text-align: center;
  width: 100%;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .gptNavList___Z4D6U .gptNavItem___3vS68 a {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .gptNavList___Z4D6U .gptNavItem___3vS68:hover a {
  font-weight: 500;
  color: #fff;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8.platformHonorOverlay___2-8-0 {
  width: 164px;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG {
  width: 164px;
  padding: 8px 24px 24px 24px;
  background: #1b1b1b;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG .platformHonorItem___1S51d {
  margin-top: 16px;
  text-align: center;
  width: 50%;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG .platformHonorItem___1S51d a {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG .platformHonorItem___1S51d:nth-child(2) {
  text-align: right;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG .platformHonorItem___1S51d:nth-child(4) {
  text-align: right;
}
.container___3TPsX .customNavDropdownOverlay___3f3i8 .platformHonorList___3oJEG .platformHonorItem___1S51d:hover a {
  font-weight: 500;
  color: #fff;
}
.container___3TPsX .leftPart___3fnxb {
  width: 800px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
}
.container___3TPsX .leftPart___3fnxb .logoBox___3GJtO {
  height: 100%;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 120px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.container___3TPsX .leftPart___3fnxb .logoBox___3GJtO .logo___38RI0 {
  height: 38px;
  display: block;
  position: relative;
  top: -2px;
}
.container___3TPsX .leftPart___3fnxb .menu___3bi_y {
  width: 700px;
  margin-left: 0px;
  flex-shrink: 0;
  padding: 0 10px;
  display: flex;
  height: 75px;
  box-sizing: border-box;
  margin-left: 70px;
  line-height: 75px;
}
.container___3TPsX .leftPart___3fnxb .menu___3bi_y .menuItem___1FpSA {
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  margin-right: 40px;
  transition: border-color 0.2s, color 0.2s;
  font-family: Inter;
}
.container___3TPsX .leftPart___3fnxb .menu___3bi_y .menuItem___1FpSA.active___1S5-D {
  color: #679df3;
}
.container___3TPsX .userInfoBox___Bp4_U {
  width: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 120px;
}
.container___3TPsX .userInfoBox___Bp4_U .loginBox___2baHy {
  display: flex;
}
.container___3TPsX .userInfoBox___Bp4_U .consoleBtn___Dpggr,
.container___3TPsX .userInfoBox___Bp4_U .dowlandBtn___1RORA {
  flex-shrink: 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
  font-size: 14px;
  transition: border-color 0.2s, color 0.2s, background-color 0.2s;
  cursor: pointer;
  margin-right: 32px;
}
.container___3TPsX .userInfoBox___Bp4_U .dowlandBtn___1RORA {
  color: white;
  line-height: 28px;
  margin-right: 32px;
}
.container___3TPsX .userInfoBox___Bp4_U .dowlandBtn___1RORA:hover {
  color: #5187d5;
}
.container___3TPsX .userInfoBox___Bp4_U .consoleBtn___Dpggr {
  color: white;
  line-height: 28px;
}
.container___3TPsX .userInfoBox___Bp4_U .consoleBtn___Dpggr:hover {
  color: #5187d5;
}
.container___3TPsX .userInfoBox___Bp4_U .loginLineRegister___2SABp {
  color: #fff;
}
.container___3TPsX .userInfoBox___Bp4_U .loginBtn___37_vD {
  color: white;
  font-size: 14px;
}
.container___3TPsX .userInfoBox___Bp4_U .registerBtn___LTJUH {
  color: white;
  font-size: 14px;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI {
  color: white;
  position: relative;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI .username___2xqpb {
  cursor: pointer;
  height: 75px;
  line-height: 75px;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI .username___2xqpb .downIcon___1U4F6 {
  position: relative;
  top: -2px;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI .funcBox___jYRCV {
  display: none;
  position: absolute;
  right: 0;
  background: #262626;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100px;
  text-align: center;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI .funcBox___jYRCV .func___1ThWw {
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  transition: background 0.2s;
  font-size: 14px;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI .funcBox___jYRCV .func___1ThWw:hover {
  background: #2e2d2d;
}
.container___3TPsX .userInfoBox___Bp4_U .userInfo___1N5FI:hover .funcBox___jYRCV {
  display: block;
}
.container___3TPsX .subMenuBox___2QPi3.active___1S5-D {
  opacity: 1;
}
.container___3TPsX .subMenuBox___2QPi3 {
  position: fixed;
  width: 100%;
  max-width: 1920px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 75px;
  background: #262626;
  opacity: 0;
  display: flex;
  flex-direction: column;
  max-height: 484px;
  overflow-y: scroll;
  min-width: 1406px;
  /* 滚动条样式 */
}
.container___3TPsX .subMenuBox___2QPi3::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
.container___3TPsX .subMenuBox___2QPi3::-webkit-scrollbar {
  width: 5px;
}
.container___3TPsX .subMenuBox___2QPi3::-webkit-scrollbar-corner {
  background-color: transparent;
}
.container___3TPsX .subMenuBox___2QPi3::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
@media only screen and (min-width: 1680px) {
  .container___3TPsX .subMenuBox___2QPi3 .aceViewCenter___5O-Dk {
    width: initial;
  }
}
@media only screen and (max-width: 1679px) and (min-width: 1440px) {
  .container___3TPsX .subMenuBox___2QPi3 .aceViewCenter___5O-Dk {
    width: 1280px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1280px) {
  .container___3TPsX .subMenuBox___2QPi3 .aceViewCenter___5O-Dk {
    width: 1200px;
  }
}
@media only screen and (max-width: 1279px) and (min-width: 768px) {
  .container___3TPsX .subMenuBox___2QPi3 .aceViewCenter___5O-Dk {
    width: 1200px;
  }
}
@media only screen and (min-width: 1680px) {
  .container___3TPsX .subMenuBox___2QPi3 .blockLeft___3VxGX {
    width: 136px;
  }
}
@media only screen and (max-width: 1679px) and (min-width: 1440px) {
  .container___3TPsX .subMenuBox___2QPi3 .blockLeft___3VxGX {
    width: 136px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1280px) {
  .container___3TPsX .subMenuBox___2QPi3 .blockLeft___3VxGX {
    width: 136px;
  }
}
@media only screen and (max-width: 1279px) and (min-width: 768px) {
  .container___3TPsX .subMenuBox___2QPi3 .blockLeft___3VxGX {
    width: 136px;
  }
}
@media only screen and (max-width: 768px) {
  .container___3TPsX .subMenuBox___2QPi3 .blockLeft___3VxGX {
    width: 200px;
  }
}
.container___3TPsX .subMenuBox___2QPi3 .aceViewCenter___5O-Dk {
  margin: 0 auto 0 120px;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F {
  font-size: 14px;
  line-height: 20px;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .secondMenuTitleBox___2wkPv {
  display: block;
  width: 260px;
  color: #fff;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 16px 0px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .secondMenuTitleBox___2wkPv .secondMenuTitleIcon___6pM9_ {
  font-size: 14px;
  margin-right: 8px;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg {
  margin-top: 16px;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx {
  margin-bottom: 16px;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx .thirdLabel___2PgH_ {
  font-size: 13px;
  line-height: 18px;
  color: hsla(0, 0%, 100%, 0.8);
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx .thirdLabel___2PgH_ .icon___2kGfX {
  display: inline-block;
  margin-left: 3px;
  transform: translateY(-1px);
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx .labelHover___5NVE6 {
  color: rgba(255, 255, 255, 0.65);
  font-weight: 400;
  transition: color 0.2s;
}
.container___3TPsX .subMenuBox___2QPi3 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx .labelHover___5NVE6:hover {
  cursor: pointer;
  font-weight: 500;
  color: #fff;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 {
  min-width: 1280px;
  background-color: #212121;
  padding: 10px 0;
  padding-left: 1%;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 .abilitySearch___2pgxI .ant-select-selection {
  border: 1px solid transparent;
  background-color: #333333;
  border-radius: unset;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 .abilitySearch___2pgxI .ant-select-arrow .ant-select-arrow-icon {
  color: #bfbfbf;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 .abilitySearch___2pgxI .ant-select-selection:hover {
  border-color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 .abilitySearch___2pgxI .ant-select-selection:hover .ant-select-arrow-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #5385d3 !important;
}
.container___3TPsX .subMenuBox___2QPi3 .searchBox___1IgN7 .abilitySearch___2pgxI input {
  color: #dfdfdf;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 {
  height: 264px;
  position: relative;
  overflow: hidden;
  min-width: 1280px;
  display: flex;
  padding: 20px 0px 35px 0px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA {
  color: #fff;
  max-height: 420px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .active___1S5-D .secondTitle___29XiO {
  color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .active___1S5-D .secondTitle___29XiO::after {
  transform: scaleX(1) !important;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .titleContent___2itpg {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .titleContent___2itpg .secondTitle___29XiO {
  display: inline-block;
  width: auto;
  position: relative;
  transition: color 0.2s;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .titleContent___2itpg .secondTitle___29XiO:hover {
  color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .titleContent___2itpg .secondTitle___29XiO::after {
  content: '';
  width: 100%;
  bottom: -2px;
  display: block;
  position: absolute;
  border-bottom: 2px solid #5385d3;
  padding-bottom: 6px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.15s ease-in-out;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityTitle___2EUPA .titleContent___2itpg .secondTitle___29XiO:hover::after {
  transform: scaleX(1);
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ {
  overflow: auto;
  flex: 1;
  padding-left: 60px;
  border-left: 1px solid #ffffff33;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .menuContainer___aVzNt {
  display: flex;
  flex-direction: row;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ::-webkit-scrollbar {
  height: 5px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 7px solid transparent;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH {
  width: 260px;
  margin-right: 60px;
  box-sizing: border-box;
  break-inside: avoid;
  vertical-align: top;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH .hasScondMenuTitleCommon___1g15F .titleContent___2itpg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx {
  width: 50%;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH .hasScondMenuTitleCommon___1g15F.noSubTitleBox___2LBcS {
  width: 600px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH .hasScondMenuTitleCommon___1g15F.noSubTitleBox___2LBcS .titleContent___2itpg .titleItem___14Vgx {
  width: initial;
  margin-right: 60px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH.aiSecondMenuItemArea___3cndu {
  width: 400px;
  margin-right: 162px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH.aiSecondMenuItemArea___3cndu .secondMenuTitleBox___2wkPv {
  width: 400px;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH.aiCourse___1mjF8 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container___3TPsX .subMenuBox___2QPi3 .technologyCapabilitySubMenu___3kKT6 .technologyCapabilityContent___3KYvQ .secondMenuItemArea___rsyNH.aiCourse___1mjF8 .hasScondMenuTitleCommon___1g15F .titleContent___2itpg .titleItem___14Vgx {
  width: 33%;
}
.container___3TPsX .subMenuBox___2QPi3 .solutionsSubMenu___1orR5 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -o-column-count: 4;
  -ms-column-count: 4;
  column-count: 4;
  flex-flow: column wrap;
  min-width: 1280px;
  padding: 24px 0px;
}
.container___3TPsX .subMenuBox___2QPi3 .solutionsSubMenu___1orR5 .caseContent___2P04s {
  margin-bottom: 40px;
  width: 100%;
  break-inside: avoid;
}
.container___3TPsX .subMenuBox___2QPi3 .noSecondTitleCommonMenu___I-KYG {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0 40px;
}
.container___3TPsX .subMenuBox___2QPi3 .noSecondTitleCommonMenu___I-KYG .subMenuType___XMm4p {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 30px;
}
.container___3TPsX .subMenuBox___2QPi3 .noSecondTitleCommonMenu___I-KYG .subMenuType___XMm4p .title___extwe {
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  display: inline-block;
  transition: color 0.2s;
}
.container___3TPsX .subMenuBox___2QPi3 .noSecondTitleCommonMenu___I-KYG .subMenuType___XMm4p .title___extwe:hover {
  color: #5187d5;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 {
  min-width: 1280px;
  display: flex;
  padding: 40px 0 35px;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo {
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  max-height: 420px;
  overflow-y: auto;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .active___1S5-D .secondTitle___29XiO {
  color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .active___1S5-D .secondTitle___29XiO::after {
  transform: scaleX(1) !important;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg {
  padding-left: 40px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg .titleIcon___S8FdL {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  transform: translateY(-1px);
  margin-left: 6px;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg .secondTitle___29XiO {
  display: inline-block;
  width: auto;
  position: relative;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg .secondTitle___29XiO:hover {
  color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg .secondTitle___29XiO::after {
  content: '';
  width: 100%;
  bottom: -2px;
  display: block;
  position: absolute;
  border-bottom: 2px solid #5385d3;
  padding-bottom: 6px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.15s ease-in-out;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiTitle___1G1lo .titleContent___2itpg .secondTitle___29XiO:hover::after {
  transform: scaleX(1);
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiContent___25KmT {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-left: 48px;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiContent___25KmT .secondMenuItemArea___rsyNH {
  width: 25%;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiContent___25KmT .secondMenuTitle___3Lf7a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiContent___25KmT .secondMenuTitle___3Lf7a .thirdTitle___8TKR5 {
  display: flex;
  align-items: center;
}
.container___3TPsX .subMenuBox___2QPi3 .aiSubMenu___2IUZ2 .aiContent___25KmT .secondMenuTitle___3Lf7a .thirdLabel___2PgH_:hover {
  cursor: pointer;
  color: #5385d3;
}
.container___3TPsX .subMenuBox___2QPi3 .serviceSubMenu___3pXnB {
  min-width: 1280px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -o-column-count: 4;
  -ms-column-count: 4;
  column-count: 4;
  flex-flow: column wrap;
  padding: 24px 0 24px;
}
.container___3TPsX .subMenuBox___2QPi3 .serviceSubMenu___3pXnB .caseContent___2P04s {
  width: 100%;
  break-inside: avoid;
}
.container___3TPsX.active___1S5-D {
  background: #000000;
  user-select: none;
}
.container___3TPsX.active___1S5-D .userInfoBox___Bp4_U .loginBtn___37_vD {
  background: #000000;
  color: white;
}
.container___3TPsX.active___1S5-D .userInfoBox___Bp4_U .loginBtn___37_vD:hover {
  color: #679df3;
}
.container___3TPsX.active___1S5-D .userInfoBox___Bp4_U .registerBtn___LTJUH {
  color: #fff;
}
.container___3TPsX.active___1S5-D .userInfoBox___Bp4_U .registerBtn___LTJUH:hover {
  color: #679df3;
}
.container___3TPsX {
  /* 选择器下拉菜单字体颜色 */
  /* 选择器下拉菜单选中的数据，与鼠标移入样式 */
}
.container___3TPsX .ant-select-selected-icon {
  display: none !important;
}
.container___3TPsX .ant-select-selection--multiple .ant-select-selection__choice {
  display: none !important;
}
.container___3TPsX .ant-select-open .ant-select-arrow-icon svg {
  transform: unset !important;
}
.container___3TPsX .ant-select-dropdown {
  overflow-y: auto !important;
  max-height: 300px !important;
  background-color: #333333 !important;
  border-radius: 0 !important;
}
@media only screen and (min-width: 1680px) {
  .container___3TPsX .ant-select-dropdown {
    width: 350px;
  }
}
@media only screen and (max-width: 1679px) and (min-width: 1440px) {
  .container___3TPsX .ant-select-dropdown {
    width: 300px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1280px) {
  .container___3TPsX .ant-select-dropdown {
    width: 300px;
  }
}
@media only screen and (max-width: 1279px) and (min-width: 768px) {
  .container___3TPsX .ant-select-dropdown {
    width: 300px;
  }
}
.container___3TPsX .ant-select-dropdown-menu::-webkit-scrollbar {
  height: 5px !important;
}
.container___3TPsX .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background: #dfdfdf !important;
  border-radius: 10px !important;
  background-clip: content-box !important;
  border: 7px solid transparent !important;
}
.container___3TPsX .ant-select-open .ant-select-selection {
  border-bottom: 1px solid #5385d3 !important;
  box-shadow: unset !important;
}
.container___3TPsX .ant-select-open .ant-select-selection .ant-select-arrow-icon {
  color: #5385d3 !important;
}
.container___3TPsX .ant-select-dropdown-menu,
.container___3TPsX .ant-select-dropdown-menu-root,
.container___3TPsX .ant-select-dropdown-menu-vertical li {
  background-color: transparent;
  color: #fff;
}
.container___3TPsX .ant-select-dropdown-menu-item-selected,
.container___3TPsX .ant-select-dropdown-menu-item-active {
  transition: color 0.2s !important;
  background-color: #292929 !important;
  color: #5385d3 !important;
}
