.challeng_box___3L07J {
  margin-bottom: 40px;
}
.challeng_box___3L07J li {
  line-height: 35px;
}
.challeng_box___3L07J .cycle_item___8cNCl {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.challeng_box___3L07J .body_text___w63G5 {
  line-height: 35px;
}
.challeng_box___3L07J .home_img_1___1OfbO {
  width: 700px;
  display: block;
  margin: 20px auto;
}
