.knowledge-list-item-popver-area___3vUQS {
  padding: 10px;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG {
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG .word___3IiDZ {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  cursor: pointer;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.green___mgnr_ {
  color: #0cd7a6;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.green___mgnr_ .word___3IiDZ {
  background-color: #0cd7a6;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.green___mgnr_:hover {
  color: #00ba8d;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.blue____Sksa {
  color: #1890ff;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.blue____Sksa .word___3IiDZ {
  background-color: #1890ff;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.blue____Sksa:hover {
  color: #0676dd;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.orange___2zXmV {
  color: #ff7f09;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.orange___2zXmV .word___3IiDZ {
  background-color: #ff7f09;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-title-area___2W2SG.orange___2zXmV .word___3IiDZ:hover {
  color: #de6b00;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek {
  max-width: 200px;
  width: 200px;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek .show-area___1EwSz {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px 0px 10px;
  margin-bottom: 12px;
  font-size: 12px;
  cursor: pointer;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek .show-area___1EwSz .first-word___12o57 {
  display: inline-block;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 20px;
  text-align: center;
  margin-right: 5px;
  line-height: 22px;
  color: #fff;
  cursor: pointer;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-green___3VwTz .show-area___1EwSz .first-word___12o57 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAzCAYAAAAtg6DjAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJkSURBVHgB7ZpLctNAEIb/HolUzKPQEcyGlFnhGygngGygzAZyguQGTm4AJ8AsIOxIbiBuEDaYHTqCKEiVy1hqpmWckOhls0g0Xf4WmrFm7PLvf15WN7BmjTNQXWPw/VMwm0z2bDW0HbsM7uK6IZwCJiZOT7zN28fJg50EK1IqMhgfdWfAW4BDtAj7ZWMGjX71Bocrvu8yd7593KMsPQBRgJYiYj3QdtIbxEv2v+Du+Gho3TuACxASNv722cNnp81d/5I7yNlruIQV6jP1mxw1cpE5mA9R12AE87WjnlxkCgzbPAfr4fDe+P2ruh5GXLRbQ22n9kPDulbzm/kpHIftHm4XzbCq3RDRE+ig0k0Dzh5DBRxWuWncXXBKKXXTQBXlbioTmVNwU6HIopsanRQuualUJIdyyFm80uokZsz7i7pakVbZS3myMa9qxf5DSafTUKp6RVo4TUMpVYskj+5LqdvJDF0pVYu08/KHFLpFgmO5Kp+TXiSlapHexkYkpVqRZGi0CCmoFellOA8l+FBI7uLWxQNnlU7+66KgTyTTydWwgT6RhEI8R5lIimzsMrp610isD0ogY96U3Tc2chtDAWLWz63nx2Vtdrhmn6ECqgyxG38ydSvwWkLuYm8wqmo3SX83kQkLp6HaRIl8dbXHnl0wr5w60gaaXBRykbJ5csOv0UYWWSBN/c73ybNHL+zcZHeE2pGXZbyzTJpLMY/n64d9Arc6h2DVPJ7CiUcc9cn07Qe9Q9vI1w0+9DY7/WUFCvW5dTaeIDkFechdItI34C7NDytRxvzlVqcz+p/cujVrHOIPWLHHPoW1quUAAAAASUVORK5CYII=);
  background-size: contain;
  background-repeat: no-repeat;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-green___3VwTz .show-area___1EwSz:hover {
  color: #0cd7a6;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-blue___2aAjk .show-area___1EwSz .first-word___12o57 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAzCAYAAAAtg6DjAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJxSURBVHgB7ZpfbhJRFMa/c8EKgUR8o2+zhLIDWIF1BeoKlMQ28Ql8MrEadAXiDtodjDuoO5gXlTcxodIUmOM9d2xFGAZ4aeee8HuYO5l7J5kv3/1zcs4AO3Z4A2V1Bj2uXU4vnwPcBHEARoBbxn7gOZgiRnxWulc5jdo0xJakiqz3xgGm/MmJyxOECGz6g6Py6+1eW2D/3W/rXNxlphryiogtmNagXY42Gz5H/f1FBzG68AD74UPD3Pp2XD3fYGyCOMjMH+ARIpSLprHOUSMXtwbtFIVnMFBL9o5snEjM4k6u12Am3Ky/HT3NGmGci4zMQbnHUCe7ezo7hO/Y87t+Mm6u6jZMeAQV8Eo3DTEdQAV2ba5w07gdSg3pbhqoIt1NZSKFZTc1ilxyU6FI4X83tYpsJqFqglKRNnifzF5c36sVCaInQe+nOx7VipTz/2q215R7vU5aOE7SN7pFmsIDaVWLBNsMI5SLJOZf0up2EhzJVbeThkJpVYvcK1yF0ioOBtCP2g9dSUGvyIm5KSXoFGldHLz6l3DWKXLORUGdSGKczbsoqBPJZJbqOcpEUmhrl+HiU+NqfUqwSeSPqc+vQx/vIYq+v6ycpnUZG6l/gQbieGWJ3ZSKU68Kr6lYFwfH1f6qbuNCH+IQPpPhopDsrpPCMylNw0fWuCg4kcnhyVv9NpILrEBMqLVu2M05+eOoatdm7I1QIgwLcfx4MbpJHbv4YP9kZJOy1Ml1Se+vg5sIFJYiHnGUp6Zhp+9n5AxxT2ZbaVRubCrQvZfVWX8zDqg4O5SSO4EOmO/A3SQiC21S6uv9i0o/6pKfG+SOHZvxByFw2fVAR6jYAAAAAElFTkSuQmCC);
  background-size: contain;
  background-repeat: no-repeat;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-blue___2aAjk .show-area___1EwSz:hover {
  color: #1890ff;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-orange___2dz3c .show-area___1EwSz .first-word___12o57 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAzCAYAAAAtg6DjAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIzSURBVHgB7ZrbcdswEEXvIhM7j4+oBJYQdcBU4KSCJBU4qUBQKlAqiNKB3QFTQewOWAL94cfYHq53QVp+8CHKHzaxozMjgSTAGd65CxDDXWDLlmigvk72kwnKy30Qp3Ka1L9nho7kSXKQOwReH5AvCmxIq0j2bxIw/ZHDFOMil+da0q+z+SY3NUTy/O0+Sng5nGC8iLP8ifxFPmSwu3/C83czEbjAuAUqGmn/2b//OGTwysnawQXiohBHp+scDU6GOViFaGxM6rWjlypc2c0w/hDtIpWw/dY3wAUXwb2DRg+Xs75ucZI+I34SMSvt6nRg7MECTJ1u6pwctAxHQNrlpoqMdcFp0uGmgy1a3bQmstVNeyJb3LQosuGmTZGVm8ntiVWRQPnqx+2hXZFUfg1fNmBZZHj/X6Z6YFmkhCyl2tgW6fhDaGAZ5kQb2yIJJ9oYn5Mu18b6nMxCA9PsZPpveDMgX9rrlIJhJ8tVKsGmyODi3Qdno06WDxJC9kQSDh+nDQw6yY18jjWRmbiYPb6oInNYgeh322VLInPyZwdtHU7U/4MFyHWm2MXJndgSr22Ii6fLrk5Xb30yxEyPi0q1uhJ/h6am46TXRSWIDC9PwkZlIyMhVIGsG7R6T5I/X8gNMQktQNdfhpS5PNgMyA1eHP2J8YduXlV9XB0NGdzY8dSOTiWf8BfjowjRRrvToYVKypraOs0nhJqCPUm7a0b6JRK24hpl8jTHwO7yKbV1W7ZExA1f9KdLLBmOkAAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek.que-orange___2dz3c .show-area___1EwSz:hover {
  color: #ff7f09;
}
@media screen and (min-width: 2000px) {
  .knowledge-list-item-popver-area___3vUQS .knowledge-item-question-area___1Taek {
    width: 240px;
    max-width: 240px;
  }
}
