.upload-wrapper___1Hnsj {
  display: inline-block;
  position: relative;
}
.upload-wrapper___1Hnsj .ant-upload {
  border: none;
  background-color: #fff;
  padding: 0 !important;
}
.upload-wrapper___1Hnsj .icon-upload {
  font-size: 25px;
  pointer-events: none;
}
.upload-wrapper___1Hnsj .icon-upload-icon___CAUDS {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOeSURBVHgB7VpbbhpBEGwwtvy5R9icIPYJkpzA+AQByQ9Z/jA+QfAJIB+W5YcEPoFzA/AJ4AbZI/Bp+ZkqZ6wsw8DOLMOClCkJ4Z3Z1XbR09XdMxYJCAgICAgICAgICMiDkuREp9OJnp+fT97e3qr4xBiKpCCUSqUhPn28/+fR0VHi9KzkwNXVFYk2pUCS01Aul5v7+/tntvc7E768vGzhqyGrhfbh4eGpzY1lccD19fUPWT2yRAOOsLLL2sMXFxcxls9vbXiEz9nGxka3Xq+PpABQOx4eHqqwhT9+nLYFdnzKsqMilsALatpQ8vr6+s1VNOaFItSFA/qwaSD/dCR6fHykl5uznrde0lDFL9r1WdFk01DvHhMr3UYTrAlDlbfS1y8vL31ZMhhK6WvdRhNcRGssBS3Tux8wxGtmmrSO4SKB+Kytra19Z0HDAgOryVv4OKWlIsDUBzHqgOxXXMb4rlGcmCXEA1aKMFMOlL9pmIrg6bZ4wEoRRm08VXRA+LN4wKoRTqbNgXAiHrBShClMFKkp07fiAQtT6VRHNQKJ3YODg6HNc1DkOhS6xbZTDb2Xr3i+Kx7gnTCF5+npiQbX1FCEv3s3NzeNvb29TC+p9LN7fn6+BXWONjc3hz7rdK+EmTpA9s5Q8UTwXBcpJ7btXY+Pj61WhCu8xbDySG9WeceUo1rMpcELYcZrpVJh5xJrU4l+L0mjd73j0pclYG7C9Bi8OlEUgNgtivtttpDyV3jSqKKV81Y9uSB3DNNDMLoDQlV9DmOsfZvqsg9i21zuMr4CuKHQw1yhPXVuD9ND+NLJjkC2niL7DhJSnk60+0l6gJDIbOt8YZ4lHWvXCSoleqtrupmkucTx5y9timlrYLsnNS+8iBb3ienBrFTCfIrdxV0uecN0qwgFn5swxWl9fd0pDrnkTaSVgrdkgZiLsBKnWp5KSJGuy6SCNxDTg0WlLRfCY4ZhGU+IkysY74x70cSMxYtN2jLMZ/7wLruWQ82oWDyAcT9DwXuzSKPJ2JEZNprgsmt5rw2d+EonH2nLYPB72kJcT+R6lrKqG0sjszmxPnlQhQZPHsZiCwa18WIKl5eOBu+gaE0Q5KEZytdbNCfc7tnBD9TQbLE6GHA6TFO5cqEqmhfUFJue2UmlkUPbU3LoUkGbbDcIcp0Pc9/YcJi1DLCUPZ1W3ZmQ+z8ACEV8B8uJ+8dFtnsUt/siTy0DAgICAgICAgICAv4z/AG19NJeiWLAGQAAAABJRU5ErkJggg==) no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  transform: translateY(3px);
}
.upload-wrapper___1Hnsj .icon-upload-icon___CAUDS:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP1SURBVHgB7ZpNUhpREMe7B0yklCqOgKtIsoieIPEE4ipRF8ETqCcQT4A5gbjwI9mYG4gngE3EZKM3CFVghHJmOt2gyDCDMwOPgaq8X5VVzBfz/nS/f78PATQajUaj0Wg0Gs0wIAxJ6uY8ZTab24CUBZvSgJiCqECoABilONHXWmb9NtyjQzB3fbaNtpWPVORAMN/IrO8HvhtCMn99UgCCHZgm0DhoLH7eDXKrASGYr57uTZ1YgeyduauTQO0KHOFU9TRtAt04X0Q1AtyfSSSKtYW1GkSAeIfVbGb55XsEkO5eQKjFXycW/NoRh4CYYOd6fx/+dBtDYyWsaYzKo6AiB6Bkkl3u+ggBm+hfiXL+pedDpLTxwXmM+1GL7UXeLdnlPNvfRjfBBZO91HsYAyjBhJlptYqOE0hLfs8EF9xXgiYZ3W4blrec/ZXT2u+ZwH04SpLV4xyB8QU7plTibFLWfUKVpSiQ0sd985DD9ZGA0vyXM5HKUiVAAVMlOFU+5JSkvOuCODDBAShgqgSbs7MDTQeR3oMCpkowG8rtoGuc5reggKkS3DEmLHldYwM7AgWMTbDMqOavjv8kqyc3c7+/+9bHJzjKW6zuR/eEDF8JduuZ9SIoQHlZehzrFoh4KIrIqQgptM2L5K9vO/U3n3yj9Fh+1uZ+Hi+hYaTiiURF5ThdqeD2BKN1f84fnRFllyWyilxy0kHnrnfvNiswBpSltETEArpgcS+kL+XbU8wJokRwewXEwLJjugadGZX7bhZ9fXYuqQ8TYGTBEjEk2zUoEFeNzSaW+dOKGI/jItlZq3mvbPQUhqEFS4R4uefcc2QEtF/PbOTEbLjPluJoLPdHW7JBukDUoocWLBHiVmcdJzmS7Mtbjcxmvve0OG+MI+0lWsbJYcrWqAwt2Ku/cr1cqWc2i173t0U3W8uOGtv5ohRaZjnomtSoqHFpXieWCPqVEpm/NhY31iTlXV+BUIjCwUcWLObEi2eh1rY6KU8e9bjt4AUYIyMKfjYnCImIRsAtDwffYTMsj6tshVjTcjZMGttvTmGR8bH0e1e95sFLkLLlus5LteBDiDUto+Jsk50GBUi/H+TgfmXrAXHVcYLQdzgaIqXtS8ch4raqcvJUtjqbZM88la3k1Um2/5n25EL2t3qbFGAKGXzngZdfzNevblwbaLyvQ4ZxNGNZSmY0LLDgqu+dF+V5pnP0EItxGbNWpa/3tqW9McA/mp95htpMk1op5QOmEPGUIHPmUC5993bjwLucTBqpFsEWCIbaH05WT3OuzaxJIENZlNUQ79GdF0P/B4AgwglJnDKNFN3mOL+zQjZcRrlrqdFoNBqNRqPRaP4z/gHqzrUK9WITKwAAAABJRU5ErkJggg==) no-repeat;
  background-size: contain;
}
