.preview-model___rlhfR {
  height: 100%;
  overflow: hidden;
  background-color: #FFFFFF;
}
.preview-model___rlhfR .box___1aGdX {
  width: 854px;
  margin: 0 auto;
}
.preview-model___rlhfR .tlt___1c9Xz {
  width: 100%;
  text-align: center;
  font-size: 24px;
  height: 36px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 24px;
  color: black;
}
.preview-model___rlhfR .custom-card___1p2dc {
  height: 386px;
}
.preview-model___rlhfR .custom-card-role___GFZjM {
  display: inline-block;
  font-weight: 600;
  position: relative;
  width: auto;
  margin-bottom: 10px;
}
.preview-model___rlhfR .custom-card-role___GFZjM::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 10px;
  background-color: #53e8c527;
}
.preview-model___rlhfR .ant-card {
  border-radius: 8px;
  border: 2px solid rgba(83, 232, 196, 0.4);
  background-image: radial-gradient(circle at top left, #e8fffa 0%, #FFFFFF 36%);
}
.preview-model___rlhfR .ant-card:hover {
  border: 2px solid #53E8C4;
}
.assistantImg___3Vkou {
  width: 100%;
  height: 190px;
  text-align: center;
  background: #F9F9F9;
  overflow: hidden;
}
.assistantImg___3Vkou img {
  height: 100%;
}
.assistantImg___3Vkou.only-img___1xsS7 {
  height: 285px;
  line-height: 285px;
}
