.table_box___2sRcs {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 20px;
}
.table_box___2sRcs .content___3OUVB {
  line-height: 35px;
  text-indent: 32px;
  font-size: 18px;
}
.table_box___2sRcs .content___3OUVB b {
  color: #377ef7;
}
.table_box___2sRcs .contentEnd___2BFWg {
  text-align: center;
  width: 700px;
  font-size: 18px;
  margin: 0 auto;
  margin-top: 20px;
}
.table_box___2sRcs .contentEnd___2BFWg b {
  color: #377ef7;
}
.table_box___2sRcs .banner___1HenV {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.table_box___2sRcs .content_main___tzVhH {
  margin-top: 20px;
}
.table_box___2sRcs b {
  font-weight: bold;
}
.table_box___2sRcs h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.table_box___2sRcs .latexClassN___3pRWr .katex___3QEna .base___1szIN {
  font-size: 18px !important;
}
.table_box___2sRcs .gzh___3Rw7C {
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
}
.table_box___2sRcs .change_contents___3T36H {
  padding: 20px 80px;
}
.table_box___2sRcs .home_img_1___36jSm {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.table_box___2sRcs .home_img_2___2k3TB {
  width: 500px;
  height: 900px;
}
.table_box___2sRcs .home_img_3___RINmd {
  width: 500px;
  height: 900px;
}
.table_box___2sRcs .home_img_4___4q2GC {
  position: relative;
  width: 900px;
  margin-top: 20px;
}
.table_box___2sRcs .home_img_5___w8Sc9 {
  width: 450px;
  display: block;
  margin: 20px auto;
}
.table_box___2sRcs .change_contents___3T36H {
  padding: 20px 80px;
}
.table_box___2sRcs .change_contents___3T36H .home_img_1___36jSm {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.table_box___2sRcs .change_contents___3T36H .like_a___3T5TS {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.table_box___2sRcs .change_contents___3T36H a {
  color: #303de2;
}
.table_box___2sRcs .change_contents___3T36H .home_connect___ruWeI img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.table_box___2sRcs .change_contents___3T36H .home_connect___ruWeI img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.table_box___2sRcs .home_connect___ruWeI p {
  line-height: 35px;
}
.table_box___2sRcs .home_connect___ruWeI b {
  margin-right: 8px;
}
.table_box___2sRcs .home_connect___ruWeI .img_box___2AIk6 {
  margin: 0 auto;
  width: max-content;
}
.table_box___2sRcs .home_connect___ruWeI .img_box___2AIk6 img {
  margin: 40px;
}
