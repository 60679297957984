.virtual-assistant___1JGcL {
  width: 1348px;
  height: 860px;
  display: flex;
  margin: 0px auto;
  padding-top: 100px;
}
.virtual-assistant___1JGcL.is-in-iframe___2KItC {
  padding-top: 0;
}
.virtual-assistant___1JGcL .evaluation-audio___13KPh {
  width: 900px;
  background-color: #142532;
  color: #fff;
  border-radius: 10px 0px 0px 10px;
  position: relative;
}
.virtual-assistant___1JGcL .evaluation-vedio___27h3Z {
  width: 448px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 0px 10px 10px 0px;
  overflow: hidden;
}
