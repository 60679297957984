.state-check-list___38QvS {
  display: flex;
  width: 100%;
  background-color: #102331;
  padding: 20px 10px 20px 10px;
}
.state-check-list___38QvS .state-check-item___1AUnz {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.state-check-list___38QvS .state-check-item___1AUnz:nth-child(3n + 1)::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 4px;
  height: 100%;
  width: 1px;
  background-color: gray;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-title___1Gnvq {
  color: #fff;
  font-size: 14px;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-result___O3AJD {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  line-height: 20px;
  color: #fff;
  background-color: gray;
  border-radius: 10px;
  font-size: 14px;
  width: 80px;
  text-align: center;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-result___O3AJD.state-result-null___ZC6GE {
  background-color: gray;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-result___O3AJD.state-result-unknown___1qent {
  background-color: gray;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-result___O3AJD.state-result-success___WqpGD {
  background-color: #3a9f20;
}
.state-check-list___38QvS .state-check-item___1AUnz .state-result___O3AJD.state-result-warn___2shEz {
  background-color: #bd3124;
}
