@keyframes spin___nqWQG {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imgCorrect-area___396Bs {
  background-color: #f9f9f9;
  padding: 20px 20px 30px 20px;
  width: calc(100% - 104px);
  height: 100%;
  border-radius: 14px;
}
.general-comment-icon___2ht7a {
  width: 14px;
  height: 15px;
  margin-right: 9px;
}
.imgCorrect-box___18PRW {
  padding: 24px 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.imgCorrect-box___18PRW .wrongBox___V7QIG {
  opacity: 0.2;
  height: 80px;
  cursor: pointer;
}
.imgCorrect-box___18PRW .wrongBox___V7QIG.acitveExpand___37TuD {
  opacity: 1;
  height: auto;
}
.imgCorrect-box___18PRW .wrongBox___V7QIG .no-clickSentence___2OPSO {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}
.imgCorrect-box___18PRW .wrongBox___V7QIG .no-clickSentence___2OPSO.expandText___OEvr2 {
  height: auto;
  display: block;
  overflow: visible;
  -webkit-line-clamp: none;
  margin-bottom: 0;
}
.imgCorrect-box___18PRW .paragraphBox___mb5s5 {
  opacity: 0.2;
  height: 80px;
  cursor: pointer;
}
.imgCorrect-box___18PRW .paragraphBox___mb5s5 .no-clickSentence___2OPSO {
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.imgCorrect-box___18PRW .paragraphBox___mb5s5 .no-clickSentence___2OPSO.expandText___OEvr2 {
  height: auto;
  display: block;
  overflow: visible;
  -webkit-line-clamp: none;
  margin-bottom: 0;
}
.imgCorrect-box___18PRW .paragraphBox___mb5s5.acitveExpand___37TuD {
  height: auto;
  opacity: 1;
}
.imgCorrect-box___18PRW .sentenctBox___19IWi {
  height: 80px;
  opacity: 0.2;
  cursor: pointer;
}
.imgCorrect-box___18PRW .sentenctBox___19IWi .no-clickSentence___2OPSO {
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.imgCorrect-box___18PRW .sentenctBox___19IWi .no-clickSentence___2OPSO.expandText___OEvr2 {
  height: auto;
  display: block;
  overflow: visible;
  -webkit-line-clamp: none;
  margin-bottom: 0;
}
.imgCorrect-box___18PRW .sentenctBox___19IWi.acitveExpand___37TuD {
  height: auto;
  opacity: 1;
}
.imgCorrect-box___18PRW .sentenctBox___19IWi .sentenceBoxTitle___3-3Ws {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imgCorrect-box___18PRW .wordBox___3SI7X .roundImg___1E8Y8 {
  visibility: hidden;
}
.imgCorrect-box___18PRW .wordBox___3SI7X.acitveExpand___37TuD .roundImg___1E8Y8 {
  visibility: visible;
}
.imgCorrect-box___18PRW .pinyinBox___16D6j .pinyinValue___1HUjQ {
  position: absolute;
  color: #FF4545;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'PingFang SC';
  white-space: nowrap;
}
.imgCorrect-box___18PRW .sentenceLeftBox___-xin4.acitveExpand___37TuD {
  background-color: rgba(12, 215, 166, 0.3);
}
.imgCorrect-box___18PRW .paragraphLeftBox___NWUUZ.acitveExpand___37TuD {
  background-color: rgba(255, 137, 29, 0.3);
}
.imgCorrect-box___18PRW .boxIcon___3LBCd {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #fff;
}
.imgCorrect-box___18PRW .comImg___2swY3 {
  position: relative;
}
.imgCorrect-box___18PRW .comImg___2swY3 .img___16sBq {
  width: 400px;
}
.imgCorrect-box___18PRW .leftBox___3z_Y9 {
  position: relative;
  width: calc((100% - 400px) / 2);
  padding-right: 20px;
}
.imgCorrect-box___18PRW .rightBox___2H9-u {
  position: relative;
  padding-left: 20px;
  width: calc((100% - 400px) / 2);
}
.imgCorrect-box___18PRW .rightBox___2H9-u .clickSentence___2jcQ0 {
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
}
.general-comment___2HIMD {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: #1a1a1a;
  margin-bottom: 34px;
}
.usr-icon-gpt___1Q01A {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 18px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  background-color: #000000;
}
.usr-icon-gpt___1Q01A img {
  width: 100%;
  height: 100%;
}
.loadingBox___2JjmG {
  display: flex;
  align-items: center;
}
.loadingBox___2JjmG .upLoading___2HQTd {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  animation: spin___nqWQG 2s linear infinite;
}
.leftBox___3z_Y9 .paragraphBoxS___NyKoB {
  background: rgba(255, 137, 29, 0.1);
  border-radius: 11px;
  padding: 10px;
  text-align: left;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leftBox___3z_Y9 .paragraphBoxS___NyKoB .paragraphHeader___1mxyQ {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #ff891d;
}
.leftBox___3z_Y9 .paragraphBoxS___NyKoB .paragraphHeader___1mxyQ .paragraphTags___346lV {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJLSURBVHgB1ZnNURtBEIVfz+pgly8bAKbWESAiYMnAjgA7AvABV3FiObgo4YsdATgCQvA6AkEETFHcUQDMND2jlZDQDysJsTNflWal1armqevNXzdhRfgsa4NsDuYtebUBSuV2NnyA0JP2CixXRf/BqqQf+gorQFgCPs0ytOyeiDwQMSkWR0vPJUxyQkdaY0EWEu3FJuZchOZ4LQgXi4qvJZqLLMUHuw/LBdaFooIOb0/qPPqiaB9dZf5h1KfrQ8Mmuy9FXc37kn9ludihi7cRDN+PMl3ufPw876GZormzuQcrEV5uoK2C6+/S9z+Dqfao/uklmkaJVQ51+fz2hOhqhug2EOFp9MTj2889PmkP1YglZpE6PX72GmFMNHc2jvF2g64uGd7bg9EbQ3tUU9sNQsUmnwY2eYp0Yo4RMm4lrvCRDj7KA6po9yMdepQHKPvVX/yH19wArRXedy3xT9kPt/xSHQeUbCvZF+eICba52IN3EBOELSVNKKtfXdoSaWojJphT5RrERaYQIdGK1ogMmT24h8iQgaiuERnOHiulqJpA4UGViIz+fvps8z6m+bo/5Rn7BxFRzdOtC0SEF+0PjC71GglPK6JJviEShqJ9tC3XSrU2zfje413rt8jXCJwx0fRdS+5M7YIo6KV9YpfnbWLsFwTM1K0pHd2VsAh2YM4tX/g8NdF5aKtlzZqLlZoLZwiE2iU5Pt0opAIVRPqs9nFLfF64BKC8/YuGWb5iq0whP99pwjZLiR7F18YlVSXHNpepSn0eZc0D9xFS9MO9AjhJZQAAAABJRU5ErkJggg==);
  width: 15px;
  height: 15px;
  transform: translateY(-2px);
  margin-right: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #fff;
}
.imgCorrect-box___18PRW .wrongWordTags___3Tb2L {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-8px, -8px);
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI6SURBVHgB1ZnNTcNAEIXf2IKzSwgVYCqIIwhCnEIFQAXQAaEDqACogBtCCAlTAVAB6QCfOESyh9m1A/mPk5B455Ms/8SWX57e/niWsCR8eBii241AtC2noewDMNf6bklke5frCbLsFZubMT08vGMJCAvABwc1EXAs4s7lNMD8dGSL4fuX9PjYwZzMJdqKTdMbOYzwf9zOK76UaG61Anx/n4mzbawKojY9PV2WunXWDYW7L3JYw+rpiOuNWa57034UwZEIfsN6BMO+R97H+/utaTdNFC0PHhcOL9LQlsH0Pvf2/RMYGw/7T+VBVA1zg56f4+HLI6KLDJtIrNvhcSSS8Z3hjI/Go5pITCIwemzv1ceAaInFBdbX6MpSk+72vP/CbzyKWHzCVXx/qxeTP6fT9AIuk4/EFuu08y73KNzOnXbd5R5ZdmJ2vXhE0ADzmdkRN5uh7N+ghY2NHU/UR9BEmkYmHnVoIsu2Pft5pAmi0DgdQhPMgRGty2kZ1j0oRKloog6UYfrpBMowTn9AGZ501kuVqKrAOB1DGfl8utn8gqL+Ou/yiK6hiFy0591CEVZ08cEYQwl/I6Lvn0IJv6Kt20SlSq1VMzj36HavNAzrA6IpjhNplA3k6yTOMjLLszFhPoLDjJ2aFuVVZxvm1OUL3t1tSVxMOcqp0bLcmkuWvQytDVZK6SU53ttrS8/iRPms9OeW5LxtCoByeIeKWXzFNk2N8/UqYrOQ6H5sLTAvrdWLwo+po6y04f4ARb7D3pYqHYwAAAAASUVORK5CYII=);
}
.imgCorrect-box___18PRW .goodSentencesTags___1JNY6 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-6px, 0px);
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFNSURBVHgBtZVPTsJQEMa/edZEg4t6g25xJSeQG+gWN8oJwBNAb8ANdCXu1BvoCXAl7ugRulDTaOk47zUhhFJD4M1v1bTNfO/Nn28I/xDOHsM8y3ry2CYgYnDkPjCnIPPGzM/7RE9ps5PUxaC1gafjKAduJVIbG0Cguz0gXidkVl80Ph56OReTTYNb5GbXOfGk8X7fr4ovcTQdD+T3IXaChp/NTlwRsCcnLkbwADNuvk4uRwsBl3ObFqIQPiCkAVPL1sTVYA4MvAW3MMKySUSr7BieQYHg4PDY/DJfQIk8++4bIjqHGubMgItTKEEy+cZrcVdgsRcDZYwMQgJFDIMSKCIpKl6hiAmyHy/+UyuQtrqpNNQLlHBdFABdt6W0BKzrSbFjKLCYg9K/2btIZSfbtScj7s2+a5f+XFanjPoVNASWhaydO8e1prjFrf4ApQV7Y+LzO+oAAAAASUVORK5CYII=);
}
.rightBox___2H9-u .sentenctBoxS___2IeyO {
  background: rgba(12, 215, 166, 0.1);
  border-radius: 11px;
  padding: 10px;
  text-align: left;
  line-height: 20px;
}
.rightBox___2H9-u .sentenctBoxS___2IeyO .sentenceHeader___35V-c {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #0cd7a6;
}
.rightBox___2H9-u .sentenctBoxS___2IeyO .sentenceHeader___35V-c .goodSentencesTag___1TCaI {
  width: 15px;
  height: 15px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFNSURBVHgBtZVPTsJQEMa/edZEg4t6g25xJSeQG+gWN8oJwBNAb8ANdCXu1BvoCXAl7ugRulDTaOk47zUhhFJD4M1v1bTNfO/Nn28I/xDOHsM8y3ry2CYgYnDkPjCnIPPGzM/7RE9ps5PUxaC1gafjKAduJVIbG0Cguz0gXidkVl80Ph56OReTTYNb5GbXOfGk8X7fr4ovcTQdD+T3IXaChp/NTlwRsCcnLkbwADNuvk4uRwsBl3ObFqIQPiCkAVPL1sTVYA4MvAW3MMKySUSr7BieQYHg4PDY/DJfQIk8++4bIjqHGubMgItTKEEy+cZrcVdgsRcDZYwMQgJFDIMSKCIpKl6hiAmyHy/+UyuQtrqpNNQLlHBdFABdt6W0BKzrSbFjKLCYg9K/2btIZSfbtScj7s2+a5f+XFanjPoVNASWhaydO8e1prjFrf4ApQV7Y+LzO+oAAAAASUVORK5CYII=);
  margin-right: 10px;
}
.rightBox___2H9-u .sentenctBoxS___2IeyO .sentenceTitle___23vxx {
  font-size: 13px;
  font-weight: 400;
}
.rightBox___2H9-u .wrongBoxS___2pnNO {
  background: rgba(255, 69, 69, 0.1);
  border-radius: 11px;
  padding: 10px;
  text-align: left;
  line-height: 20px;
}
.rightBox___2H9-u .wrongBoxS___2pnNO .wrongHeader___1Hfmg {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #ff4545;
}
.rightBox___2H9-u .wrongBoxS___2pnNO .wrongHeader___1Hfmg .wrongWordTag___37DsA {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI6SURBVHgB1ZnNTcNAEIXf2IKzSwgVYCqIIwhCnEIFQAXQAaEDqACogBtCCAlTAVAB6QCfOESyh9m1A/mPk5B455Ms/8SWX57e/niWsCR8eBii241AtC2noewDMNf6bklke5frCbLsFZubMT08vGMJCAvABwc1EXAs4s7lNMD8dGSL4fuX9PjYwZzMJdqKTdMbOYzwf9zOK76UaG61Anx/n4mzbawKojY9PV2WunXWDYW7L3JYw+rpiOuNWa57034UwZEIfsN6BMO+R97H+/utaTdNFC0PHhcOL9LQlsH0Pvf2/RMYGw/7T+VBVA1zg56f4+HLI6KLDJtIrNvhcSSS8Z3hjI/Go5pITCIwemzv1ceAaInFBdbX6MpSk+72vP/CbzyKWHzCVXx/qxeTP6fT9AIuk4/EFuu08y73KNzOnXbd5R5ZdmJ2vXhE0ADzmdkRN5uh7N+ghY2NHU/UR9BEmkYmHnVoIsu2Pft5pAmi0DgdQhPMgRGty2kZ1j0oRKloog6UYfrpBMowTn9AGZ501kuVqKrAOB1DGfl8utn8gqL+Ou/yiK6hiFy0591CEVZ08cEYQwl/I6Lvn0IJv6Kt20SlSq1VMzj36HavNAzrA6IpjhNplA3k6yTOMjLLszFhPoLDjJ2aFuVVZxvm1OUL3t1tSVxMOcqp0bLcmkuWvQytDVZK6SU53ttrS8/iRPms9OeW5LxtCoByeIeKWXzFNk2N8/UqYrOQ6H5sLTAvrdWLwo+po6y04f4ARb7D3pYqHYwAAAAASUVORK5CYII=);
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.rightBox___2H9-u .wrongBoxS___2pnNO .grammarValue___cZa0m {
  font-size: 13px;
}
