.banner___ow024 {
  position: relative;
  margin-top: 75px;
}
.banner___ow024 .bannnerImage___2cxzh {
  width: 100%;
  display: block;
}
.banner___ow024 .bannerContent___1Tui5 {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 185px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.banner___ow024 .bannerContent___1Tui5 .bannerContentInner___1dbR_ {
  height: 104px;
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 104px;
  text-align: center;
}
.containerOut___cYzDp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}
.containerOut___cYzDp .containerIn___2sBvO {
  width: 830px;
  text-align: center;
}
.containerOut___cYzDp .containerIn___2sBvO .title___2v1ff {
  font-size: 50px;
  font-weight: 500;
  padding: 50px 0;
  margin-bottom: 0px;
}
.containerOut___cYzDp .containerIn___2sBvO .desc___3LwaJ {
  line-height: 1.5;
}
.containerOut___cYzDp .containerIn___2sBvO .imageAlt___23nEK {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 29px;
}
.containerOut___cYzDp .containerIn___2sBvO p,
.containerOut___cYzDp .containerIn___2sBvO span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.containerOut___cYzDp .containerIn___2sBvO .image___3vaLt {
  width: 800px;
  margin-bottom: 16px;
}
.containerOut___cYzDp .containerIn___2sBvO .ant-typography___13zAD {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.containerOut___cYzDp .containerIn___2sBvO .group2___Gvog2 {
  background-color: #ccc;
}
.containerOut___cYzDp .containerIn___2sBvO .group4___3hHt1 {
  background-color: #ccc;
}
.containerOut___cYzDp .containerIn___2sBvO .group5___3vo3- {
  background-color: #ccc;
}
.containerOut___cYzDp .containerIn___2sBvO .imgMiddle___1E084 {
  width: 400px;
}
