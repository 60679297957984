.organizerContainer___nLsHO .banner___2JYdS {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.organizerContainer___nLsHO .content_main___3WTZu {
  margin-top: 20px;
}
.organizerContainer___nLsHO h1 {
  font-size: 46px;
}
.organizerContainer___nLsHO h2 {
  margin: 20px;
  margin-top: 30px;
}
.organizerContainer___nLsHO .home_img_1___G8rAM {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.organizerContainer___nLsHO .change_contents___1CMIR {
  padding: 20px 80px;
}
.organizerContainer___nLsHO .change_contents___1CMIR .home_img_1___G8rAM {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.organizerContainer___nLsHO .change_contents___1CMIR .like_a___1Pyan {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2;
}
.organizerContainer___nLsHO .change_contents___1CMIR a {
  color: #303de2;
}
.organizerContainer___nLsHO .change_contents___1CMIR .like_a___1Pyan:hover {
  text-decoration: underline;
}
.organizerContainer___nLsHO .home_connect___PjdqN b {
  margin-right: 8px;
}
.organizerContainer___nLsHO .home_connect___PjdqN p {
  line-height: 35px;
}
.organizerContainer___nLsHO .home_connect___PjdqN img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.organizerContainer___nLsHO .home_connect___PjdqN img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.organizerContainer___nLsHO .home_connect___PjdqN .img_box___1zLKS {
  margin: 0 auto;
  width: max-content;
}
.organizerContainer___nLsHO .body_text___duoIh {
  line-height: 35px;
}
.organizerContainer___nLsHO .latexClassN___CXsES .katex____L9Q1 .base___SQ0SE {
  font-size: 20px;
}
