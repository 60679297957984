.video-device-choose-btn-area___36Ybp {
  display: flex;
  width: initial;
  width: 48px;
  border-radius: 6px;
}
.video-device-choose-btn-area___36Ybp .video-device-choose-icon-area___11rgp .open-close-icon___11xCP {
  font-size: 30px;
  color: #fff;
}
.video-device-choose-btn-area___36Ybp .video-device-choose-icon-area___11rgp .open-close-icon___11xCP:hover {
  cursor: pointer;
  opacity: 0.8;
}
.video-device-choose-btn-area___36Ybp .device-choose-crontl-btn-area___1vspx .up-arrow-icon___2LhTN {
  position: relative;
  top: 4px;
  font-size: 12px;
  color: #fff;
  padding: 2px;
}
.video-device-choose-btn-area___36Ybp .device-choose-crontl-btn-area___1vspx .up-arrow-icon___2LhTN:hover {
  cursor: pointer;
  opacity: 0.8;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}
.video-device-choose-btn-area___36Ybp .select-device-popover___3WxRt .device-list-area___2Y09J {
  border: none;
}
.video-device-choose-btn-area___36Ybp:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
