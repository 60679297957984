.evaluation-audio-ctn___2uW0S {
  padding: 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.evaluation-audio-ctn___2uW0S ul,
.evaluation-audio-ctn___2uW0S li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.evaluation-audio-ctn___2uW0S .page-split-loading-area___1X4Ny {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr {
  margin: 20px auto 40px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all-text___2Gpxj {
  font-size: 14px;
  font-weight: bold;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX {
  width: 580px;
  height: 26px;
  background-color: #767e85;
  margin-left: 4px;
  border-radius: 5px;
  position: relative;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-progress___1MOH9 {
  height: 100%;
  background-color: #5bcd3e;
  border-radius: 4px 0px 0px 5px;
  transition: width 0.5s;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-progress___1MOH9 .score-num___1dmUP {
  position: absolute;
  right: 4px;
  font-size: 14px;
  font-weight: bold;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK {
  left: 60%;
  padding-left: 4px;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK::before {
  content: '';
  height: 100%;
  background-color: #bababa;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto 0;
  transform: scale(1, 0.75);
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK::after {
  content: '\901A\8FC7';
  left: 0;
  position: absolute;
  font-size: 12px;
  bottom: -26px;
  width: 30px;
  transform: scale(0.9);
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB {
  left: 80%;
  padding-left: 4px;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB::before {
  content: '';
  height: 100%;
  background-color: #bababa;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto 0;
  transform: scale(1, 0.75);
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB::after {
  content: '\4F18\79C0';
  left: 0;
  position: absolute;
  font-size: 12px;
  bottom: -26px;
  width: 30px;
  transform: scale(0.9);
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_ {
  right: 0;
  padding-right: 6px;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_::after {
  content: '\5B8C\7F8E';
  left: 0;
  position: absolute;
  font-size: 12px;
  bottom: -26px;
  width: 30px;
  transform: scale(0.9);
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_ {
  position: absolute;
  top: 0px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  color: #fff;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK::before,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB::before,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_::before {
  color: #fff;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK::after,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB::after,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_::after {
  color: #fff;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK.score-is-ok___qAl32,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB.score-is-ok___qAl32,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_.score-is-ok___qAl32 {
  color: transparent;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-pass___3cKoK.score-is-ok___qAl32::before,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-excellent___206XB.score-is-ok___qAl32::before,
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-full___2GC1_.score-is-ok___qAl32::before {
  color: transparent;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-rewards___1rHaD {
  position: absolute;
  left: 0;
  top: 46px;
  width: 100%;
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  font-size: 22px;
  font-weight: 600;
  font-style: italic;
  color: #fcf3bb;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-rewards___1rHaD.take-reward___2EJZy.score-reward-pass___1eabY {
  animation: passMove___i8FCn 0.8s 0s ease-in-out;
  animation-fill-mode: forwards;
}
.evaluation-audio-ctn___2uW0S .score-all-area___3DWpr .score-all___2gatX .score-rewards___1rHaD.take-reward___2EJZy.score-reward-excellent___3LlBU {
  animation: excellentMove___1mkDk 0.8s 0s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes passMove___i8FCn {
  0% {
    opacity: 0.4;
    transform: translateX(42%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(52%);
  }
}
@keyframes excellentMove___1mkDk {
  0% {
    opacity: 0.4;
    transform: translateX(60%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(70%);
  }
}
.evaluation-audio-ctn___2uW0S .score-star-list-area___1x9Af {
  z-index: 3;
}
.evaluation-audio-ctn___2uW0S .score-star-list-area___1x9Af .reward-start___Xoqgl {
  width: 30px;
  z-index: 3;
  position: absolute;
  border-radius: 100%;
  transition: transform 0.5s 0.3s ease-in-out;
  top: 225px;
  left: 60px;
  transform: scale(0.9) rotate(8deg);
  animation: showRewardStarArea1___3t_sP 0.3s 0s ease-in-out;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO {
  height: 520px;
  padding: 0 10px;
  overflow: hidden;
  position: relative;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO.overflow-auto___3lre0 {
  overflow-y: auto;
  overflow-x: hidden;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO.overflow-auto___3lre0 .hilight-panel___sd5ei {
  padding: 0;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei {
  color: #040609;
  position: absolute;
  width: 100%;
  height: 100px;
  padding: 0 10px;
  top: 60px;
  z-index: 1;
  border-radius: 5px;
  visibility: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei .backgroundArea___12TSV {
  position: relative;
  width: inherit;
  height: inherit;
  border-radius: 5px;
  background-color: #fff;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei.overflow-visible___1lqzS {
  visibility: visible;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei .score-list___3pjef {
  line-height: 12px !important;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei .score-list___3pjef p {
  height: 12px;
  margin: 0 0 2px 0;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .hilight-panel___sd5ei .score-list___3pjef span {
  display: block;
  height: 12px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .volume-ctn___1VLLn {
  position: absolute;
  top: 0;
  left: 160px;
  height: 30px;
  width: 612px;
  z-index: 3;
  font-size: 12px;
  z-index: 1;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .volume-ctn___1VLLn i {
  position: absolute;
  width: 30px;
  height: 20px;
  left: -30px;
  top: 20px;
  color: #9eab8d;
  line-height: 10px;
  transform: scale(0.8);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .volume-ctn___1VLLn canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 612px;
  height: 30px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 {
  padding-bottom: 306px;
  padding-top: 60px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  color: #888e94;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH {
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  padding: 0 20px 0 0;
  margin-left: 160px;
  transition: font-size 0.5s;
  position: relative;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-line-num___3uO34 {
  width: 34px;
  height: 34px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #888e94;
  position: absolute;
  left: -38px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  opacity: 0.7;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .hilight-panel___sd5ei {
  background-color: transparent;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .hilight-panel___sd5ei .score-list___3pjef {
  line-height: 12px !important;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd {
  height: 100px;
  line-height: 100px;
  border-radius: 5px;
  color: #383333;
  font-size: 24px;
  background-color: transparent;
  position: relative;
  margin-left: 0;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .active-sen___1ux82 {
  position: absolute;
  height: 100%;
  z-index: 10;
  left: 160px;
  top: 0;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd.white-bg___2fiVu {
  background-color: #fff;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd.white-bg___2fiVu .hilight-panel___sd5ei {
  visibility: visible;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf {
  width: 24px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf.word-broke___2VvzP {
  transform: translateX(2px);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .stoped-tip___2Ggy1,
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .missed-tip___-D4LB {
  bottom: 18px;
  left: 5px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .square-bt___1R-Ld.missed-tip___-D4LB {
  bottom: 6px;
  left: 6px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .read-louder___3bcRR {
  bottom: 18px;
  left: 4px;
  transform: scale(0.96);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .read-loudered___hyLQn {
  bottom: 17px;
  transform: scale(0.96) translateX(2px);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH.line-active___Sn3kd .article-word___T5Rbf .read-pause___bqpxS {
  left: 19px;
  bottom: 21px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf {
  position: relative;
  display: inline-block;
  width: 21px;
  text-align: center;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf.word-broke___2VvzP {
  transform: translateX(1px);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .word-repeated___2HzLl {
  text-decoration: underline;
  opacity: 0.6;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .stoped-tip___2Ggy1,
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .missed-tip___-D4LB {
  position: absolute;
  left: 3px;
  bottom: 2px;
  font-size: 12px;
  color: #fff;
  width: 14px;
  height: 14px;
  line-height: 14px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .repeated-tip___3hLDb,
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .missed-tip___-D4LB {
  background-color: #ddafc6;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .square-bt___1R-Ld.missed-tip___-D4LB {
  bottom: 2px;
  left: 4px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .stoped-tip___2Ggy1 {
  background-color: #e5ba9b;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .read-pause___bqpxS {
  position: absolute;
  color: #ce4436;
  bottom: 6px;
  left: 14px;
  font-size: 12px;
  transform: scale(0.9);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .read-pause___bqpxS.read-paused___3KFRi {
  color: #318323;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .read-pause___bqpxS.is-paused___u2H_G {
  opacity: 0;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .read-louder___3bcRR {
  font-size: 17px;
  position: absolute;
  color: #ce4436;
  bottom: 4px;
  left: 3px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8 .article-line___3iPDH .article-word___T5Rbf .read-loudered___hyLQn {
  position: absolute;
  color: #ce4436;
  bottom: 3px;
  left: 1px;
  font-size: 17px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8.read-processing___H6dEH {
  transition: transform 0.6s;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .article-wrap___2_Yy8.read-processing___H6dEH .article-line___3iPDH.line-active___Sn3kd {
  background-color: transparent;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .readed-bg___27YGc {
  position: absolute;
  left: 0;
  background-image: linear-gradient(#deecce, #f3f6ed 80%);
  background-color: transparent;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .readed-bg___27YGc.bg-processing___2E0Uz {
  transition: width 0.3s linear;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .readed-bg___27YGc.is-full___2i714 {
  border-radius: 5px;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .readed-bg___27YGc .processing-tag-icon___8DzaU {
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
  margin: auto;
  display: flex;
  align-items: center;
  color: #567772;
  animation: processingIconFlicker___28FWv 1s infinite alternate;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .score-wrap___2mgz5 {
  position: absolute;
  z-index: 2;
  width: 200px;
  height: 90px;
  text-align: center;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .score-wrap___2mgz5 .score-list___3pjef {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
  color: #b8bab7;
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .score-wrap___2mgz5 .score-list___3pjef div {
  margin-left: 10px;
  transform: scale(0.8);
}
.evaluation-audio-ctn___2uW0S .caption-ctn___1FZJO .score-wrap___2mgz5 .score-total___1DYJ7 {
  width: 50px;
  height: 50px;
  margin: 12px 40px 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 50px;
  font-size: 25px;
  color: #bec4b5;
}
.evaluation-audio-ctn___2uW0S .list-bottom-mask___3L4vJ {
  width: 100%;
  height: 40px;
  position: relative;
  top: -40px;
  margin-bottom: -40px;
  z-index: 2;
  background-image: linear-gradient(to top, #142532, rgba(20, 37, 50, 0.5));
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w {
  user-select: none;
  height: 150px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background-color: transparent;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .recorder-btn___2tBvE {
  width: 66px;
  position: relative;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .recorder-btn___2tBvE .ant-progress-text {
  display: none;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-start___24Lfb {
  cursor: pointer;
  color: #fff;
  position: absolute;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  background-color: #7fb334;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-start___24Lfb.disable-record___1MAJ9 {
  background-color: gray;
  pointer-events: none;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU {
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 54px;
  transform: translateX(-50%);
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU span {
  display: inline-block;
  text-align: center;
  width: 22px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU span.time-seprator___2hGGP {
  width: 10px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU span.time-min___9lYNN {
  text-align: right;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU span.time-sec___12Ujx {
  text-align: left;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-duration___1lKRU.record-started___3fyiR {
  bottom: 72px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .btn-name___16ha_ {
  position: absolute;
  bottom: -20px;
  width: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-done___1ObeP {
  cursor: pointer;
  position: absolute;
  right: 50%;
  top: 62px;
  transform: translateX(100px);
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .icon-text___1O5eS {
  font-size: 12px;
  margin-top: 4px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .icon-help___2FHK1 {
  position: absolute;
  right: 20px;
  top: 80px;
  font-size: 25px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-processing___3oItq,
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-paused___iSjnZ {
  color: #7fb334;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-processing___3oItq {
  font-size: 35px;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .record-paused___iSjnZ {
  font-size: 40px;
  transform: translate(-40%, -50%);
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .ant-progress-circle-trail {
  stroke: #5f6b74 !important;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .ant-progress-text {
  color: transparent;
}
.evaluation-audio-ctn___2uW0S .operation-ctn___3Q02w .anticon-check-circle {
  font-size: 25px;
  color: #7fb334;
}
.help-panel___3rMLZ {
  border: 1px solid #768084;
  border-radius: 5px;
  padding-bottom: 0;
}
.help-panel___3rMLZ::after {
  position: absolute;
  background-color: #040609;
  content: '';
  width: 5px;
  height: 5px;
  border: 1px solid #768084;
  right: 8px;
  bottom: -3px;
  transform: rotate(45deg);
  border-color: transparent #768084 #768084 transparent;
}
.test-speed-btn___2Ul2M {
  width: 80px;
  height: 80px;
  background: red;
  position: fixed;
  top: 80%;
  left: 50%;
  z-index: 200;
}
@keyframes processingIconFlicker___28FWv {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showRewardStarArea1___3t_sP {
  0% {
    transform: scale(0) rotate(8deg);
  }
  45% {
    transform: scale(1) rotate(8deg);
  }
  75% {
    transform: scale(0.75) rotate(8deg);
  }
  100% {
    transform: scale(0.9) rotate(8deg);
  }
}
@keyframes showRewardStarArea2___1wj7K {
  0% {
    transform: scale(0) rotate(10deg);
  }
  55% {
    transform: scale(0.7) rotate(10deg);
  }
  85% {
    transform: scale(0.6) rotate(10deg);
  }
  100% {
    transform: scale(0.8) rotate(10deg);
  }
}
@keyframes showRewardStarArea3___SsZmj {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.7);
  }
}
@keyframes showRewardStarArea4___3BWtX {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.3);
  }
}
