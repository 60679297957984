.loading___1i-iD {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 550px;
}
.loading___1i-iD .loading_icon___1fOhv {
  font-size: 40px;
  color: #86cfff;
}
.video_box___Rv4R5 {
  position: relative;
  z-index: 999;
  height: 100%;
  width: 100%;
}
.video_box___Rv4R5:hover .box_buttom___1FjqS {
  bottom: 0;
}
.video_box___Rv4R5 .cover_discuss___16X0U {
  position: absolute;
  background-color: white;
  width: 25%;
  height: 67.5%;
  top: 34%;
  left: 75%;
  display: block;
}
.video_box___Rv4R5 .cover_box___1l_5g {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 999;
  height: calc(100% - 70px);
}
.video_box___Rv4R5 video::-webkit-media-controls {
  display: none !important;
}
.video_box___Rv4R5 .video___3r9kn {
  width: 100%;
  height: auto;
}
.video_box___Rv4R5 .video___3r9kn video {
  width: 100%;
}
.video_box___Rv4R5 .box_buttom___1FjqS {
  color: white;
  position: absolute;
  bottom: -70px;
  transition: 0.6s;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  width: 100%;
  height: 70px;
  padding: 14px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj {
  display: flex;
  line-height: 25px;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .icon___1s3x1 {
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y {
  position: absolute;
  right: 0;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .speedBtn___1OCdC {
  color: white;
  width: 27px;
  padding: 0;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .speedBtn___1OCdC:hover {
  color: #86cfff;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .speed_active___20P3S {
  color: #86cfff;
  width: 27px;
  padding: 0;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .sound_box___10SBu {
  display: inline-block;
  margin-left: 10px;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .sound_box___10SBu .no_sound___1_WOV {
  position: absolute;
  right: 153px;
  display: inline-block;
  transform: rotateZ(45deg);
  border: 1px solid white;
  height: 24px;
  cursor: pointer;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .sound_box___10SBu .sound_slider___2uLXt {
  display: inline-block;
  height: 12px;
  width: 100px;
  margin: 0;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .speed_slider___IdnKp {
  display: inline-block;
  height: 12px;
  width: 100px;
  margin: 0;
}
.video_box___Rv4R5 .box_buttom___1FjqS .video_action___27vxj .video_action_right___2Re5y .fullScreen___2chi0 {
  margin-left: 10px;
}
.video_box___Rv4R5 .progress___2i-1r {
  margin-top: 7px;
  width: 100%;
}
