.market_box___3YvCP {
  width: 100%;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
}
.market_box___3YvCP .contrast___3NdlI {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 16px;
}
.market_box___3YvCP .content_main___3qiI- {
  margin-top: 0px;
}
.market_box___3YvCP h2 {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.market_box___3YvCP .abouttitle___DEC-Z {
  text-align: center;
  line-height: 53px;
  margin-top: 100px;
  color: #000000;
  overflow-wrap: break-word;
}
.market_box___3YvCP .change_contents___1ZncH li {
  line-height: 35px;
  margin-top: 20px;
  text-indent: 32px;
}
.market_box___3YvCP .change_contents___1ZncH .imgContrast___1S1Kn {
  text-align: center;
  width: 850px;
  margin: 10px auto;
}
.market_box___3YvCP .change_contents___1ZncH .imgContrast___1S1Kn .home_img_1___2QY8J {
  width: 400px;
}
.market_box___3YvCP .change_contents___1ZncH .like_a___3Qy9Y {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.market_box___3YvCP .change_contents___1ZncH a {
  color: #303de2;
}
.market_box___3YvCP .change_contents___1ZncH .headercontent___1-049 {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.market_box___3YvCP .change_contents___1ZncH .headercontent___1-049 img {
  width: 100%;
  height: 500px;
}
.market_box___3YvCP .change_contents___1ZncH .headercontent___1-049::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 52%;
  background-repeat: no-repeat;
  background-size: contain;
}
.market_box___3YvCP .change_contents___1ZncH .headerleft___2HISo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.market_box___3YvCP .change_contents___1ZncH .headerp1___2ogDg {
  font-size: 42px;
  font-weight: 600;
  font-family: PingFangSC-Semibold;
  line-height: 59px;
  overflow-wrap: break-word;
  color: #ffffff;
}
.market_box___3YvCP .change_contents___1ZncH .headerp2___1yM-I {
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  margin-top: 19px;
}
.market_box___3YvCP .change_contents___1ZncH .aboutcontent___1pB-p {
  width: 100%;
  height: 376px;
  background: #dfe3ff;
  text-align: center;
  background-image: url('https://lanhu.100tal.com/images/SketchPng3a1eb862a4fcf3849f2b155041b7309d7aa5acda83bebc493af64a62b30b3706');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.market_box___3YvCP .change_contents___1ZncH .aboutcontentmiddle___18eKY {
  width: 1200px;
  height: 100%;
  position: relative;
  padding: 0 120px;
}
.market_box___3YvCP .change_contents___1ZncH .abouttitle___DEC-Z {
  font-size: 38px;
  font-weight: 400;
  line-height: 53px;
  color: #000000;
  margin-bottom: 24px;
}
.market_box___3YvCP .change_contents___1ZncH .abouticon___ZMe6i {
  width: 213px;
  height: 47px;
  position: absolute;
  top: 47px;
  right: 120px;
}
.market_box___3YvCP .change_contents___1ZncH .aboutmaintext___1s-bC {
  width: 800px;
  height: 165px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
  margin-top: 36px;
}
.market_box___3YvCP .change_contents___1ZncH .aboutul___1jXys {
  display: flex;
  justify-content: center;
  align-items: center;
}
.market_box___3YvCP .change_contents___1ZncH .aboutli___3i2-e {
  width: 140px;
  font-size: 18px;
  font-weight: 400;
}
.market_box___3YvCP .change_contents___1ZncH .unionIntroductioncontent___2Ppwg {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.market_box___3YvCP .change_contents___1ZncH .unionIntroduction___2tlKN {
  position: relative;
  width: 322px;
  height: 268px;
  background-image: url(/_next/static/images/aboutimage-060c0317e2ecd412f5791f838552f311.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.market_box___3YvCP .change_contents___1ZncH .unionIntroductionh3___3k1qw {
  position: absolute;
  right: 10px;
  top: 300px;
  font-size: 36px;
}
.market_box___3YvCP .change_contents___1ZncH .unionIntroductionmainborder___20VbH {
  width: 1000px;
  height: 268px;
  background-color: #f5f7fc;
  padding-top: 45px;
  padding-right: 49px;
  padding-left: 40px;
  border-radius: 18px;
}
.market_box___3YvCP .change_contents___1ZncH .unionIntroductionmain___13SDQ {
  width: 800px;
  font-size: 16px;
  text-indent: 2em;
  line-height: 33px;
  font-weight: 400;
  background-color: #f5f7fc;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersback___18cGD {
  background: #f6f8fd;
  padding-top: 1px;
  padding-bottom: 80px;
}
.market_box___3YvCP .change_contents___1ZncH .unionmemberstext___3WYy_ {
  margin-top: 72px;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersmain___2FILH {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi {
  background: #f6f8fd;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  /* 设置网格线间隙 */
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi a:focus {
  outline: none;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i {
  height: 150px;
  border-radius: 12px;
  background-color: #ffffff;
  width: 580px;
  justify-content: flex-end;
  padding-bottom: 21px;
  align-items: flex-start;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G {
  width: 498px;
  height: 102px;
  display: flex;
  flex-direction: column;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .group2___17RZ1 {
  width: 498px;
  height: 64px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .group2___17RZ1 .layer3___3D1h9 {
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: -12px -12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 11px;
  border: 1px solid #efefef;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .group2___17RZ1 .layer4___2_ZGW {
  width: 420px;
  height: 52px;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .group2___17RZ1 .layer4___2_ZGW .word1___1yIs2 {
  display: block;
  overflow-wrap: break-word;
  color: #000000;
  font-size: 20px;
  font-family: PingFangSC-Medium;
  line-height: 28px;
  text-align: center;
  align-self: flex-start;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .group2___17RZ1 .layer4___2_ZGW .txt2___HBtR9 {
  position: relative;
  width: 420px;
  overflow-wrap: break-word;
  color: #000000;
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  align-self: center;
  margin-top: 4px;
  display: block;
  max-height: 3em;
  overflow: hidden;
  /* 显示省略号 */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i .group1___jLJ4G .word2___2eh77 {
  width: 192px;
  display: block;
  overflow-wrap: break-word;
  color: #828282;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
  align-self: flex-start;
  margin-top: 21px;
}
.market_box___3YvCP .change_contents___1ZncH .unionmembersul___1NSUi .layer2___3zm4i:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  /* 添加阴影，模糊值20px，不透明度6% */
  border: 1px solid #5187d5;
}
