.katex .base,
.katex .strut {
  display: inline;
}
.katex .base {
  position: relative;
  white-space: pre-wrap;
  width: 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(4, 32, 68, 1);
  line-height: 20px;
}
.katex-error {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
