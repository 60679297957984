.menu-list-area___1CaOZ .menu-item-title___2B-hA {
  color: #a6a6a6;
  font-size: 15px;
  padding: 12px 12px 2px 22px;
  display: block;
  position: sticky;
  top: 196px;
  background-color: #292929;
  z-index: 1;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo {
  display: flex;
  flex-direction: column;
  background-color: #292929;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .edit-item___1Uj-t {
  border: 1px solid #0cd7a6;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G {
  margin-left: 8px;
  margin-right: 8px;
  height: 50px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G:hover {
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 6px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G:hover .item-content-area___OlAyv {
  color: #ffffff;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G:hover .item-content-area___OlAyv .icon-huihua___O6M2y {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPZSURBVHgB7ZpNbtpAFMffeEygIlI5At00JcqCnCDJCZpsKiVZJJyA5AQ1J2hyAhJVaboLPUHJCaBqFbrkCETCqgHbrzMmKGDjT2zA0vwkJBjm68+8eTO8ZwCBQCAQCASCJUEgAQqtemGU2SgSNAsQASRSLzMadnu7lR7ETCyCXwQeAoU91uU+67QIMYAAXUKgjQgNtXRyCzGwkGAuVM/lqoB4wXqKtJpB4eLZdJsGaDWtVOlCRCILzv/9XiWmqSQt1M5YOChRVzy0YGtV32TrbORDWCEI5CajaZdh93kowblOvSiT7AMbrexeC5tIaAPQeCZgdiECBLGASMsgjX2C60hstQ0YHIQx8cCCLceUy7bmOiSEHpvltawNr+L2rLnOfVEG85yt6NncsZlTk/8NDoKOG1hw/unbDfOYZ3N6aLABK0kcIdNw4RRRcZtD/8PJUZB+AgnO/7k7J5TUnd9grV86VWCJbHbuFDbtz/ZydnRdqtsnV37tfQXzfUsh+9NpTssXO8FFdE/WBu/8LE0CHyhmFbtY7iFXJZZjjc3M2FZc0Dc2Lvza+gpmHe9Nfxx7RqjBiuF+w3KW00ikyp2rVztPwdbeta0uAbzVSsddWDGW6SJe24rHV1wPPAUTiXy0l+kg3cCaIA+HTidFpT2vNp6CkdgvGNhch9WdMHZQ2JwtxX2vNq6C+V5weGYTHmHNQCQ/pj/zOXvtY1fBo4zzVkMobcG6YaLjGBrl80W36rLbFwRlx6+EOHoGD5iTKxN5fD7qOLicd8eNq87rPPl9nc6WGYPwKxwWy4woebD+RbGXDNmW3bTiqrMIsQnWZbls2/MsOCCXk6izCLEJTguxCUaiO5wH0mwviTqLEJtgdafSnrn5sPfq+0/tJOosQqwm3d8+vUADd/mLv0+yTlRkiBl157S9rDpRSL3TIhSd9wWPPZ96wVawz0ZGVbtu9dN/LEnO/+teUY9UC+bhJ3sYlyD55dUm1YJlyDkimEjxwatNagXz1UXA8+kybs7qlncKJrWC5wUXebLNr13s5/Ay2Ozcs7+OOGPOQYOLqRP8IlZxfCGBom35h59SI/g1a4mOqCSPk6tbx4HSp2sveJJ013nSHZ25aG7KPG0atL9QgvmtZvP3V0gaJFIRZPqWoHGoW+cszk8KscyhgeRIDZHICyWYZe6+sIlA0lja0ATP1NdL1rIfMmuZPi/N0itsvWtqyT9TOA9XwTzyQCD51QzMJOk+WCzp7pku3Xy6u2J2XIUVMX6AxcosPGa0YSOOpLtvftiKEdNoD5hFBY3kHkwTCAQCgUAgWBr/AZCo3CNeFIKuAAAAAElFTkSuQmCC) no-repeat;
  background-size: 20px 20px;
  background-position: center;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv {
  width: 240px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .ant-input {
  background-color: #1a1a1a !important;
  color: rgba(255, 255, 255, 0.8) !important;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .ant-input:focus {
  caret-color: #0cd7a6;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .talk-icon___3xUA9 {
  color: rgba(26, 26, 26, 0.7);
  font-size: 16px;
  padding-right: 6px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .icon-huihua___O6M2y {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPSSURBVHgB7Zq7UttAFIaPLzAGiihdSqVLA+MMw0y6mC5dnCfAFFw6208Q+wniFMzYUGDKVIgupekoYFDeQI9gCjPhnv8wcgbW0urilW3N7DejkXy0kvff3XO0OisijUaj0Wg0mgmRoQSo1WrGYDAwc7mcQTF4eHjoLy0tOa1Wq0+KUSLYFVjOZrOfn56eSjCZpAYHm43N2t/fPyIFjCWYhV5fX1chspbJZGL1ZgQc/EcPW7PdbjsUkxzFZGdnp3p7e2uhAl+wFSh5uEGLaNzy6upq/+Li4g/FIHIPu716iMMyTRE0cvfm5qbe7XYj+Xkkwbu7u+bj4+MxDot+ZdADPewsbFc4digGuM5AwCtC1DAm+OEgbqxHGeKhBbs9e0keAQmV6qNyPxcXF1uqI6vbyBUcbpB3MLThWuthezq0D6+srLSxK3mcsvAIWd/b2/t9dnb2lxRzfn7O/tpbW1s7QcO+pdHR9Q6j4QPK/Apzv1A9vLW1VUEPHnqcauJx0aAJsr293cDuu8epOurSCro+sId5SGHHYsXHzsTFMtzbiNLcUSXh1Kfl5eWObdvSUZalAOA/DRJ8hyPkNMQOcf/bEszG/Px8LejaQMHgs/CbJwBNmjIIVJscLAVztVKpSCdAUsHsuzQaGY/GmemogqMyPxkEszE3NyedH0gF44ZfRy7IZrs0I6CXR4IUz+dl1wQN6VePAJ7LzkLvDnF7uffSFjBR8Rfs+oIp3OyUZgzU6UQwmTI/9hUMXzBFG1rzkmYMj8DlWfchecmNDAh8ZcPvK5KAIMfz3+dJAXyp7jX8VZV5UU9HrKcs8RDmsRQKHkb4Y36x4ChZxvP7UhxaqsqMgzLB+XyeA5z5wmQUCoViEmXGQZngtKBMMIbhSPDgZFwSZcZBmeCDgwMbAeT/zIeP2ZZEmXFQOqRRsRoq+JE3Pk6yTFzypJgwvaGqTBxSH7R4viDaZD6fesGc7BNtd3d3jl/51AvmzKZgcmQJvVQL5vST+HaEBpAm6FMtGNPODdGGBjiWXZNawW5ysSKYnaBFt9QK9kku9oKuS6Vg5Kb51VEczqGSi6kT7IpteJxqhEk/KZ9pJYVs1ZLz5J1OJ9SC+cwLHi66DwYDv0V3Z2FhoR72fpEE39/fFxEdKWkwNTSxe4OtDLEltolpHBcbKaBvUVYsIwnGzX8gOlLS+IgTsbA8uxl1eTY1PjzEXYtuhlkp9MI3SntlHqaJm45tYi36fVyxjHTsIF3agvAqTY/nL3fgRqcQaqn4uiDQWThH7PXOmSQ8upL6ME2j0Wg0Go1mYvwDkhfgJYROZkUAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px 20px;
  background-position: center;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .icon-act___1v-JI {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPZSURBVHgB7ZpNbtpAFMffeEygIlI5At00JcqCnCDJCZpsKiVZJJyA5AQ1J2hyAhJVaboLPUHJCaBqFbrkCETCqgHbrzMmKGDjT2zA0vwkJBjm68+8eTO8ZwCBQCAQCASCJUEgAQqtemGU2SgSNAsQASRSLzMadnu7lR7ETCyCXwQeAoU91uU+67QIMYAAXUKgjQgNtXRyCzGwkGAuVM/lqoB4wXqKtJpB4eLZdJsGaDWtVOlCRCILzv/9XiWmqSQt1M5YOChRVzy0YGtV32TrbORDWCEI5CajaZdh93kowblOvSiT7AMbrexeC5tIaAPQeCZgdiECBLGASMsgjX2C60hstQ0YHIQx8cCCLceUy7bmOiSEHpvltawNr+L2rLnOfVEG85yt6NncsZlTk/8NDoKOG1hw/unbDfOYZ3N6aLABK0kcIdNw4RRRcZtD/8PJUZB+AgnO/7k7J5TUnd9grV86VWCJbHbuFDbtz/ZydnRdqtsnV37tfQXzfUsh+9NpTssXO8FFdE/WBu/8LE0CHyhmFbtY7iFXJZZjjc3M2FZc0Dc2Lvza+gpmHe9Nfxx7RqjBiuF+w3KW00ikyp2rVztPwdbeta0uAbzVSsddWDGW6SJe24rHV1wPPAUTiXy0l+kg3cCaIA+HTidFpT2vNp6CkdgvGNhch9WdMHZQ2JwtxX2vNq6C+V5weGYTHmHNQCQ/pj/zOXvtY1fBo4zzVkMobcG6YaLjGBrl80W36rLbFwRlx6+EOHoGD5iTKxN5fD7qOLicd8eNq87rPPl9nc6WGYPwKxwWy4woebD+RbGXDNmW3bTiqrMIsQnWZbls2/MsOCCXk6izCLEJTguxCUaiO5wH0mwviTqLEJtgdafSnrn5sPfq+0/tJOosQqwm3d8+vUADd/mLv0+yTlRkiBl157S9rDpRSL3TIhSd9wWPPZ96wVawz0ZGVbtu9dN/LEnO/+teUY9UC+bhJ3sYlyD55dUm1YJlyDkimEjxwatNagXz1UXA8+kybs7qlncKJrWC5wUXebLNr13s5/Ay2Ozcs7+OOGPOQYOLqRP8IlZxfCGBom35h59SI/g1a4mOqCSPk6tbx4HSp2sveJJ013nSHZ25aG7KPG0atL9QgvmtZvP3V0gaJFIRZPqWoHGoW+cszk8KscyhgeRIDZHICyWYZe6+sIlA0lja0ATP1NdL1rIfMmuZPi/N0itsvWtqyT9TOA9XwTzyQCD51QzMJOk+WCzp7pku3Xy6u2J2XIUVMX6AxcosPGa0YSOOpLtvftiKEdNoD5hFBY3kHkwTCAQCgUAgWBr/AZCo3CNeFIKuAAAAAElFTkSuQmCC) no-repeat;
  background-size: 20px 20px;
  background-position: center;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .edit-input___3CubA {
  border: none;
  flex: 1;
  height: 20px;
  color: #1a1a1a;
  background-color: #f5f5f5;
  padding: 0 4px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .item-title-text-gpt___1_VzE {
  width: 200px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ {
  display: none;
  transform: translateY(2px) translateX(10px);
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 1px 4px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K.hidden___2hvZZ {
  display: none;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-right___2ticc {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGZSURBVHgB7dpRkcIwFIXhK2ElIGElIAEJdbA4AAesAySsBCQgoRKQcDahnaFsc1MYYKch/zfDM/SQcxsazAAAAAAAAFASSZ/xZRgLwex0sTNchEAajbGSohDEIrxaAnKEIA6JcPaGczhfiXDauKqsdn21TomAGsM5oCPVcoQgNlTL0VdLVMuh9C2dakVUKyNTrZXBrda3IVutD6tdplpLg1utrcGvluEcztKp1sJqF4cv1crQ9eNTqjUUglhRLYf8x6dbm6t+WP7Ldj68z76oaun6Ntu+cpkrfTIx72qlvk29YAdbZLUipZ/5Rht7IqdaB5u78CHX8j3l9FLpap1mXa0hdbddbyUdH7kQ+dVaW0kyFyI9MLxVarVS+pAOTkhxhd21FVC6Wq1K3xCGC9jKd9PwzqzIxt7BREiTw1s1nKer22G3Tkju8FZN5+m6c3irxvN0dc9ufpyQTsOLV82HfpoY3uLQb3LnndJYbdT907S9IZx6z9OVH971VStF+eG9NHQ0Ht6cp/+l7nfXXqX9SgcAAAAAADP3Cw3y2D+FUPS6AAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-right___2ticc:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABICAYAAAC6L9h5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPQSURBVHgB7ZpfTttAEMZniSFUSSV6g6QPrRL1AXoC6AlQ3yBqm3CBhgtA4AK0FyC0FX/e4ASQExSkVgT1oXCDIjVRE7AznUlqGhwHsL22Y2t/UmTFG9nJ5/lmd2cCoFAoFAqFQqFQKBQKhUKhUCgUQSMg5kwdV7sHXdOmNV0/uZwpXYJDYi3SZL0rUFbD5Ff6pVMIcA4JfN18Vjhxcp0xiDEJSAp6HbJA/J4iIiv0sVVwiAYxJV3f5QMJghnwSCwjKf3tC4Cuz5FA1qhBXcAyOCR2InGi1gzjCWqJzcFRXG/lFi7AIbGzm/4oyZPRikDI9J/npN3MFSrgglhFUur7NvkJi6RI2TKEBohX4JLYiMTTvZG4ykJCrAyOurOZSWzslsCuzVaFkGczk1hEEtsMOlgkgd5ZhjzZzCTyIvlpM5PI222YzUigo4ZHm5lEOpKG2gzhlw5jSyCJyIp0h82QkrUUm5lE1m532KzWzBc+gEQiGUnDbMbTvUybmUSunmStEfUNIY5Bqfl88RNIJnJ202CCH+ymRSCKIrHVfL4gXaDePSNE6myPLfVeYGe2/zzbzKBkDT4RGbuFYTOTyEQSl2LpcBikzUwiIdKwUqzfNjNxbTeqAE5dj0/M04Ku1sqVLsAnbmwGyZ+WId9tZuJqnURfPHM9mTwWCVGlL39OT9pxB+Kh3HQ8LPRs5r9AjCuRtM5EkUIw8/8MVtJnexscXSARtpkAsXr7XsHZzMSVSCjEYBcUO2WOLo4ykIQOraxdx0OAsSRzb3YfrkQab7e36GleWM/zEydrHKV+bE+DBOxsRpnoYyP3pgYB4kok7qePt9ozpMqBdYyFEoY4Tp3ulMEDnOfsbKa1r9YgYDwvJtP17QpdZkjiFpVGbsHxj2LL8oRgOY1o4MvmC2d9fBl4rgJw9Q+xs0yP2ebfGpzQd/adJnS2rM211sMQiJG2LZms71I+wiOrRRjOXwa05x6yniJRN6x9s17HY/EphIS0ehLPNtSZmPOS0KmHPyu7sSgDqUU3Fqqb0GkGso7dl9DZktS/rw6O4HqQ070dvlUBnCb0UbSZia+lksenn+cRtKp15/7vzgfan3aJlxNUji3yFsf6ER1ENuwoYnyvJ92X0IVulNhmg+O4Jqtv5pVAim4slIa4T3d70EqcxSObZWFECKRbwpZp5BdtE7odPEvCCBFoS6mRL5TZRnd/CtdGIQ/1E0qNe3hCxxrloZGKIiaU5uTv/NsDXYiZWwtP2tZQY7EEI0io3ZJeQu9010bYga2w9mYKhUKhUCgUCoVCMeL8BQtBmGJph3plAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-close___32V4f {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7dptisIwEMbxYU+0N9UbmJvOuuDgWEisNc28PT/wk9AMf6WkJUQAAAAAAAAAALDFzL//H3LC1Tz3QS78dCFj9xlubuZ5/FJbNzKyiSPs/kmdQCaROnFsAz0Ga9aRBnGu5IFlpEGcRp5YRAoTR6yMFC6OWBEpbBxxZqTwccQZkdLEETMjpYsjZkRKG0d8Eyl9HHEkUpk44pNI5eKIPZHKxhGjSOXjiEEkxBE7IzWq7E2kRsZ+yB4f/C6/wQ1ZM3vHbWpnnJqRBnEaO3jHbYp37HPKRuIPNoHlIvGBHXKZSPzF40P6SDzh2SptpBlx1LVyRZoZR10zR6Qz4qhrx450Zhy1RsxIK+KotWJFWhlHrRkjkkUctbbvSPx6RnFpHDWDz0jcP4LXaLFBJHdnFBsZ6UQyP6N49RCnM4+bM4o4SA4AAAAAAAAAEMMfobbZYBLGkdQAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-close___32V4f:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABICAYAAAC6L9h5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKkSURBVHgB7dpBbtNAFMbx58Q0kboJNyibSpVYJEdoboGy6g3gBIgTwA3KpseguQHJAgmJTXsCmk2kSjUM7yt1NSqO42Se3zPq+22mlVpn9I+dTOIhcs4555xzzjnnnHPOOW0ZCRktzkdFno/zoliuJmcrMsRzuR+k5tMjAcPv50fFYHBFeX9+NxwsDn9cjMnI4bcLuntxMInm8zV1PiKR8jD4yOfkCD/zqXmU/crmFqEQiE2yXnYZzedVVvTeUwKRSBVG2qGqAkkRiRR+hw8U6Ol1rxaqNlCgmyKjd5RAJNL69WzJoU4tQm0LxPOa3p68uaYEYu9uwBMe82TnFaf7KvTD6fp4tiRBTQLhCaREopFAK5RWIBCPBG2H0gwErUSCtkJpB4LWIoF0KItA0GokkAplFQhajwSpoSwDgUok2DeUdSBQiwS7hupCIGjrs1ulPVbm5oFA9UwqNTmjNv6NciAwiQS1oYjeZoE+dSEQmEWCmlD/MgoEppGgUSjDQGAeCWpDGQcC1Xe3veBpPCBT/8flRnw29flsOn6Gl9tOL9yGocwut5p1EC8BwlnFgvMlLzgvLe7CmESqDcQr8vXJ7POGlblJKPVIWwM9vIs9fISZdiGUaqSmgSKLLoSy/6pkc6Dy/zBsuumo8mJu+6XblkDR/2MwC2X39W3DQNFxMJiEsrkRsGOg6HgY1EPp31LaM1B0XAyqoXRvTiYGio6PQS2U3m1uoUDR42BQCaWzYUI4UPR4GGpD8bjkWJRCbDGpHQj4mBhqF5w8TnjRSSnE9kxqByo1CPWFx6SVufieyUcKgUpbQ3Vyz6RioNKTUDckSGzPJN8Guv77i36g0mOoEKY8n6v72QT6mbpnUnaz+zAf57cd2uzekfk455xzzjnnnGvfH6qNoXpZho/OAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-icon___1Hsj_ {
  font-size: 20px;
  color: #ffffff;
  margin-left: -4px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-icon___1Hsj_:hover {
  color: #0cd7a6;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  margin-left: 8px;
  margin-right: 6px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 .item-content-area___OlAyv {
  color: #ffffff;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 .item-control-area___3wyqJ {
  display: flex;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 .item-title-text-gpt___1_VzE {
  width: 150px !important;
}
@keyframes opacity1___2Tg-A {
  0% {
    background: transparent;
  }
  50% {
    background: rgba(12, 215, 166, 0.6);
  }
  100% {
    background: transparent;
  }
}
