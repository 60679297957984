.cvpr2022_box___2ljgI {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.cvpr2022_box___2ljgI .contrast___npS5z {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 13px;
  color: #734744;
}
.cvpr2022_box___2ljgI .ganrao___SPxKX {
  width: 300px;
  margin: 0 auto;
  text-align: center;
}
.cvpr2022_box___2ljgI .ranking___3o2ug {
  font-size: 14px;
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
.cvpr2022_box___2ljgI .banner___2oAtX {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.cvpr2022_box___2ljgI .content_main___3GZao {
  margin-top: 0px;
}
.cvpr2022_box___2ljgI b {
  font-weight: bold;
}
.cvpr2022_box___2ljgI h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.cvpr2022_box___2ljgI .imgFont___29SwI {
  width: 700px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #b2b2b2;
  font-style: italic;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX {
  padding: 20px 80px;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX b {
  color: #377ef7;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX span {
  font-weight: bold;
  color: black;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX li {
  line-height: 35px;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .body_contect___27ot6 {
  line-height: 35px;
  text-indent: 32px;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .cycle_item___3UAg4 {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .imgContrast___3CZAf {
  text-align: center;
  width: 850px;
  margin: 10px auto;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .imgContrast___3CZAf .home_img_1___torJg {
  width: 400px;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .imgContrast2___29rFx {
  text-align: center;
  width: 700px;
  margin: 10px auto;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .imgContrast2___29rFx .home_img_3___2Kmog {
  width: 300px;
  height: 300px;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .home_img_2___Yp4T1 {
  width: 800px;
  display: block;
  margin: 20px auto;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .home_img_4___185Mb {
  width: 700px;
  display: block;
  margin: 30px auto;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .like_a___3tC98 {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX a {
  color: #303de2;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .home_connect___27khc img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.cvpr2022_box___2ljgI .change_contents___1SlAX .home_connect___27khc img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
