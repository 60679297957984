@font-face {
  font-family: 'WenDaoChaoHei';
  src: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/WenDaoChaoHei-2.ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'DinAlternate';
  src: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/DIN_Alternate_Bold.ttf');
  font-style: normal;
  font-weight: 400;
}
.evaluation-report-header___3dSrW {
  height: 374px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du {
  margin-right: 98px;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .title-area___3ntJV {
  display: flex;
  flex-direction: column;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .title-area___3ntJV .title-text___2bh3y {
  font-style: normal;
  font-size: 78px;
  line-height: 102px;
  font-family: WenDaoChaoHei;
  white-space: nowrap;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .title-area___3ntJV .subTitle-text___2-rlK {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  white-space: nowrap;
  color: #777777;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .img-area___1y5nc {
  display: flex;
  flex-direction: column;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .img-area___1y5nc .line-area___3rf1T {
  margin-top: 28px;
  width: 205px;
  height: 8px;
  background: #7875f5;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .left-content___nT_Du .img-area___1y5nc .arrow-img___3Rr8x {
  width: 240px;
  margin-left: -40px;
  margin-top: -60px;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp {
  width: 340px;
  background-image: url(/_next/static/images/com6bScore-eaaf277c7d9038832504af246459b1bd.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY {
  position: relative;
  top: 90px;
  left: -90px;
  height: 54px;
  text-align: center;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .score-num___3rfue {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 53px;
  padding-right: 10px;
  color: #fff;
  font-family: DinAlternate;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .score-num-area___31aTv .score-unit___xiQro {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 48.3376px;
  line-height: 68px;
  color: #fff;
  position: relative;
  bottom: 20px;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .retry-btn-area___2QY9j {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .retry-btn-area___2QY9j .left-line___1GPi3 {
  flex: 1;
  background-color: #fff;
  height: 4px;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .retry-btn-area___2QY9j .right-line___3onfi {
  flex: 1;
  height: 4px;
  background-color: #fff;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .retry-btn-area___2QY9j .retry-btn___V4Cb_ {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  padding: 0 8px 4px 8px;
  transition: all 0.3s ease-in 0s;
  border-bottom: 2px solid #7875f5;
}
.evaluation-report-header___3dSrW .header-content___QJ_Qw .right-content___2H1xp .score-area___2b9vY .retry-btn-area___2QY9j .retry-btn___V4Cb_:hover {
  cursor: pointer;
  border-color: #fff;
}
.evaluation-report-header___3dSrW .header-footer-line___1Rc7I {
  position: absolute;
  bottom: 48px;
  right: 82px;
  width: 900px;
  background-image: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/evaluation_report_dotted_line.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 4px;
}
.evaluation-report-header___3dSrW .chart-dom___GU-Yj {
  width: 267px;
  height: 195px;
}
.evaluation-report-header___3dSrW .chart-dom___GU-Yj > div:hover {
  cursor: default !important;
}
