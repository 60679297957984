.outer___kvP0A {
  width: 100%;
  background: #ffffff;
  padding-bottom: 50px;
}
.outer___kvP0A .top___3RN4H {
  width: 100%;
  height: 86px;
  padding-top: 53px;
  line-height: 33px;
  font-size: 24px;
  font-weight: 500;
  color: #042044;
  text-align: center;
  margin-bottom: 48px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___kvP0A .bottom___1hudR {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.outer___kvP0A .bottom___1hudR .bottomCon___1CaIf {
  width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.outer___kvP0A .bottom___1hudR .bottomCon___1CaIf .inner___3yWcM {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.outer___kvP0A .bottom___1hudR .bottomCon___1CaIf .inner___3yWcM .image___TSv0w {
  max-width: 32px;
  max-height: 32px;
  margin-right: 15px;
}
.outer___kvP0A .bottom___1hudR .bottomCon___1CaIf .inner___3yWcM .content___3wK21 .title___1nNwF {
  width: 308px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #042044;
  line-height: 22px;
  margin-bottom: 7px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___kvP0A .bottom___1hudR .bottomCon___1CaIf .inner___3yWcM .content___3wK21 .desc___2i0zF {
  width: 308px;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
