.knowledge___3PG5w {
  z-index: 3002 !important;
}
.knowledge___3PG5w .ant-modal-content {
  height: 100%;
  border-radius: 14px;
  background-color: #ffffff !important;
}
.knowledge___3PG5w .ant-modal-body {
  overflow: hidden;
  height: 100%;
  padding: 24px 22px 20px !important;
}
.knowledge-title___3VfMo {
  top: 23px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 450px;
  display: flex;
  justify-content: space-evenly;
}
.knowledge-title-item___1iVHq {
  font-size: 17px;
  color: #1a1a1a;
  font-weight: 600;
  cursor: pointer;
  line-height: 23.8px;
  flex: 1;
}
.knowledge-title___3VfMo .item-active1___11Ska {
  color: #0cd7a6;
  position: relative;
}
.knowledge-title___3VfMo .item-active1___11Ska::after {
  left: 65px;
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 4px;
  background-color: #0cd7a6;
  border-radius: 27px;
}
.knowledge-title___3VfMo .item-active2___13SKf {
  color: #1890ff;
  position: relative;
}
.knowledge-title___3VfMo .item-active2___13SKf::after {
  left: 65px;
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 4px;
  background-color: #1890ff;
  border-radius: 27px;
}
.knowledge-title___3VfMo .item-active3___1iOJo {
  color: #ff7f09;
  position: relative;
}
.knowledge-title___3VfMo .item-active3___1iOJo::after {
  left: 65px;
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 4px;
  background-color: #ff7f09;
  border-radius: 27px;
}
.knowledge-header___3Ot_J {
  height: 24px;
  line-height: 24px;
  margin-bottom: 23px;
}
.knowledge-header-left___1cSai {
  cursor: pointer;
}
.knowledge-header-left___1cSai.subject-1___lSEN7:hover .text___2gXIJ {
  color: #0cd7a6;
}
.knowledge-header-left___1cSai.subject-1___lSEN7:hover .change___13DBv {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA0hJREFUaEPtmUtoE1EUhv+TxBcz0SJIRRAXos2k6KqrbsSFuBAXkmjNpIoIFgV1YV1oF1YRW8QXKiJFJbQyMyVoETeuREUEV0pp7VQQuxIEV5pJK5jOkXuTis9mkhIzAz2rhEzO/N953HPnDiHgRgHXj7oCNLzONBRUZZWzbtdYtYGsK4Bqmy8AtIL5lBNPn60Gos4AVjfAp4VwYj6Ri6fPVwpRVwAhVrHNHgJOis/MfCwfT1+pBKLuAEJs1DYvMtAphTMdduKpG14hfAEgxKq2eQ3AEVlOoI6clrrlBcI3AEUIow+gDpkIYF9e0/vLQfgKoNgTRoZA+0oQel7TrdkgfAdQyoQBkC6FE+10Yql7/4LwJQD4SUQd/2gBnASoQIRELpZ6+DcIfwIAaBweUJyFEYuA7QBNuu50crK5/dHvEL4FEEKXjmaXu+GCCWArgM/sUiLfnHr8M4SvAWRTT2RX0lcJsRnAJwpRIteUej4D4XsAIXTxmLEmQiQgWgF8CLGb/BJvf1mcGQGxqD24HuQazGhh8ARzODkZ3/2KlPdmI03xoZpwhELs2a/rlg+m9McJABsAvAWHkqTYVobAcnAE0AxS3xinEaJNARQPBveXT5vPyeYB6p2g+QzMZ2COEZgvoTkGcM5/J7llpW9H5+ypDg5cWtxHim0+LD40BNAID2a2Et0l+U8DheHyM9nEqm2dA7hLiicYTkxvDwrIj1UoalsXGHxcCGdCJh/T9wcB4pdlVLWNqwDNNHSfo+kH/Q7xxxxQx4ybIJoRft3RdF+vUH8dZMqYeYcIsoQIuJTTdFlafrR/TuLouDXAzHtkTwC9eU0vNrnPbNathDpuDYK5raiZzzhaWr6M8JPNvhdikDpu3gewo1ROXTlN7w0OgFA6ml2ohAtDBGwrlVNnXtMv+wXC0250xWhWnQoXhgBsKVZTZW9RagnrCUAIKJ1TCgh5gkEhHMg16bdrKc6Lb88AwpkymllJ4UWiJ8QRHzhEe/NNqbteblSrayoCECKWvLNWR77xEAMtUhShzYnp2VoJLOe3YgDhcNnI4NrpiCvKaaP47mh6VX7KifPye9U3VkcGNCyI9GCah53m+s2HqgG8ROd/XBN4gO+7o/vg2HbH0QAAAABJRU5ErkJggg==);
}
.knowledge-header-left___1cSai.subject-2___3X9WL:hover .text___2gXIJ {
  color: #1890ff;
}
.knowledge-header-left___1cSai.subject-2___3X9WL:hover .change___13DBv {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA0RJREFUaEPtmU9IFFEcx7+/XZGgDiKos1Z0CIKCOnXqEh2iQ3gINwSpkCApsJ3d7FAe0oiUMt1ZS0IqxEINySW8dAqKCDoFXYJOnWJmCYIuXVz3G292Vvqj7syyz50F5zTLe/v9fT/vvd97P+YJ6vyROvePmgI0pdm0LYp2JyGfKx3ImgLELL4ncATEDScltyqBqCmAkeEgiCHP+DUnKXeCQtQUQJmNZThM4rp6F+CKnZR0EIiaAyizhsV7APpd40Sfk5JJvxChAHAhMpwAcdmbiV47KY/8QIQGQJltS3NKBL2ucUGPY8pMOYhQAXjLaRpAj7uaIujOJWR+I4jQAbiJbXGWQLeXE6edlLxYDyKUABhkg9GMeRBxAHkCnbmkLK0FEU4AlQ+j3C4NmIegA4JfKCDupOTVvxChBVBGd42zOR/BHIATIH4ygs6cKa//hAg1gDLaMkkjuuxCHAPwXSLotBPyrgQRegA3qR9wD/KYU3UTgW9YQTzXLx+8M6PcThuO9p1j3LcSxSyAwwC+ChG3U/JRWi22RYhLWmxGQN+6BR+lvdIjOgEcBPCFgrgYFlcPDt/BQtKRglkx0hyC4GhIPAWzQczURRLXXSkRZBq2ZiDIaOnouzUDOkY1iObWDAQZLR19xS1ZBQkd4ro188CUGBkugejQHUyHPoGXpVJi0AvwRkcgbZrEWzeJYxZvExhQ76pAyplyRlvQKguv7kJGhqMgrnr6005Szlc5lha5v7bRmMUMUUxoAlO5pFzUErWKov+dA4bFhwCKxgX3HVNCvUOteZAZaT6BoLSExpyklJZWFceuOlLrnsRGhk9BnHUnQjBim+ImedieDUsJw+JzAF2uaeKmk5LSZURoOMrUQpQ2C4sCnPK22IGcKSOhcV+8FNn4ObDAxh82siBOesup3zZlvNz/Nqu9LIAy0jLJHZFlZAU47u1OfY7p/xZFJ4wvAGXAK/qypS8YFFzImfJYpzk/2r4B3Jm4S6OhEYvu1Whxdzpnm/LMTyBdfQIBKBPtY9xdiCLrfeJTWdTlmLKgy2A53cAASrB1gnsjBRfikPrtJKUinXLm/LRXHDiW5v6CYFiIT7U8HyoG8DM6m9Gn7gF+A+ze5YuOeiRWAAAAAElFTkSuQmCC);
}
.knowledge-header-left___1cSai.subject-3___2acoy:hover .text___2gXIJ {
  color: #ff7f09;
}
.knowledge-header-left___1cSai.subject-3___2acoy:hover .change___13DBv {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAwVJREFUaEPt2TtoFEEcBvDvy4Xk9iISbBRBLATBQiurNGIhFmIhdxIIKkEwKKiFSaEh3N6seSC+UBEJKiFKEhE9JI2VoIhgJdgIVlZWVja7a6L+ZWf3fOVxu3dcbhZu2t2d/X7z2JmbI1JemPL8aCpA7O5uwN1MtfCh1oZsMsB6A6AHIkU6/sVaEM0FFLM2yJIOLjhPx7uUFNFUgM5t58YBuRAhztHxridBNB0QIqwrAAZDBE/TcW/HRRgBiBA3AZyJEAN03LtxEMYAQkR2EuBAiJB+Ov50NYRRgGhOTAHSr4O3oY+2N7cawjhANJxmAPRFwQ9TeU9WQhgKQDtgBS1fAPAdYJ7KnV8OYSRA98IQupCz5kAcBOBCWKDjPv8fYSwgHErrNwALswD3Q+QrgDwd/8XfCKMBIaJrE/BzFsBeCL6gXfIs+q8rCOMBGjGa3YpFBogeCD6DKFB5b4NrqQCEPdG5HWgLvk67QXzSc0K57yhjXRvx7cepagtGjdclwXNxGlNA5AHuBPARzBQodu7PwpHgbYbcOkMpZksg9xgSKGEMmY7TbQkrXdvbW4C1be+lb2v1QKsH6myB1hCqswHrfpx6yyoLZ+uuqRkVdLZNUkrWPET/aEhjeVbZSthR+pepUoi80pNY7NwYIMNR+Bkq70haIL+/QmJblwEM6eDkFEvu8TQg/vmMim3dABBNaJmk8k+ajliyDoidvQOwEvwWlWf0F2rZhUzs3H1AKkPoKpUXDi0Dy4orsZSsBxAcDTNzgsqtTHKjGKtuJcS2HgHojRCKyg3/jDCorA4ITi1s6ymAQzqzyDAdf8Kg/NWPVcRGB2iVITgQBR+k8q6Zgoi1GxUb68BcGSL7wp5I9i9KI7GxAOFqHWz6FssgohMMnqBy7zUyXJy6YwNChD6nDOZETzQnjtHxH8Z5UaPuSQTQiFFrCxZR1kd8Yeml8h43KmC1ehMDNGKkcxsybQFil14llFdTPdXCxble84tlpGMHMplxiLyn4zdtfagZEKd11uKe1AN+AWvJ39u4nk/CAAAAAElFTkSuQmCC);
}
.knowledge-header-left___1cSai .icon___2_B2G {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #666;
  border-radius: 50%;
  margin: 8px 6px 0;
  float: left;
}
.knowledge-header-left___1cSai .text___2gXIJ {
  display: block;
  font-size: 14px;
  margin-right: 4px;
  float: left;
}
.knowledge-header-left___1cSai .change___13DBv {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAyJJREFUaEPtmT1oFEEUx9+7Oy4ELcTGIIiFIFhoZWUjFmIhFnIngWBkMbezeqiFsdAUXkRMEL9QkWPenIQoSURMEBsrQRHBSrARrKwkASsbmxz35C1zwY8kt3vHZWfhprpjZ9/8f/OfjzezCCkvmHL9kCiA53lb+vr6tmutv7TbkYkCKKU+AMABALhKRNfbgUgaoAIA4yKcmS8bY27GhUgUQMQGQTDBzFes8ItEdC8OROIAIlYpdRsARq3wc0T0KCqEEwDWiQfMfN4OJ2WMMVEgnAGwTmgxxAr3iGi6FYRTANaJKWb25DciDmmt59aDcA7AOjEDAEMW4oTW+sVaEE4CVCqV3NLS0hwzFxGx3mg0CsaYV6tBOAkgQoeHhzf19/fL8DkGAL8Exhjz+l8IZwFE6MjIyNZcLjfLzEcA4CciFrTWb/6EcBpAhJbL5YF6vT4LAIeY+UculytUq9X3TQjnAawTO7PZrEBI3vS90WgUa7Xax3CSt1pnXXnu+/5uRJTVaT8AfAOAIhF9wlKptC2TyZztklCOETdKZ0q8AgDsRcSviFjEIAhWNo4YjTlRVRxBpdQ4Mx90QlF8EdNRbIsfdgPf6AFsYGev2lTPgZ4DHfZAbwh12IEdv46Ssmaz2QsdR0omgJadWE46cmhIY3kZphIAIDdkcjv2Nk0UiPgunMRKqRsAMBbm14gzWuuTaQFZWYWCILjFzJes8CkiOp0GiL+WUaXUfQBoTmhNRGdch/hvH/B9v4qIoXBEfKi1dnqFWnUjU0o9BoDmELpDRM2h5Zwha+7ESqkncj1jnZjUWoeT3LWybiqhlHoGAINW9DUiCj9GuFRa5UJyZp5n5uPWiTGt9WSaAKBSqeQXFxcXAOCohRjVWt91BaKVA6HOcrm8eXl5eQERD1vhsb6idBM2EoAIsEmfONG8wfCJqNZNcVFiRwaQYJ7nDeTz+Xl7xSe50yljzNMoDXWrTiwAO5x21Ot1cUKu+ARi0BjzvFsCW8WNDSABlVK7mFnmxD75T0RtxWklLsrzthsulUp7MpnMBAB8TnJ/aBsgSu9sRJ3UA/wG6p/12zed+G8AAAAASUVORK5CYII=);
  background-size: cover;
  margin-top: 2px;
  float: left;
}
.knowledge-header-right___2UyHq {
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
}
.knowledge-content___3Klp0 {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.knowledge-content-left___2WaXb {
  margin-top: 46px;
  font-weight: 400;
  width: 100px;
  text-align: left;
  font-size: 14px;
  color: #1a1a1a;
  line-height: 20px;
  height: calc(100% - 46px);
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  /* 滚动条样式 */
}
.knowledge-content-left___2WaXb::-webkit-scrollbar {
  width: 5px;
}
.knowledge-content-left___2WaXb::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 10px;
}
.knowledge-content-left___2WaXb span {
  display: block;
  width: 94px;
  padding-right: 10px;
  margin-bottom: 32px;
  cursor: pointer;
}
.knowledge-content-left___2WaXb span.subject-1___lSEN7:hover,
.knowledge-content-left___2WaXb span.subject-1___lSEN7.current___1wF2q {
  color: #0cd7a6;
}
.knowledge-content-left___2WaXb span.subject-2___3X9WL:hover,
.knowledge-content-left___2WaXb span.subject-2___3X9WL.current___1wF2q {
  color: #1890ff;
}
.knowledge-content-left___2WaXb span.subject-3___2acoy:hover,
.knowledge-content-left___2WaXb span.subject-3___2acoy.current___1wF2q {
  color: #ff7f09;
}
.knowledge-content-left___2WaXb span:last-child {
  margin-bottom: 0;
}
.knowledge-content-right___CJ05U {
  margin-top: 44px;
  width: 753px;
  height: 100%;
  margin-left: 20px;
  padding-bottom: 44px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  /* 滚动条样式 */
}
.knowledge-content-right___CJ05U .load-more___1oHPJ {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00ba8d;
}
.knowledge-content-right___CJ05U .load-more___1oHPJ i {
  margin-right: 10px;
  font-size: 20px;
}
.knowledge-content-right___CJ05U::-webkit-scrollbar {
  width: 5px;
}
.knowledge-content-right___CJ05U::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 10px;
}
.knowledge-content-right___CJ05U .box___W6eJ8 {
  column-count: 3;
  column-gap: 16px;
  /* 列间距 */
}
.knowledge-content-right___CJ05U .box___W6eJ8 .load-more___1oHPJ {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00ba8d;
}
.knowledge-content-right___CJ05U .box___W6eJ8 .load-more___1oHPJ i {
  margin-right: 10px;
  font-size: 20px;
}
.knowledge-content-right___CJ05U .item___1p4GI {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 14px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 16px;
  text-align: left;
  cursor: pointer;
  break-inside: avoid;
  /* 防止元素被分割 */
  margin-bottom: 16px;
  /* 元素间距 */
}
.knowledge-content-right___CJ05U .item___1p4GI p {
  font-size: 13px;
  line-height: 20px;
  color: #1a1a1a;
  margin-bottom: 0;
}
.knowledge-content-right___CJ05U .item___1p4GI.subject-1___lSEN7:hover,
.knowledge-content-right___CJ05U .item___1p4GI.subject-1___lSEN7.current___1wF2q {
  background-color: #e7fbf5;
  border-color: #e7fbf5;
}
.knowledge-content-right___CJ05U .item___1p4GI.subject-2___3X9WL:hover,
.knowledge-content-right___CJ05U .item___1p4GI.subject-2___3X9WL.current___1wF2q {
  border-color: #e8f4ff;
  background-color: #e8f4ff;
}
.knowledge-content-right___CJ05U .item___1p4GI.subject-3___2acoy:hover,
.knowledge-content-right___CJ05U .item___1p4GI.subject-3___2acoy.current___1wF2q {
  border-color: #fff2e6;
  background-color: #fff2e6;
}
.knowledge___3PG5w .cover___3wQgL {
  width: 100%;
  height: 35px;
  background: linear-gradient(to top, #f9f9f9, rgba(249, 249, 249, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 0 0 12px 12px;
}
