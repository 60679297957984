.ask-inner___2RMuk {
  line-height: 30px;
  font-size: 15px;
}
.ask-inner___2RMuk mfrac * {
  font-size: 16px !important;
  font-family: 'PingFang SC', '\5FAE\8F6F\96C5\9ED1', 'Microsoft YaHei', '\5B8B\4F53', sans-serif !important;
}
.ask-inner___2RMuk mfrac mrow[data-mjx-texclass='ORD'] mn {
  font-size: 12px !important;
}
.ask-inner___2RMuk math {
  white-space: normal !important;
}
textarea {
  resize: none;
}
.ask-list-box___19Xk2 {
  padding-left: 9px;
  padding-right: 5px;
  padding-bottom: 60px;
  padding-top: 36px;
  overflow: auto;
}
.ask-list-box___19Xk2.share___XDVxD {
  height: auto !important;
}
.ask-list-box___19Xk2.share___XDVxD .ask-ques___2qxND,
.ask-list-box___19Xk2.share___XDVxD .ask-ans___aqN2J {
  padding: 28px 10%;
}
.ques-input___1HTQY {
  width: calc(100% - 80px) !important;
  padding: 10px 15px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  vertical-align: middle !important;
  overflow-x: auto !important;
}
.ques-input___1HTQY::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
.ques-input___1HTQY::-webkit-scrollbar {
  width: 5px;
}
.ques-input___1HTQY::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ques-input___1HTQY::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
.ques-input___1HTQY p {
  margin: 12px 0 !important;
}
.ques-input___1HTQY:focus {
  outline: none;
}
.control-icon-btn___2-jaU {
  border: none;
  background-color: transparent;
  outline: none;
}
.control-icon-btn___2-jaU .control-icon___3LYA4 {
  color: #c5c5d2;
}
.control-icon-btn___2-jaU .control-icon___3LYA4:hover {
  color: #fff;
}
.control-icon-btn___2-jaU .diancai-icon___IkTq- {
  transform: rotate(180deg);
}
.control-icon-btn___2-jaU .icon-shan-dian___i1Kuf {
  font-size: 16px;
}
.dialog-box___1DlEg {
  margin-bottom: 40px;
}
.dialog-box___1DlEg .ask-ques___2qxND,
.dialog-box___1DlEg .ask-ans___aqN2J {
  position: relative;
}
.dialog-box___1DlEg .ant-checkbox-wrapper {
  position: absolute;
  left: -30px;
  top: 8px;
}
.dialog-box___1DlEg .usr-icon___1sWJf,
.dialog-box___1DlEg .usr-icon-gpt___2QV9s {
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 9px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
}
.dialog-box___1DlEg .usr-icon___1sWJf {
  border-radius: 14px;
  background: linear-gradient(144.94deg, #7cf0f0 -7.27%, #94f6ab 55.01%, #adfd66 117.71%);
}
.dialog-box___1DlEg .usr-icon___1sWJf .external-icon___3T80T {
  width: 100%;
  height: 100%;
  border-radius: 14px;
}
.dialog-box___1DlEg .usr-icon-gpt___2QV9s {
  border-radius: 14px;
  background-color: #040000;
  text-align: center;
}
.dialog-box___1DlEg .usr-icon-gpt___2QV9s img {
  width: 26px;
  margin-top: -3px;
}
.dialog-box___1DlEg .usr-icon-gpt___2QV9s.chatgpt-img___2VEf6 {
  background-color: transparent;
}
.dialog-box___1DlEg .usr-icon-gpt___2QV9s.chatgpt-img___2VEf6 img {
  margin-top: 0px;
  width: 100%;
  border-radius: 4px;
}
.dialog-box___1DlEg .ask-ques___2qxND,
.dialog-box___1DlEg .ask-ans___aqN2J {
  margin: 0 auto;
  width: 70%;
  padding: 9px 2px;
  min-width: 788px;
  min-height: 80px;
  box-sizing: border-box;
  line-height: 36px;
  font-size: 16px;
  word-break: break-all;
  text-align: justify;
  display: block;
  justify-content: center;
  transform: translateX(-17px);
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 {
  display: inline-block;
  width: calc(100% - 54px);
  position: relative;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .edit-wrapper___fgu42,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .edit-wrapper___fgu42 {
  min-height: 90px;
  padding-bottom: 38px;
  background-color: #e7fbf5;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-toolbar,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-toolbar {
  border: 0 !important;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck-editor__main,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck-editor__main {
  overflow-y: auto;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-editor__main > .ck-editor__editable,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-editor__main > .ck-editor__editable {
  border: 0 !important;
  background-color: #e7fbf5;
  caret-color: #0cd7a6;
  padding: 0 41px 0 16px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable),
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .edit-wrapper___fgu42 .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: 0 0 0 0 !important;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .editor-ctn___3SY8x,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .editor-ctn___3SY8x {
  padding: 16px 41px 10px 16px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 6px 31px 6px 16px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV {
  border: 1px solid #0cd7a6;
  color: #0cd7a6;
  border-radius: 27px;
  height: 26px;
  padding: 0 16px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV .mathPop___1xa1J,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV .mathPop___1xa1J {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAYAAABVsFofAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHNSURBVHgB7dpBSgJRHMfx35upFCzyCNJOaFE3GDpBtIuIzAt0g+omDUTWthNkJ7AoslXZBcpFgeXov2dLk//iTTB/6PcBN28hztd54vxnACIiIiIiIiIi0lQ7J9Vy56QGIyIYUXm8OMhKpee5cum50m09LT20NlEwBwMq92drLnadqeW3ucHnSn99v4+CmDhzXITGjOXqcH6h0LPHRByBq8IgM785FjGOgnEUjKNgHAXjKBhHwTgKxlEwjoJxFIyjYBzFn8xzFu9OE8TRpjgsI4hL/Aep/V6XtgA9BHBjvGTRVzqo7/cQKHecxe75kT+IY9jk24423uu7bQTIva0E0oBd/suPDhEoV5xy97w2eztY4hIEyhVnUN/u+VPnDaZJG4FybyuHrImfvZ3rpQl/X8FrhqiJ4GP7A5PtFWOcIJBDtOePJZle97PlFBhfI4zMD4aX/fVG8N0LG7dmHlqpc9ibWhYZSfNjdSdFQfgnUME4CsZRMI6CcRSMo2AcBeMoGEfBOArGUTCOwnacgi+LbTwTCJk5VojiuNBBmo1nAsdIMTX0mtx1GIrcokAm4viZzY2TbMsXmTxu67vIFUay9TOGJSIiIiIiIqJ/6Bt/lYzU6o/KLAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV:hover,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV:hover {
  background-color: #0cd7a6;
  color: #fff;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV:hover .mathPop___1xa1J,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .mathpop-btn___13IMV:hover .mathPop___1xa1J {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEcSURBVHgB7dphDYJAGMbxBxMYgQhGwATaQE1gBKsQwQZiAiOgCbTB68PwmzAPpuf5+vy2mxtD55/DmzAAEREREe/MLMc/YOiW42qtmmMJrxg3s2dN/BSRTBDXumNbExttlmMHR5vJPrGDv07B3inYOwV7p2DvFOydgr3LhuzM69aCLwuMv+opOPKO7RXHGeNcOMosy854J8buLG1FSEfwDPMDa3TPTioqzvL81U5BwdbecKuROAa/7AlatB6/jxvSVoXsNGSV3iBdzWQEfb+hq3SOdqUda9Xz/pLjiPH2PAvTOwN5wMqeFXaNSPRPyzsFe6dg7xTsnYK9U7B3Cv6w28Dtv826n+KpzfMzW4xbcpwesYfmIEBERETEozs4gTgOP+pCtAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .ensure-btn___dZhlJ img,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .ensure-btn___dZhlJ img {
  width: 26px;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-wrap___Qr_I8 .operate-ctn___cjqYa .ensure-btn___dZhlJ .mathedit-cancel___W815a,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-wrap___Qr_I8 .operate-ctn___cjqYa .ensure-btn___dZhlJ .mathedit-cancel___W815a {
  margin-right: 24px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk {
  white-space: pre-wrap;
  display: inline-block;
  width: 100%;
  padding-bottom: 0;
  color: #1a1a1a;
  word-break: break-all;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk em,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk em {
  display: inline-block;
  width: 30px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o {
  position: relative;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o:not(.no-use-content___37rSA):hover,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o:not(.no-use-content___37rSA):hover {
  background-color: #e7fbf5;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o:not(.no-use-content___37rSA):hover .answer-recommend___Lk5mp,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o:not(.no-use-content___37rSA):hover .answer-recommend___Lk5mp {
  display: block;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa {
  margin: 0 30px 0 16px;
  text-align: left;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa::-webkit-scrollbar,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa::-webkit-scrollbar-thumb,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .line-content___1gzEa::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 5px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f {
  position: absolute;
  width: 100%;
  max-height: 220px;
  min-height: 108px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 80px 0px #e4e4e4;
  border-radius: 14px;
  z-index: 10;
  overflow-y: auto;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .origin-content___2fp6b,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .origin-content___2fp6b {
  position: relative;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .origin-content___2fp6b .line-expand___1V1ve,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .origin-content___2fp6b .line-expand___1V1ve {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .current-content___3pL-J,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .current-content___3pL-J {
  background-color: #e7fbf5 !important;
  border-radius: 14px 14px 0 0;
  padding: 12px 39px 12px 21px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .remommend-item___2yqcl,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .remommend-item___2yqcl {
  padding: 12px 39px 12px 21px;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .remommend-item___2yqcl:hover,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .remommend-item___2yqcl:hover {
  background-color: #f9f9f9;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .no-recommend___3W2A7,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .no-recommend___3W2A7 {
  display: block;
  text-align: center;
  padding: 30px 0;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .ant-spin-spinning,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .answer-recommend-panel___nFm1f .ant-spin-spinning {
  padding: 30px 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 {
  min-height: 90px;
  max-height: 144px;
  padding-bottom: 38px;
  background-color: #e7fbf5;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-toolbar,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-toolbar {
  border: 0 !important;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck-editor__main,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck-editor__main {
  max-height: 90px;
  overflow-y: auto;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-editor__main > .ck-editor__editable,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-editor__main > .ck-editor__editable {
  border: 0 !important;
  background-color: #e7fbf5;
  caret-color: #0cd7a6;
  padding: 0 41px 0 16px;
  overflow-y: hidden;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-editor__editable.ck-focused:not(
                .ck-editor__nested-editable
              ),
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .edit-wrapper___fgu42 .ck.ck-editor__editable.ck-focused:not(
                .ck-editor__nested-editable
              ) {
  box-shadow: 0 0 0 0 !important;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .editor-ctn___3SY8x,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .editor-ctn___3SY8x {
  padding: 16px 41px 10px 16px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 6px 31px 6px 16px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV {
  border: 1px solid #0cd7a6;
  color: #0cd7a6;
  border-radius: 27px;
  height: 26px;
  padding: 0 16px 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV .mathPop___1xa1J,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV .mathPop___1xa1J {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAYAAABVsFofAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHNSURBVHgB7dpBSgJRHMfx35upFCzyCNJOaFE3GDpBtIuIzAt0g+omDUTWthNkJ7AoslXZBcpFgeXov2dLk//iTTB/6PcBN28hztd54vxnACIiIiIiIiIi0lQ7J9Vy56QGIyIYUXm8OMhKpee5cum50m09LT20NlEwBwMq92drLnadqeW3ucHnSn99v4+CmDhzXITGjOXqcH6h0LPHRByBq8IgM785FjGOgnEUjKNgHAXjKBhHwTgKxlEwjoJxFIyjYBzFn8xzFu9OE8TRpjgsI4hL/Aep/V6XtgA9BHBjvGTRVzqo7/cQKHecxe75kT+IY9jk24423uu7bQTIva0E0oBd/suPDhEoV5xy97w2eztY4hIEyhVnUN/u+VPnDaZJG4FybyuHrImfvZ3rpQl/X8FrhqiJ4GP7A5PtFWOcIJBDtOePJZle97PlFBhfI4zMD4aX/fVG8N0LG7dmHlqpc9ibWhYZSfNjdSdFQfgnUME4CsZRMI6CcRSMo2AcBeMoGEfBOArGUTCOwnacgi+LbTwTCJk5VojiuNBBmo1nAsdIMTX0mtx1GIrcokAm4viZzY2TbMsXmTxu67vIFUay9TOGJSIiIiIiIqJ/6Bt/lYzU6o/KLAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV:hover,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV:hover {
  background-color: #0cd7a6;
  color: #fff;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV:hover .mathPop___1xa1J,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .mathpop-btn___13IMV:hover .mathPop___1xa1J {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEcSURBVHgB7dphDYJAGMbxBxMYgQhGwATaQE1gBKsQwQZiAiOgCbTB68PwmzAPpuf5+vy2mxtD55/DmzAAEREREe/MLMc/YOiW42qtmmMJrxg3s2dN/BSRTBDXumNbExttlmMHR5vJPrGDv07B3inYOwV7p2DvFOydgr3LhuzM69aCLwuMv+opOPKO7RXHGeNcOMosy854J8buLG1FSEfwDPMDa3TPTioqzvL81U5BwdbecKuROAa/7AlatB6/jxvSVoXsNGSV3iBdzWQEfb+hq3SOdqUda9Xz/pLjiPH2PAvTOwN5wMqeFXaNSPRPyzsFe6dg7xTsnYK9U7B3Cv6w28Dtv826n+KpzfMzW4xbcpwesYfmIEBERETEozs4gTgOP+pCtAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .ensure-btn___dZhlJ img,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .ensure-btn___dZhlJ img {
  width: 26px;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .ensure-btn___dZhlJ .mathedit-cancel___W815a,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .line-ctn___3FF-o .operate-ctn___cjqYa .ensure-btn___dZhlJ .mathedit-cancel___W815a {
  margin-right: 24px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk .answer-recommend___Lk5mp,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk .answer-recommend___Lk5mp {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk::-webkit-scrollbar-track,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: transparent;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk::-webkit-scrollbar,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk::-webkit-scrollbar {
  width: 5px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk::-webkit-scrollbar-corner,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk::-webkit-scrollbar-corner {
  background-color: transparent;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk::-webkit-scrollbar-thumb,
.dialog-box___1DlEg .ask-ans___aqN2J .ask-inner___2RMuk::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #dfdfdf;
  border-radius: 10px;
  background-clip: content-box;
  border: 6px solid transparent;
}
.dialog-box___1DlEg .ask-ques___2qxND .btn-box___1Hfkf,
.dialog-box___1DlEg .ask-ans___aqN2J .btn-box___1Hfkf {
  position: absolute;
  bottom: 18px;
  right: 18px;
  height: 24px;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW {
  position: absolute;
  bottom: 10px;
  left: 11px;
  background-color: #fff;
  border-radius: 37px;
  height: 40px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW .comment-title___344ol,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW .comment-title___344ol {
  color: #000;
  margin-right: 30px;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW .comment-select-item___1QIj_,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW .comment-select-item___1QIj_ {
  background-color: #f9f9f9;
  border-radius: 29px;
  padding: 5px 19px;
  margin-right: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW .comment-select-item___1QIj_.also-so-so___3h_BA,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW .comment-select-item___1QIj_.also-so-so___3h_BA {
  margin-right: 108px;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW .comment-select-item___1QIj_:hover,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW .comment-select-item___1QIj_:hover {
  color: #1ad9ab;
  border-color: #1ad9ab;
  background-color: #fff;
}
.dialog-box___1DlEg .ask-ques___2qxND .comment-ctn___3pWuW img,
.dialog-box___1DlEg .ask-ans___aqN2J .comment-ctn___3pWuW img {
  width: 20px;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ques___2qxND .generate-action___1rSh6,
.dialog-box___1DlEg .ask-ans___aqN2J .generate-action___1rSh6 {
  position: absolute;
  bottom: -33px;
  left: 16px;
  color: #1ad9ab;
  font-size: 14px;
  cursor: pointer;
}
.dialog-box___1DlEg .ask-ans___aqN2J {
  margin-bottom: 15px;
}
.dialog-box___1DlEg .ask-ques___2qxND {
  margin-bottom: -15px;
}
.dialog-box___1DlEg .ask-ques___2qxND .icon-copy-ask___UEMNA {
  display: none;
  position: absolute;
  right: 14px;
  bottom: 4px;
}
.dialog-box___1DlEg .ask-ques___2qxND .ask-inner___2RMuk {
  font-size: 15px;
  font-weight: 600;
}
.dialog-box___1DlEg .ask-ques___2qxND:hover .icon-copy-ask___UEMNA {
  display: block;
}
.dialog-box___1DlEg .answer-wrap___1RsiS {
  display: inline-block;
  width: calc(100% - 54px);
  position: relative;
  padding: 10px 0px 42px;
  background-color: #f9f9f9;
  border-radius: 14px;
}
.dialog-box___1DlEg .answer-wrap___1RsiS a:focus {
  outline: none;
}
.dialog-box___1DlEg .composition-ans___2ydF4.evaluate-report___1xG78 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-box___1DlEg .composition-ans___2ydF4.evaluate-report___1xG78 .evaluate-content___33mvG {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-box___1DlEg .icon-copy-ask___UEMNA,
.dialog-box___1DlEg .icon-copy-ques___1Oirt {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK3SURBVHgB7Zo7bttAEIaHkqBClcqU9AliF6qTnCA+glJIUCfmBKHLVJEBFYLU6AjxCawDCEh8gy3TRZUKQo/MJI5BULJ3SS4fWP8fQNBeExDxaXf4mztEAAAAAAAAAAAAAE7hUcGMRiOfTz6VyH6/3ywWi59kgUIEBUHQ3W634+PxGHie16VqUHx8bzQat7PZTFFGrAuSGXM4HO6p5FnzAoolfcgqyaqgGsr5j4qi6Gq5XG4oJQ2yCMsZU/3kCH673Q4oAy2yy/WZsRsWt6IS4bp3zcc4MSy/h5QSa0tsMBhc8k39SAxP5vP5Z6qA4XAY8ulLfIxr0UXaWmRtifET6+RpxTd0RxXxzKz1KSVWa5CL5KpB8RDI4ext8u+73e6SryGb2AyBJqQWFA+BPI2flhXXn5NreYl942vIJvI5XF8UWQiBJqRaYjJjWI4U4rDChCz4fMgXdP84iwvDWFBNQ+Dfe+r3+4V9WcaCXAyBJqSpQc6FQBOMBEkIpNPZIyEwpPJZcZGW/6niIbArJaCIgm20xFwNgSYgKGqAIA0QpAGCNECQBgjSAEEaIEgDBGmAIA0QpAGCNECQBgjSAEEanBXE76s+Jsdky4hSYntvvjLie3D8Qu0dn5LvqVWW/TRnBOn24PitaKY3oK+lBqlmszmhDLwGQbk6zJwVxEtKCvJNp9O5yrPb4UwNkl4BPh7k51arpWxtATkjiGvMA0tZkWUQFDUYCZIpmxzjR+p7qghbIdAE4x5F3n7+fablZVL2DutjCAwTw4q3wS+oAIxrEMu5pURTJP3r0Smss8KUrCHQBOMaFEWRBC1F9SNzCDTBWJB0qUvgonpJyhUCTWimuXi9Xm96vd4dLytecd4bHqqkDU9CIH/+Vw6Bn6bT6S8qkFyN5NI3dK41pkhshkAAAAAAAAAy8wf4kw/wcTZwcwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
  cursor: pointer;
}
.dialog-box___1DlEg .icon-copy-ask___UEMNA:hover,
.dialog-box___1DlEg .icon-copy-ques___1Oirt:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALYSURBVHgB7dtPbtNAFAbwb2yniZQscgR3gwhCIj0B9AR0hfgjFj1BwgkaTkB6gixQyxZOQG4QEIVsfQOyaEXauH54XCHFscqMnXFsjd5v1TpSO/pqTz5lXgHGGGOMMcYYY4wxqwiUrDWb+J7n+dghEs7i6vGbbzCglIC6s0k3bLUGIBrGv6GLChAQCOF8DunP6bJ3HKAg4wG15hPfRfNr/IN91IAM6hbXh0VDMhpQ3cL5R4bUWF4fLA6OF8jJgUGeaA7qFo4k1xTu7Q1RgAeDiHCUvSXpPRBNsUvkHEGIQeqak3w/Qk7GHrH2xaQv3OYs/dOd8eXDl+9Qgc7Ps1Ecysn6tRBif9l7FSAHY4+YIC/7bkWrL6hKlL1rPYQ+cjK6B9loqz1ovQRSo/FEUJR6ncjtd358hEkmS6CO3AHdVwI3w0muCXyA58IkuWm25+dGSqCOXI+Y7DmrltyIaVRVQ5aSKkHRUHYuuSaUSDugOpZAuRa5JnlXoyTaAdlYAnVo70E2lkAdWgElJXDz7rkrgSPs3jQugYuNEthtzT/5eUugDq1HzNYSqIOLogIHpMABKXBAChyQAgekwAEpcEAKHJACB6TAASlwQAockAIHpMABKdgbUMN9vnmJ3Gbu4QWjZ/NVSp3Bed7T5Fhq/XUguHrwIvd5mjUBpc/gKPs6UaFPQK0J6H+SIar4M3QUYP0mfTdhJg6LfqBvb0CEhTyWkpNl25x22LNJC2coaPVdfh0iDJaPzJzZ27NJx+Fc9t5OYRgXRQWtgELPCzIXI+cZqmKoBOrQnlHs/Dr/nRl5ERhjdbvbE1ZZAuX4zZqkBPZe76ME+nsQ0Wncxk7S1zCMy1lpkxX3LCRzpWgJ1KE//nJzM5Z/KdSMXFNYsATqyDUGLCcoXFDN/s2geAnUketdTC5ELkg+bpXeTYZKoI6tBsnbF2d94UY7nVVMSmDJg5uMMcYYY4wp/QUJTgEdcm+YkwAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .icon-zan___34UyV {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ+SURBVHgB7ZtbThsxFIZPLkIIHpquoO4KICsgrKC0GyBIgHhjWEHJChr6gLhJDSuAHZCsIOlb35iuoOkDCHFJ+rsapBnPJGM7Hjut/EmjZIydmfxzfI7PcSDyeDwej8fj8Xg8Ho9tSjRH7O3tMbzwgxYXFwftdntIjnEuUBAEtfv7+/3xeByUSqVa/G8476K9c3Z2dkmOcCoQLKbx8vJyJQojwoXCsXVychKSZZwJtLu7u4GXK4Uh4ePjY73T6ViddmVyQORrvpAabGFh4TNZxolAo9HokCJn/Ap8DbeMFqbcR37gfZbfCfi0JItUyQ1rwnlYqVTWBR9zjWnIzxNWA/H41OySJaxb0Pb2doME6wFHWQ4Y0esQL4l2OOs1soh1gcrl8qrYhunVnTKkJ5wzsoh1gWABH4Sm8Pz8fDBlyBtyiFWBms1mDdbSiLdBsO85w5jQf0AWsSoQHHFDbINgE9dC0XIgMSURAfMENYptgVJrH/ik3qT+iFiNjM+4JotYCfNRvvWN0tOlOy19yPBXhP5dsoiyQFH+tIKbfyvTH/3e3d3dbWTlW5guBznDxYgXYm10SPIMYKGDWXI46VyMWwG+KE8sG2SGVrTOyYT7Hwh4S2ZoI49r6eRx0j4IU6RtShx88ctp4nAeHh5MJqUB8rgbHkVJESmBomiySTMS5VsHFxcXzby+/GlzIckcqxApIEWkBHp+fmakCRclWim3lpeX38Ny2rJjcd0AY48iYU2wr2pF2lEMzm89K6LAiXK/wWL9eqenpxukQeQzguhQZmdnh7uF/VhTDaVc7vi7sp9hYx30i9zRERsQgRkpUIRALH4CP/KbHIFrM7ENC82QFCjcgmDiznYmIEaqcoDoqJTLGRVoggN0uXWzIpwPVddCRgWCA0wJZDAC6cDiJzqVAKMCwQGmBEIUc+KkI2tOTDE8rB4pYlQg3EBKIDw1J066Wq2m/A/upU+KONnVsEFWaRdR7ScpYlQgPCEmtmHaufJBokDDnNJuJoVbEEKtK4ESEUy3VFu4BRnOylUwUqot3IJs76VzkINlOeiQNCg6ijmxnqwUg1cWSQPTU2wuBIIYDbFNt5ZteoqJm3yuBBJTDO29tEItyFWiiim2KtyH8vrnlf9uocjLw+KDytn7n4ppJ82EJutpxtPTUyqC6Trov2OpWKwnqiZqQHFMC8TiJ46qiTPXgOIUakGOnDSj5D1oWw/HmEDzUE00VQOKY0ygeagmmqoBxTEm0DxUE03VgOJI/XiB//ASF79JDCyVOrCQ+MUZCdvTGX0Kg699IMamsAYaYidX6lcok5DaWc1ytvjizbxxMn1MgWvx+0y04fyaZkRqivFKnOPdCR1uIVCLZkTaB8F8t+jf4RYP9JOJf36pyHbs9/s/6vV6D76IkeXfKucwjr0PcXxdWlraOj4+Dsnj8Xg8Ho/H4/F45pM/fceodqxtQ4oAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___1DlEg .icon-zan___34UyV:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASWSURBVHgB7ZpdbttGEMdnyJUlIyrqnqDsSwEFAWz3AlFOELUPBeqgTXQCKyeodYI6J1CCwumjfYPKJ7CKNtGj1RNURS2EiklOdy0nIHclm6SWuw6wP0C2d7zUx1+zszOzBHA4HA6Hw+FwOBwOh2kQ7hCNs0HAGAvE3yyKRtPd7hQsY12grbPBVtRo7ANRj7+brex/aUiAL2etvVdgCasCNf/8tQ2+f6wKI0PDCN53w1Z3AoaxJtBnb193COE473wCmNTC+a7pZeeBBRrjQZAg/FLkGv5NBtHm5s9gGCsC+VQ/EB84YyTgnkF9pOhb8SACNe5Q0muO+bI0CAMbIDxMD8XyiREfha29Scp80hwf8TFmvYa8Dv85BEMY9yARmJd4z4uw9cNEnnvRenIgxMsYPXwIBjEuEPn+jmJkbLj6AjiVxgEYxLhAiPQ4PRYeMvv6+9HK+QCfg0WMCiSSQv6R22kbEv5xy2VBdkgjMIjRIB3X/bZsI59W5kIiHeC/skuS4DZBtWLUgxJkSu4TJ3i6ar4fb7QVIyYnYBAjHnRVb23WB2qApaG0tWdADx/LtovWj0MwSGGBxDZNtdo2xvEXeeaTB19GhB0ujlJvkQ/Pb7wWYSddC4mA3nxzdAA5QcTRJc5H69RwuWuxRdW9cSwH2fJQX+Q5q/7bGP8WMKBz0AF6h+zdu36ZOi53DLqs1w91icM94dVN4ggaYaivKOUlCl/ivy920WLkEkjsJojwFNaF11u8xnrO+zvPbpsqvu2l9Vj5196JNjZ6UJBcArFo0eUrxaIIHYolxebzr2b39w7zXlqbz3u8kfZi8Rwa8HC/qBetsYvFj5btKPfGr8+ztRaeXrT2OlCC65jRu34Upvn26JBH6v2UicdRJvKqYd7nqDwP4jvRP2AJSt6/VGyxF0ABtAskV+pIyb9gCc/zA9mGfjKBAlSfSeuKH2VemtTOAQujQrWcVoGWBUBCtHd0g7gtWaZFcyGtAoWNhrpDJGTzbCvIDot3ArQK5MehIpDHfCtB+sqbUeoEJHAKBdEqEBJTlxhdWgnSEWNK/EHfP4OCWDnVMMGy1m7ieX9DQbQKRKjmGOTXbcUgWaDpTa3dVVTuQXEcWxGI52PSDlauVas3BqF64qC1Ki8C6mnVVu5BNm5huffXQIk/BDiBEugVSL1Lw4r3LC0xMLa/xPi3tJUd2xGIQD09KdvL1huDpEM+HpNsxZ9taVz6LE3zEqPsEiNLZQahlEFj4fznA3bu7qiQ68NGaamLjmY5dMegIGvwjJcZtSUtjrIBWlDpNm+jm6ijB5RGd5AOMmMb3UQNPaA01SaKdrqJQXa43t0g2gS6C91EXT2gNNoEugvdRF09oDTaBLoL3URdPaA0pfMgAva0+eao/XGMGMhzEqIOn7MLJljUgfLxeKkeUJpcAhFGUwQfsu+HnvGj3NRYRZ5jGp6XncCa5Fpiswfdkc3zrTLwQvk8BujDmuSOQQhRFz4RhDgQ03fL7r0uSm6B/rv/04m4YQHWqGsqgj48roQB6tfC+TezB0/Wij0Oh8PhcDgcDofDUSH/A41qn9LJOnLQAAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .icon-zan___34UyV.active___2aCLv {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQESURBVHgB7Zs/TBRBFMa/N0tpcWqhnSTai7aagJV0EmNjZSisLIBWQ4RgYScNtVDZYKDUyjPR2rMnATssTK7Ajpvn2z3+7O0sl529uZnzMr/kDu4xc7d89+bNe293gUgkEolEIpFIJBKJ+IYwQvBKYxIJJrMXHbRopd1GYIILJKI0MKEW5NdFeTQKf21CqU16+WcLgQgqEK81ZkBqB4YwxsgmjnlePOoAngkmEK9dnQPxTvUJOEBH3/G97BQCkMUaxe+sJpHEpgn1Gp4J4kH85sqm/HjWa8SRHM1HOaS9E8M9eZo1J+sHtNxuwhMTCAFhWgQ5h3EI5iUgOcwN+gbSv1EUEmpOnprwhPcllgVmPtnKTyHeBuXFOR2sNjPxesdOwyP+YxBhyrBp+tlnRqvwBpPwSIAgTY96XqYeQmrv4uF8CQHxKlCWFIJmeoyEvf6TcL1gaMEjfj0oSWYMG9P3C8crEYfoVmF8v+XoHL8CleU+rC/2CObbplHvwiNetvluvUXvjd0rXS5lu9cZ+n4xVfOZA2WfB0uybVqp29C4XHHKDfmUNHcpq7eey1beJ0DrD/J8HoOYD8RoUbhSC51Oa5AarrJAJ16wYwTZ+mxlec6FHyjCqEwgF6zjWK/WqeOqx6AJte5QnM99xUkhKT3csSjH/6W7i9pRSaCsuDRS/hpk9RZviDhvK4xOBfoEd0whUYuWcyp6UFIMrhakonRzF4kd6il0sl19rtoQT9ruvocDCAu2XjTILrYk/0DJFi1xg3KBlaRU4GQZ9TiCFpGAjZ7itipKv5B5T3KWhnzZaanTrPwWGD4uY4kdLLHOsCWTsMC9QFQoDTigQETXTFvnABaMtwdpvmXYOrCq5VwLZFbexAE9yBCobZsLuRWIUdaaCBmDBu4EuBVI6RKB1F+E4VJJJ+ArLHErkC7zoEBLjLUZf0j/gCVBTvt4QfFNw0b4BUscL7GSbVWHikFkBuhX7cAxqJRQS6y4g9Vr1Trexfi6YaMkjECOWrXjmShSWYCWc/s1GHaiGMp7zFjYr/fdh/EUSLNxcrJuL9utQMWTfEyBPAhOAnSKYw+iXoFIhyozijHIOv85ZfwSRZXVX4WlTk3UxHWxWtjmQyyxkgy6ZoBOGbYH+RfIQQ8oj+MgPQLdRAc9oDzj50GOrwZxKdAodBOd9IDyuBNoFLqJjnpAedwJNArdREc9oDyDnDh8KFtG/vods5LPLuUtOehhoMSDmYqXDdfqAeWpJpBCu+TM5myFa0OqjHEDl9oGvtiq0hLrfgsc/M4bK5j30dGrGJDqMYh5Hv8LqTiKH7u4+aWyQNIu2E1vA8juvBkt+OxB2JfnVXT47qCxJxKJRCKRSCQSiUSGyD9H/k6b2HhqvAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .composition-ans___2ydF4 {
  display: flex;
  margin-bottom: 5px;
}
.dialog-box___1DlEg .composition-ans___2ydF4 .evaluate-title___3DUnY {
  max-width: 200px;
  font-weight: 500;
}
.dialog-box___1DlEg .composition-ans___2ydF4 .evaluate-content___33mvG {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
}
.dialog-box___1DlEg .icon-cai___ZXgbY {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARCSURBVHgB7Zu7ThtBFIYPvkgUSDFvsGW6WJgiHUuXdH4DjASG0jwB5glwOi5Ccbp0hjIVTpcCw6ZLuenSxSmQHItL/iOtJXt27ezMrmcWmE9C9oxn18s/c86cOTMmslgsFovFYrFYLBaLbhYoJRqNRun29tbJ5/MlyhiLi4teq9XqkwKJBdre3i7j5XBhYcGlbNM6OTnZI0nylIB6vV7FSwfivKbs87ZSqZR6vd4XmYuUR9Du7q5zf39/A3EyZ1KzGA6Hy+12O7a55UiRh4eH5lMThykWi1WZ9gVSZ00o+xhRexBNyRnKgu9x8PdRqD5Ax3VHhVwuxx3YoQQoCVSr1fiLHaH609nZ2TlpYmtry4VAE3UQpAtH3B2V2Q1AMEqCkokVCoWyWIeHvaFniJJA6Kkogb6TRtjEIqp9ShlVJy36n/7R0ZFPzxBVgZzxAnrTI81EjaB5dJKqQBMmBkeo1bx0Ii0Qzx6hm+Ryl6SZx8dHMQabS3ghLVCUg8YI+kmaiQhSsyEQes4R605PT7X7IPBKKGdmBL0RqkyIExpB84rgpQWCOU2YmIkZTCdSAnHuR+w5mJyRGSzC1H2aA1ICYfQ4oRvkcnYEjUA6NTSDDQYDUwI54wWMqLnMpFICwbzEJYYnk3x6isiamOigtcc/TJBuETE7i/FDRThoI+aFXYqQQHgWswJlKQeEzGVIIEwWv2kOxBYoCzmgERHrMH6WPzQHZHzQi8kBjSMjkDNeMBlB68omMjICvZgc0DixBMpKDmiErmwio7xx+FKItS+GKd4X95cwk7Sw76Q0c6C3u5QMRyj38Syu2AjhgCPunckS++qdnZ1LiOJSOvgw0XUZs2AB8P0b6Khqki1vXFs9Pj6+iNs+tomhNw4oPXjHM7YPq9frh9weAtWSngeQjd1iH3+5vr72K5UKjziX0qG0srJygfv+mtUI4nC0LnXgYBoQ+AO2pj/LXCPlpHHzJr5kk1JKs/7vNBqPHBLCCxV4ncbiIHfeIEkSnQ+6u7tz4raHGPuCD+tD8OVp7Tm0mBJKeLhPWyaTubS0pHwET/n4S+Bg/bjtka7tjM8oeP91VvtA0Ik61VGQBC1xUHBURUyVTD23w6mViBnT1y0Oo0UgmIor1s0yEYweN6K6SQbQIlBEqtaftdkIgUKz1nA4jB27pIkWgcT8TYxYZE1o3zWV+za1Fns17QOeHUlYSpjMHOgSaOIfxIgqB0KEgBgbYh1M7pwMoUsg0d+UIERHFClYcDaFtn4Ki1tlkhwDjg0cbLtYLO4LU32Z11dYBHd50w9/ayi74rXsf8ggiX6KEBfP8warq6t/8fad8BELxksJd1oaFSHC5tXVlbHNSS0CMb1e75vMYjfY53qP5cgPMog2gRiI1I0pkgfHvG5aHCa134vJwM4ZI6TBfoeC1XowYjyYGqckjM1aFovFYrFYLBaLxaKBfxlCo3LuuJS3AAAAAElFTkSuQmCC) no-repeat;
  background-size: 100%;
  cursor: pointer;
  margin-left: 13px;
}
.dialog-box___1DlEg .icon-cai___ZXgbY:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARzSURBVHgB7ZtRcts2EIZ3QbjSjNyJcgM+dSK3nSonsHMD9aWTxm1T3yA5QZwT2DmB0mnjzPTF6QmsnsDuNGN3+qSeoOpY7khjihuAshIRlG0ApEA6xvcGmBTpX9hd7C4E4PF4PB6Px+PxeDwe1yAURPOw2zxf+SREiptQMXgUHQ3ubw3AgtwCNd6+amMAO+KjNqDKINsd3nv4FAzJJdCnx3sdAuiKT6ncqlmIhUjWAtVPuiGn2uGNEecCPhrfNTE3BpYEVNu+aeJIhJ/smFzPwRaE9fmhMLU+o+gpIVo5Q1MIWIiAXWX2OUDcm42QqEnI9yEHVgLJiBUBhPNzGNNPp5//8AYcsfrnzxvAA2U27g1b3/dmo/rJ65DLry4HViYWcd5W5zAIDuEjxEogCoKMQOdEf4BDCFmozkXA+1AwVgKJ0LeuTA1GrW/78BFiG8XC9JCOwDGI6jsALONLshMIIW1iBE7NyyXGAiXRQwFZcACuye7BlrK9MBZokYOOGfsHHEOAzfS4IgIhUKjOnX32jXsfBHAnNcaKCCTe7Ctl7Fyc6XMpbWJEFRGIMGViRFiOQI4wEqjxttvOOEfHG8T3jwUM02PWhyVgJBBjQajOIU78CppBlI1gfBSVIhBmkuV4KZHUzAcxJcUQDtq21ntTMBNIcdAQo/P9j0SWW9S5ZdWhtAVKXirjoONSzGtUr2crmXHJYb5KNaBgMsoIxHjwLywBbYGqUAOagcSzJkbn/8ES0BboNtWA5jFx0mF6SKX4n+TJjqqJEn2BblENaB4tgSpTA5o921E1UWLdOLwtaPXFRIjvq/2lmOJdsbKsIsfwyw+9KxvEpjBUeuaDRaucJnEIQb7zGdp3r568OijqBIfswk5g/GDU2urr3iMFIM4eI2EnT8sbkXVO7z38Tfd6AxOLn0NByEQzgJq2D1v9a29HdFEPRKv5x7znAUz3btoCTVu6VKhIjb9/bV93nRDnUCy5J1AERC9MnbmRkx62NrcJaEu8cCF7IJyMr1wNycohuFbEayFRrxbiDNc2jYXOcT5IHgyIQv072DPFhw2GrUd3L7v64nBC1gxFiUVUD18inWubiqxZ2ZZlrI+/XCzVvu71q8d7+6mvg/D3K2/gUlAFuQpam8WYmyZO9kHJalCcKwV06bmdab0nHTFl5LMxkby42SgytpGZQ36piUxqQfZ6BttQAo4Eyp5Gu6rZGAPvqHMr/4+19y5F4kYgSreJxWbvageLammFemXVvsvJxTC+c9mf5OlZtWNRZuXAiUDCpJR/ENtSiEXXcqg/zkxi/AZKwtUKUv1Nk2NtXxVpmnDS9vxcEr1a+ZLbPNgfAzZgZTx+GdVqz1KhXuyQZT7WOHndS5p+iSNflAxjD0qksB+zXEfj+JcnIpPeMblnmvXjgzJr386c9Nnad7tGya7Mnyb0ddmNAadRTCa7WiKJfCtCvH/2xWbpByOcmdg8SaJLsUgbcP19M0CuGKQjsUd6cbr2qLSo5fF4PB6Px+PxeDwOeAfZRXwESQkdAgAAAABJRU5ErkJggg==) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .icon-cai___ZXgbY.active___2aCLv {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP5SURBVHgB7Zq/bxMxFMffc8KWIYiBjBm6E/ZKLVNZkIrEHwBzl3ZioFGDCjNlYGKg/AewsdGhzJQdpI5daG8oC2r8eL4DmrOvydl3sa+tP1KlnHVXn77n99MGiEQikUgkEolEIpGIbxBqgkbdLtyAPkjoQtMYwwGOkgQcqCwQvewOgPAV/6tlaDY7uHm8AZZUEoi2b60C0jv+2bxVU4y1SM4CsUn1oS2+wuURJ+NM3rQxNwGutMUILps4CtFatbm9Da4gLAFNXBMcAeIb/nEKPiDssXk/1Ubf89/B+SV2lCOACjgJlEYs4oiV5xO/9H6NgXHGS8hBwVQHQOJcIIIeCIIquJlYCwbGGOIPuIK4CYQFAkn5HXwi8LYxJukIasbRSeOSNnAK2Kr95ZqAq0D9/DX5XT3plNQzxubwkVzDfN7ECP0L5AlrgWi7u2wMIn4D/3S067mkF/YrqNBBUwj/01CBSPSNMRT+TQwpLxBhU1YQ3cldB/M/mBcIZUME0h000pV10AorgdLeD6BWoAYSSJUROXAuftBuBclW3xy8miXGPyxNjMwIRiLMCkJtBaluwhywEwgpX2JkDtpPeyMQtk5ad9Ch6q+OMYIUNoqlPSDdQROEctCdgtHAYb5JPSAhCwQSv2AOlBeoCT2g//MWrSAKnShenx7QJDYC9fPXATNoT93EdCqLe69ND2iSUgI1qAeU4ambqHDfOLwmlNsXG8OheSet8Zd0ixw4sXfl9LxeqHLAUPtkOmpzsSKld/noxc3PNZ7g4F1Y2uDit7xZKAEErPCvRYDCRLEkchU3k49l7y5vYkTPoT562ZGZ0pOv8Zuq++9DJXGYM7DynaUFwmGyxyl+zSLJhZl3CXrLq+0R1AHBaxwlhzaPWDlpHB6POHt+wjNV8yHns89YDamfmy3iTChJxRker4Ml1c4HtYwDDFNmwi3Nh7FjFQ8uniD1OQVmyB8HaZfLjfKmEvIIXlnYyZ9o3YB9Fmh44QMolThacuq2Cqrgfj7IgizR1Fsl+GXKI8r0NHHoEIcnXsVReEoUxbIxRFNqOaFtLSlaNIIA+BHIaNWqPGhKL5to0Rj7DaVzlzrxtII088KZnUgtK6Y9VydblVC12MXhXaRlhLZjEa4w9iMQGSF5AcS4uE6StFIw+AEC4ckHST2x7IAU24ZI6cFMepwfU9GLs/hAeAnzXP/sQpu2cr4IOUNW9ZgSL3XaMCjsewPuQUA8JopdzmGERYEK6eqBMd2zrZ/qxJuT5hbDjl2xy/WToIchxVF4jWJpsVtKJK63zuguPkvqKYor4M3EJskKXbGeJZD41++QynPUSXmut34Gi1qRSCQSiUQikUgk4oE/MztDr9z3FKUAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.dialog-box___1DlEg .remark___2LJtJ {
  color: #868686;
  border: 1px solid #868686;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  display: inline-block;
  transform: translate(12px, -5px);
  line-height: 10px;
  cursor: pointer;
}
.dialog-box___1DlEg .remark___2LJtJ:hover {
  color: #1bc37d;
  border-color: #1bc37d;
}
.close-modal___3FbV3 {
  color: #333333;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABICAYAAAC6L9h5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKCSURBVHgB7ZpBbtpAFIYHVixYuDdIb0CPkNygvUEXSOygJ6h6grJEYtOTJEdIbhBO0GQBEiyAvj+1o1FijMFv3nOS/5NGzyB75unTjD32TAiEEEIIIYQQQgghJKcTlJhMJtl6vR70er276XT6GByRXMJ+v882m41KPiqSRqPRxXa7ve10Opn8XEiCX+fz+V1wYDgcInyRcp3ncy/5fGuSTzcosNvtfucJgQs5vpFkB8GYEkHgc7fb/RkaoCKphMxa1AFBKqhIku78S8rLcW8mqkqQ5PUg//0IDVCRlI/3Sw9RxwRJuJrNZovQALWnG8hl3JR090dJ+FL7Zl5HkEabqpKAlSgrQUBdEkgtylIQSCIJpBJlLQgkkwS0RXkIAkklAS1RXoJAckmgqShPQcBEEjhXlLcgYCYJnCqqDYJAqne3Us6YmbsLAqY9qaBOjzp0jrUg4CIJVImSMhEZ0zYIAm6SQIWoV3gJAq6SQB1RnoKAuyRQJcpbEDB9up2DiAvevInhJjxIj/qYw+2UG3dwFOU23CrmQZgrfS+ZcH6Sc689VmFcJFUJkoDJ5J9QPjN3EWUuqYagp+GUx6s2iDKVVFdQxG1ogSj3TyUVgorrEA4tOprczF0/uh0TFF2P4CbK7fNtXUFRPQguolwWAk4VFNWHYC7KfEnpXEFRvQimokwXJ5sKiupHMBNltsytJShqB8FElMmGCW1BUXsIyUWpSbIWFLWLkFRUij2Tz6QWVJBaVIo9k09YCQLSBsLBV5hW7pm0FFRwRFQj1PZMSljkx+aCCmJRUu7/p7P/23TPpOpm9+VyOej3++6b3cfjMUK2Wq1akQ8hhBBCCCGEEELIO+If2AWTJ1upolYAAAAASUVORK5CYII=) no-repeat;
  background-size: 100%;
}
.ant-input:focus,
.ant-input-focused {
  outline: none;
  box-shadow: 0 0;
  -webkit-box-shadow: 0 0;
}
.cursor-blink {
  animation: blink 1s infinite;
  color: #0cd7a6;
  font-weight: 800;
  margin-left: 5px;
}
.ant-input {
  resize: none;
}
.mathtex-block {
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
  cursor: default;
}
.copy-tip {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  background-color: #000;
  color: #fff;
  left: 0;
  top: 0;
  border-radius: 5px;
  position: fixed;
  display: none;
  text-align: center;
  transform: translateX(-50%);
}
.copy-tip::after {
  content: '';
  border: 5px solid #000;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback-modal .ant-modal-content {
  border-radius: 14px;
}
.feedback-modal .ant-modal-close {
  top: 5px !important;
}
.feedback-modal .ant-modal-title {
  text-align: center;
  color: #1a1a1a;
  font-size: 17px;
  font-weight: 600;
  font-family: PingFang SC;
}
.feedback-modal .ant-modal-header {
  border-radius: 14px 14px 0 0;
  border: 0;
}
.feedback-modal .ant-modal-close-x {
  color: #333;
}
.feedback-modal .ant-modal-body {
  padding: 0px 25px;
}
.feedback-modal .ant-modal-footer {
  border-radius: 0 0 14px 14px;
  border: 0;
}
.feedback-modal textarea {
  padding: 14px;
  border-radius: 20px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal textarea::placeholder {
  color: #a6a6a6;
}
.feedback-modal .ant-btn {
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.1);
}
.feedback-modal .ant-btn-primary {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.feedback-modal .ant-btn-primary:hover {
  border-color: #2aebbc;
  background-color: #2aebbc;
}
.feedback-modal .ant-btn[disabled] {
  opacity: 0.3;
  color: #fff;
}
.feedback-modal .ant-btn[disabled]:hover {
  background-color: #0cd7a6;
  border-color: #0cd7a6;
}
.model-ques-input___1AdQ- {
  resize: none;
}
.tal-gpt-box___UmCOv {
  width: 70%;
  min-width: 733px;
  height: calc(100vh - 120px);
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  color: #1a1a1a;
}
.tal-gpt-box___UmCOv.hide-example___3ekYe {
  display: none;
}
.tal-gpt-box___UmCOv .tal-title___1RLhi {
  font-size: 35px;
  text-align: center;
  padding-top: 120px;
  margin-bottom: 80px;
  color: #040000;
}
.tal-gpt-box___UmCOv .tal-title___1RLhi .title-bold___YNJ_w {
  font-weight: 500;
}
.tal-gpt-box___UmCOv .tal-title___1RLhi .title-thin___sbceP {
  font-weight: 400;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 54px;
  box-sizing: content-box;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy {
  display: flex;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT {
  display: flex;
  flex: 1;
  justify-content: center;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT img {
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT p {
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh {
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  z-index: 1998;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh .arrow-louder-icon___19dUk {
  font-size: 14px;
  color: #5e5d5d;
  margin-left: 2px;
  transform: rotate(180deg);
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh .arrow-icon___2B895 {
  transform: translateY(-1px);
  width: 24px !important;
  height: 24px !important;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-primary___2R7nc:hover .arrow-louder-icon___19dUk {
  color: #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-primary___2R7nc:hover p {
  color: #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-primary___2R7nc.active___2aCLv p {
  color: #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-primary___2R7nc.active___2aCLv .arrow-louder-icon___19dUk {
  color: #0cd7a6;
  transform: rotate(0deg);
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-junior___2Kyh-:hover .arrow-louder-icon___19dUk {
  color: #1890ff;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-junior___2Kyh-:hover p {
  color: #1890ff;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-junior___2Kyh-.active___2aCLv p {
  color: #1890ff;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-junior___2Kyh-.active___2aCLv .arrow-louder-icon___19dUk {
  color: #1890ff;
  transform: rotate(0deg);
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-high___2bN2g:hover p {
  color: #ff7f09;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-high___2bN2g:hover .arrow-louder-icon___19dUk {
  color: #ff7f09;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-high___2bN2g.active___2aCLv p {
  color: #ff7f09;
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .item-content-area___3gZLh.item-content-area-high___2bN2g.active___2aCLv .arrow-louder-icon___19dUk {
  color: #ff7f09;
  transform: rotate(0deg);
}
.tal-gpt-box___UmCOv .tal-content___24ZEe .tal-tab-title___21dIy .tal-tab-title-item___2VZwT .anticon {
  font-size: 30px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p {
  display: flex;
  position: relative;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK {
  position: relative;
  padding-bottom: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 290px);
  overflow-y: scroll;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK::-webkit-scrollbar {
  display: none;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK::-moz-scrollbar {
  display: none;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK::-o-scrollbar {
  display: none;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK::-ms-scrollbar {
  display: none;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe {
  color: #1a1a1a;
  width: calc(100% - 50px);
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-radius: 14px;
  opacity: 1;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 10px;
  justify-content: center;
  border: 1px solid transparent;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .p-green___3VRY0:hover {
  color: #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .p-blue___2IDJY:hover {
  color: #1890ff;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .p-orange___SAr0d:hover {
  color: #ff7f09;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe p {
  text-align: left;
  margin-bottom: 0;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 22px;
  font-size: 13px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-green___S71Sr {
  background-color: rgba(12, 215, 166, 0.1);
  color: #0cd7a6;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-green___S71Sr span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-green___S71Sr:hover {
  color: #00ba8d;
  background-color: rgba(12, 215, 166, 0.2);
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-blue___ZNKxa {
  background-color: rgba(24, 144, 255, 0.1);
  color: #1890ff;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-blue___ZNKxa span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #1890ff;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-blue___ZNKxa:hover {
  color: #0676dd;
  background-color: rgba(24, 144, 255, 0.2);
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-orange___3HMfm {
  background-color: rgba(255, 127, 9, 0.1);
  color: #ff7f09;
  display: inline-block;
  border-radius: 4px;
  line-height: 20px;
  padding-right: 5px;
  margin-top: 5px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-orange___3HMfm span {
  display: inline-block;
  border-top-right-radius: 4px;
  height: 20px;
  padding: 0 4px;
  margin-right: 5px;
  color: #ffffff;
  background-color: #ff7f09;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .tal-ques-colum-item___DdqVe .knowledge-points-orange___3HMfm:hover {
  color: #de6b00;
  background-color: rgba(255, 127, 9, 0.2);
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .p-border-green___1KH7k:hover {
  cursor: pointer;
  border: 1px solid #0cd7a6;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .p-border-blue___2AbrU:hover {
  cursor: pointer;
  border: 1px solid #1890ff;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK .p-border-orange___15aK0:hover {
  cursor: pointer;
  border: 1px solid #ff7f09;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK:nth-child(1) {
  margin-left: -15px;
}
.tal-gpt-box___UmCOv .tal-ques-content___1sA5p .tal-ques-colum___IT6cK:nth-child(3) {
  margin-right: -15px;
}
.tal-gpt-box___UmCOv .shadow-box-content___3amJQ {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -40px;
  z-index: 10;
}
.tal-gpt-box___UmCOv .shadow-box-content___3amJQ .shadow-box___TJsA5 {
  width: 100%;
  margin: 0 25px;
  height: 40px;
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.tal-gpt-box___UmCOv .shadow-box-content___3amJQ .shadow-box___TJsA5:nth-child(1) {
  margin-left: 10px;
}
.tal-gpt-box___UmCOv .shadow-box-content___3amJQ .shadow-box___TJsA5:nth-child(3) {
  margin-right: 12px;
}
.hidden___3f12z {
  display: none;
}
.model-tip___OS9ZS {
  position: absolute;
  top: 22px;
  right: 30px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.know-point-modal-wrap___2zaYn .ant-modal-content {
  height: inherit;
  width: inherit;
  background-color: #f5f5f5;
  border-radius: 0 0 14px 14px;
}
.know-point-modal-wrap___2zaYn .ant-modal-header {
  background-color: #f5f5f5;
  border-radius: 14px 14px 0 0;
}
.dialog-list-area___3KhtN {
  height: auto;
  position: relative;
}
.dialog-list-area___3KhtN.tip-show___qXxmW {
  z-index: 101;
}
.load-more___3mo54 {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00ba8d;
}
.load-more___3mo54 i {
  margin-right: 10px;
  font-size: 20px;
}
.know-point-popover-card-area___3ONX6 {
  width: 75%;
  left: 0px !important;
  right: 0px !important;
  margin: 0 auto;
}
.know-point-popover-card-area___3ONX6 .ant-popover-content {
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
}
.know-point-popover-card-area___3ONX6 .ant-popover-inner {
  border-radius: 20px;
}
.know-point-popover-card-area___3ONX6 .ant-popover-arrow {
  display: none;
}
.know-point-popover-card-area___3ONX6 .ant-popover-inner-content {
  padding: 10px 0px;
}
