.dialog_assistant___2qg7P {
  font-size: 15px;
}
.dialog_assistant___2qg7P .ant-card {
  height: 382px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid rgba(83, 232, 196, 0.4);
  background-image: radial-gradient(circle at top left, #e8fffa 0%, #ffffff 36%);
}
.dialog_assistant___2qg7P .ant-card:hover {
  border: 2px solid #53e8c4;
  color: #53e8c4;
}
.dialog_assistant___2qg7P .ant-divider {
  margin: 12px 0;
}
.dialog_assistant___2qg7P .ant-typography-expand {
  display: none;
}
.dialog_assistant___2qg7P .ant-card {
  cursor: pointer;
}
.dialog_assistant___2qg7P .ant-typography {
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #1a1a1a;
}
.main-title___1Gbyp {
  color: #1a1a1a;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.example-list-item____1zrt {
  color: #1a1a1a;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 600;
}
.role-name___Fstub {
  color: #000;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 11px;
  height: 21px;
  width: max-content;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOQAAAAYCAYAAADqB1HvAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABgSURBVHgB7dNBAQAQAAAxVJJFYl3kUOMeW4fN8+4eQMIaQIaQECIkhAgJIUJCiJAQIiSECAkhQkKIkBAiJIQICSFCQoiQECIkhAgJIUJCiJAQIiSECAkhQkKIkBAiJIR89CcCYW2xkt4AAAAASUVORK5CYII=) no-repeat;
  background-size: 100% 8px;
  background-position: 0 13px;
}
.assistantImg___1j7Eq {
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #f9f9f9;
  overflow: hidden;
  display: flex;
}
.assistantImg___1j7Eq img {
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
