.banner_title___1dl2x {
  height: 75px;
  line-height: 75px;
  padding-left: 10px;
  background-color: #069dee;
  color: whitesmoke;
  font-size: 16px;
  align-items: center;
  position: relative;
}
.banner_title___1dl2x .logo___34Y6P {
  height: 38px;
}
.banner_title___1dl2x .title_txt___VmmY_ {
  position: absolute;
  margin-left: 20px;
  margin-top: 5px;
}
.content___3kawm {
  width: 70% !important;
  margin: 20px auto;
}
.content___3kawm .Form___w-8RL {
  margin-bottom: 20px;
}
.content___3kawm .title___21r7H {
  margin-bottom: 20px;
}
.content___3kawm .title___21r7H .big_title___1och3 {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.content___3kawm .video_box___2E-f3 {
  margin-top: 20px;
}
.content___3kawm .video_box___2E-f3 .video_main___37Fd_ {
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid gainsboro;
  margin-bottom: 20px;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 {
  overflow-y: scroll;
  height: 600px;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z {
  width: 180px;
  height: 100px;
  margin-right: 5px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border: 3px solid rgba(0, 0, 0, 0.7);
  transition: 0.5s;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z:hover {
  border: 3px solid #0993ec;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z:hover .video_player___2EAHH {
  opacity: 0.8;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z .video_label___XD1Za {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: black;
  color: whitesmoke;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z .video_label_active___1vo0U {
  background-color: #069dee;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item___3et8z .video_player___2EAHH {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item_active___1pimA {
  border: 3px solid #0993ec;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item_buttom___3GQRQ {
  margin-bottom: 20px;
}
.content___3kawm .video_box___2E-f3 .vidoe_list___299z1 .video_item_buttom___3GQRQ p {
  margin-top: 5px;
}
.content___3kawm .video_box___2E-f3 .btn_list___bjP0F {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content___3kawm .video_box___2E-f3 .btn_list___bjP0F .video_btn___1RIfs {
  overflow: hidden;
  margin-bottom: 20px;
  height: 40px;
  line-height: 40px;
  width: 240px;
}
.content___3kawm .video_box___2E-f3 .btn_list___bjP0F .video_span___3s5Kq {
  width: 240px;
  height: 0;
}
.content___3kawm .video_box___2E-f3 .btn_list___bjP0F::before {
  display: none;
}
.content___3kawm .video_box___2E-f3 .btn_list___bjP0F::after {
  display: none;
  content: '';
  flex: auto;
}
.content___3kawm .video_box___2E-f3 .video_btn_active___2vTEi {
  color: #069dee;
  border: 1px solid #069dee;
}
