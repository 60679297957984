.tab-item-area___2oZ-X {
  margin-top: 14px;
  padding: 4px 10px;
  border-top: 1px dotted gray;
  border-bottom: 1px dotted gray;
}
.tab-item-area___2oZ-X .tab-item-status-item___39VdY {
  display: flex;
  margin-top: 10px;
}
