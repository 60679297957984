.select-area___2ZRMo {
  display: flex;
}
.select-area___2ZRMo .select-ctn-small___38vZs {
  width: 160px;
  border: 2px solid #ecedee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0 10px;
}
.select-area___2ZRMo .select-ctn___1LFb_ {
  flex: 1;
  margin: 0 10px;
  border: 2px solid #ecedee;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.select-area___2ZRMo .select-ctn___1LFb_ .read-types___A51Sj {
  width: 200px;
}
.select-area___2ZRMo .select-ctn___1LFb_ .read-articles___2o9uN {
  width: 400px;
}
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-selection {
  font-size: 20px;
  background-color: #142532;
  color: #fff;
  border-color: transparent;
}
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-selection .ant-select-arrow {
  color: #fff;
}
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-focused .ant-select-selection,
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-selection:focus,
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-selection:hover,
.select-area___2ZRMo .select-ctn-common___1Y6T2 .ant-select-selection:active {
  border-color: transparent;
  box-shadow: none;
}
