.canvasDiv___321ze {
  height: 1px;
}
.canvas___2YO09 {
  position: relative;
  left: 192px;
  bottom: 63px;
}
.buttonOuter___1dQ7R {
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -142px;
  left: 440px;
  cursor: pointer;
  border-radius: 50%;
}
.buttonOuter___1dQ7R .buttonInnerOne___1Sa0B {
  width: 89px;
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___1dQ7R .buttonInnerOne___1Sa0B .buttonInnerTwo___26Xyw {
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___1dQ7R .buttonInnerOne___1Sa0B .buttonInnerTwo___26Xyw .buttonInnerThree___whRsk {
  width: 65px;
  height: 65px;
  background: linear-gradient(180deg, #5187d5 0%, #356cbc 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___1dQ7R .buttonInnerOne___1Sa0B .buttonInnerTwo___26Xyw .buttonInnerThree___whRsk .buttonInner___1Zxsl {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 2px;
}
.audioDuration___3wVYy {
  position: relative;
  bottom: 38px;
}
.audioDurationDisplay___1TQl0 {
  display: none;
}
.buttonActive___27Z9k {
  position: relative;
  top: -160px;
}
