.allContainer___-tjtJ .detailsArea___m8reN {
  margin: 0 auto !important;
  width: 1000px;
  padding-top: 0px;
  min-height: 100vh;
  box-sizing: border-box;
}
.allContainer___-tjtJ .detailsArea___m8reN .highlight___3d6p5 .ant-table-tbody tr:hover td {
  background: none;
}
.allContainer___-tjtJ .detailsArea___m8reN .even {
  background: #ffffff;
}
.allContainer___-tjtJ .detailsArea___m8reN .odd {
  background: #fafafa;
}
.allContainer___-tjtJ .detailsArea___m8reN .ant-table-tbody .ant-table-row td {
  vertical-align: middle;
}
.allContainer___-tjtJ .myTable___25Plv {
  margin-bottom: 40px;
}
.allContainer___-tjtJ .banner___18xyo {
  position: relative;
  margin-top: 20px;
}
.allContainer___-tjtJ .banner___18xyo .bannnerImage___1Osbm {
  width: 100%;
  display: block;
}
.allContainer___-tjtJ .banner___18xyo .bannerContent___E_T4d {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 38%;
  left: 0px;
  display: flex;
  justify-content: center;
}
.allContainer___-tjtJ .banner___18xyo .bannerContent___E_T4d .bannerContentInner___3qavo {
  height: 104px;
}
.allContainer___-tjtJ .banner___18xyo .bannerContent___E_T4d .bannerContentInner___3qavo p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.allContainer___-tjtJ .imgTitle___YeWKH {
  margin: 0 auto;
  width: 1000px;
  text-align: center;
}
.allContainer___-tjtJ .imgTitle___YeWKH word-wrap:break-word .desc___3-md1 {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.allContainer___-tjtJ .containerOut___1dTYK {
  width: 100%;
  display: flex;
  justify-content: center;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw {
  width: 1030px;
  text-align: center;
  padding-top: 50px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .title___1y2Q6 {
  height: 33px;
  font-size: 35px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .desc___3-md1 {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .image___L7dfs {
  width: 714px;
  height: 472px;
  margin-bottom: 22px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .imageAlt___2nRf9 {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 29px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .content___2TbC0 {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw .content___2TbC0 span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw p,
.allContainer___-tjtJ .containerOut___1dTYK .containerIn___1jZNw span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.allContainer___-tjtJ .containerOuter___3EAUo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr {
  width: 1030px;
  text-align: center;
  padding-top: 0px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .title___1y2Q6 {
  height: 33px;
  font-size: 35px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .desc___3-md1 {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .image___L7dfs {
  width: 714px;
  height: 472px;
  margin-bottom: 22px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .imageAlt___2nRf9 {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 29px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .content___2TbC0 {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr .content___2TbC0 span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr p,
.allContainer___-tjtJ .containerOuter___3EAUo .containerIner___14hwr span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.allContainer___-tjtJ .content_main___DrHoH {
  margin-top: 20px;
}
.allContainer___-tjtJ b {
  font-weight: bold;
}
.allContainer___-tjtJ h3 {
  font-weight: bold;
  font-size: 18px;
}
.allContainer___-tjtJ .tab_box___34Oks {
  background: #5187d5;
  margin-bottom: 20px;
}
.allContainer___-tjtJ .tab_box___34Oks ul {
  height: 60px;
  line-height: 60px;
}
.allContainer___-tjtJ .tab_box___34Oks ul li {
  display: inline-block;
  width: 20%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.allContainer___-tjtJ .tab_box___34Oks ul > li:last-child {
  width: 15%;
}
.allContainer___-tjtJ .tab_box___34Oks ul .isActived___3qgYE {
  background: #1a3a68;
  font-weight: bold;
}
.allContainer___-tjtJ .imgFont___cIEL8 {
  width: 700px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #b2b2b2;
  font-style: italic;
}
.allContainer___-tjtJ .change_contents___3VZLe {
  padding: 20px 80px;
}
.allContainer___-tjtJ .change_contents___3VZLe b {
  color: #377ef7;
}
.allContainer___-tjtJ .change_contents___3VZLe span {
  font-weight: bold;
  color: #504f4f;
}
.allContainer___-tjtJ .change_contents___3VZLe li {
  line-height: 35px;
}
.allContainer___-tjtJ .change_contents___3VZLe .cycle_item___30ZTz {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.allContainer___-tjtJ .change_contents___3VZLe .home_img_1___3--W1 {
  width: 800px;
  display: block;
  margin: 20px auto;
}
.allContainer___-tjtJ .change_contents___3VZLe .like_a___3N5ug {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.allContainer___-tjtJ .change_contents___3VZLe a {
  color: #303de2;
}
.allContainer___-tjtJ .change_contents___3VZLe .home_connect___eN-CU img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.allContainer___-tjtJ .change_contents___3VZLe .home_connect___eN-CU img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.allContainer___-tjtJ table {
  margin: 30px auto !important;
  width: 1000px;
}
.allContainer___-tjtJ table thead {
  background: rgba(220, 220, 220, 0.25);
  color: #1e1e1ed4;
  font-weight: 600;
}
.allContainer___-tjtJ table thead td {
  border: 1px solid;
  border-color: #eeeeee;
}
.allContainer___-tjtJ table td {
  padding: 15px 30px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.allContainer___-tjtJ table tbody tr td {
  border: solid 1px;
  border-color: #eeeeee;
}
