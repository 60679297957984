.modelContainer___fh-tp .myTable___1zzU_ {
  margin-bottom: 40px;
  margin-top: 100px;
}
.modelContainer___fh-tp .banner___3oCKx {
  position: relative;
  margin-top: 20px;
}
.modelContainer___fh-tp .banner___3oCKx .bannnerImage___3MafT {
  width: 100%;
  display: block;
}
.modelContainer___fh-tp .banner___3oCKx .bannerContent___2lriN {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 38%;
  left: 0px;
  display: flex;
  justify-content: center;
}
.modelContainer___fh-tp .banner___3oCKx .bannerContent___2lriN .bannerContentInner___2C6sB {
  height: 104px;
}
.modelContainer___fh-tp .banner___3oCKx .bannerContent___2lriN .bannerContentInner___2C6sB p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.modelContainer___fh-tp .containerOut___1ccJ7 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul {
  width: 1030px;
  text-align: center;
  padding-top: 50px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul .title___1VsKX {
  height: 33px;
  font-size: 35px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul .desc___3DyCN {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 80px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul .content___20WEV {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul .content___20WEV span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul p,
.modelContainer___fh-tp .containerOut___1ccJ7 .containerIn___3wkul span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.modelContainer___fh-tp .content_main___ECe-b {
  margin-top: 20px;
}
.modelContainer___fh-tp b {
  font-weight: bold;
}
.modelContainer___fh-tp table {
  margin: 40px auto !important;
  width: 1000px;
}
.modelContainer___fh-tp table thead {
  background: rgba(220, 220, 220, 0.25);
  color: #1e1e1ed4;
  font-weight: 600;
}
.modelContainer___fh-tp table thead td {
  border: 1px solid;
  border-color: #eeeeee;
}
.modelContainer___fh-tp table td {
  padding: 20px 30px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.modelContainer___fh-tp table tbody tr td {
  border: solid 1px;
  border-color: #eeeeee;
}
