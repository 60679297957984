.gptContainer___2Ft5T {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}
.gptContainer___2Ft5T .downloadContainer {
  background-color: white;
  box-sizing: border-box;
  min-width: 840px;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
  min-width: 837.5px;
  position: relative;
  left: -25px;
  padding: 16px 0px 29px 0;
  box-sizing: border-box;
  text-align: justify;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck {
  text-align: center;
  width: 95px;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0CD7A6;
  border-color: #0CD7A6;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0CD7A6;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox:hover .ant-checkbox-inner,
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0CD7A6;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck .ant-checkbox-checked::after {
  border-color: #0CD7A6;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .cancelBtn {
  color: black;
  background-color: white;
  border: 1px solid rgba(25, 26, 26, 0.3);
  border-radius: 5px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .cancelBtn:hover {
  color: #63d4a9;
  border: 1px solid #63d4a9;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .forbid {
  cursor: not-allowed;
  opacity: 0.3;
}
.gptContainer___2Ft5T .downloadContainer .downloadDrawer .downloadBtn {
  color: white;
  background-color: #0CD7A6;
  border: 1px solid #0CD7A6;
  border-radius: 5px;
  margin-left: 20px;
  width: 209px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
@media only screen and (max-width: 1290px) {
  .gptContainer___2Ft5T .downloadContainer .downloadDrawer {
    margin-right: 115px !important;
    left: 0 !important;
  }
  .gptContainer___2Ft5T .downloadContainer .downloadDrawer .allCheck {
    text-align: center;
    width: 86px;
  }
}
.gptContainer___2Ft5T .inner-wrapper___297ed {
  background-color: #fff;
}
.gptContainer___2Ft5T .main-wrapper___9rI9E {
  background-color: #292929;
}
.gptContainer___2Ft5T .radius-wrapper___3flS7 {
  height: 100%;
  width: 100%;
  border-radius: 15px 0 0 15px;
  background-color: #fff;
}
.gptContainer___2Ft5T .radius-wrapper___3flS7 > div {
  border-radius: 15px 0 0 15px;
}
.gptContainer___2Ft5T .radius-wrapper___3flS7 > div > div {
  border-radius: 15px 0 0 15px;
}
.gptContainer___2Ft5T .radius-wrapper___3flS7 > div .ant-layout {
  border-radius: 15px 0 0 15px;
}
.gptContainer___2Ft5T .radius-wrapper___3flS7 > div .ant-layout-content {
  border-radius: 15px 0 0 15px;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .isArrangeModalTitle___2LzsC {
  text-align: left;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .radio-group___1-cxM .radio___10zva {
  width: 100% !important;
  margin-bottom: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content {
  border-radius: 14px;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-header {
  border-radius: 14px 14px 0 0;
  border: none;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-body {
  padding: 16px 24px;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-body .ant-radio-group {
  width: 100%;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-body .ant-radio-wrapper {
  padding: 0;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-body .ant-radio-wrapper .ant-radio-inner {
  border-color: black;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-content .ant-modal-body .ant-radio-wrapper .ant-radio-inner::after {
  background-color: black;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-footer {
  border: none;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-footer .isArrangeCancel {
  color: black;
  background-color: white;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-footer .isArrangeEnsure {
  color: white;
  background-color: black;
  border-color: black;
}
.gptContainer___2Ft5T .isArrangeModal___2PzBL .ant-modal-footer .isArrangeEnsure:hover {
  border-color: black;
}
.gptContainer___2Ft5T::-webkit-scrollbar {
  display: none;
}
/* 针对 Firefox */
html {
  scrollbar-width: none;
  /* Firefox 64 */
}
/* 针对 IE, Edge */
body {
  -ms-overflow-style: none;
  /* IE 10+ */
}
.default_module_setting___3GsSS {
  position: absolute;
  top: 40px;
  right: 14px;
  background: transparent;
}
.default_module_setting___3GsSS button {
  width: 100%;
  height: 42px;
  vertical-align: middle;
  border: none;
  box-shadow: none;
  text-align: left;
  background: transparent;
}
.default_module_setting___3GsSS:hover {
  color: #53e8c4;
}
