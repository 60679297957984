.aaai_box___XoOJd {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
}
.aaai_box___XoOJd .banner___2JAxh {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.aaai_box___XoOJd .content_main___26ais {
  margin-top: 20px;
}
.aaai_box___XoOJd b {
  font-weight: bold;
}
.aaai_box___XoOJd h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.aaai_box___XoOJd h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.aaai_box___XoOJd h3 {
  font-weight: bold;
  font-size: 18px;
}
.aaai_box___XoOJd .tab_box___3WX_d {
  background: #5187d5;
  margin-bottom: 20px;
}
.aaai_box___XoOJd .tab_box___3WX_d ul {
  height: 60px;
  line-height: 60px;
}
.aaai_box___XoOJd .tab_box___3WX_d ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.aaai_box___XoOJd .tab_box___3WX_d ul > li:last-child {
  width: 15%;
}
.aaai_box___XoOJd .tab_box___3WX_d ul .isActived___2rArV {
  background: #1a3a68;
  font-weight: bold;
}
.aaai_box___XoOJd .change_contents___3IJQ4 {
  padding: 20px 80px;
}
.aaai_box___XoOJd .change_contents___3IJQ4 li {
  line-height: 35px;
}
.aaai_box___XoOJd .change_contents___3IJQ4 .cycle_item___2EAeq {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
.aaai_box___XoOJd .change_contents___3IJQ4 .home_img_1___2wBy2 {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.aaai_box___XoOJd .change_contents___3IJQ4 .like_a___1uIvj {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.aaai_box___XoOJd .change_contents___3IJQ4 a {
  color: #303de2;
}
.aaai_box___XoOJd .change_contents___3IJQ4 .home_connect___1J_nF img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.aaai_box___XoOJd .change_contents___3IJQ4 .home_connect___1J_nF img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.aaai_box___XoOJd .home_connect___1J_nF {
  margin-bottom: 40px;
}
.aaai_box___XoOJd .home_connect___1J_nF p {
  line-height: 35px;
}
.aaai_box___XoOJd .home_connect___1J_nF b {
  margin-right: 8px;
}
.aaai_box___XoOJd .home_connect___1J_nF .img_box___HppL7 {
  margin: 0 auto;
  width: max-content;
}
.aaai_box___XoOJd .home_connect___1J_nF .img_box___HppL7 img {
  margin: 40px;
}
.aaai_box___XoOJd .body_text___1tNGz {
  line-height: 35px;
}
