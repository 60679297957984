.children_box___MK88g {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.children_box___MK88g .contrast___3WZVw {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 16px;
}
.children_box___MK88g .study_banner___1AoH- {
  display: block;
  margin: 50px auto;
  width: 700px;
}
.children_box___MK88g .content_main___3vBeY {
  margin-top: 0px;
}
.children_box___MK88g b {
  font-weight: bold;
}
.children_box___MK88g h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.children_box___MK88g h2 {
  font-size: 42px;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.children_box___MK88g .change_contents___2ksU0 {
  padding: 0px 80px 20px 80px;
}
.children_box___MK88g .change_contents___2ksU0 .study_project___3WSuB {
  display: block;
  margin: 20px auto;
  width: 700px;
}
.children_box___MK88g .change_contents___2ksU0 span {
  font-weight: bold;
  color: black;
}
.children_box___MK88g .change_contents___2ksU0 li {
  line-height: 35px;
  margin-top: 20px;
  text-indent: 32px;
}
.children_box___MK88g .change_contents___2ksU0 .like_a___jAEBJ {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.children_box___MK88g .change_contents___2ksU0 a {
  color: #303de2;
}
