.outer___2ufti {
  width: 100%;
  height: 333px;
  background: #ffffff;
}
.outer___2ufti .top___2SJa_ {
  width: 100%;
  height: 77px;
  padding-top: 44px;
  line-height: 33px;
  font-size: 24px;
  font-weight: 500;
  color: #042044;
  text-align: center;
  margin-bottom: 30px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___2ufti .bottom___2TTYn {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF {
  width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF .inner___3xhNC {
  width: 380px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 34px 58px 42px 58px;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF .inner___3xhNC .image___2--X2 {
  display: none;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF .inner___3xhNC .content___3gIxU .title___1bkWg {
  width: 264px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #161616;
  line-height: 25px;
  margin-bottom: 16px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF .inner___3xhNC .content___3gIxU .desc___2KRia {
  width: 264px;
  font-size: 14px;
  font-weight: 400;
  color: #313131;
  line-height: 20px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___2ufti .bottom___2TTYn .bottomCon___SI3GF .inner___3xhNC:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
