.enDemoWrap___1J8eY {
  margin-top: 50px;
  display: flex;
  width: 100%;
  min-height: 550px;
}
.enDemoWrap___1J8eY .left___2DBq1 {
  display: flex;
  flex: 1;
  background: cornsilk;
}
.enDemoWrap___1J8eY .left___2DBq1 .breadExpand___3wPmM {
  padding: 30px 40px;
}
.enDemoWrap___1J8eY .left___2DBq1 .content___20ufb {
  padding: 50px 20px;
}
.enDemoWrap___1J8eY .right___3AKS1 {
  width: 320px;
  padding: 50px 10px;
  background: #fafafb;
}
.enDemoWrap___1J8eY .right___3AKS1 .score___1Ib2O {
  display: flex;
  align-items: center;
}
.enDemoWrap___1J8eY .right___3AKS1 .score___1Ib2O .socreVal___Vzshz {
  text-align: center;
  width: 90px;
  font-size: 40px;
  font-weight: bold;
}
.enDemoWrap___1J8eY .right___3AKS1 .score___1Ib2O .progress___2okKs {
  flex: 1;
}
.enDemoWrap___1J8eY .right___3AKS1 .content___20ufb {
  margin-top: 20px;
  padding: 0 12px;
}
.enDemoWrap___1J8eY .right___3AKS1 .content___20ufb .categoryContent___Zkfod {
  line-height: 22px;
}
.articleParagraph___1vZjz > span {
  line-height: 30px;
}
.textWrap___1j9IX {
  position: relative;
}
.borderText___AmUXo {
  border-bottom: 1px solid;
}
.grade___3-e69 {
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  left: -12px;
  top: -9px;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 16px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
