.articial_box___3IFbr {
  max-width: 1920px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-bottom: 40px;
}
.articial_box___3IFbr .contrast___2v23U {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 16px;
}
.articial_box___3IFbr .content_main___3RoPh {
  margin-top: 0px;
}
.articial_box___3IFbr h2 {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.articial_box___3IFbr .abouttitle___15Z7t {
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.articial_box___3IFbr .change_contents___3Vq88 li {
  line-height: 35px;
  margin-top: 20px;
  text-indent: 32px;
}
.articial_box___3IFbr .change_contents___3Vq88 .imgContrast___1wjit {
  text-align: center;
  width: 850px;
  margin: 10px auto;
}
.articial_box___3IFbr .change_contents___3Vq88 .imgContrast___1wjit .home_img_1___1hvU_ {
  width: 400px;
}
.articial_box___3IFbr .change_contents___3Vq88 .like_a___1EHBx {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.articial_box___3IFbr .change_contents___3Vq88 a {
  color: #303de2;
}
.articial_box___3IFbr .change_contents___3Vq88 .headercontent___2gQvU {
  width: 100%;
  font-size: 24px;
  position: relative;
}
.articial_box___3IFbr .change_contents___3Vq88 .headercontent___2gQvU img {
  width: 100%;
  height: auto;
}
.articial_box___3IFbr .change_contents___3Vq88 .headercontent___2gQvU::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 52%;
  background-image: url(/_next/static/images/headerleft-39a919d80231c18db59bba3fddad3ffb.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.articial_box___3IFbr .change_contents___3Vq88 .headerp1___1JwDy {
  z-index: 1;
  position: absolute;
  left: 25%;
  top: 35%;
  color: #e2eeff;
  font-size: calc(35px + 1.5vw);
  font-weight: 600;
  margin-bottom: 35px;
  line-height: 96px;
}
.articial_box___3IFbr .change_contents___3Vq88 .headerp2___2J9xp {
  z-index: 1;
  position: absolute;
  left: 25%;
  top: 54%;
  color: #e2eeff;
  font-size: calc(16px + 0.5vw);
  font-weight: 400;
  line-height: 48px;
}
.articial_box___3IFbr .change_contents___3Vq88 .aboutcontent___1rMV9 {
  width: 100%;
  height: 480px;
  text-align: center;
  background-image: url('https://openplantform.oss-cn-beijing.aliyuncs.com/technical-capacity/banner/Mask_group.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articial_box___3IFbr .change_contents___3Vq88 .aboutcontentmiddle___3pfPX {
  width: 1200px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  padding: 0 120px;
}
.articial_box___3IFbr .change_contents___3Vq88 .abouttitle___15Z7t {
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  color: #000000;
  margin-bottom: 54px;
}
.articial_box___3IFbr .change_contents___3Vq88 .abouticon___3Bog_ {
  width: 213px;
  height: 47px;
  position: absolute;
  top: 47px;
  right: 120px;
}
.articial_box___3IFbr .change_contents___3Vq88 .aboutmaintext___30ec0 {
  width: 960px;
  height: 165px;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #000000;
}
.articial_box___3IFbr .change_contents___3Vq88 .aboutul___1Vc6h {
  display: flex;
  justify-content: center;
  align-items: center;
}
.articial_box___3IFbr .change_contents___3Vq88 .aboutli___20AWj {
  width: 140px;
  font-size: 18px;
  font-weight: 400;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionIntroductioncontent___3U1dN {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionIntroduction___2oXF3 {
  position: relative;
  width: 322px;
  height: 268px;
  background-image: url(/_next/static/images/aboutimage-060c0317e2ecd412f5791f838552f311.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionIntroductionh3___FkwbV {
  position: absolute;
  right: 10px;
  top: 300px;
  font-size: 36px;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionIntroductionmainborder___3WGv- {
  width: 1000px;
  height: 268px;
  background-color: #f5f7fc;
  padding-top: 45px;
  padding-right: 49px;
  padding-left: 40px;
  border-radius: 18px;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionIntroductionmain___2mabj {
  width: 800px;
  font-size: 16px;
  text-indent: 2em;
  line-height: 33px;
  font-weight: 400;
  background-color: #f5f7fc;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersback___2vhl8 {
  background-color: #f4f4f4;
  padding-top: 1px;
  padding-bottom: 167px;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmemberstext___1Zf-p {
  margin-top: 115px;
  margin-bottom: 40px;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionMembersBox___1F5Ui {
  width: 1335px;
  height: 800px;
  overflow-y: auto;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionMembersBox___1F5Ui::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionMembersBox___1F5Ui::-webkit-scrollbar-thumb {
  background-color: #dddde5;
  border-radius: 5px;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersmain___2exNj {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersul___27UuS {
  width: 1335px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 创建四个等宽的列 */
  grid-gap: 16px;
  /* 设置网格线间隙 */
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersul___27UuS .unionmembersli___joERG {
  width: 320px;
  height: 202px;
  background-color: #ffffff;
  text-align: center;
  margin-top: 0;
  text-indent: 0;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersul___27UuS .unionmembersli___joERG .unionmembersdiv___xRHOS {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #1a1a1aa6;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersul___27UuS .unionmembersli1___19ooR {
  width: 320px;
  height: 202px;
  background-color: #ffffff;
  text-align: center;
  font-size: 18.5px;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  text-indent: 0;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersul___27UuS .unionmembersli2___2ILKT {
  width: 320px;
  height: 146px;
  padding: 0 10px;
  background-color: #ffffff;
  text-align: center;
  font-size: 18.5px;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  text-indent: 0;
}
.articial_box___3IFbr .change_contents___3Vq88 .img-box___3LM4C {
  height: 108px;
  margin-top: 32px;
  margin-bottom: 26px;
  position: relative;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionmembersimg___3K_sI {
  max-width: 96%;
  max-height: 108px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontenttitle___zsnjb {
  margin-bottom: 50px;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentdiv___3KBL5 {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentmain___3ksmK {
  width: 1500px;
  height: 500px;
  padding: 30px;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentul___255xA {
  display: flex;
  justify-content: space-between;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentli___13X-j {
  width: 30%;
  height: 460px;
  margin: 0 20px;
  padding: 110px 50px 0 50px;
  text-align: center;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background-color: #f7f9fc;
  text-indent: 0;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontenticontext___31hrJ {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 26px;
  font-weight: 400;
  line-height: 36.4px;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentspan___3XehV {
  opacity: 0.65;
}
.articial_box___3IFbr .change_contents___3Vq88 .unionimg___3EQya {
  width: 62px;
  height: 55px;
}
.articial_box___3IFbr .change_contents___3Vq88 .purposecontentp___3RHHU {
  line-height: 35px;
}
.articial_box___3IFbr .change_contents___3Vq88 .structureback___hSX1u {
  margin: 0;
  width: 100%;
  height: 600px;
  background-color: #f4f4f4;
}
.articial_box___3IFbr .change_contents___3Vq88 .structurebackborder___1todN {
  background-color: #f4f4f4;
  height: 200px;
  padding-top: 20px;
  margin-top: 50px;
}
.articial_box___3IFbr .change_contents___3Vq88 .structurebacktext___G6FIM {
  margin-top: 50px;
  background-color: #f4f4f4;
  margin-bottom: 50px;
}
.articial_box___3IFbr .change_contents___3Vq88 .structure-img___22OR5 {
  display: block;
  margin: auto;
  width: 700px;
}
.articial_box___3IFbr .change_contents___3Vq88 .outbox___1JljL {
  width: 1750px;
}
.articial_box___3IFbr .change_contents___3Vq88 .newsul___jpHwA {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(350px * 5);
}
.articial_box___3IFbr .change_contents___3Vq88 .newslist___1sndI {
  width: 320px;
  height: 282px;
  text-align: center;
  background-color: #d9d9d9;
  border-radius: 20px;
  margin: 15px;
  background-repeat: no-repeat;
  background-size: cover;
}
.articial_box___3IFbr .change_contents___3Vq88 .newstitle___j0oMl {
  margin-bottom: 50px;
}
.articial_box___3IFbr .change_contents___3Vq88 .newscontent___1jXJY {
  text-align: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 100px;
}
.articial_box___3IFbr .change_contents___3Vq88 .newscontentwrapper___24C9s {
  position: relative;
  width: 1060px;
  overflow-x: hidden;
  margin: 0 auto;
}
.articial_box___3IFbr .change_contents___3Vq88 .buttonleft___2gNQd {
  position: absolute;
  top: 120px;
  left: 12%;
  display: block;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  z-index: 1;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIjSURBVHgB7d3LbcJQFIThSxqADuiAHSVABZRCGZRCB1SBO3AH9tKrRGPkREJyWM45c+aXIpQs54M88CObaZq+m6P11Rw1A5AzADkDkDMAOQOQMwA5A5AzADkDkDMAOQOQMwA5A5AzADkDkDMAOQOQMwA5A5AzADlpgK7r2vF4bH3ft6jJAmD80+n0+xgVQRJgGX0YhvlzjB8VQQ7gffylqAhSAGvjL43jOH9ESgbg0/i73a49Ho92OBxapCQAso6P0gNkHh+lBsg+PkoLoDA+SgmgMj5KB6A0PkoFoDY+SgOgOD5KAaA6PgoPoDw+Cg2gPj4KC1BhfBQSoMr4KBxApfFRKIBq46MwABXHRyEAqo6P6ACVx0dUgOrjIxqAx39FAfD4f1EALpfL6vjodruVGB9RAO73+/wsX+t6vbbn89kqRAHAsxvfYtYQ8Oo4n88lEGg/hI3wivprqBEC/CFWHSHEWxGVEcK8GVcVIdTb0RURwh2QqYYQ8pBkJYSwB+WrIIQ+LaUCQvgTs9QRUpyaqIyQ5uRcVYRUp6crIqS7QEMNIeUlSkoIaS/SU0FIfZmqAkL6C7WzI0jcqiAzgszNOrIiSN2u5hPCdrv993QYRnI3bFpD2O/389fxGCnJW5a9I0QdH0kCoAVheYw4Ptr4n3lyk30FZMkA5AxAzgDkDEDOAOQMQM4A5AxAzgDkDEDOAOQMQM4A5AxAzgDkDEDOAOR+AJ476FpeErgRAAAAAElFTkSuQmCC);
  background-size: cover;
  background-repeat: no-repeat;
}
.articial_box___3IFbr .change_contents___3Vq88 .buttonright___1hzxi {
  position: absolute;
  top: 120px;
  right: 12%;
  display: block;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  z-index: 1;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAImSURBVHgB7dyxbQJREIThPVdAJ1RBSkYIESUQQgQdEEJGB5BRBpWQEmGtJUvINk5nZ3b+yBKO5hMC7p5ueDwez3CwPsJBMwA4A4AzADgDgDMAOAOAMwA4A4AzADgDgDMAOAOAMwA4A4AzADgDgDMAOAOAKw1wPp9DvbIA2+02ZrNZLJfLUK4kQI6/2+2+/j6dTtII5QBex/9OGWGodC5ov9/HarV6+/p8Po/j8RhKlXoH5MDj8fjt64rvhFIAo9EortdrK4RynwHdEEp+C+qEUPZ3QBeE0r+EOyCUvxakjkBxMU4ZgeZqqCoC1eVoRQS6+wFqCJQ3ZJQQaO+IqSBQ35JUQKC/J8yOIHFTnhlB5lQEK4LUsRRGBLlzQWwIkgezmBBkT8axIEgfTWRAkD8bWh2hzeHc5/P/40/DMAQieYD7/R6TySRut9vb/1ksFnE4HAKRNED18TNZAIbxM0kAlvEzOQCm8TMpALbxMxkAxvEzCQDW8TN6AObxM2oA9vEzWgCF8TNKAJXxMzoApfEzKgC18TMaAMXxMwoA1fGz8gDK42elAdTHz8oCdBg/KwnQZfysHECn8bNSAN3Gz0oBXC6XVuNn5R5Xs9ls/nxNcfys3GfAer3+haA6flbyW9ArgvL4WalHlv0sH1s5nU5DudIAHWpzOLdqBgBnAHAGAGcAcAYAZwBwBgBnAHAGAGcAcAYAZwBwBgBnAHAGAGcAcAYAZwBwnwMXlerm3jCSAAAAAElFTkSuQmCC);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 1700px) {
  .articial_box___3IFbr .change_contents___3Vq88 .buttonleft___2gNQd {
    left: 20%;
  }
  .articial_box___3IFbr .change_contents___3Vq88 .buttonright___1hzxi {
    right: 20%;
  }
}
