.background___2zpGY .banner___2rG9r {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.background___2zpGY .content_main___2pmEw {
  margin-top: 20px;
}
.background___2zpGY h1 {
  font-size: 46px;
}
.background___2zpGY h2 {
  margin: 20px;
  margin-top: 30px;
}
.background___2zpGY .home_img_1___1gxAp {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.background___2zpGY .tab_box___1MPaa {
  background: #5187d5;
  margin-bottom: 20px;
}
.background___2zpGY .tab_box___1MPaa ul {
  height: 60px;
  line-height: 60px;
}
.background___2zpGY .tab_box___1MPaa ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.background___2zpGY .tab_box___1MPaa ul > li:last-child {
  width: 15%;
}
.background___2zpGY .tab_box___1MPaa ul .isActived___3jB1Z {
  background: #1a3a68;
  font-weight: bold;
}
.background___2zpGY .change_contents___F29YM {
  padding: 20px 80px;
}
.background___2zpGY .change_contents___F29YM .home_img_1___1gxAp {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.background___2zpGY .change_contents___F29YM .like_a___3fY70 {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2;
}
.background___2zpGY .change_contents___F29YM a {
  color: #303de2;
}
.background___2zpGY .change_contents___F29YM .like_a___3fY70:hover {
  text-decoration: underline;
}
.background___2zpGY .change_contents___F29YM .home_connect___SuUrb img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.background___2zpGY .change_contents___F29YM .home_connect___SuUrb img:nth-of-type(3) {
  width: 220px;
  height: 70px;
}
.background___2zpGY .home_connect___SuUrb b {
  margin-right: 8px;
}
.background___2zpGY .home_connect___SuUrb p {
  line-height: 35px;
}
.background___2zpGY .home_connect___SuUrb img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.background___2zpGY .home_connect___SuUrb img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.background___2zpGY .home_connect___SuUrb .img_box___1DMxa {
  margin: 0 auto;
  width: max-content;
}
.background___2zpGY .body_text___YXok2 {
  line-height: 35px;
}
.background___2zpGY .body_text_in___1Z9dj {
  line-height: 35px;
  text-indent: 32px;
}
