.award___1CjMR .banner___295vm {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.award___1CjMR .content_main___3oxYo {
  margin-top: 20px;
}
.award___1CjMR h1 {
  font-size: 46px;
}
.award___1CjMR h2 {
  margin: 20px;
  margin-top: 30px;
}
.award___1CjMR .home_img_1___OSmQc {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.award___1CjMR .tab_box___11Zb6 {
  background: #5187d5;
  margin-bottom: 20px;
}
.award___1CjMR .tab_box___11Zb6 ul {
  height: 60px;
  line-height: 60px;
}
.award___1CjMR .tab_box___11Zb6 ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.award___1CjMR .tab_box___11Zb6 ul > li:last-child {
  width: 15%;
}
.award___1CjMR .tab_box___11Zb6 ul .isActived___oUmEV {
  background: #1a3a68;
  font-weight: bold;
}
.award___1CjMR .change_contents___3bhRt {
  padding: 20px 80px;
}
.award___1CjMR .change_contents___3bhRt .home_img_1___OSmQc {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.award___1CjMR .change_contents___3bhRt .like_a___1QLrS {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2;
}
.award___1CjMR .change_contents___3bhRt a {
  color: #303de2;
}
.award___1CjMR .change_contents___3bhRt .like_a___1QLrS:hover {
  text-decoration: underline;
}
.award___1CjMR .change_contents___3bhRt .home_connect___YG_CM img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.award___1CjMR .change_contents___3bhRt .home_connect___YG_CM img:nth-of-type(3) {
  width: 220px;
  height: 70px;
}
.award___1CjMR .home_connect___YG_CM b {
  margin-right: 8px;
}
.award___1CjMR .home_connect___YG_CM p {
  line-height: 35px;
}
.award___1CjMR .home_connect___YG_CM img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.award___1CjMR .home_connect___YG_CM img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.award___1CjMR .home_connect___YG_CM .img_box___3M-jO {
  margin: 0 auto;
  width: max-content;
}
.award___1CjMR .home_connect1___1krkZ img {
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.award___1CjMR .home_connect1___1krkZ img:nth-of-type(1) {
  width: 200px;
  height: 200px;
}
.award___1CjMR .body_text___1koex {
  line-height: 35px;
}
.award___1CjMR .body_text_in___3BeCQ {
  line-height: 35px;
  text-indent: 32px;
}
