.basic-row-list-area___2ZhR- {
  display: flex;
}
.basic-row-list-area___2ZhR-.basic-row-list-area-horizontal___1QTaF {
  flex-direction: row;
  align-items: center;
}
.basic-row-list-area___2ZhR-.basic-row-list-area-horizontal___1QTaF .basic-row-item___3xyVc {
  margin-right: 30px;
}
.basic-row-list-area___2ZhR-.basic-row-list-area-vertical___17gK9 {
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc {
  display: flex;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc .basic-row-item-title-area___3k-aw .basic-row-item-icon___xSKWd {
  border-radius: 50%;
  background-color: gray;
  color: gray;
  margin-right: 4px;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc .basic-row-item-title-area___3k-aw .basic-row-item-label___IA3Zh {
  font-size: 14px;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc .basic-row-item-value-area___3I_ox {
  flex: 1;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc .basic-row-item-value-area___3I_ox .row-child-item-value___1IYlv {
  font-size: 14px;
  flex-wrap: wrap;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc .basic-row-item-value-area___3I_ox .row-child-item-value___1IYlv.row-child-item-array___3s2oM {
  margin-right: 4px;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc.basic-row-item-act___2HuFC.item-status-success___3gej9 .basic-row-item-icon___xSKWd {
  background-color: #fff;
  color: green;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc.basic-row-item-act___2HuFC.item-status-success___3gej9 .row-child-item-value___1IYlv.row-child-item-value-act___1fuR6 {
  color: green;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc.basic-row-item-act___2HuFC.item-status-error___1Zx36 .basic-row-item-icon___xSKWd {
  background-color: #fff;
  color: red;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc.basic-row-item-act___2HuFC.item-status-error___1Zx36 .basic-row-item-label___IA3Zh {
  color: red;
}
.basic-row-list-area___2ZhR- .basic-row-item___3xyVc.basic-row-item-act___2HuFC.item-status-error___1Zx36 .row-child-item-value___1IYlv.row-child-item-value-act___1fuR6 {
  color: red;
}
