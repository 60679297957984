.container___ZXZUh {
  padding-top: 75px;
  height: 640px;
}
.container___ZXZUh .top___DFScn img {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 90px;
}
.container___ZXZUh .middle___2WzDk {
  margin-top: 20px;
  text-align: center;
}
.container___ZXZUh .bottomButton___3EZzp .button___Xzhv3 {
  width: 160px;
  height: 38px;
  border: none;
  border-radius: 2px;
  background: #5187d5;
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  color: #fff;
  font-size: 14px;
}
