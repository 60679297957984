.functionBox___1q15d {
  width: 1200px;
  padding: 0px 108px;
}
.functionBox___1q15d .title___p2UL7 {
  color: #5187d5;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
}
.functionBox___1q15d .title___p2UL7 .bottomLine___gFGjg {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px 0 0 15px;
}
.functionBox___1q15d .audioCustomCollapse___-ULXk {
  margin-top: 24px;
}
.functionBox___1q15d .audioCustomCollapse___-ULXk .audioCustomPanel___2M3B2 {
  border-radius: 3px;
  margin-bottom: 14px;
  border-bottom: none;
}
.functionBox___1q15d .audioCustomCollapse___-ULXk .audioCustomPanelActive___2f-Ba {
  background: #bcd8ff;
}
.functionBox___1q15d .audioCustomCollapse___-ULXk .audioTranstionResponse___iFV5y {
  font-size: 16px;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.functionBox___1q15d .audioTips___oQzRH {
  margin: 40px 0 37px;
  font-size: 16px;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.functionBox___1q15d .audioTips___oQzRH .audioTipsHighlight___3vERJ {
  color: #5187d5;
}
.functionBox___1q15d .fileUploadBtn___2DCLx {
  text-align: center;
  width: 149px;
  height: 36px;
  background: #5187d5;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  cursor: pointer;
}
.functionBox___1q15d .fileTypeTips___2mgCd {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px;
  margin-left: 10px;
}
