.evalutionContainer___8uuaz {
  margin-bottom: 40px;
}
.evalutionContainer___8uuaz .banner___3U4CX {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.evalutionContainer___8uuaz .content_main___3WvOr {
  margin-top: 20px;
}
.evalutionContainer___8uuaz h1 {
  font-size: 46px;
}
.evalutionContainer___8uuaz h2 {
  margin: 20px;
  margin-top: 30px;
}
.evalutionContainer___8uuaz .home_img_1___3nibT {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.evalutionContainer___8uuaz .tab_box___1JaTm {
  background: #5187d5;
  margin-bottom: 20px;
}
.evalutionContainer___8uuaz .tab_box___1JaTm ul {
  height: 60px;
  line-height: 60px;
}
.evalutionContainer___8uuaz .tab_box___1JaTm ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.evalutionContainer___8uuaz .tab_box___1JaTm ul > li:last-child {
  width: 15%;
}
.evalutionContainer___8uuaz .tab_box___1JaTm ul .isActived___3OsTN {
  background: #1a3a68;
  font-weight: bold;
}
.evalutionContainer___8uuaz .change_contents___yv73I {
  padding: 20px 80px;
}
.evalutionContainer___8uuaz .change_contents___yv73I .home_img_1___3nibT {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.evalutionContainer___8uuaz .change_contents___yv73I .like_a___2sJZQ {
  cursor: pointer;
  padding: 0 3px;
  color: #303de2;
}
.evalutionContainer___8uuaz .change_contents___yv73I a {
  color: #303de2;
}
.evalutionContainer___8uuaz .change_contents___yv73I .like_a___2sJZQ:hover {
  text-decoration: underline;
}
.evalutionContainer___8uuaz .change_contents___yv73I .home_connect___3Io1y img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.evalutionContainer___8uuaz .change_contents___yv73I .home_connect___3Io1y img:nth-of-type(3) {
  width: 220px;
  height: 70px;
}
.evalutionContainer___8uuaz .home_connect___3Io1y b {
  margin-right: 8px;
}
.evalutionContainer___8uuaz .home_connect___3Io1y p {
  line-height: 35px;
}
.evalutionContainer___8uuaz .home_connect___3Io1y img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
  margin: 40px;
}
.evalutionContainer___8uuaz .home_connect___3Io1y img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.evalutionContainer___8uuaz .home_connect___3Io1y .img_box___sBApG {
  margin: 0 auto;
  width: max-content;
}
.evalutionContainer___8uuaz table {
  margin: 20px auto;
  width: 1000px;
}
.evalutionContainer___8uuaz table thead {
  background: gray;
  color: #fff;
  font-weight: 600;
}
.evalutionContainer___8uuaz table td {
  padding: 20px 30px;
  text-align: center;
}
.evalutionContainer___8uuaz table tbody tr {
  border-bottom: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: #eeeeee;
}
.evalutionContainer___8uuaz table tbody tr:nth-child(2n + 1) {
  background-color: rgba(220, 220, 220, 0.25);
}
.evalutionContainer___8uuaz .body_text___1wDhf {
  line-height: 35px;
}
.evalutionContainer___8uuaz .latexClassN___1fCwB .katex___25M-8 .base___1hpl8 {
  font-size: 20px;
}
