.banner___3iCgu {
  position: relative;
  margin-top: -1px;
}
.banner___3iCgu .bannnerImage___3F1K8 {
  width: 100%;
  display: block;
}
.banner___3iCgu .bannerContent___2UZZO {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 185px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.banner___3iCgu .bannerContent___2UZZO .bannerContentInner___3AgMR {
  height: 104px;
}
.banner___3iCgu .bannerContent___2UZZO .bannerContentInner___3AgMR p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.containerOut___18T1o {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}
.containerOut___18T1o .containerIn___1S_06 {
  width: 1030px;
  text-align: center;
  padding-top: 50px;
}
.containerOut___18T1o .containerIn___1S_06 .title___2dkv4 {
  height: 33px;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 20px;
}
.containerOut___18T1o .containerIn___1S_06 .desc___1mMKd {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
}
.containerOut___18T1o .containerIn___1S_06 .image___4VwAA {
  width: 714px;
  height: 472px;
  margin-bottom: 22px;
}
.containerOut___18T1o .containerIn___1S_06 .imageAlt___1k09d {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 29px;
}
.containerOut___18T1o .containerIn___1S_06 .content___1vjTX {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.containerOut___18T1o .containerIn___1S_06 .content___1vjTX span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.containerOut___18T1o .containerIn___1S_06 p,
.containerOut___18T1o .containerIn___1S_06 span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
