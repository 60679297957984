.container____iYEg {
  width: 100%;
}
.container____iYEg .FooterTop___2-JFN {
  height: 250px;
  background-color: #171717;
  display: flex;
  justify-content: center;
}
.container____iYEg .FooterTop___2-JFN .left___2A2AD {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container____iYEg .FooterTop___2-JFN .left___2A2AD .logo___2MGAZ {
  height: 55px;
  margin-right: 140px;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE {
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item1___3dm6X {
  width: 170px;
  height: 100%;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item1___3dm6X .title___2lA9R {
  margin-top: 60px;
  margin-bottom: 25px;
  color: #fff;
  font-size: 18px;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item1___3dm6X .ai_block1___3cl4w {
  margin-bottom: 20px;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item1___3dm6X .ai_block1___3cl4w .ai_item___3-w-c {
  font-size: 14px;
  color: #dadcdf;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item1___3dm6X .ai_item___3-w-c:hover {
  color: #5187d5;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz {
  width: 170px;
  height: 100%;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .title___2lA9R {
  margin-top: 60px;
  margin-bottom: 25px;
  color: #fff;
  font-size: 18px;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .ai_block___2cJc_ {
  margin-bottom: 20px;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .ai_block___2cJc_ .ai_item___3-w-c,
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .ai_block___2cJc_ .ai_item1___2Yrju {
  font-size: 14px;
  color: #dadcdf;
}
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .ai_item___3-w-c:hover,
.container____iYEg .FooterTop___2-JFN .middle___uU3OE .item2___26KWz .ai_item1___2Yrju:hover {
  color: #5187d5;
}
.container____iYEg .FooterTop___2-JFN .right___A1ADG {
  width: 252px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container____iYEg .FooterTop___2-JFN .right___A1ADG .qrCodeBox___MIkSA .qrCode___2Qbft {
  display: flex;
  height: 114px;
  padding-top: 10px;
}
.container____iYEg .FooterTop___2-JFN .right___A1ADG .qrCodeBox___MIkSA .aiExperience___2G6K6 {
  font-size: 14px;
  display: block;
  color: #fff;
  margin-top: 12px;
  text-align: center;
}
.container____iYEg .FooterBottom___VyHdR {
  height: 80px;
  background-color: #1f1f1f;
}
.container____iYEg .FooterBottom___VyHdR .bottom___3hZLM {
  font-size: 14px;
  height: 80px;
  color: #e0e1e4;
  display: flex;
  justify-content: center;
  align-items: center;
}
