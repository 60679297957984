.registerbox___1Fwhu .links___1D3II {
  position: absolute;
  padding: 0px 4px;
  text-align: right;
  top: -37px;
  right: 0px;
  font-size: 14px;
}
.registerbox___1Fwhu .links___1D3II .register-text___rT4we {
  color: #6d6d6d;
}
.registerbox___1Fwhu .links___1D3II .login-link___2XwAy {
  color: #5187d5;
}
.registerbox___1Fwhu .ant-tabs-nav-container {
  position: absolute;
  left: -180px;
  margin-top: -10px;
}
.registerbox___1Fwhu .ant-tabs {
  overflow: unset;
}
.registerbox___1Fwhu .ant-tabs-bar {
  border: none;
}
.registerbox___1Fwhu .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 0px;
  margin: 10px 0px 0px 30px;
  padding-bottom: 10px;
}
