.no-result-data-area___2R8m2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-result-data-area___2R8m2 .no-result-data-img___8eiil {
  width: 234px;
}
.no-result-data-area___2R8m2 .no-result-data-text___379v6 {
  margin-top: 44px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #333333;
}
