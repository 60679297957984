.result-list-area___3PQLg {
  width: 1348px;
  height: 800px;
  margin: 0 auto 0 auto;
  transform: translateY(100px);
  position: relative;
  background: #fafafa;
  border: 1px solid #e8ebee;
  box-shadow: 0px 6px 20px #e7e7e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 50px;
  user-select: none;
}
.result-list-area___3PQLg .content-area___wMgSr {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.result-list-area___3PQLg .content-area___wMgSr .title-area___3t6Lj {
  background: #f1f3fc;
  box-shadow: inset 0px -1px 0px #e8ebee;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  height: 60px;
  color: #333333;
  text-align: center;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ {
  height: 536px;
  overflow: auto;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  padding: 0 54px;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp:hover {
  cursor: pointer;
  border: 1px solid #7875f5;
  box-shadow: inset 0px -1px 0px #e8ebee;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp.even___Mgwi- {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e8ebee;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp.odd___2ZVVo {
  background: #fcfbfe;
  box-shadow: inset 0px -1px 0px #e8ebee;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp .create-time___34Kg1 {
  display: flex;
  align-items: center;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp .create-time___34Kg1 .create-time-text___3Zzyp {
  min-width: 170px;
  text-align: center;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-item___eVLcp .create-time___34Kg1 .result-item-arrow___39ggW {
  font-size: 12px;
  margin-left: 10px;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-loading-area___2ArDW {
  margin-top: 20px;
  text-align: center;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .list-loading-area___2ArDW .ant-spin {
  color: #ccc;
}
.result-list-area___3PQLg .content-area___wMgSr .list-area___2AXsJ .no-more-data-area___2yGYy {
  margin: 20px 0;
  text-align: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #aaaaaa;
}
