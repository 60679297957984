.study_box___SfcNc {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.study_box___SfcNc .contrast___2CD_M {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  font-size: 16px;
}
.study_box___SfcNc .study_banner___3thyL {
  display: block;
  margin: 50px auto;
  width: 500px;
  height: 500px;
}
.study_box___SfcNc .content_main___3lJLU {
  margin-top: 0px;
}
.study_box___SfcNc b {
  font-weight: bold;
}
.study_box___SfcNc h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.study_box___SfcNc h2 {
  font-size: 42px;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.study_box___SfcNc .change_contents___clqMf {
  padding: 0px 80px 20px 80px;
}
.study_box___SfcNc .change_contents___clqMf .study_project___1A87N {
  display: block;
  margin: 20px auto;
  width: 700px;
}
.study_box___SfcNc .change_contents___clqMf span {
  font-weight: bold;
  color: black;
}
.study_box___SfcNc .change_contents___clqMf li {
  line-height: 35px;
  margin-top: 20px;
  text-indent: 32px;
}
.study_box___SfcNc .change_contents___clqMf .like_a___PaWTq {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.study_box___SfcNc .change_contents___clqMf a {
  color: #303de2;
}
