.canvasDiv___2pBe2 {
  height: 1px;
}
.canvasDiv___2pBe2 .canvas___2x_hX {
  position: relative;
  bottom: 68px;
}
.buttonOuter___nlsG7 {
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -136px;
  left: 440px;
  cursor: pointer;
  border-radius: 50%;
}
.buttonOuter___nlsG7 .buttonInnerOne___1uwYC {
  width: 89px;
  height: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___nlsG7 .buttonInnerOne___1uwYC .buttonInnerTwo___1FBrJ {
  width: 76px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___nlsG7 .buttonInnerOne___1uwYC .buttonInnerTwo___1FBrJ .buttonInnerThree____bkA5 {
  width: 65px;
  height: 65px;
  background: linear-gradient(180deg, #5187d5 0%, #356cbc 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.buttonOuter___nlsG7 .buttonInnerOne___1uwYC .buttonInnerTwo___1FBrJ .buttonInnerThree____bkA5 .buttonInner___15c0S {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 2px;
}
.audioDuration___2e75v {
  position: relative;
  bottom: -134px;
}
.buttonActive___1kfaY {
  position: relative;
  top: -160px;
}
