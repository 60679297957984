.container___2Sszc .bannerBox___1K5uW {
  height: 500px;
  position: relative;
}
.container___2Sszc .bannerBox___1K5uW .left___32ZWE,
.container___2Sszc .bannerBox___1K5uW .right___3Gmyj {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
  cursor: pointer;
  display: block;
}
.container___2Sszc .bannerBox___1K5uW .right___3Gmyj {
  right: 100px;
}
.container___2Sszc .bannerBox___1K5uW .left___32ZWE {
  left: 100px;
}
.container___2Sszc .bannerBox___1K5uW .mathgpt___1H-Wm {
  width: 320px;
  height: 71px;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem0___Y7Ly0 {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/mathgpt.png);
  background-size: cover;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem1___2IjOY {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/banner-1.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582554479&Signature=%2Fkl92q7U%2B%2BsMhWN2CFS4P%2BA%2BDVo%3D);
}
.container___2Sszc .bannerBox___1K5uW .bannerItem2___1QUYM {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/banner-2.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183393569&Signature=59EHjA7cKaKNXWwpcHOgVo5r0Lo%3D);
}
.container___2Sszc .bannerBox___1K5uW .bannerItem3___azyzb {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/banner-3.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183393902&Signature=TlRi%2FLgVTRKU26vvULoEZvc6q7E%3D);
}
.container___2Sszc .bannerBox___1K5uW .bannerItem4___3epsk {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/banner-4.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=4739486331&Signature=i%2B2pvkSFtaSOsUHdggTvMTM8TbQ%3D);
  background-position: left 50% top -30px !important;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem5___3wZKo {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/assets/images/hkwbn3.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5211643557&Signature=gglAO%2F4mvDUmaeSDaqLg1v4Goh4%3D);
  background-size: cover;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv {
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .empty___1dVQ- {
  margin-top: 280px;
  font-size: 38px;
  color: #f3f6fa;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .title___2TiIj {
  text-align: center;
  margin-top: 220px;
  font-size: 38px;
  color: #f3f6fa;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .title___2TiIj.mathContainer___7fhrV {
  display: flex;
  justify-content: center;
  margin-top: 174px;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .description___1owxi {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #f3f6fa;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .description___1owxi.mathdes___19NYW {
  font-family: PingFang SC;
  font-size: 26px;
  font-weight: 400;
  margin-top: 50px;
  line-height: 24px;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .techTitle___1LzMh,
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .techDescription___1b9yl span {
  font-family: PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 34px;
  font-weight: 500;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .techTitle___1LzMh {
  margin-top: 168px;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .btnToDetail___20xAd {
  text-align: center;
  width: 140px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  margin-top: 40px;
  background: #ffffff;
  border-radius: 2px;
  color: #5187d5;
  font-size: 17px;
  cursor: pointer;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .btnToDetail___20xAd.linkBtn___2MLGx {
  margin-top: 43px;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .detailLink___3-LfY {
  color: #5187d5;
}
.container___2Sszc .bannerBox___1K5uW .bannerItem___2Oxxv .btnToDetail___20xAd:hover {
  background: #d8e1ed;
  transition: 0.4s;
}
.container___2Sszc .solutionsBox___2UMv1 {
  padding: 42px 0 40px;
  background: #f6f6f6;
}
.container___2Sszc .solutionsBox___2UMv1 .title___2TiIj {
  color: #042044;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii {
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin: 0 auto;
  color: #042044;
  font-size: 18px;
  padding-top: 54px;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii .solutionType___IB1NU {
  cursor: pointer;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii .solutionType___IB1NU.active___3T03J,
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii .solutionType___IB1NU:hover {
  color: #5187d5;
  font-weight: 400;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii .solutionType___IB1NU.active___3T03J .bottomLine___3jlz3,
.container___2Sszc .solutionsBox___2UMv1 .solutionTypesBox___rJ5Ii .solutionType___IB1NU:hover .bottomLine___3jlz3 {
  display: block;
  height: 2px;
  width: 50px;
  background: #5187d5;
  margin: 12px auto 0;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt {
  width: 85% !important;
  display: block !important;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 20px;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU {
  display: flex;
  justify-content: center;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .sandglass___1OVqO {
  display: block;
  margin: 0 auto;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .text___1ZXl1 {
  margin-top: 20px;
  color: #8d8d8e;
  font-size: 14px;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ {
  cursor: pointer;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 {
  width: 400px;
  height: 320px;
  margin: 0 30px;
  background: #ffffff;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner1___1BExM,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner1___1BExM,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner2___1vb4a,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner2___1vb4a,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner3___INu55,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner3___INu55,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner4___3KmEh,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner4___3KmEh,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner5___2UQbc,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner5___2UQbc,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner6___3r_3n,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner6___3r_3n,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner7___2wbs4,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner7___2wbs4,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner8___1n154,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner8___1n154,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner9___lzinZ,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner9___lzinZ,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner10___3hrw6,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner10___3hrw6,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner11___vfZ-H,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner11___vfZ-H,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner12___bgwKX,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner12___bgwKX,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner13___1nT6g,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner13___1nT6g {
  height: 60%;
  position: relative;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner1___1BExM .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner1___1BExM .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner2___1vb4a .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner2___1vb4a .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner3___INu55 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner3___INu55 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner4___3KmEh .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner4___3KmEh .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner5___2UQbc .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner5___2UQbc .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner6___3r_3n .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner6___3r_3n .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner7___2wbs4 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner7___2wbs4 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner8___1n154 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner8___1n154 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner9___lzinZ .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner9___lzinZ .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner10___3hrw6 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner10___3hrw6 .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner11___vfZ-H .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner11___vfZ-H .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner12___bgwKX .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner12___bgwKX .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner13___1nT6g .title___2TiIj,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner13___1nT6g .title___2TiIj {
  text-align: center;
  background: rgba(0, 0, 0, 0.69);
  color: #ffffff;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner1___1BExM,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner1___1BExM {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/shijuancailu.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183405928&Signature=422Y3bRvlA%2BVTHhwsKYtJ%2BP6V%2BU%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner2___1vb4a,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner2___1vb4a {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/jiaoshimoke.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183405992&Signature=2Ct8wgiECIJ4Z%2FKGo4mWkDHWfHQ%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner3___INu55,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner3___INu55 {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/jiaoxuezhiliang.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406049&Signature=0YbjCm78SjJy0jblDqQdfyCOgI8%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner4___3KmEh,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner4___3KmEh {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/ketangyichang.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406107&Signature=7XNPrI1yz5HbxzM3LfvGXB0nf6c%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner5___2UQbc,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner5___2UQbc {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/hudongzhiboke.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406168&Signature=jTiovv2I1InX4iiziMMiRQcjIVY%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner6___3r_3n,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner6___3r_3n {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/zhongwenkouyubiaoda.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406670&Signature=9QMMHX%2Fvhrmxwl1dd0A7KJDAvWQ%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner7___2wbs4,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner7___2wbs4 {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/kousuan.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406729&Signature=EQWvBs8ZiM0ct3EdTDPRilDhGBY%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner8___1n154,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner8___1n154 {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/kouyu.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406782&Signature=HrgcXlPzRxEwaNXuYdPG2Xqd2jU%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner9___lzinZ,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner9___lzinZ {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/fayin.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183406831&Signature=0h3FbqNQC8M1MhYVKRwnG2IuTxQ%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner10___3hrw6,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner10___3hrw6 {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/ertongshige.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183404497&Signature=5qvgqRGd%2Fa5pTganbMUu8sdFhjg%3D);
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner11___vfZ-H,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner11___vfZ-H {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/assets/images/hhh1.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5211307037&Signature=wFJn1wiQxEtX3UIhyytVto57cnQ%3D);
  background-size: 100%;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner12___bgwKX,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner12___bgwKX {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/neirongshenhe.png);
  background-size: 100%;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .banner13___1nT6g,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .banner13___1nT6g {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/technical-capacity/banner/printing-character-recognition.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=4742430926&Signature=9zIw41GNbUKNdcKP6AlP3FvnrDw%3D);
  background-size: 100% 100%;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .description___1owxi,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .description___1owxi {
  padding-top: 15px;
  margin: 0 10%;
  font-size: 14px;
  color: #363636;
  height: 100px;
}
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detail___3WyjZ .more___LQgJH,
.container___2Sszc .solutionsBox___2UMv1 .solutionDetailCon___2BPlt .solutionDetailBox___AgLrU .detailMore___z1EP5 .more___LQgJH {
  text-align: center;
  font-size: 12px;
  color: #363636;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container___2Sszc .solutionsBox___2UMv1 .detail___3WyjZ:hover {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.23);
  transition: 0.4s;
}
.container___2Sszc .solutionsBox___2UMv1 .detail___3WyjZ:hover .more___LQgJH .text___1ZXl1 {
  color: #5187d5;
}
.container___2Sszc .solutionsBox___2UMv1 .moreSolutionsBtn___39ACg:hover {
  background: #3b6eb7;
  transition: 0.4s;
}
.container___2Sszc .solutionsBox___2UMv1 .moreSolutionsBtn___39ACg {
  width: 80px;
  height: 30px;
  line-height: 30px;
  background: #5187d5;
  border-radius: 18px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  margin: 10px auto 20px;
  transition: background 0.25s;
  left: 20px;
}
.container___2Sszc .capabilityBox___2Pzvx {
  padding: 59px 0 60px;
}
.container___2Sszc .capabilityBox___2Pzvx .title___2TiIj {
  color: #042044;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS {
  width: 80%;
  margin: 59px auto 0px;
  display: flex;
  justify-content: space-between;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP {
  width: 150px;
  margin-bottom: 60px;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .title___2TiIj {
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #042044;
  text-align: left;
  margin-bottom: 7px;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .titleUnderline___2veTO {
  width: 80%;
  height: 2px;
  background: #f9f7f9;
  margin-bottom: 12px;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .item___1_D5o {
  font-size: 14px;
  margin: 20px 0;
  font-weight: 400;
  color: #5187d5;
  cursor: pointer;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .item___1_D5o:hover {
  font-weight: 700;
  text-decoration: underline;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_ {
  width: 42px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #979797;
  text-align: center;
  color: #979797;
  cursor: pointer;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_ .arrow___G5kQH {
  display: block;
  height: 8px;
  width: 8px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #979797;
  border-top: none;
  border-left: none;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_ .arrow___G5kQH.up___3Ci8f {
  transform: translateY(6px) rotate(-135deg);
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_ .arrow___G5kQH.down___24SJI {
  transform: translateY(2px) rotate(45deg);
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_:hover {
  background: #5187d5;
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  transition: 0.4s;
}
.container___2Sszc .capabilityBox___2Pzvx .capabilityGroup___25lxS .type___3PCSP .moreItemsBtn___2AaG_:hover .arrow___G5kQH {
  border-color: #ffffff;
}
.container___2Sszc .strengthBox___31hpM {
  padding: 86px 0 42px;
  height: 360px;
  background: #5187d5;
}
.container___2Sszc .strengthBox___31hpM .title___2TiIj {
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 60px;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r .item___1_D5o {
  display: flex;
  align-items: center;
  width: 300px;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r .item___1_D5o .img___1merB {
  width: 65px;
  height: 66px;
  flex-grow: 0;
  flex-shrink: 0;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r .item___1_D5o .textBox___1FGIU {
  padding-left: 15px;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r .item___1_D5o .textBox___1FGIU .title___2TiIj {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: left;
}
.container___2Sszc .strengthBox___31hpM .itemsBox___1lW7r .item___1_D5o .textBox___1FGIU .desc___1UkUC {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.container___2Sszc .cooperationBox___344al {
  padding: 60px 0 80px;
  background: #f6f6f6;
  margin: 0 auto;
}
.container___2Sszc .cooperationBox___344al .title___2TiIj {
  color: #042044;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH {
  width: 1200px;
  margin: 0 auto;
  margin-top: 45px;
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox___1tipL .logos___gQmpn {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox___1tipL .logos___gQmpn .logo___2gDRo {
  width: 270px;
  height: 100px;
  background: #c1c9d4;
  margin: 13px 15px;
  flex-grow: 1;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/qingqing.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368769&Signature=Cm5IBEcCbhsNjFOX66Oe%2FySAEuo%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/deli.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368816&Signature=0ai2vWazUk3xnV7i%2Fp0cXxLyaQc%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xuekewang.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368873&Signature=rtiM9ofLlWH1QvnlAbwtO903yGo%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaoe.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368927&Signature=19ZmgivkhmVEhciKyaZgoz4gUBk%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaohei.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368968&Signature=0HsaS1C4S%2F9zY91ftj3V6g98flg%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/waijiao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369028&Signature=q1hbwUTj6hOGc8zDxHOBHOSMQG4%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/1dui1.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369070&Signature=r3jJv47swwmmgevJ7vn65onIEWo%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/aizhikang.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369153&Signature=GMmmV4A2YWxKAs2WA2xvTw3EWWE%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/wangxiao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369255&Signature=AGYI6sBnKJHQ4%2BOtEM1tTRJW6Xw%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/mofaxiao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369308&Signature=3fM2LOJwFFRt8KOhxzKXVzFLHwU%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaosouti.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369398&Signature=NlC50tHTMJ9EV5EuaCT%2F6SGOlIY%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox1___2uLiy .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/qukoucai.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369342&Signature=yhOpqeuDsdXU%2FirzeOxQceorDGc%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/zhicuo.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361592367762&Signature=GgboZP8jGsGkgNxUQJHWXrw8vA4%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/benyou.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192367917&Signature=4a16JckCsFmuf2%2BUFK%2Fo0FBh3Zo%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/qiushang.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192367984&Signature=f9YhK2F8wLho7rPfCUmBGGjmboI%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/qimeng.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368094&Signature=6%2B%2F8JD2dLP4gv%2FJpPGM6MDRUdVM%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaoying.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368167&Signature=igAqDexYVirkMpATcY5W%2FJzvIhY%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/peiyou.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582715543&Signature=ynI6q0Onv%2F9EF0XCIZktwvfp32g%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaohouyuwen.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582715603&Signature=RAXfP5Ax%2FsPEuzVKyZgrhiTarZE%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(2) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiaowei.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368359&Signature=CGM4YaP6dQsm%2BF51FuovgqbTYbQ%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/jiaoji.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192368441&Signature=eI%2FsMEYVtnZu3v%2Bdwrz4b2H7AoI%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/mobi.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582715644&Signature=wMm3x%2BRKd8ch6mqTaDB1AgJKcO0%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/guoji.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582716005&Signature=7X6b4nHoiuI3fawjnn%2F1Mgu0SO0%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox2___T1apA .logos___gQmpn:nth-child(3) .logo___2gDRo:nth-child(4) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/chuban.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582716042&Signature=tdfV20usgKq4zHQGSn5TVCRVONk%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox3___2xwBy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(1) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/xiongmao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369841&Signature=mEQHvI5UauytdjrxQ78Ha7Qy4CQ%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox3___2xwBy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(2) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/kaomanfen.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369816&Signature=Mr2ZqVwHVdojdRskwRR%2BWe7htwU%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox3___2xwBy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(3) {
  background-image: url(https://openplantform.oss-cn-beijing.aliyuncs.com/index/abcreading.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5192369782&Signature=62sdMJr9Bj2g5Sj0akRV%2FYQ%2F7g8%3D);
}
.container___2Sszc .cooperationBox___344al .carouselBox___1ijIH .logosBox3___2xwBy .logos___gQmpn:nth-child(1) .logo___2gDRo:nth-child(4) {
  opacity: 0;
}
.container___2Sszc .ai_taste___1s3Q- {
  height: 300px;
  background-color: #fff;
  box-sizing: border-box;
}
.container___2Sszc .ai_taste___1s3Q- .ai_title___A0vAf {
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: #042044;
  margin-top: 108px;
}
.container___2Sszc .ai_taste___1s3Q- .ai_smalltitle___Fmwp3 {
  display: flex;
  justify-content: center;
  color: #374d6a;
  padding-top: 32px;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ {
  display: inline-block;
  display: flex;
  justify-content: center;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button1___djoqn,
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button2___277S5 {
  display: block;
  width: 140px;
  height: 36px;
  border-radius: 2px;
  margin-top: 60px;
  font-size: 14px;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button1___djoqn {
  background-color: #5187d5;
  color: #fff;
  border: none;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button2___277S5 {
  background-color: #fff;
  border: 1px solid #042044;
  margin-left: 30px;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button1___djoqn:hover {
  background-color: #417acc;
  transition: 0.4s;
}
.container___2Sszc .ai_taste___1s3Q- .button___2RpfZ .button2___277S5:hover {
  border: 1px solid #5187d5;
  color: #5187d5;
  transition: 0.4s;
}
.container___2Sszc .check-valid___2NFPp {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
