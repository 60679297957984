.raceContainer___3ahGf .myTable___VWDFM {
  margin-bottom: 40px;
}
.raceContainer___3ahGf .home_img_1___Qv87e {
  width: 800px;
  display: block;
  margin: 20px auto;
}
.raceContainer___3ahGf .home_img_2___Q8wiD {
  width: 400px;
  display: block;
  margin: 20px auto;
}
.raceContainer___3ahGf .banner___3tOsQ {
  position: relative;
  margin-top: 20px;
}
.raceContainer___3ahGf .banner___3tOsQ .bannnerImage___1TSk5 {
  width: 100%;
  display: block;
}
.raceContainer___3ahGf .banner___3tOsQ .bannerContent___2lolb {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 38%;
  left: 0px;
  display: flex;
  justify-content: center;
}
.raceContainer___3ahGf .banner___3tOsQ .bannerContent___2lolb .bannerContentInner___2tce7 {
  height: 104px;
}
.raceContainer___3ahGf .banner___3tOsQ .bannerContent___2lolb .bannerContentInner___2tce7 p {
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.raceContainer___3ahGf .imgTitle___2Glle {
  margin: 0 auto;
  width: 1000px;
  text-align: center;
}
.raceContainer___3ahGf .imgTitle___2Glle word-wrap:break-word .desc___8REnW {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.raceContainer___3ahGf .containerOut___1Mw24 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T {
  width: 1030px;
  text-align: center;
  padding-top: 50px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .title___1y_Pq {
  height: 33px;
  font-size: 35px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 20px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .desc___8REnW {
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .image___3LeF2 {
  width: 714px;
  height: 472px;
  margin-bottom: 22px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .imageAlt___1MVzc {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 29px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .content___39_uo {
  width: 1030px;
  text-align: justify;
  text-indent: 30px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T .content___39_uo span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T p,
.raceContainer___3ahGf .containerOut___1Mw24 .containerIn___3nV3T span {
  color: #042044;
  font-family: PingFangSC-Regular, PingFangSC-Medium, 'PingFang SC', 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.raceContainer___3ahGf .imgFont___2BBsj {
  width: 700px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #b2b2b2;
  font-style: italic;
}
.raceContainer___3ahGf table {
  margin: 30px auto !important;
  width: 1000px;
}
.raceContainer___3ahGf table thead {
  background: rgba(220, 220, 220, 0.25);
  color: #1e1e1ed4;
  font-weight: 600;
}
.raceContainer___3ahGf table thead td {
  border: 1px solid;
  border-color: #eeeeee;
}
.raceContainer___3ahGf table td {
  padding: 15px 30px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.raceContainer___3ahGf table tbody tr td {
  border: solid 1px;
  border-color: #eeeeee;
}
