@font-face {
  font-family: 'AvenirMediumwoff';
  src: url('https://openplantform.oss-cn-beijing.aliyuncs.com/index/AIability/AvenirMediumwoff.ttf');
  font-style: normal;
  font-weight: 400;
}
.gpt-report-area___1YD0f {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
.gpt-report-area___1YD0f .report-desc-area___Azl2V .composition-title___1vehC {
  font-size: 42px;
}
.gpt-report-area___1YD0f .report-desc-area___Azl2V .author-area___2pcPA {
  margin: 10px 0;
  text-align: center;
}
.gpt-report-area___1YD0f .report-desc-area___Azl2V .author-area___2pcPA .write-num___1AMcv {
  font-size: 16px;
  margin-right: 10px;
}
.gpt-report-area___1YD0f .report-desc-area___Azl2V .author-area___2pcPA .write-grade___2skRT {
  font-size: 16px;
  margin-right: 10px;
}
.gpt-report-area___1YD0f .report-desc-area___Azl2V .author-area___2pcPA .write-time___16NIp {
  font-size: 16px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 {
  position: relative;
  user-select: none;
  width: 100%;
  height: 250px;
  padding-top: 35px;
  background: url(/_next/static/images/com6bResult-cb10b1e2d0cdf355c184d00e3ff0bfdb.png) no-repeat;
  background-size: cover;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 28px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww {
  display: flex;
  flex-direction: column;
  margin-top: 76px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-header-area___2x9Nb {
  display: flex;
  justify-content: space-between;
  padding-left: 82px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-header-area___2x9Nb .header-title___3SY1d {
  margin-right: 82px;
  display: flex;
  align-items: flex-end;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-content-area___3lILh {
  display: flex;
  padding: 0 82px;
  min-height: 226px;
  position: relative;
  justify-content: center;
  margin-top: 22px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-content-area___3lILh .comment-teacher-img___RQy6o {
  height: 255px;
  width: 330px;
  background-position: -120px -48px;
  background-repeat: no-repeat;
  background-size: 600px;
  position: absolute;
  bottom: 0px;
  left: 18px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-content-area___3lILh .comment-content___2UYok {
  width: 100%;
  min-height: 226px;
  overflow: hidden;
  padding: 38px 28px 38px 265px;
  background: linear-gradient(90deg, #d6d2fb 0%, #e7f1fc 76.56%);
  border-radius: 28px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-content-area___3lILh .comment-content___2UYok .comment-content-text___32rQ7 {
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #312dbb;
  text-align: justify;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-comments___3Koww .comment-content-area___3lILh .comment-content___2UYok .comment-content-text___32rQ7.comment-content-middle___1wOxY {
  margin: 20px 0;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb {
  margin-top: 40px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-title___2OLvr {
  padding-left: 82px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .chart-area___xV_Ju {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .chart-area___xV_Ju #main-chart___26ynN {
  width: 320px;
  height: 270px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .chart-area___xV_Ju #main-chart___26ynN > div:hover {
  cursor: default !important;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq {
  width: 1186px;
  margin: 20px auto 0 auto;
  min-height: 260px;
  border-radius: 23px;
  overflow: hidden;
  background: linear-gradient(180deg, #e7f1fc 0%, #d6d2fb 100%);
  padding: 8px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR {
  background-color: #fff;
  width: 100%;
  min-height: inherit;
  height: 100%;
  border-radius: 15px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk {
  padding: 44px 54px;
  position: relative;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf {
  display: flex;
  align-items: flex-start;
  margin-top: 14px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf:first-child {
  margin-top: 0;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf .dimension-tag-area___H7u5w {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  margin-right: 16px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf .dimension-tag-area___H7u5w .dimension-tag___2Glnl {
  border-radius: 10px;
  width: 6px;
  height: 18px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf .dimension-name___3bnoK {
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin-right: 16px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-dimension-area___oYRRb .evaluation-report-dimension-content___1WeZq .evaluation-report-dimension___31jOR .dimension-detail-area___3wSwk .dimension-item-area___2CeOf .dimension-score-text___HEPEw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 26px;
  background: rgba(255, 164, 199, 0.26);
  border-radius: 39px;
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #f874a6;
  margin-right: 16px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-area___20eb2 {
  margin-left: 88px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 88px 0 88px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-origin-text-area___3O1F1 {
  flex: 1;
  margin: 0;
  margin-right: 25px;
  padding: 0;
  background: linear-gradient(90deg, #d2d1da 0%, #e7f1fc 76.56%);
  border-radius: 27px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-origin-text-area___3O1F1 .evaluation-origin-text___3WHGJ {
  overflow-y: auto;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-origin-text-area___3O1F1 .origin-text-title___3xqNu {
  display: block;
  padding: 20px 42px 20px 42px;
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-size: 20px;
  font-weight: bold;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-origin-text-area___3O1F1 .origin-text___3fW2p {
  width: 100%;
  overflow-y: auto;
  padding: 0 42px 20px 42px;
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 174%;
  color: #333333;
  white-space: pre-wrap;
  word-break: break-all;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ {
  flex: 1;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi {
  padding: 20px 42px;
  background: linear-gradient(90deg, #d6d2fb 0%, #e7f1fc 76.56%);
  border-radius: 27px;
  overflow: auto;
  font-family: Microsoft YaHei, AvenirMediumwoff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 174%;
  color: #333333;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X {
  font-size: 16px;
  margin-top: 20px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X:first-child {
  margin-top: 0;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X .advice-title___3p2s3 {
  font-size: 20px;
  font-weight: bold;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X .desc-area___3mdpP {
  margin-top: 10px;
  font-size: 16px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X .desc-area___3mdpP .desc-score___23LKX {
  padding-left: 10px;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .evaluation-report-area___14WZp .advice-content-area___39GKG .evaluation-report-advice-area___3Z6q_ .evaluation-report-advice___1OpkD .advice-content___34sdi .advice-content-item___12p8X .advice-desc___2WQQi {
  white-space: pre-wrap;
  word-break: break-all;
}
.gpt-report-area___1YD0f .gpt-report-detail-area___2HNd6 .background-bottom-img___1JXcR {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 600px;
  z-index: -1;
}
.gpt-report-area___1YD0f .no-data-area___3GOPm {
  width: 100%;
  height: 400px;
  position: relative;
  background: #fafafa;
  border: 1px solid #e8ebee;
  box-shadow: 0px 6px 20px #e7e7e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 50px;
  user-select: none;
}
