.outer___2G28A {
  width: 100%;
  height: 290px;
  background: #5187d5;
}
.outer___2G28A .top___2K2t6 {
  width: 100%;
  height: 83px;
  padding-top: 50px;
  line-height: 33px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 59px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___2G28A .bottom___2WHTi {
  width: 100%;
  height: 148px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.outer___2G28A .bottom___2WHTi .bottomCon___3mBwF {
  width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 50px;
}
.outer___2G28A .bottom___2WHTi .bottomCon___3mBwF .inner___3LKv2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.outer___2G28A .bottom___2WHTi .bottomCon___3mBwF .inner___3LKv2 .image___2N2St {
  width: 66px !important;
  height: 66px;
  margin-right: 12px;
}
.outer___2G28A .bottom___2WHTi .bottomCon___3mBwF .inner___3LKv2 .content___12PuR .title___3O0Vt {
  width: 250px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 6px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.outer___2G28A .bottom___2WHTi .bottomCon___3mBwF .inner___3LKv2 .content___12PuR .desc___2w4TA {
  width: 250px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
