.dialog-assistant___2YYEu .ant-card {
  height: 382px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid rgba(83, 232, 196, 0.4);
  background-image: radial-gradient(circle at top left, #E8FFFA 0%, #FFFFFF 36%);
}
.dialog-assistant___2YYEu .ant-card:hover {
  border: 2px solid #53E8C4;
  color: #53E8C4;
}
.dialog-assistant___2YYEu .ant-divider {
  margin: 12px 0;
}
.dialog-assistant___2YYEu .ant-typography-expand {
  display: none;
}
.dialog-assistant___2YYEu .ant-card {
  cursor: pointer;
}
.assistantImg___Kz4aq {
  width: 100%;
  text-align: center;
  background: #F9F9F9;
  overflow: hidden;
}
.assistantImg___Kz4aq img {
  height: 100%;
}
